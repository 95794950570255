<template>
  <div class="content-wrapper original-orders">
    <!-- Main content -->
    <div class="row status-list-wrap" style="margin: -6px 0 0 0">
      <a-tabs v-model="searchData.labelPage" class="status-tab" @change="searchByStatus">
        <a-tab-pane v-for="item in tabList" :key="item.code">
          <template slot="tab">
            {{ item.name }}
            <a-popover v-if="item.tips">
              <template slot="content">
                {{ item.tips }}
              </template>
              <a-icon type="question-circle"></a-icon>
            </a-popover>
          </template>
        </a-tab-pane>
      </a-tabs>
    </div>
    <section class="content">
      <div class="tag-select">
        <div class="flex-ct tag-select-item mb10">
          <div class="label">{{ $t('异常') }}：</div>
          <a-tag
            v-for="(item, index) in abnormalList"
            :key="item.status"
            :color="searchData.abnormalType === item.status ? '#1890ff' : ''"
            @click="setAbnormalType(item.status)"
          >
            <a-badge v-if="index === 0" :count="errCount" :offset="[20, -6]">
              {{ item.statusName }}
            </a-badge>
            <span v-else>{{ item.statusName }}</span>
            <a-popover v-if="item.tips">
              <template slot="content">
                <p v-for="(elem, index2) in item.tips" :key="index2">{{ $t(elem) }}</p>
              </template>
              <a-icon class="ml2" type="question-circle"></a-icon>
            </a-popover>
          </a-tag>
        </div>
      </div>
      <div class="form-content" @keydown="enterPress">
        <a-row style="margin-bottom: 10px" :gutter="16">
          <a-col :span="12">
            <!-- 原始订单号 -->
            <a-input
              allowClear
              type="text"
              :placeholder="$t(`平台订单号/OMS订单号，多个用英文逗号或空格分隔`)"
              v-model.trim="userInputOrderIds"
              @change="omsSnTransferOriginalSn = []"
            >
              <a-select slot="addonBefore" v-model="selectType" style="width: 150px">
                <a-select-option value="orderNo">{{ $t('订单号') }}</a-select-option>
                <a-select-option value="WAYBILL_NO">{{ $t('快递单号') }}</a-select-option>
              </a-select>
            </a-input>
          </a-col>
          <a-col :span="6">
            <!-- 选择店铺 -->
            <template v-if="showShop">
              <SelectShop
                :placeholder="$t(`请选择`) + $t(`店铺`)"
                @selected="selectShopData"
              ></SelectShop>
            </template>
          </a-col>
          <!-- 按钮 -->
          <a-button v-if="priv('f_origin_order_list')" @click="getTableList(true)" type="primary">
            <i class="iconfont icon-search" style="margin-right: 5px"></i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <i class="iconfont icon-synchronize" style="margin-right: 5px"></i>
            {{ $t(`重置`) }}
          </a-button>
          <a-button type="primary" @click="exportOriginalOrder">
            <i class="iconfont icon-export" style="margin-right: 5px"></i>
            {{ $t(`导出`) }}
          </a-button>
        </a-row>

        <div id="search-more" :class="{ htAuto: showMore }" class="showMore ht0">
          <a-row style="margin-bottom: 10px" :gutter="16">
            <a-col :span="8">
              <!-- 时间 -->
              <DateRangePicker
                id="payTime"
                :needhms="true"
                @get_date="getPayTime"
                :placeholder="$t(`平台创建时间`)"
                :start-time="searchData.startPayTime"
                :end-time="searchData.endPayTime"
              />
            </a-col>
            <a-col :span="8">
              <!-- 时间 -->
              <DateRangePicker
                id="orderTime"
                :needhms="true"
                @get_date="getOrderTime"
                :placeholder="$t(`订单时间`)"
                :start-time="searchData.startOrderTime"
                :end-time="searchData.endOrderTime"
              />
            </a-col>
            <a-col :span="8">
              <!-- 时间 -->
              <DateRangePicker
                id="receiveTime"
                :needhms="true"
                @get_date="getReceiveTime"
                :placeholder="$t(`签收时间`)"
                :start-time="searchData.startReceiveTime"
                :end-time="searchData.endReceiveTime"
              />
            </a-col>
          </a-row>
          <a-row style="margin-bottom: 10px" :gutter="16">
            <a-col :span="8">
              <!-- 时间 -->
              <DateRangePicker
                id="receiveTime"
                :needhms="true"
                @get_date="getLatestDeliveryTime"
                :placeholder="$t(`最晚发货时间`)"
                :start-time="searchData.startLatestDeliveryTime"
                :end-time="searchData.endLatestDeliveryTime"
              />
            </a-col>
            <a-col :span="4">
              <!-- 商家编码 -->
              <a-input
                allowClear
                type="text"
                v-model.trim="searchData.skuId"
                :placeholder="$t(`商家编码`)"
                @change="changeSkuCode"
              />
            </a-col>
            <a-col :span="4">
              <!-- 商品名称 -->
              <SearchSku :placeholder="$t(`商品名称`)" @selected="selectedSku"></SearchSku>
            </a-col>
            <a-col :span="4">
              <!-- 收件人 -->
              <a-input
                allowClear
                type="text"
                :placeholder="$t(`收件人`)"
                v-model.trim="searchData.receiverName"
              />
            </a-col>
            <a-col :span="4">
              <!-- 手机号 -->
              <a-input
                allowClear
                type="text"
                :placeholder="$t(`手机号`)"
                v-model.trim="searchData.receiverPhone"
              />
            </a-col>
          </a-row>
          <!-- <input type="text" v-model.trim="searchData.skuName" class="form-control input-sm"/> -->
          <a-row :gutter="16">
            <a-col :span="4">
              <!-- 用户ID -->
              <a-input
                allowClear
                type="text"
                :placeholder="$t(`用户ID`)"
                v-model.trim="searchData.buyer"
              />
            </a-col>
            <a-col :span="4">
              <!-- 支付方式 -->
              <a-select
                allowClear
                v-model="searchData.payType"
                style="width: 100%"
                :placeholder="$t(`支付方式`)"
              >
                <a-select-option value="ONLINE">ONLINE</a-select-option>
                <a-select-option value="COD">COD</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="4">
              <!-- 支付状态 -->
              <a-select
                allowClear
                v-model="searchData.payStatus"
                style="width: 100%"
                :placeholder="$t(`支付状态`)"
              >
                <a-select-option value="PAID">{{ $t(`已付款`) }}</a-select-option>
                <a-select-option value="UNPAID">{{ $t(`待付款`) }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="4">
              <!-- 订单状态 -->
              <a-select
                allowClear
                v-model="searchData.preSaleType"
                style="width: 100%"
                :placeholder="$t(`订单状态`)"
              >
                <a-select-option value="PRE_SALE_NORMAL">{{ $t(`预售单`) }}</a-select-option>
                <a-select-option value="NORMAL">{{ $t(`正常单`) }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="8" v-if="isStore">
              <!-- 订单子类型 -->
              <div class="amout-range-input flex-ct">
                <SelectOrderSubType mode="multiple" style="width: 100%" @change="selectOrderTag" />
              </div>
            </a-col>
            <!-- 有无备注 -->
            <!-- <a-col :span="4">
              <a-select
                allowClear
                v-model="searchData.hasMemo"
                style="width: 100%"
                :placeholder="$t(`有无备注`)"
              >
                <a-select-option value="ALL_MEMO">{{ $t(`全部备注`) }}</a-select-option>
                <a-select-option value="BUYER_MEMO">
                  {{ $t(`买家备注`) }}
                </a-select-option>
                <a-select-option value="SELLER_MEMO">
                  {{ $t(`卖家备注`) }}
                </a-select-option>
              </a-select>
            </a-col> -->
            <!-- 备注内容 -->
            <!-- <a-col :span="4">
              <a-input
                allowClear
                type="text"
                :placeholder="$t(`备注内容`)"
                v-model.trim="searchData.memo"
              />
            </a-col> -->
            <!-- 快递方式搜索 -->
            <!-- <a-col :span="4">
              <search-shipping
                :placeholder="$t(`快递方式搜索`)"
                @selected="chooseShipping"
              ></search-shipping>
            </a-col> -->
          </a-row>
        </div>

        <div class="buttons">
          <div class="highSearchBar">
            <label v-show="showMore" class="showAndHide" @click="more">
              {{ $t(`收起`) }}
              <i class="fa fa-angle-up" style="margin-left: 5px"></i>
            </label>
            <label v-show="!showMore" class="showAndHide" @click="more">
              {{ $t(`展开`) }}
              <i class="fa fa-angle-down" style="margin-left: 5px"></i>
            </label>
          </div>
        </div>
      </div>
    </section>
    <div class="content" style="margin-top: 10px">
      <div class="table-content table-responsive">
        <div class="table-header flex-ct">
          <a-button type="primary" @click="jumpPage">
            <i class="iconfont icon-new mr5"></i>
            {{ $t(`订单录入`) }}
          </a-button>
          <a-button
            v-if="priv('f_sale_order_handing_send')"
            type="primary"
            @click="showWindow = true"
          >
            <i class="iconfont icon-new mr5"></i>
            {{ $t(`批量录单`) }}
          </a-button>
          <a-button type="primary" @click="syncOrderVisible = true">
            {{ $t(`同步订单`) }}
          </a-button>
          <a-button type="primary" @click="syncBatchOrderVisible = true">
            {{ $t(`批量拉单`) }}
          </a-button>
          <a-button type="primary" @click="clearData" v-if="isStoreMaster">
            {{ $t(`清空美宜佳订单数据`) }}
          </a-button>
          <a-button
            type="primary"
            @click="exportShipmentList"
            :loading="exportLoading"
            :disabled="selectedRowKeys.length === 0"
            v-if="isStore"
          >
            {{ $t(`打印发货清单`) }}
          </a-button>
          <a-button
            type="primary"
            @click="batchConvert"
            :loading="covertLoading"
            :disabled="selectedRowKeys.length === 0"
          >
            {{ $t(`已发货强制转化`) }}
          </a-button>
          <a-popover class="ml4" placement="topRight">
            <template slot="content">
              <p>
                {{
                  $t(
                    `强制转化：当订单状态为已发货时，系统不会自动转化订单，此时若需通过OMS进行发货，可使用强制转化功能。为避免重复发货，除上述情况，不建议使用该功能。`
                  )
                }}
              </p>
            </template>
            <a-icon class="text-main" type="question-circle" />
          </a-popover>

          <div class="flex-ct" style="margin-left: auto">
            <div style="cursor: pointer" v-if="searchResultText" @click="showOrderSnModal">
              <a-icon type="info-circle" class="text-warning" />
              {{ searchResultText }}
            </div>
            <div class="switch-content">
              <a-radio-group v-model="showType" @change="changeShowType">
                <a-radio-button value="plymouth">{{ $t('原始商品视图') }}</a-radio-button>
                <a-radio-button value="oms">{{ $t('OMS商品视图') }}</a-radio-button>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div class="table-body">
          <a-table
            :scroll="{ x: 1400, y: tableHeight }"
            :bordered="false"
            :columns="columns"
            :loading="tableLoading"
            :expandIconAsCell="false"
            :expandIconColumnIndex="-1"
            :dataSource="tableList"
            :pagination="showPage"
            :expandedRowKeys.sync="expandedRowKeys"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :rowKey="(record) => record.originOrder?.id"
          >
            <!-- 原始订单号 -->
            <template slot="platformOrderId" slot-scope="text, record">
              <span>
                <a-icon
                  type="minus-square"
                  class="square-icon"
                  @click="closeExpand(record)"
                  v-if="isShowExpand(record)"
                />
                <a-icon v-else type="plus-square" class="square-icon" @click="openExpand(record)" />
                <span class="ml4 fz15" style="font-weight: bold">
                  {{ record.originOrder.platformOrderId }}
                </span>
                <a @click="Clone(text, record)" class="ml2">
                  <a-icon class="text-main" type="copy" />
                </a>
                <!-- <a-tag
                  color="orange"
                  v-if="platformOrderStatus[record.originOrder.platformOrderStatus]"
                >
                  {{ platformOrderStatus[record.originOrder.platformOrderStatus] }}
                </a-tag> -->
                <a-tag color="red" v-if="record.originOrder.transferStatus == 'ERROR'">
                  {{ $t(record.originOrder.conversionErrorCode) }}
                </a-tag>
              </span>
              <br />
              <!-- 判断OMS订单是否存在 -->
              <template v-if="record.orderInfos?.length > 0 && showType === 'plymouth'">
                <div
                  v-for="(item, index) in record.orderInfos"
                  :key="index"
                  class="detail-link pl10"
                >
                  <a
                    class="detail-link ml4"
                    :class="{ visited: hasVisited(item.orderInfo?.orderSn) }"
                    @click="jumpDetails(item.orderInfo?.orderSn)"
                  >
                    {{ item.orderInfo?.orderSn }}
                  </a>
                </div>
              </template>
            </template>

            <!-- 收件人信息 -->
            <template slot="RecipientInformation" slot-scope="record">
              <div>{{ record.originOrder.receiverMobile }}</div>
              <div
                v-show="!record.receiverNameEdit && record.originOrder.transferStatus == 'ERROR'"
              >
                {{ record.originOrder.receiverName }}
                <a-icon
                  style="cursor: pointer; color: #1890ff"
                  type="edit"
                  @click="editReceiverName(record)"
                />
              </div>
              <div v-show="record.receiverNameEdit && record.originOrder.transferStatus == 'ERROR'">
                <a-input
                  style="width: 150px"
                  type="text"
                  v-model="receiverNameModify.modifyReceiverName"
                />
                <a href="#" @click.prevent="confirmReceiverName(record)" style="margin-left: 5px">
                  <a-icon type="check-circle" class="mr10 ml6" />
                </a>
                <a href="#" @click.prevent="cancelReceiverName(record)">
                  <a-icon type="close-circle" />
                </a>
              </div>
              <div>{{ record.originOrder.buyer }}</div>
            </template>

            <!-- 店铺 -->
            <template slot="ShopName" slot-scope="record">
              <div>{{ record.originOrder.shop.shopName }}</div>
              <div>
                <a-tag v-if="record.originOrder.distributor">
                  {{ record.originOrder.distributor.distributorName }}
                </a-tag>
              </div>
              <div class="gray">
                <nobr>{{ $t(`付`) }}：{{ record.originOrder.payTime }}</nobr>
              </div>
            </template>

            <!-- 应收金额 -->
            <template slot="PaymentMethod" slot-scope="record">
              <span>
                {{ toThousandAmount(record.originOrder.totalFee) }}
              </span>
              <a-tag color="orange" v-if="record.originOrder.payType">
                {{ record.originOrder.payType }}
              </a-tag>
              <span v-else></span>
            </template>

            <!-- 订单类型 -->
            <template slot="OrderType" slot-scope="text, record">
              {{ orderTypeFilter[record.originOrder.orderType] }}
            </template>

            <!-- 订单状态 -->
            <template slot="OrderStatus" slot-scope="record">
              {{ platformOrderStatus[record.originOrder.platformOrderStatus] }}
            </template>

            <!-- 订单时间-->
            <template slot="OrderTime" slot-scope="record">
              {{ record.originOrder.platformOrderTime }}
            </template>

            <!-- 仓库-->
            <template slot="Warehouse" slot-scope="record">
              <span v-if="record.orderInfos">
                {{ record.orderInfos[0].orderInfo.warehouse.warehouseName }}
              </span>
            </template>

            <!-- 快递-->
            <template slot="Express" slot-scope="record">
              <span v-if="record.orderInfos && record.orderInfos[0].orderInfo.shipping">
                {{ record.orderInfos[0].orderInfo.shipping.shippingName }}
                <template v-for="(pkg, packageIndex) in record.orderInfos[0].packages">
                  <template v-if="packageIndex != 0">,</template>
                  {{ pkg.logisticsNumber }}
                </template>
              </span>
            </template>

            <!-- 快递-->
            <template slot="action" slot-scope="record">
              <div class="text-link" @click="showActionList(record)">{{ $t('查看日志') }}</div>
            </template>

            <div slot="expandedRowRender" slot-scope="record" style="margin: 0">
              <template v-if="record.originOrder && record.originOrder.transferStatus == 'ERROR'">
                <div>
                  <!-- 转换失败原因 -->
                  <label style="font-weight: bold">{{ $t(`转换失败原因`) }}：</label>
                  <span class="text-danger">{{ record.originOrder.transferNote }}</span>
                  <a
                    v-if="record.originOrder.orderFrom == 'MANUAL'"
                    href="#"
                    class="ml10"
                    @click.prevent="deleteOriginOrder(record.originOrder?.id)"
                  >
                    {{ $t(`删除原始单`) }}
                  </a>
                  <a v-if="isShopError(record)" href="#" @click.prevent="goToShopSetting(record)">
                    {{ $t(`修改仓配`) }}
                  </a>
                  <a
                    v-if="isManualShippingError(record)"
                    href="#"
                    @click.prevent="goToManualShippingSetting(record)"
                  >
                    {{ $t(`修改手工单配置`) }}
                  </a>
                </div>
                <div>
                  <!-- 顾客地址 -->
                  <label style="font-weight: bold">{{ $t(`顾客地址`) }}：</label>
                  <span>
                    {{ record.originOrder.receiverProvince }}&nbsp;{{
                      record.originOrder.receiverCity
                    }}&nbsp;{{ record.originOrder.receiverDistrict }}&nbsp;{{
                      record.originOrder.receiverStreet
                    }}&nbsp;{{ record.originOrder.receiverAddress }}&nbsp;
                  </span>
                  <a
                    href="#"
                    v-if="!record.originOrder.addressEditable"
                    @click.prevent="editAddress(record.originOrder)"
                  >
                    <a-icon type="edit" />
                  </a>
                  <div v-if="record.originOrder.addressEditable">
                    <SelectRegion
                      :placeholder="$t(`省`)"
                      region-type="1"
                      :parent-id="parentId"
                      @selected="selectedProvince"
                      style="width: 150px; display: inline-block; margin-right: 5px"
                    />
                    <SelectRegion
                      v-if="address.receiverProvinceId"
                      :placeholder="$t(`市`)"
                      region-type="2"
                      :parent-id="address.receiverProvinceId"
                      @selected="selectedCity"
                      style="width: 150px; display: inline-block; margin-right: 5px"
                    />
                    <SelectRegion
                      v-if="showDistrictSelect"
                      :placeholder="$t(`区`)"
                      region-type="3"
                      :parent-id="address.receiverCityId"
                      @selected="selectedDistrict"
                      style="width: 150px; display: inline-block; margin-right: 5px"
                    />
                    <SelectRegion
                      v-if="showStreetSelect"
                      :placeholder="$t(`街道`)"
                      region-type="4"
                      :parent-id="address.receiverDistrictId || address.receiverCityId"
                      :default-region-id="address.receiverStreetId"
                      @selected="selectedStreet"
                      style="width: 150px; display: inline-block; margin-right: 5px"
                    />

                    <a
                      href="#"
                      v-if="record.originOrder.addressEditable"
                      @click.prevent="confirmAddress(record.originOrder)"
                    >
                      <a-icon type="check-circle" class="mr10 ml6" />
                    </a>
                    <a
                      href="#"
                      v-if="record.originOrder.addressEditable"
                      @click.prevent="cancelAddress(record.originOrder)"
                    >
                      <a-icon type="close-circle" />
                    </a>
                  </div>
                </div>
              </template>
              <div
                class="oms-order-item"
                v-for="(item, index) in getTableSonList(record)"
                :key="index"
              >
                <div class="flex-ct mt5 mb5" v-if="showType === 'oms'">
                  <div class="mr20">
                    <a
                      class="detail-link ml4 fz15"
                      style="font-weight: bold"
                      :class="{ visited: hasVisited(item.orderSn) }"
                      @click="jumpDetails(item.orderSn)"
                    >
                      {{ item.orderSn }}
                    </a>
                  </div>
                  <a-tag color="orange">{{ $t(item.orderStatus) }}</a-tag>
                </div>
                <a-table
                  style="width: 100%"
                  :bordered="false"
                  :pagination="showPage"
                  :columns="columnsSon"
                  :dataSource="item.tableList"
                  :rowKey="(record, index) => index"
                >
                  <!-- 商家编码-->
                  <template slot="ProductId" slot-scope="res">
                    <template v-if="!res.editable">
                      {{ res.outerCode }}
                      <a-tag color="orange" v-if="orderTypeFilter[res.skuType]">
                        {{ orderTypeFilter[res.skuType] }}
                      </a-tag>
                    </template>
                    <template v-else>
                      <input type="text" v-model="goodsModify.outerCode" />
                    </template>
                    <template
                      v-if="record.originOrder && record.originOrder.transferStatus == 'ERROR'"
                    >
                      <a
                        href="#"
                        v-if="!res.editable"
                        @click.prevent="editSkuCode(res)"
                        style="margin-left: 5px"
                      >
                        <a-icon type="edit" />
                      </a>
                      <a
                        href="#"
                        v-if="res.editable"
                        @click.prevent="confirmSkuCode(res)"
                        style="margin-left: 5px"
                      >
                        <a-icon type="check-circle" class="mr10 ml6" />
                      </a>
                      <a href="#" v-if="res.editable" @click.prevent="cancelSkuCode(res)">
                        <a-icon type="close-circle" />
                      </a>
                    </template>
                  </template>

                  <!-- 子订单分摊-->
                  <template slot="SubOrderAllocation" slot-scope="text, record">
                    {{ toThousandAmount(record.totalFee) }}
                  </template>

                  <!-- 商品名称-->
                  <template slot="ProductName" slot-scope="text, record">
                    {{ record.title }}
                  </template>
                  <!-- sku名称-->
                  <template slot="SkuName" slot-scope="text, record">
                    {{ record.skuPropertiesName }}
                  </template>
                </a-table>
              </div>
            </div>
          </a-table>
        </div>
        <div class="table-footer clearfix">
          <Pagination
            :offset="searchData.offset"
            :length="searchData.length"
            :total-count="pageData.totalCount"
            @paginate="paginate"
          />
        </div>
      </div>
    </div>
    <!-- 开始弹出框-批量操作 -->
    <a-modal v-model="showWindow" :title="uploadTitle" :width="500" :footer="null">
      <template v-if="uploadLoading">
        <div class="flex-c-c mb20">
          <a-progress type="circle" :percent="percent" style="display: block; text-align: center" />
        </div>
        <div class="show-item mb10" v-for="(item, index) in showMsgList" :key="index">
          {{ item }}
        </div>
      </template>
      <div class="upload-content" v-else>
        <a-upload-dragger name="file" :fileList="fileList" action="" :customRequest="handleChange">
          <p class="ant-upload-drag-icon">
            <a-icon type="cloud-upload" />
          </p>
          <p class="ant-upload-hint">{{ $t('点击选择或者拖拽要上传的文件') }}</p>
        </a-upload-dragger>
        <a
          v-if="modalInfo.xlsDownloadUrl"
          target="_blank"
          class="text-info mt10"
          :href="modalInfo.xlsDownloadUrl"
        >
          {{ modalInfo.modalTitle }}{{ $t('批量录单') }}{{ $t('模板') }}.xls
        </a>
      </div>
    </a-modal>
    <a-modal
      :title="$t(`同步订单`)"
      :visible="syncOrderVisible"
      @ok="syncOrder"
      @cancel="cancelSyncOrder"
      :confirmLoading="syncLoading"
    >
      <a-form-model-item :label="$t(`选择需要同步的店铺`)">
        <SelectShop @selected="seleSyncShop" />
      </a-form-model-item>
      <a-form-model-item :label="$t(`请输入原始订单号`)">
        <!-- 原始订单号 -->
        <a-input
          allowClear
          type="text"
          :placeholder="$t(`原始订单号，多个用英文逗号或空格分隔`)"
          v-model.trim="userModalInputOrderIds"
        />
      </a-form-model-item>
    </a-modal>

    <a-modal
      :title="$t(`批量拉单`)"
      :visible="syncBatchOrderVisible"
      @ok="batchSyncPlatformOrder"
      @cancel="
        () => {
          syncBatchOrderVisible = false
        }
      "
      :confirmLoading="syncLoading"
    >
      <div class="tips">
        {{ $t('注: 会同步24小时内的订单') }}
      </div>
      <a-form-model-item :label="$t(`选择需要同步的店铺`)">
        <a-radio-group v-model="allShop">
          <a-radio :value="true">{{ $t('所有店铺') }}</a-radio>
          <a-radio :value="false">{{ $t('指定店铺') }}</a-radio>
        </a-radio-group>

        <SelectMutiShop class="mt10" @change="changeShopHandle" :maxTagCount="10" v-if="!allShop" />
      </a-form-model-item>
    </a-modal>

    <OrderSnModal ref="orderSnModal" :searchType="selectType" :list="notExistSn" />

    <a-modal :title="$t(`操作日志`)" v-model="actionModalVisible" :footer="null">
      <a-spin :spinning="logSpin">
        <ActionLog :list="actionModalList" />
      </a-spin>
      <div class="flex-c-c" v-if="actionListEmpty">{{ $t('暂无操作日志') }}</div>
    </a-modal>

    <a-modal
      :width="800"
      :title="$t(`导入结果`)"
      v-model="importResultVisible"
      :maskClosable="false"
      :keyboard="false"
    >
      <div class="modal-content">
        <a-table
          size="small"
          :bordered="false"
          :columns="importColumns"
          :dataSource="resultList"
          :pagination="false"
        >
          <!-- 执行结果-->
          <template slot="result" slot-scope="text, record">
            <span :class="record.success ? 'text-success' : 'text-danger'">
              {{ text || $t('导入成功') }}
            </span>
          </template>
        </a-table>
      </div>
    </a-modal>

    <PrintDispatchInfo
      :dispatchInfoList="dispatchInfoList"
      :visible="printVisible"
      @close="printVisible = false"
    />
  </div>
</template>

<script>
import OSocket from "@/common/socket.js"
import { wsURL } from "@/config/config"
import { copy, handleOrderIds, judgeOMSOrderSn } from "@common"
import debounce from "@common/debounce"
import deepClone from "@common/deepClone"
import { getDateStr } from "@common/getDateStr"
import ActionLog from "@component/actionLog"
import DateRangePicker from "@component/dateRangePicker"
import OrderSnModal from "@component/orderSnModal"
import Pagination from "@component/pagination"
import SearchSku from "@component/searchSku"
import SelectMutiShop from "@component/selectMutiShop"
import SelectOrderSubType from "@component/selectOrderSubType"
import SelectRegion from "@component/selectRegion"
import SelectShop from "@component/selectShop"
import api from "@service/api"
import http from "@service/axios"
import moment from "moment"
import { mapActions, mapState } from "vuex"
import PrintDispatchInfo from "./PrintDispatchInfo"

export default {
	name: "originalOrders",
	components: {
		SelectShop,
		Pagination,
		DateRangePicker,
		SelectRegion,
		SearchSku,
		OrderSnModal,
		ActionLog,
		SelectOrderSubType,
		PrintDispatchInfo,
		SelectMutiShop,
	},
	activated() {
		if (this.$i18n.locale === "zh") {
			this.modalInfo.xlsDownloadUrl = this.isStore
				? "https://geez-prod.oss-cn-shanghai.aliyuncs.com/template/physicalShop/%E8%AE%A2%E8%B4%A7%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx"
				: "https://cloudoms.oss-ap-southeast-1.aliyuncs.com/OMS_FOLDER/Files/Chinese/%E6%89%B9%E9%87%8F%E5%BD%95%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx"
		} else if (this.$i18n.locale === "en") {
			this.modalInfo.xlsDownloadUrl =
				"https://cloudoms.oss-ap-southeast-1.aliyuncs.com/OMS_FOLDER/Files/English/Batch%20Entry%20Orders%20Template.xlsx"
		} else {
			this.modalInfo.xlsDownloadUrl =
				"https://cloudoms.oss-ap-southeast-1.aliyuncs.com/OMS_FOLDER/Files/Thailand/%E0%B9%80%E0%B8%97%E0%B8%A1%E0%B9%80%E0%B8%9E%E0%B8%A5%E0%B8%95%E0%B8%99%E0%B8%B3%E0%B9%80%E0%B8%82%E0%B9%89%E0%B8%B2%E0%B8%AD%E0%B8%AD%E0%B9%80%E0%B8%94%E0%B8%AD%E0%B8%A3%E0%B9%8C.xlsx"
		}
	},
	mounted() {
		this.searchData.startPayTime = getDateStr(-7, true)
		this.searchData.endPayTime = `${getDateStr(1, false)} 00:00:00`
		this.getTableList(true)
		this.getClientHeight()
		window.addEventListener("resize", this.getClientHeight)
		this.csvUrl = "http://lazada-erp.oss-cn-shanghai.aliyuncs.com/2/c98a9b2458854032bf496b0665253020"
	},
	computed: {
		...mapState(["userSession", "countryMap"]),
		platformOrderStatus() {
			//平台订单状态
			return {
				WAIT_CHECK: this.$t("待审核"), //待审核
				CHECK_PASS: this.$t("审核通过"), //审核通过
				REJECT: this.$t("已拒收"), //已驳回
				CLOSE_PLATFORM: this.$t("平台关闭"), //平台关闭
				CLOSED_MANUAL: this.$t("人工关闭"), //人工关闭
				INVALID: this.$t("无效"), //无效
				CLOSE_PLATFORM_PAID: this.$t("已付款，但平台关闭"), //平台已付款关闭
				DELIVERY_SUCCESS: this.$t("发货成功"), //发货成功
				DELIVERY_FAIL: this.$t("发货失败"), //发货失败
				WAIT_BUYER_PAY: this.$t("待付款"), //待付款
				WAIT_SELLER_SEND_GOODS: this.$t("待发货"), //待发货
				TRADE_FINISHED: this.$t("交易结束"), //交易结束
				WAIT_BUYER_CONFIRM_GOODS: this.$t("已发货"), //已发货
				TRADE_CLOSED: this.$t("平台关闭"), //平台关闭
				TRADE_CLOSED_BY_PLATFORM: this.$t("平台关闭，待付款"), //平台关闭，待付款
				WAIT_SELLER_APPROVE_REFUND: this.$t("申请退款"), //申请退款
			}
		},
		columns() {
			return [
				{
					title: this.$t("原始订单号"),
					key: "platformOrderId",
					width: 220,
					scopedSlots: { customRender: "platformOrderId" },
				},
				{
					title: this.$t("收件人信息"),
					key: "RecipientInformation",
					scopedSlots: { customRender: "RecipientInformation" },
				},
				{
					title: this.$t("店铺"),
					scopedSlots: { customRender: "ShopName" },
					key: "ShopName",
				},
				{
					title: this.$t("应收金额"),
					scopedSlots: { customRender: "PaymentMethod" },
					key: "PaymentMethod",
				},
				{
					title: this.$t("订单类型"),
					key: "OrderType",
					scopedSlots: { customRender: "OrderType" },
				},
				{
					title: this.$t("订单状态"),
					scopedSlots: { customRender: "OrderStatus" },
					key: "OrderStatus",
				},
				{
					title: this.$t("平台创建时间"),
					width: 200,
					scopedSlots: { customRender: "OrderTime" },
					key: "OrderTime",
				},
				{
					title: this.$t("操作"),
					width: 100,
					scopedSlots: { customRender: "action" },
					key: "action",
				},
			]
		},
		columnsSon() {
			return [
				{
					title: this.$t("商家编码"),
					key: "ProductId",
					scopedSlots: { customRender: "ProductId" },
				},
				{
					title: this.$t("商品名称"),
					key: "ProductName",
					scopedSlots: { customRender: "ProductName" },
				},
				{
					title: this.$t("SKU名称"),
					key: "SkuName",
					scopedSlots: { customRender: "SkuName" },
				},
				{
					title: this.$t("订货数量"),
					customRender: (text, record) => record.num + record.unit,
				},
				{
					title: this.$t("单品数量"),
					customRender: (text, record) => this.getNum(record, "orderQuantity"),
				},
				{
					title: this.$t("oms已发货数量"),
					customRender: (text, record) => this.getNum(record, "omsShippedQuantity"),
				},
				{
					title: this.$t("平台已发货数量"),
					customRender: (text, record) => this.getNum(record, "shippedQuantity"),
				},
				{
					title: this.$t("平台已收货数量"),
					customRender: (text, record) => this.getNum(record, "receivedQuantity"),
				},
				{
					title: this.$t("平台已退货数量"),
					customRender: (text, record) => this.getNum(record, "returnedQuantity"),
				},
				{
					title: this.$t("子订单商品金额"),
					key: "SubOrderAllocation",
					scopedSlots: { customRender: "SubOrderAllocation" },
				},
				{
					title: this.$t("平台子订单号"),
					key: "platformOrderGoodsId",
					dataIndex: "platformOrderGoodsId",
				},
			]
		},
		OMSOrderColumns() {
			return [
				{
					title: this.$t("原始商家编码"),
					dataIndex: "shopSkuCode",
				},
				{
					title: this.$t("OMS商家编码"),
					dataIndex: "skuCode",
				},
				{
					title: this.$t("商品名称"),
					dataIndex: "skuName",
				},
				{
					title: this.$t("订货数量"),
					dataIndex: "quantity",
				},
			]
		},
		tableHeight() {
			return (window.innerHeight - 440) * this.$store.state.rate
		},
		orderTypeFilter() {
			return this.constants.ORDER_TYPE_MAPPING
		},
		orderIdTypeList() {
			return this.constants.ORDER_ID_TYPE
		},
		tabList() {
			return [
				{
					name: this.$t("全部"),
					code: undefined,
				},
				{
					code: "WAITING_CONVERT",
					name: this.$t("待转化"),
				},
				{
					code: "WAITING_SEND",
					name: this.$t("待发货"),
				},
				{
					code: "SHIPPED",
					name: this.$t("已发货"),
				},
				{
					code: "TRADE_CLOSED",
					name: this.$t("平台关闭"),
					tips: this.$t("订单已被平台关闭"),
				},
				{
					code: "TRADE_FINISHED",
					name: this.$t("交易结束"),
					tips: this.$t("订单已完结，包括签收/拒签"),
				},
			]
		},
		abnormalList() {
			return [
				// {
				//   statusName: this.$t(`全部`), //全部
				//   status: 'allException',
				// },
				{
					status: "CONVERT_ERROR",
					statusName: this.$t("转化失败"),
					tips: [
						"转化失败是指原始单已经下载至OMS系统，但并没有转化成OMS单。",
						"转化失败的常见原因已在订单列表展开页面标出，修改后即可正常转化。",
						"为避免重复发货，遇到无法解决的转化失败请及时联系技术，而不是直接录单发货。",
					],
				},
				{
					status: "DELIVERY_ERROR",
					statusName: this.$t("回传发货失败"),
					tips: [
						"回传失败是指发货快递号回传平台失败。 ",
						"回传失败的常见原因有：手工录单，原始单已关闭，多包裹拆单等等。 ",
						"遇到回传失败请及时联系技术。",
					],
				},
				{
					status: "RECEIVE_ERROR",
					statusName: this.$t("回传签收失败"),
				},
			]
		},
		isStore() {
			return this.$store.getters.isStore
		},
		isStoreMaster() {
			return this.isStore && this.$store.state.userSession?.isMaster
		},
		importColumns() {
			return [
				{
					title: this.$t("外部单号"),
					dataIndex: "sn",
				},
				{
					title: this.$t("平台订单号"),
					dataIndex: "platformOrderSn",
				},
				{
					title: this.$t("结果"),
					dataIndex: "error",
					scopedSlots: { customRender: "result" },
				},
			]
		},
	},
	data() {
		return {
			syncOrderVisible: false,
			syncBatchOrderVisible: false,
			syncDay: undefined,
			shopNick: undefined,
			platform: undefined,
			modalInfo: {
				modalTitle: undefined,
				modalDesc: undefined,
				modalUrl: api.uploadBatchOrderFile,
				xlsRequestUrl: undefined,
				xlsDownloadUrl: "",
			},
			fileList: [],
			showWindow: false,
			csvUrl: undefined,
			copyData: undefined, //复制文字
			showPage: false,
			userInputOrderIds: undefined,
			userModalInputOrderIds: undefined,
			showStreetSelect: false,
			showDistrictSelect: false,
			searchData: {
				//searchData
				partyId: undefined, // 业务组ID
				payType: undefined, //支付方式ONLINE或COD
				orderIds: [], //    订单号列表
				originOrderStatus: undefined, //   原始单状态-平台状态
				deliveryTimedOut: undefined, //    发货超时
				originOrderType: "SALE", // 原始单类型-
				orderFrom: undefined, //订单来源
				sendConditionType: undefined, //   发货条件
				shopCode: undefined, //  店铺code
				shopNick: undefined, //  店铺code
				platform: undefined, //    平台
				distributorId: undefined, //   分销商ID
				refundStatus: undefined, //    平台退款状态
				omsOrderStatus: undefined, //   OMS订单状态
				shippingStatus: undefined, //待推送
				sysStatus: undefined, //待发货，已发货，已关闭
				skuId: undefined, //   商品skuId
				skuName: undefined, // 商品（sku）名称
				// skuCode: undefined,
				startOrderTime: undefined, //   订单创建时间（开始）
				endOrderTime: undefined, // 订单创建时间（结束）
				startPayTime: undefined, //    订单付款时间（开始）
				endPayTime: undefined, //  订单付款时间（结束）
				startReceiveTime: undefined, //签收时间（开始）
				endReceiveTime: undefined, //签收时间（结束）
				startLatestDeliveryTime: undefined, //最晚发货时间（开始）
				endLatestDeliveryTime: undefined, //最晚发货时间（结束）
				warehouseType: undefined, //   仓库类型
				buyer: undefined, //  用户ID
				receiverName: undefined, // 收件人
				receiverPhone: undefined, //    手机号
				hasMemo: undefined, // 有无备注
				memo: undefined, //    备注
				offset: 0,
				length: 10,
				payStatus: undefined, //支付方式 "PAID": 已付款；"UNPAID": 待付款
				preSaleType: undefined, //订单类型 "NORMAL": 正常单；"PRE_SALE_NORMAL": 预售单
				labelPage: undefined,
				orderTags: [],
			},
			address: {
				originOrderId: undefined,
				receiverProvinceId: undefined,
				receiverProvince: undefined,
				receiverCityId: undefined,
				receiverCity: undefined,
				receiverDistrictId: undefined,
				receiverDistrict: undefined,
				receiverStreetId: undefined,
				receiverStreet: undefined,
			},
			goodsModify: {
				originOrderGoodsId: undefined,
				outerCode: undefined,
			},
			pageData: {
				totalCount: undefined,
			},
			showMore: false, //显示更多查询条件
			tableList: [],
			tableLoading: false,
			currentStatus: "all", //用于表示当前处于哪个tab页
			warehouseTypeList: constants.commonWarehoustType,
			orderDownloadShow: false,
			orderDownloadData: {
				shopId: undefined,
				isShipped: "Y",
				orderStartTime: undefined,
				orderEndTime: undefined,
				payStartTime: undefined,
				payEndTime: undefined,
				platformOrderIds: undefined,
			},
			odType: "odOrderTime",
			tableBodyStyle: {},
			parentId: undefined,
			selectedRowKeys: [],
			selectedOriginOrderSns: [],
			expandedRowKeys: [],
			selectedOmsSns: [],
			syncLoading: false,
			visitedList: [],
			receiverNameModify: {
				modifyReceiverName: undefined,
			},
			notExistSn: [],
			omsSnTransferOriginalSn: [], //通过oms订单sn转化为原始sn去查询

			covertLoading: false,
			exportLoading: false,
			searchResultText: undefined,
			selectType: "orderNo",
			uploadLoading: false,
			percent: 0,
			showMsgList: [],

			actionModalVisible: false,
			actionModalList: [],
			logSpin: false,
			actionListEmpty: false,
			printVisible: false,
			dispatchInfoList: [],
			resultList: [],
			importResultVisible: false,
			errCount: 0,

			modalData: {
				shopList: [],
				startTime: undefined,
				endTime: undefined,
			},
			socket: {},
			currentOrderSn: undefined,

			showType: "plymouth",
			allShop: true,

			uploadTitle: "",
		}
	},
	watch: {
		odType(newVal) {
			switch (newVal) {
				case "odOrderTime":
					this.orderDownloadData.payStartTime = undefined
					this.orderDownloadData.payEndTime = undefined
					this.orderDownloadData.platformOrderIds = undefined
					break
				case "odPayTime":
					this.orderDownloadData.orderStartTime = undefined
					this.orderDownloadData.orderEndTime = undefined
					this.orderDownloadData.platformOrderIds = undefined
					break
				case "odPlatformOrderIds":
					this.orderDownloadData.orderStartTime = undefined
					this.orderDownloadData.orderEndTime = undefined
					this.orderDownloadData.payStartTime = undefined
					this.orderDownloadData.payEndTime = undefined
					break
			}
		},
		//更新模板url
		"$i18n.locale"() {
			if (this.$route.name === "originalOrders") {
				if (this.$i18n.locale === "zh") {
					this.modalInfo.xlsDownloadUrl = this.isStore
						? "https://geez-prod.oss-cn-shanghai.aliyuncs.com/template/physicalShop/%E8%AE%A2%E8%B4%A7%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx"
						: "https://cloudoms.oss-ap-southeast-1.aliyuncs.com/OMS_FOLDER/Files/Chinese/%E6%89%B9%E9%87%8F%E5%BD%95%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx"
				} else if (this.$i18n.locale === "en") {
					this.modalInfo.xlsDownloadUrl =
						"https://cloudoms.oss-ap-southeast-1.aliyuncs.com/OMS_FOLDER/Files/English/Batch%20Entry%20Orders%20Template.xlsx"
				} else {
					this.modalInfo.xlsDownloadUrl =
						"https://cloudoms.oss-ap-southeast-1.aliyuncs.com/OMS_FOLDER/Files/Thailand/%E0%B9%80%E0%B8%97%E0%B8%A1%E0%B9%80%E0%B8%9E%E0%B8%A5%E0%B8%95%E0%B8%99%E0%B8%B3%E0%B9%80%E0%B8%82%E0%B9%89%E0%B8%B2%E0%B8%AD%E0%B8%AD%E0%B9%80%E0%B8%94%E0%B8%AD%E0%B8%A3%E0%B9%8C.xlsx"
				}
			}
		},
	},
	methods: {
		...mapActions(["getShopInfoList"]),
		onSelectChange(selectedRowKeys, selectedRow) {
			this.selectedRowKeys = selectedRowKeys
			this.selectedOriginOrderSns = selectedRow.map((item) => {
				return item?.originOrder?.platformOrderId
			})
			this.selectedOmsSns = selectedRow.map((item) => {
				return item?.orderInfos?.[0].orderInfo?.orderSn
			})
		},
		batchReview() {
			http({
				url: api.checkBatch,
				data: {
					data: {
						originOrderIds: this.selectedRowKeys,
					},
				},
				type: "post",
				hasLoading: false,
				success: () => {
					this.$success(this.$t("审核通过"))
					this.clearSelectKeys()
				},
				fail: (res) => {
					this.$message.error(res.subMsg || res.msg)
					this.clearSelectKeys()
				},
			})
		},
		batchConvert: debounce(function () {
			if (this.selectedRowKeys.length === 0) {
				return this.$message.warning(this.$t("至少选择一项"))
			}
			this.covertLoading = true
			http({
				url: api.batchForceTransform,
				data: { unifiedOrderList: this.selectedRowKeys },
				type: "post",
				success: () => {
					this.$success(this.$t("提交成功，需等待几分钟"))
					this.covertLoading = false
					this.clearSelectKeys()
					this.$nextTick(() => {
						this.getTableList()
					})
				},
				fail: (res) => {
					this.covertLoading = false
					this.$message.error(res.subMsg || res.msg)
					this.clearSelectKeys()
				},
			})
		}),
		exportShipmentList: debounce(function () {
			http({
				url: api.printShippingList,
				data: {
					platform: "RUI_BEN_YUN",
					platformOrderSnList: this.selectedOriginOrderSns,
				},
				success: (res) => {
					this.dispatchInfoList = res.result.list
					this.printVisible = true
				},
			})
		}),
		syncOrder() {
			const OrderIdList = handleOrderIds(this.userModalInputOrderIds)
			if (OrderIdList.length > 20) {
				this.$warning(this.$t("订单号数量不能大于20个"))
			}
			this.syncLoading = true
			http({
				url: api.syncPlatformOrder,
				type: "post",
				data: {
					data: {
						shopCode: this.shopNick,
						shopNick: this.shopNick,
						platform: this.platform,
						platformOrderSns: this.userModalInputOrderIds ? OrderIdList : undefined,
					},
				},
				hasLoading: true,
				success: (res) => {
					const data = res.data
					this.syncLoading = false
					if (data.successOrderSns.length > 0) {
						this.$success(`${this.$t("添加任务成功")},${data.successOrderSns}`)
					} else {
						this.$error(`${this.$t("添加任务失败")},${data.failedOrderSns}`)
					}
					this.syncOrderVisible = false
					this.userModalInputOrderIds = null
					this.syncDay = null
				},
				fail: () => {
					this.syncLoading = false
				},
			})
		},
		cancelSyncOrder() {
			this.syncOrderVisible = false
			this.syncDay = null
		},
		selectShopData(data) {
			this.searchData.shopCode = data.code
			this.searchData.shopNick = data.code
			this.searchData.platform = data.platform
		},
		seleSyncShop(val) {
			this.shopNick = val.code
			this.platform = val.platform
		},
		handleChange(info) {
			if (info.file.name === undefined) {
				this.$warning(this.$t("请添加上传商品！"))
				return false
			}
			const size = Math.floor(info.file.size / 1024)
			if (size > 20 * 1024) {
				this.$warning(this.$t("上传文件大小不能大于20M！"))
				return false
			}
			const filetypes = [".xls", ".xlsx"]
			let isnext = false
			const fileend = info.file.name.substring(info.file.name.lastIndexOf("."))
			for (let i = 0; i < filetypes.length; i++) {
				if (filetypes[i] === fileend) {
					isnext = true
					break
				}
			}
			if (!isnext) {
				this.$warning(this.$t("不接受此文件类型！"))
				info.file = undefined
				return false
			}
			const form = new FormData()
			form.append("file", info.file)
			if (this.isStore) {
				form.append("batchType", "BAKING")
			} else {
				form.append("batchType", "ORDER")
			}
			this.uploadLoading = true
			const requestId = Common.guid("")
			form.append("uploadRequestId", requestId)
			this.uploadTitle = `${this.$t("批量录单")} ${requestId}`
			this.showMsgList = []
			if (this.isStore) {
				this.createWS(requestId, () => {
					this.uploadOrderHandle(form)
				})
			} else {
				this.uploadOrderHandle(form)
			}
		},
		createWS(requestId, callback) {
			this.socket = new OSocket()
			this.socket.createWebSocket(
				wsURL + requestId,
				(data) => {
					this.percent = 0
					this.getMessage(data)
				},
				callback
			)
		},
		uploadOrderHandle(form) {
			http({
				url: api.uploadBatchOrderFile,
				type: "post",
				contentType: false,
				processData: false,
				showErrorMessage: true,
				data: form,
				success: (res) => {
					const { successList, failList } = res.result
					successList?.forEach((item) => {
						item.success = true
					})
					failList?.forEach((item) => {
						item.success = false
					})
					this.resultList = [...(successList || []), ...(failList || [])]
					if (this.successList?.length > 0 || this.failList?.length > 0) {
						this.importResultVisible = true
					} else {
						this.$message.success(this.$t("导入成功"))
					}
					this.showWindow = false
					this.uploadLoading = false
					this.fileList = []
					this.getTableList(true)
					this.socket.close()
				},
				fail: (res) => {
					this.uploadLoading = false
					this.$error({
						title: this.$t("批量录单失败"),
						content: h("div", { domProps: { innerHTML: res.msg.replaceAll("\n", "<br />") } }),
					})
					this.socket.close()
				},
			})
		},
		//获取消息
		getMessage(data) {
			if (data?.msgList?.length > 0) {
				this.showMsgList.push(data.msgList[0].msg)
				this.percent = Math.ceil(data?.msgList?.[0]?.progress * 100)
			}
		},
		hasVisited(orderSn) {
			return this.visitedList.includes(orderSn)
		},
		jumpDetails(orderSn) {
			const orderId = ""
			this.visitedList.push(orderSn)
			this.$addPane({
				view: `orderDetails/${orderSn}`,
				name: "订单详情",
				params: {
					orderId,
					orderSn,
				},
				route: `/orderManage/omsOrderDetail/orderDetails/${orderId}?orderSn=${orderSn}`,
			})
		},
		jumpPage() {
			this.$router.push("orderEntry")
		},
		//一键复制功能
		Clone(text) {
			this.copyData = text.originOrder.platformOrderId
			copy(this.copyData)
		},
		getClientHeight() {
			const clientHeight = `${document.body.clientHeight - 400}px`
			this.tableBodyStyle = {
				height: clientHeight,
			}
		},
		reset() {
			for (const item in this.searchData) {
				this.searchData[item] = undefined
			}
			this.userInputOrderIds = undefined
			this.searchData.startPayTime = getDateStr(-7, true)
			this.searchData.endPayTime = `${getDateStr(1, false)} 00:00:00`
			this.searchData.offset = 0
			this.searchData.length = 10
			this.searchData.originOrderType = "SALE"
			this.getTableList(true)
		},
		clearSelectKeys() {
			this.selectedRowKeys = []
			this.selectedOriginOrderSns = []
			this.selectedOmsSns = []
		},
		getQueryString(data) {
			this.searchData = data
		},
		more() {
			this.showMore = !this.showMore
		},
		selectedDistributor(res) {
			//选择分销商
			if (res?.data) {
				this.searchData.distributorId = res.data.distributorId
			} else {
				this.searchData.distributorId = null
			}
		},
		chooseShipping(res) {
			//选择了快递方式
			if (res) {
				this.searchData.shippingCode = res.shippingCode
			} else {
				this.searchData.shippingCode = undefined
			}
		},
		getOrderTime(date) {
			this.searchData.startOrderTime = date.start_time
			this.searchData.endOrderTime = date.end_time
		},
		getPayTime(date) {
			this.searchData.startPayTime = date.start_time
			this.searchData.endPayTime = date.end_time
		},
		getReceiveTime(date) {
			this.searchData.startReceiveTime = date.start_time
			this.searchData.endReceiveTime = date.end_time
		},
		getLatestDeliveryTime(date) {
			this.searchData.startLatestDeliveryTime = date.start_time
			this.searchData.endLatestDeliveryTime = date.end_time
		},
		paginate(data) {
			this.searchData.offset = data.offset
			this.searchData.length = data.length
			this.getTableList()
		},
		searchByStatus(value) {
			this.searchData.labelPage = value
			this.searchData.abnormalType = undefined
			this.clearSelectKeys()
			this.$nextTick(() => {
				this.getTableList(true)
			})
		},
		setAbnormalType(status) {
			if (this.searchData.abnormalType === status) {
				this.searchData.abnormalType = undefined
			} else {
				this.searchData.abnormalType = status
			}
			this.clearSelectKeys()
			this.$nextTick(() => {
				this.getTableList(true)
			})
		},
		getRequestParams() {
			const searchDataTemp = deepClone(this.searchData)
			if (this.userInputOrderIds) {
				let orderIdType
				if (this.selectType === this.orderIdTypeList[2]) {
					orderIdType = this.orderIdTypeList[2]
				} else {
					orderIdType = judgeOMSOrderSn(this.userInputOrderIds, this.orderIdTypeList)
					if (!orderIdType) {
						this.$message.error(this.$t("搜索内容不能同时包含平台订单号和OMS订单号"))
						return
					}
				}
				searchDataTemp.orderIdType = orderIdType
				searchDataTemp.orderIds = handleOrderIds(this.userInputOrderIds)
				searchDataTemp.startOrderTime = undefined
				searchDataTemp.endOrderTime = undefined
				searchDataTemp.startPayTime = undefined
				searchDataTemp.endPayTime = undefined
				searchDataTemp.startReceiveTime = undefined
				searchDataTemp.endReceiveTime = undefined
				searchDataTemp.startLatestDeliveryTime = undefined
				searchDataTemp.endLatestDeliveryTime = undefined
			} else {
				searchDataTemp.orderIds = []
			}

			if (searchDataTemp.abnormalType) {
				this.searchData.labelPage = undefined
				searchDataTemp.labelPage = searchDataTemp.abnormalType
			}
			return searchDataTemp
		},
		async getTableList(bol) {
			//查询订单,bol用于区分是点击查询按钮true还是在点击其他按钮false时被调用
			if (this.searchData.startPayTime) {
				const t = this.getDateDiff(this.searchData.startPayTime, this.searchData.endPayTime)
				if (t > 31) {
					this.$warning(this.$t("付款时间间隔不能超过一个月！"))
					return
				}
			}
			if (bol) {
				//点击了查询按钮
				this.currentStatus = "all"
				this.searchData.offset = 0
				this.searchData.shippingStatus = undefined
				this.searchData.sysStatus = undefined
			}
			this.expandedRowKeys = []
			const searchDataTemp = this.getRequestParams()
			this.getConversionFailNum(searchDataTemp)
			this.tableLoading = true
			http({
				url: api.getOriginOrderList,
				type: "post",
				data: searchDataTemp,
				success: (res) => {
					const result = res.data || res.result
					this.pageData.totalCount = result.totalCount
					this.notExistSn = result.notExistSn || []
					if (this.notExistSn.length) {
						this.$refs.orderSnModal.open()
					}
					if (searchDataTemp.orderIds.length && result.notExistSn?.length) {
						this.searchResultText = this.$t("共X条记录，Y条记录未找到", {
							X: searchDataTemp.orderIds.length,
							Y: result.notExistSn.length,
						})
					} else {
						this.searchResultText = undefined
					}
					if (result.totalCount > 0) {
						for (let i = 0; i < result.content.length; i++) {
							const item = result.content[i]
							this.expandedRowKeys.push(item.originOrder.id)
							item.expandTableList = [
								{
									tableList: item.originOrder.orderGoodses,
								},
							]
						}
					}
					this.tableList = result.content
					if (this.showType === "oms") {
						this.getOmsOrderGoodsList()
					} else {
						this.tableLoading = false
					}
				},
			})
		},
		getDateDiff(startDate, endDate) {
			const startTime = new Date(Date.parse(startDate.replace(/-/g, "/"))).getTime()
			const endTime = new Date(Date.parse(endDate.replace(/-/g, "/"))).getTime()
			const dates = Math.abs(startTime - endTime) / (1000 * 60 * 60 * 24)
			return dates
		},
		selectedProvince(data) {
			this.address.receiverProvinceId = data.region_id
			this.address.receiverProvince = data.display_name
			this.address.receiverCityId = undefined
			this.address.receiverCity = undefined
			this.address.receiverDistrictId = undefined
			this.address.receiverStreet = undefined
			this.address.receiverStreetId = undefined
			this.address.receiverDistrict = undefined
			this.showStreetSelect = false
			this.showDistrictSelect = false
		},
		selectedCity(data) {
			this.address.receiverCityId = data.region_id
			this.address.receiverCity = data.display_name
			this.address.receiverDistrictId = undefined
			this.address.receiverDistrict = undefined
			this.address.receiverStreetId = undefined
			this.address.receiverStreet = undefined
			this.showDistrictSelect = true
			this.showStreetSelect = false
		},
		selectedDistrict(data) {
			this.address.receiverDistrictId = data.region_id
			this.address.receiverDistrict = data.display_name
			this.address.receiverStreetId = undefined
			this.address.receiverStreet = undefined
			this.showStreetSelect = true
		},
		selectedStreet(data) {
			this.address.receiverStreetId = data.regionId
			this.address.receiverStreet = data.display_name
		},
		editAddress(item) {
			item.addressEditable = true
			this.address.originOrderId = item.id
			this.parentId = this.countryMap[item.receiverCountry]
			this.tableList.splice(0, 0)
			this.address.receiverProvince = undefined
			this.address.receiverProvinceId = undefined
			this.address.receiverCity = undefined
			this.address.receiverCityId = undefined
			this.address.receiverDistrict = undefined
			this.address.receiverDistrictId = undefined
			this.address.receiverStreet = undefined
			this.address.receiverStreetId = undefined
		},
		confirmAddress(item) {
			if (this.address.receiverCityId === undefined) {
				this.$warning(this.$t("未选择省市区，不能保存"))
				return false
			}
			http({
				type: "post",
				hasLoading: true,
				url: api.updateOriginOrderAddress,
				data: {
					data: this.address,
				},
				success: () => {
					item.receiverProvince = this.address.receiverProvince
					item.receiverCity = this.address.receiverCity
					item.receiverDistrict = this.address.receiverDistrict
					item.addressEditable = false
					this.address.originOrderId = undefined
					this.address.receiverProvince = undefined
					this.address.receiverProvinceId = undefined
					this.address.receiverCity = undefined
					this.address.receiverCityId = undefined
					this.address.receiverDistrict = undefined
					this.address.receiverDistrictId = undefined
					this.address.receiverStreet = undefined
					this.address.receiverStreetId = undefined
					this.showStreetSelect = false
					this.showDistrictSelect = false
				},
			})
		},
		cancelAddress(item) {
			item.addressEditable = false
			this.tableList.splice(0, 0)
			this.address.originOrderId = undefined
			this.address.receiverProvince = undefined
			this.address.receiverProvinceId = undefined
			this.address.receiverCity = undefined
			this.address.receiverCityId = undefined
			this.address.receiverDistrict = undefined
			this.address.receiverDistrictId = undefined
			this.address.receiverStreet = undefined
			this.address.receiverStreetId = undefined
		},
		editSkuCode(res) {
			res.editable = false
			this.goodsModify.outerCode = res.outerCode
			this.goodsModify.originOrderGoodsId = res.id
			res.editable = true
			this.tableList.splice(0, 0)
		},
		confirmSkuCode(result) {
			http({
				type: "post",
				url: api.updateOriginOrderGoodsOuterCoder,
				hasLoading: true,
				data: {
					data: this.goodsModify,
				},
				success: () => {
					result.outerCode = this.goodsModify.outerCode
					result.editable = false
					this.goodsModify.outerCode = undefined
					this.goodsModify.originOrderGoodsId = undefined
				},
			})
		},
		cancelSkuCode(result) {
			result.editable = false
			this.goodsModify.outerCode = undefined
			this.goodsModify.originOrderGoodsId = undefined
			this.tableList.splice(0, 0)
		},
		editReceiverName(res) {
			res.receiverNameEdit = true
			this.receiverNameModify.modifyReceiverName = res.originOrder.receiverName
			this.$forceUpdate()
		},
		cancelReceiverName(res) {
			res.receiverNameEdit = false
			this.receiverNameModify.modifyReceiverName = undefined
			this.$forceUpdate()
		},
		confirmReceiverName(res) {
			http({
				type: "post",
				url: api.modifyReceiverName,
				hasLoading: true,
				data: {
					data: {
						originOrderId: res.originOrder.id,
						modifyReceiverName: this.receiverNameModify.modifyReceiverName,
					},
				},
				success: () => {
					res.originOrder.receiverName = this.receiverNameModify.modifyReceiverName
					this.receiverNameModify.modifyReceiverName = undefined
					res.receiverNameEdit = false
					this.$forceUpdate()
				},
			})
		},
		deleteOriginOrder(originOrderId) {
			if (confirm(this.$t("确定删除该原始单？"))) {
				http({
					url: api.originOrderDelete,
					type: "post",
					hasLoading: true,
					data: {
						data: {
							originOrderId: originOrderId,
						},
					},
					success: (res) => {
						this.$success(res.data)
						this.getTableList()
					},
				})
			}
		},
		enterPress() {
			const e = event || window.event
			if (e.keyCode === 13) {
				this.getTableList(true)
			}
		},
		selectedSku(data) {
			this.$set(this.searchData, "skuId", data?.skuCode)
			this.$set(this.searchData, "skuName", data?.skuName)
		},
		changeSkuCode() {
			this.$set(this.searchData, "skuName", undefined)
		},
		openOrderDownload() {
			this.orderDownloadShow = true
		},
		closeOrderDownload() {
			this.orderDownloadShow = false
		},
		get_od_order_time(date) {
			this.orderDownloadData.orderStartTime = date.start_time
			this.orderDownloadData.orderEndTime = date.end_time
		},
		get_od_pay_time(date) {
			this.orderDownloadData.payStartTime = date.start_time
			this.orderDownloadData.payEndTime = date.end_time
		},
		showOrderSnModal() {
			this.$refs.orderSnModal.open()
		},
		exportOriginalOrder() {
			const currentShop = this.$store.state.currentShop
			const mergeCode = currentShop?.uniqueCode
			const appCode = localStorage.getItem("appCode")
			const data = deepClone(this.searchData)
			let orderIdType = undefined
			if (this.selectedOmsSns.length) {
				data.orderIds = this.selectedOmsSns
				orderIdType = this.orderIdTypeList[1] //表格中有勾选就写死orderSn
			} else if (this.userInputOrderIds) {
				if (this.selectType === this.orderIdTypeList[2]) {
					orderIdType = this.orderIdTypeList[2]
				} else {
					orderIdType = judgeOMSOrderSn(this.userInputOrderIds, this.orderIdTypeList)
					if (!orderIdType) {
						this.$message.error(this.$t("搜索内容不能同时包含平台订单号和OMS订单号"))
						return
					}
				}
				data.orderIds = handleOrderIds(this.userInputOrderIds)
			} else {
				data.orderIds = []
			}
			data.orderIdType = orderIdType

			if (data.abnormalType) {
				this.searchData.labelPage = undefined
				data.labelPage = data.abnormalType
			}

			const authShopUniqueCodes = this.$store.state.shopList.map((item) => item.uniqueCode)
			data.authShopUniqueCodes = authShopUniqueCodes

			data.offset = undefined
			data.length = undefined
			http({
				url: api.createTask,
				data: {
					bookCode: "originOrderExport",
					mergeCode,
					merchantShopName: currentShop?.name,
					appCode,
					params: data,
				},
				type: "post",
				hasLoading: false,
				success: (res) => {
					if (res.result.task_id) {
						this.$message.success(this.$t("创建导出任务成功,请前往【数据中心/下载中心】下载"))
					} else {
						this.$message.error(this.$t("创建导出任务失败"))
					}
				},
			})
		},
		async showActionList(record) {
			this.actionListEmpty = false
			this.actionModalVisible = true
			this.logSpin = true
			const res = await http({
				url: api.getPlymouthOrderActionList,
				data: {
					unifiedOrderId: record.originOrder.id,
					pageNo: 1,
					pageSize: 9999,
				},
			})
			this.logSpin = false
			const list = res.result.content
			this.actionModalList = list
			if (list.length === 0) {
				this.actionListEmpty = true
			}
		},
		isShopError(record) {
			const errList = ["warehouse_shipping_not_exist", "warehouse_not_exist", "shipping_not_exist", "set_warehouse_error"]
			return errList.includes(record.originOrder.transferErrorCode)
		},
		isManualShippingError(record) {
			return record.originOrder.transferErrorCode === "manual_shipping_not_exist"
		},
		goToShopSetting(record) {
			const shop = record.originOrder.shop
			window.open(`/shop_web/deployInfo?platform=${shop.platform}&shopCode=${shop.code}`)
		},
		goToManualShippingSetting(record) {
			const shop = record.originOrder.shop
			window.open(`/shop_web/deployInfo?platform=${shop.platform}&shopCode=${shop.code}&activeKey=3`)
		},
		selectOrderTag(val) {
			this.searchData.orderTags = val?.map((item) => ({
				type: "orderSubType",
				key: item,
			}))
		},
		getNum(record, name) {
			if (record[name] === null) return "-"
			return record[name] + record.orderUnit
		},
		async getConversionFailNum(data) {
			const res = await http({
				url: api.getConversionFailNum,
				data: {
					...data,
					labelPage: "CONVERT_ERROR",
				},
			})
			this.errCount = res.result
		},

		changeShopHandle(list) {
			this.modalData.shopList = list.map((item) => ({
				platform: item.platform,
				shopCode: item.code || item.shopCode,
			}))
		},
		batchSyncPlatformOrder() {
			this.syncLoading = true
			const nowStr = +new Date()
			const endTime = moment(nowStr).format("YYYY-MM-DD HH:mm:ss")
			const startTime = moment(nowStr - 24 * 3600 * 1000).format("YYYY-MM-DD HH:mm:ss")
			const data = {
				startTime,
				endTime,
			}
			if (this.allShop) {
				data.all = true
			} else {
				data.shopList = this.modalData.shopList
			}
			http({
				url: api.batchSyncPlatformOrder,
				type: "post",
				data,
				success: (res) => {
          this.syncLoading = false
					if (res.success) {
						this.syncBatchOrderVisible = false
						this.$success(this.$t("同步成功"))
						this.getTableList(true)
					}
				},
				fail: (err) => {
					this.$message.warning(err.msg)
					this.syncLoading = false
				},
			})
		},
		openExpand(record) {
			const key = record.originOrder.id
			this.expandedRowKeys = [...this.expandedRowKeys, key]
		},
		closeExpand(record) {
			const key = record.originOrder.id
			this.expandedRowKeys = this.expandedRowKeys.filter((k) => k !== key)
		},
		isShowExpand(record) {
			const isExpanded = this.expandedRowKeys.includes(record.originOrder.id)
			return isExpanded
		},
		getOrderGoods(orderSn) {
			this.tableLoading = true
			http({
				url: api.getOmsOrderList,
				data: {
					orderSnList: orderSn,
					merchantCode: localStorage.getItem("merchantCode"),
				},
				type: "post",
				success: (res) => {
					const list = res.result.list
					this.tableList.forEach((item) => {
						const orderGoodses = item.originOrder.orderGoodses
						const expandList = item.orderInfos.map((elem) => {
							const orderSn = elem.orderInfo?.orderSn
							const listItem = list.find((e) => e.orderSn === orderSn)
							if (listItem) {
								return {
									orderSn: listItem.orderSn,
									orderStatus: listItem.orderStatus,
									tableList: listItem.goodsList.map((i) => {
										return orderGoodses.find((j) => j.id === i.originalOrderGoodsId)
									}),
								}
							}
							return {
								tableList: [],
							}
						})
						this.$set(item, "expandTableList2", expandList)
						this.tableLoading = false
					})
				},
				fail: (res) => {
					this.$message.error(res.subMsg || res.msg)
					this.tableLoading = false
				},
			})
		},
		changeShowType(e) {
			const val = e.target.value
			if (val === "oms") {
				this.getOmsOrderGoodsList()
			}
		},
		getOmsOrderGoodsList() {
			const selectedOmsSns = this.tableList.reduce((acc, item) => {
				// biome-ignore lint/performance/noAccumulatingSpread: <explanation>
				return [...acc, ...item.orderInfos.map((item) => item.orderInfo?.orderSn)]
			}, [])

			this.getOrderGoods(selectedOmsSns)
		},
		getTableSonList(record) {
			if (this.showType === "oms") {
				return record.expandTableList2
			}
			return record.expandTableList
		},
		clearData() {
			http({
				url: api.clearMYJData,
				data: {},
				type: "post",
				success: (res) => {
					if (res.success) {
						this.$message.success(this.$t("操作成功"))
					}
				},
			})
		},
	},
}
</script>

<style scoped lang="scss">
.content {
  min-height: auto !important;
}
.childTable > th {
  text-align: center;
}

.form-group {
  margin-bottom: 11px;
}

.status-tag {
  margin-left: 0px;
}

.added-tag {
  margin-left: 0px;
}

.highSearchBar {
  text-align: center;
  height: 1px;
  background: $border-color-light;
}

.showAndHide {
  width: 100px;
  padding: 8px 10px;
  background: $white;
  cursor: pointer;
  color: $primary-color;
  font-size: 12px;
  line-height: 12px;
  border: 1px solid $border-color-light;
  border-top: 0;
}

.breadcrumb {
  margin: 0 0 0 0;
}

.tableContent {
  background: $white;

  .form-group {
    margin-bottom: 5px;
  }
}

.status-list-top {
  border-bottom: 1px solid #ddd;
}

.status-list-top li {
  position: relative;
  float: left;
  color: #333;
  padding: 10px;
  cursor: pointer;
}

.status-list-top li:last-child {
  border-right: none;
}

.status-list-top li.active {
  color: #3c8dbc;
  font-weight: 900;
  border-bottom: 2px solid #3c8dbc;
}

.closed {
  color: #a6a6a6;
}

.confirm-icon {
  color: #78bf2a;
}

.cancel-icon {
  color: #f86767;
}

.back-blue {
  background: #e5f3fd;
}

.question {
  font-size: 12px;
  color: #999;
}

.added-tag,
.status-tag {
  display: inline-block;
}

.buyer {
  display: inline-block;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
}

.gray {
  color: #999;
}

.table > tbody > tr > td,
.table > thead > tr > th {
  border-left: 0;
  border-right: 0;
  text-align: left;
}

.btn i.fa {
  margin-right: 0.5em;
}

.pw-order-download .popup-content {
  width: 400px;
}

.pw-order-download .form-group {
  margin-bottom: 6%;
  padding-left: 20px;
}

.pw-order-download .form-control {
  width: 280px;
}

.showMore {
  overflow: hidden;
  transition: height 0.5s;
}

.ht0 {
  height: 0;
}

.htAuto {
  height: 135px;
}

.tips-wrap {
  padding: 10px 16px 0 16px;
  position: relative;
  border-radius: 6px;
  margin-bottom: 10px;
  color: #495060;
  line-height: 1.5;
  border: 1px solid #d5e8fc;
  background-color: #eaf4fe;
}

#textarea-orders {
  height: 6%;
  resize: none;
  vertical-align: top;
}

.radioLabel {
  cursor: pointer;
}

.radioLabel input[type='radio'] {
  margin-right: 0.5em;
  position: relative;
  top: 2px;
}

.original-orders ::v-deep .ant-table-thead > tr > th,
.original-orders ::v-deep .ant-table-tbody > tr > td {
  padding: 8px;
}
.detail-link.visited {
  color: $error-color; /*已访问的链接颜色*/
}
.tips {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 20px;
  color: $error-color;
}
.oms-order-item {
  width: 100%;
  margin-left: 60px;
  background: #fff;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 10px;
}
</style>

<style scoped>
.original-orders ::v-deep .ant-tabs-bar {
  margin-bottom: 2px;
  border-bottom: none;
}
.original-orders ::v-deep .abnormal-tab .ant-tabs-tab {
  min-width: 100px;
  text-align: center;
}
.original-orders .tag-select ::v-deep .ant-tag {
  font-size: 13px;
  padding: 2px 10px;
  cursor: pointer;
}

.square-icon {
  cursor: pointer;
}
.oms-order-item ::v-deep th {
  background: #fff !important;
  border-bottom: none;
}
.oms-order-item ::v-deep td {
  border-bottom: none;
}
</style>
