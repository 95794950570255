<template>
  <a-select
    class="autocomplete-select"
    show-search
    allowClear
    :value="value"
    :placeholder="_placeHolder"
    style="width: 100%"
    :default-active-first-option="false"
    :show-arrow="false"
    :filter-option="false"
    :not-found-content="loading ? undefined : null"
    @search="handleSearch"
    @change="handleChange"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option v-for="item in skuList" :key="item.skuCode" :option="item">
      {{ item.skuName + ' | ' + item.skuCode }}
    </a-select-option>
  </a-select>
</template>

<script>
import deepClone from "@/common/deepClone"
import debounce from "@common/debounce"
import api from "@service/api"
import http from "@service/axios"

export default {
	data() {
		return {
			value: undefined,
			skuList: [],
			choosedData: null,
			loading: false,
		}
	},
	computed: {
		_placeHolder() {
			return this.placeholder || this.$t("搜索商品名称/商家编码")
		},
	},
	props: {
		placeholder: {
			type: String,
		},
		isGroup: {
			//是否是组合商品
			type: Boolean,
			default: undefined,
		},
	},
	methods: {
		clear() {
			this.value = undefined
			this.skuList = []
		},
		searchSkuNameList: debounce(function (value) {
			if (!value) return
			this.loading = true
			const data = {
				keyWord: value,
				isGroup: this.isGroup,
			}
			http({
				url: api.getSkuList,
				type: "post",
				data,
				hasLoading: false,
				success: (data) => {
					const skuList = data.result?.skuResVO
					if (skuList.length > 0) {
						this.skuList = skuList
					} else {
						this.$warning(this.$t("暂无数据"))
						this.choosedData = {}
					}
					this.loading = false
				},
			})
		}, 800),

		handleSearch(value) {
			this.searchSkuNameList(value)
		},
		handleChange(value, option) {
			this.value = value
			this.choosedData = option?.data?.attrs?.option
		},
	},
	watch: {
		id(val) {
			this.value = val
		},
		choosedData(val) {
			this.$emit("selected", deepClone(val || {}))
		},
	},
}
</script>
