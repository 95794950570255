<template>
  <a-popover>
    <template slot="content">
      {{ text }}
    </template>
    <div :class="'mul-ellipsis' + lineNum">{{ text }}</div>
  </a-popover>
</template>

<script>
export default {
	props: {
		lineNum: {
			//几行
			type: Number,
			default: 1,
		},
		text: {
			//内容
			type: String,
		},
	},
}
</script>

<style lang="scss" scoped></style>
