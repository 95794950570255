<template>
  <a-select
    allowClear
    show-search
    :placeholder="selfPlaceholder"
    style="width: 100%"
    @change="selectShop"
    :disabled="disabled"
    :filter-option="filterOption"
    v-model="shopCode"
    @focus="fetchShopList"
  >
    <a-select-option v-for="item in shopList" :value="item.shopCode" :key="item.shopCode">
      {{ item.platformLocale + '-' + item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

export default {
	props: ["code", "placeholder", "disabled", "platform", "init", "allShop"],
	data() {
		return {
			shopCode: undefined,
			shopList: [],
		}
	},
	computed: {
		...mapGetters(["currentShop"]),
		selfPlaceholder() {
			return this.placeholder || this.$t("请选择店铺")
		},
	},
	methods: {
		...mapActions(["getShopInfoList"]),
		fetchShopList() {
			this.getShopInfoList({
				params: { platform: this.platform },
				callback: (list) => {
					if (!this.allShop) {
						const partyId = this.$store.getters.partyId
						list = list.filter((item) => item.partyId === partyId)
					}
					this.shopList = list.map((item) => ({
						code: item.shopCode,
						...item,
					}))
					if (this.code) {
						this.shopCode = this.code
						if (this.init) {
							//如果init为true，手动执行一次选中操作
							this.selectShop(this.code)
						}
					}
				},
			})
		},
		selectShop(value) {
			this.$emit("update:code", value)
			if (value) {
				const data = this.shopList.find((item) => item.shopCode === value) || {}
				this.$emit("selected", data)
			} else {
				this.$emit("selected", {})
			}
		},
		reset() {
			this.shopCode = undefined
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
	},
	watch: {
		platform: {
			handler() {
				this.shopCode = undefined
				this.$nextTick(() => {
					this.fetchShopList()
				})
			},
			immediate: true,
		},
		code: {
			handler(val) {
				this.$nextTick(() => {
					this.shopCode = val
					if (this.init && this.shopList.length) {
						//如果init为true，手动执行一次选中操作
						this.selectShop(val)
					}
				})
			},
			immediate: true,
		},
	},
}
</script>
