<template>
  <a-select
    v-if="mode === 'multiple'"
    allowClear
    mode="multiple"
    :placeholder="_placeHolder"
    v-model="valueList"
    option-filter-prop="children"
    style="width: 100%"
    :filter-option="filterOption"
    :disabled="disabled"
    :not-found-content="loading ? undefined : null"
    @change="chooseShippings"
    @focus="handleFocus"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option
      v-for="item in shippingList"
      :key="item.shippingCode"
      :value="item.shippingCode"
      :option="item"
    >
      {{ item.shippingName }}
    </a-select-option>
  </a-select>
  <a-select
    v-else
    allowClear
    :value="value"
    :placeholder="_placeHolder"
    style="width: 100%"
    :default-active-first-option="false"
    :disabled="disabled"
    :not-found-content="loading ? undefined : null"
    option-filter-prop="children"
    :filter-option="filterOption"
    @focus="handleFocus"
    @change="handleChange"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option
      v-for="item in shippingList"
      :key="item.shippingCode"
      :value="item.shippingCode"
      :option="item"
    >
      {{ item.shippingName }}
    </a-select-option>
  </a-select>
</template>

<script>
import deepClone from "../common/deepClone"
import api from "../service/api"
import http from "../service/axios"

export default {
	data() {
		return {
			value: undefined,
			shippingList: [],
			choosedData: null,
			loading: false,
			valueList: [],
		}
	},
	computed: {
		_placeHolder() {
			return this.placeholder || this.$t("请选择快递")
		},
	},
	props: ["code", "placeholder", "mode", "disabled"],
	methods: {
		async searchShippingList() {
			if (this.shippingList.length) return
			this.loading = true
			const res = await http({
				url: api.getShippingListByUser,
				type: "post",
				data: {
					merchantCode: localStorage.getItem("merchantCode"),
				},
			})
			this.shippingList = res.result || []
			this.loading = false
		},
		handleFocus() {
			this.searchShippingList()
		},
		handleChange(value, option) {
			this.value = value
			this.$emit("update:code", value)
			const data = option?.data?.attrs?.option
			this.choosedData = data
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		chooseShippings(codes, options) {
			this.$emit("update:code", codes)
			const list = options.map((item) => item.data.attrs?.option)
			this.$emit("change", list)
		},
	},
	watch: {
		code: {
			handler(val) {
				if (Array.isArray(val)) {
					this.valueList = val
					if (val.length) {
						this.searchShippingList()
					}
				} else {
					this.value = val
					if (val) {
						this.searchShippingList()
					}
				}
			},
			deep: true,
			immediate: true,
		},
		choosedData: function () {
			this.$emit("selected", deepClone(this.choosedData || {}))
		},
	},
}
</script>
