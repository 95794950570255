import { message } from "ant-design-vue"
import {
	Alert,
	AutoComplete,
	Badge,
	Button,
	Card,
	Cascader,
	Checkbox,
	Col,
	DatePicker,
	Divider,
	Drawer,
	Dropdown,
	Form,
	FormModel,
	Icon,
	Input,
	InputNumber,
	Layout,
	List,
	Menu,
	Modal,
	Popconfirm,
	Popover,
	Progress,
	Radio,
	Row,
	Select,
	Spin,
	Steps,
	Switch,
	Table,
	Tabs,
	Tag,
	Timeline,
	Tooltip,
	Tree,
	Upload,
} from "ant-design-vue"
import Vue from "vue"
import i18n from "./lang"

const antdComponents = [
	Alert,
	Badge,
	Icon,
	Card,
	Divider,
	Row,
	Col,
	Table,
	Button,
	FormModel,
	Input,
	InputNumber,
	Select,
	DatePicker,
	Checkbox,
	Radio,
	Popover,
	Upload,
	Modal,
	Tabs,
	Tag,
	Steps,
	Switch,
	Popconfirm,
	AutoComplete,
	Progress,
	Timeline,
	Tooltip,
	Dropdown,
	Menu,
	Tree,
	Form,
	Layout,
	Spin,
	Cascader,
	Drawer,
	List,
]
antdComponents.forEach((item) => {
	Vue.use(item)
})
Vue.prototype.$message = message
Vue.prototype.$info = (value) => {
	if (typeof value === "string") {
		Modal.info({
			title: i18n.t("通知"),
			content: value,
		})
		return
	}
	Modal.info(value)
}
Vue.prototype.$success = (value) => {
	if (typeof value === "string") {
		Modal.success({
			title: i18n.t("成功"),
			content: value,
		})
		return
	}
	Modal.success(value)
}
Vue.prototype.$error = (value) => {
	if (typeof value === "string") {
		Modal.error({
			title: i18n.t("错误"),
			content: value,
		})
		return
	}
	Modal.error(value)
}
Vue.prototype.$warning = (value) => {
	if (typeof value === "string") {
		Modal.warning({
			title: i18n.t("警告"),
			content: value,
		})
		return
	}
	Modal.warning(value)
}
Vue.prototype.$confirm = (value) => {
	if (typeof value === "string") {
		Modal.confirm({
			title: i18n.t("确定"),
			content: value,
		})
		return
	}
	Modal.confirm(value)
}
Vue.prototype.$destroyAll = (value) => {
	if (typeof value === "string") {
		Modal.destroyAll({
			title: i18n.t("错误"),
			content: value,
		})
		return
	}
	Modal.destroyAll(value)
}
