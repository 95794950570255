<template>
  <div class="content-wrapper sales-order">
    <div class="row status-list-wrap" style="margin: -6px 0 0 0">
      <a-tabs @change="setSearchStatus" class="status-tab" v-model="formData.statusType">
        <a-tab-pane v-for="item in statusListTop" :key="item.status">
          <template slot="tab">
            {{ item.statusName }}
            <a-popover v-if="item.tips">
              <template slot="content">
                <p>{{ item.tips }}</p>
              </template>
              <a-icon type="question-circle" style="margin-right: 0"></a-icon>
            </a-popover>
          </template>
        </a-tab-pane>
      </a-tabs>
    </div>
    <div class="content">
      <div class="tag-select">
        <div class="flex-ct tag-select-item mb12" v-if="!isStore">
          <div class="label">{{ $t('订单分类') }}：</div>
          <a-tag :color="formData.payType === 'COD' ? '#1890ff' : ''" @click="changeTag">COD</a-tag>
          <a-tag :color="formData.orderType === 'PRESALE' ? '#1890ff' : ''" @click="changeTag2">
            {{ $t('预售') }}
          </a-tag>
          <a-tag
            :color="formData.statusType === 'reserved_no_outbound' ? '#1890ff' : ''"
            @click="changeTag3"
          >
            {{ $t('预定没发货') }}
          </a-tag>
        </div>
        <div class="flex-ct tag-select-item">
          <div class="label">{{ $t('履约SLA') }}：</div>
          <a-tag
            :color="formData.timeoutSituation === item.code ? '#1890ff' : ''"
            v-for="item in timeoutSituationList"
            :key="item.code"
            @click="changeTimeoutSituation(item)"
          >
            {{ item.name }}
            <a-popover>
              <template slot="content">
                <p>{{ item.tips }}</p>
              </template>
              <a-icon type="question-circle"></a-icon>
            </a-popover>
          </a-tag>
        </div>
        <div class="flex-ct tag-select-item">
          <div class="label">{{ $t('异常') }}：</div>
          <a-badge
            v-for="item in statusListBottom"
            :key="item.status"
            :count="item.num"
            :offset="[-12, 0]"
          >
            <a-tag
              :color="formData.abnormalType === item.status ? '#1890ff' : ''"
              @click="setAbnormalType(item.status)"
            >
              {{ item.statusName }}
              <a-popover v-if="item.tips">
                <template slot="content">
                  <p>{{ item.tips }}</p>
                </template>
                <a-icon type="question-circle"></a-icon>
              </a-popover>
            </a-tag>
          </a-badge>
        </div>
      </div>
      <div class="form-content" @keydown="enterPress">
        <a-row class="mb10" :gutter="16">
          <a-col :span="10">
            <!-- 原始订单号 -->
            <a-input
              allowClear
              type="text"
              :placeholder="$t(`平台订单号/OMS订单号，多个用英文逗号或空格分隔`)"
              v-model.trim="formData.orderSns"
            >
              <a-select slot="addonBefore" v-model="selectType" style="width: 130px">
                <a-select-option value="orderNo">{{ $t('订单号') }}</a-select-option>
                <a-select-option value="waybillNo">{{ $t('快递单号') }}</a-select-option>
              </a-select>
            </a-input>
          </a-col>
          <a-col :span="6">
            <!-- 店铺 -->
            <SelectMutiShop @change="changeShopHandle" />
          </a-col>
          <!-- 按钮 -->
          <a-col :span="8">
            <a-button v-if="priv('f_sale_order_list')" type="primary" @click="searchSaleOrderList">
              <i class="iconfont icon-search" style="margin-right: 5px"></i>
              {{ $t(`查询`) }}
            </a-button>
            <a-button @click="reset">
              <i class="iconfont icon-synchronize" style="margin-right: 5px"></i>
              {{ $t(`重置`) }}
            </a-button>
            <a-button
              v-if="priv('f_sale_order_export')"
              type="primary"
              @click="exportSaleOrderList"
            >
              <i class="iconfont icon-export" style="margin-right: 5px"></i>
              {{ $t(`导出`) }}
            </a-button>
          </a-col>
          <iframe name="form" id="form" style="display: none"></iframe>
        </a-row>
        <a-row class="mb10" :gutter="16">
          <a-col :span="8">
            <!-- 时间 -->
            <DateRangePicker
              id="pay-time"
              :needhms="true"
              :placeholder="$t('平台下单时间')"
              @get_date="(date) => setDateHandle('Pay', date)"
              :start-time="formData.startPayTime"
              :end-time="formData.endPayTime"
            />
          </a-col>
          <a-col :span="8">
            <!-- 时间 -->
            <DateRangePicker
              id="order-time"
              :needhms="true"
              :placeholder="$t('订单时间')"
              @get_date="(date) => setDateHandle('Order', date)"
              :start-time="formData.startOrderTime"
              :end-time="formData.endOrderTime"
            />
          </a-col>
          <a-col :span="8">
            <!-- 时间 -->
            <DateRangePicker
              id="receive-time"
              :needhms="true"
              :placeholder="$t('签收时间')"
              @get_date="(date) => setDateHandle('Receive', date)"
              :start-time="formData.startReceiveTime"
              :end-time="formData.endReceiveTime"
            />
          </a-col>
        </a-row>
        <div id="search-more" class="showMore ht0" :class="{ htAuto: openSearchDiv }">
          <a-row class="mb10" :gutter="16" style="display: flex; flex-wrap: wrap">
            <a-col :span="4">
              <!-- 支付方式 -->
              <a-select
                allowClear
                v-model="formData.payType"
                style="width: 100%"
                :placeholder="$t(`支付方式`)"
              >
                <a-select-option value="ONLINE">ONLINE</a-select-option>
                <a-select-option value="COD">COD</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="4">
              <!-- 原始订单商家编码 -->
              <a-input
                allowClear
                type="text"
                v-model.trim="formData.outerCode"
                :placeholder="$t(`原始订单商家编码`)"
              />
            </a-col>
            <a-col :span="8">
              <!-- 商品名称搜索 -->
              <SearchSku
                ref="searchSku"
                :placeholder="$t(`商品名称搜索`)"
                @selected="selectedSku"
              ></SearchSku>
              <a-input type="hidden" id="hidden-skuid" v-model="formData.skuId" />
            </a-col>
            <a-col :span="4">
              <!-- 收件人 -->
              <a-input
                allowClear
                type="text"
                :placeholder="$t(`收件人`)"
                v-model.trim="formData.receiverName"
              />
            </a-col>
            <a-col :span="4">
              <!-- 手机号 -->
              <a-input
                allowClear
                type="text"
                :placeholder="$t(`手机号`)"
                v-model.trim="formData.receiverPhone"
              />
            </a-col>
            <a-col :span="4">
              <!-- 仓库 -->
              <SearchWarehouse
                :code.sync="formData.warehouseCode"
                :placeholder="$t(`仓库搜索`)"
              ></SearchWarehouse>
            </a-col>
            <a-col :span="4">
              <!-- 快递 -->
              <SearchShipping
                :code.sync="formData.shippingCode"
                :placeholder="$t(`快递搜索`)"
              ></SearchShipping>
            </a-col>
            <a-col :span="4">
              <!-- 商品类型 -->
              <a-select
                allowClear
                v-model="formData.goodsType"
                style="width: 100%"
                :placeholder="$t(`商品类型`)"
              >
                <a-select-option :value="key" v-for="(item, key) in goodsTypeMapping" :key="key">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :span="4" v-if="isStore">
              <!-- 销售类型 -->
              <a-select
                allowClear
                v-model="formData.saleType"
                style="width: 100%"
                :placeholder="$t(`销售类型`)"
              >
                <a-select-option :value="key" v-for="(item, key) in saleTypeMapping" :key="key">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-col>
            <!-- 用户id -->
            <a-col :span="4" v-else>
              <a-input
                allowClear
                type="text"
                :placeholder="$t(`用户ID`)"
                v-model.trim="formData.buyer"
              />
            </a-col>
            <a-col :span="4">
              <!-- 订单状态 -->
              <SelectOrderStatus mode="multiple" @change="changeOrderStatusList" />
            </a-col>
            <a-col :span="4">
              <!-- 付款状态 -->
              <a-select
                allowClear
                v-model="formData.payStatus"
                style="width: 100%"
                :placeholder="$t(`付款状态`)"
              >
                <a-select-option value="UNPAID">{{ $t('待付款') }}</a-select-option>
                <a-select-option value="PAID">{{ $t(`已付款`) }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="4">
              <!-- 备注类型 -->
              <a-select
                allowClear
                v-model="formData.hasMemo"
                style="width: 100%"
                :placeholder="$t(`备注类型`)"
              >
                <a-select-option value="ALL_MEMO">{{ $t(`全部`) }}</a-select-option>
                <a-select-option value="SELLER_MEMO">{{ $t(`卖家备注`) }}</a-select-option>
                <a-select-option value="BUYER_MEMO">{{ $t(`买家备注`) }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="4">
              <!-- 备注 -->
              <a-input
                allowClear
                v-model="formData.memo"
                style="width: 100%"
                :placeholder="$t(`备注内容`)"
              />
            </a-col>
            <a-col :span="8">
              <!-- 发货时间 -->
              <DateRangePicker
                id="pay-time"
                :needhms="true"
                :placeholder="$t('发货时间')"
                @get_date="(date) => setDateHandle('Shipping', date)"
                :start-time="formData.startShippingTime"
                :end-time="formData.endShippingTime"
              />
            </a-col>
            <a-col :span="4">
              <!-- 缺货状态 -->
              <a-select
                allowClear
                v-model="formData.shortageStatus"
                style="width: 100%"
                :placeholder="$t('缺货状态')"
              >
                <a-select-option :value="undefined">{{ $t('全部') }}</a-select-option>
                <a-select-option value="stockout">{{ $t('缺货') }}</a-select-option>
                <a-select-option value="nonShortage">{{ $t('不缺货') }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="4">
              <!-- 预定状态 -->
              <a-select
                allowClear
                v-model="formData.inventoryStatus"
                style="width: 100%"
                :placeholder="$t('预定状态')"
              >
                <a-select-option :value="undefined">{{ $t('全部') }}</a-select-option>
                <a-select-option value="WAIT_RESERVE">{{ $t('待预定') }}</a-select-option>
                <a-select-option value="RESERVED">{{ $t('已预订') }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="8">
              <!-- 订单金额 -->
              <div class="amout-range-input flex-ct">
                <a-input-number
                  v-model="formData.lowPayment"
                  :placeholder="$t('订单最小金额')"
                  style="flex: 1"
                />
                <span class="ml5 mr5">~</span>
                <a-input-number
                  v-model="formData.highPayment"
                  :placeholder="$t('订单最大金额')"
                  style="flex: 1"
                />
              </div>
            </a-col>
            <a-col :span="8" v-if="isStore">
              <!-- 预计到货时间 -->
              <DateRangePicker
                id="latest-delivery-time"
                :needhms="true"
                :placeholder="$t('预到货时间')"
                @get_date="(date) => setDateHandle('LatestDelivery', date)"
                :start-time="formData.startLatestDeliveryTime"
                :end-time="formData.endLatestDeliveryTime"
              />
            </a-col>
            <a-col :span="4">
              <!-- 订单状态 -->
              <SelectOrderType
                style="width: 100%"
                :code.sync="formData.orderType"
                :placeholder="$t(`订单类型`)"
                @change="changeOrderType"
              />
            </a-col>
            <a-col :span="4" v-if="isStore">
              <!-- 订单子类型 -->
              <div class="amout-range-input flex-ct">
                <SelectOrderSubType
                  :list="orderSubTypeList"
                  mode="multiple"
                  style="width: 100%"
                  @change="selectOrderTag"
                />
              </div>
            </a-col>
            <a-col :span="4" v-if="isStore">
              <!-- 平台 -->
              <a-select
                allowClear
                v-model="formData.platform"
                style="width: 100%"
                :placeholder="$t('平台')"
              >
                <a-select-option value="RUI_BEN_YUN">{{ $t('瑞本云') }}</a-select-option>
                <a-select-option value="MEI_YI_JIA">{{ $t('美宜佳') }}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="4">
              <!-- 订单来源 -->
              <a-select
                allowClear
                v-model="formData.orderFrom"
                style="width: 100%"
                :placeholder="$t('订单来源')"
              >
                <a-select-option value="MANUAL">{{ $t('手工录单') }}</a-select-option>
                <a-select-option value="DIRECT">{{ $t('平台订单') }}</a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </div>

        <div class="buttons">
          <div class="highSearchBar">
            <label v-show="openSearchDiv" class="showAndHide" @click="more">
              {{ $t(`收起`) }}
              <i class="fa fa-angle-up" style="margin-left: 5px"></i>
            </label>
            <label v-show="!openSearchDiv" class="showAndHide" @click="more">
              {{ $t(`展开`) }}
              <i class="fa fa-angle-down" style="margin-left: 5px"></i>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="content" style="margin-top: 10px">
      <div class="table-content" v-if="priv('f_sale_order_list')">
        <ul class="action-list clearfix">
          <template v-if="isStore">
            <a-button type="primary" @click="showExportShippingModal">
              {{ $t(`导出订货明细`) }}
            </a-button>
            <a-button type="primary" @click="exportNoOrderShopList">
              {{ $t(`导出今日未下单门店`) }}
            </a-button>
          </template>
          <!-- 批量复制订单号 -->
          <a-dropdown placement="topCenter" :disabled="selectedRowKeys.length === 0">
            <a-menu slot="overlay">
              <a-menu-item @click="batchCopy('orderSn')">
                {{ $t('OMS订单号') }}
              </a-menu-item>
              <a-menu-item @click="batchCopy('platformOrderId')">
                {{ $t('平台订单号') }}
              </a-menu-item>
            </a-menu>
            <a-button type="primary" class="operateBtn">
              {{ $t(`批量复制订单号`) }}
            </a-button>
          </a-dropdown>

          <!-- 同名合并 -->
          <a-button
            :disabled="waitCheckedOrderSns.length === 0"
            type="primary"
            class="operateBtn"
            :loading="mergeLoading"
            @click="mergeOrders"
          >
            {{ $t('合并订单') }}
          </a-button>
          <!-- 批量添加赠品 -->
          <a-button
            :disabled="waitCheckedOrderSns.length === 0"
            type="primary"
            class="operateBtn"
            @click="jumpToBatch('BATCH_ADD_GIFT')"
          >
            {{ $t(`批量添加赠品`) }}
          </a-button>
          <!-- 批量添加赠品 -->
          <a-button
            :disabled="waitCheckedOrderSns.length === 0"
            type="danger"
            class="operateBtn"
            @click="jumpToBatch('BATCH_DELETE_GIFT')"
          >
            {{ $t(`批量删除赠品`) }}
          </a-button>
          <a-button
            :disabled="waitCheckedOrderSns.length === 0"
            type="primary"
            class="operateBtn ml10"
            @click="jumpToBatch('BATCH_CHECK')"
          >
            {{ $t(`审核`) }}
          </a-button>
          <!-- 批量驳回 -->
          <a-tooltip>
            <template slot="title">
              <p>{{ $t(`取消下游订单，并把订单恢复为待审核`) }}</p>
            </template>
            <a-button
              :disabled="selectedRowKeys.length === 0"
              type="primary"
              class="operateBtn"
              :style="selectedRowKeys.length === 0 ? 'margin-left: 5px' : ''"
              @click="jumpToBatch('BATCH_REJECT')"
            >
              {{ $t(`驳回`) }}
              <a-icon type="question-circle"></a-icon>
            </a-button>
          </a-tooltip>
          <!-- 作废 -->
          <a-tooltip>
            <template slot="title">
              <p>{{ $t(`取消订单，需要手动恢复订单为待审核状态`) }}</p>
            </template>
            <a-button
              :disabled="selectedRowKeys.length === 0"
              type="danger"
              :style="selectedRowKeys.length === 0 ? 'margin-right: 5px' : ''"
              class="operateBtn"
              @click="jumpToBatch('BATCH_CANCEL')"
            >
              {{ $t(`作废`) }}
              <a-icon type="question-circle"></a-icon>
            </a-button>
          </a-tooltip>
          <!-- 恢复待审核 -->
          <a-button
            :disabled="closedOrderSns.length === 0"
            type="primary"
            class="operateBtn"
            @click="jumpToBatch('BATCH_REVERT_WAIT_CHECK')"
          >
            {{ $t(`恢复待审核`) }}
          </a-button>
          <!-- 批量拆单 -->
          <a-button
            :disabled="selectedRowKeys.length === 0"
            type="primary"
            class="operateBtn ml10"
            @click="jumpToBatch('BATCH_SPLIT_BY_BARCODE')"
          >
            {{ $t(`批量拆单`) }}
          </a-button>
          <!-- 批量挂起 -->
          <a-button
            :disabled="selectedRowKeys.length === 0"
            type="primary"
            class="operateBtn ml10"
            @click="jumpToBatch('BATCH_SUSPEND')"
          >
            {{ $t(`批量挂起`) }}
          </a-button>
          <!-- 批量关闭 -->
          <a-button
            :disabled="selectedRowKeys.length === 0"
            type="danger"
            class="operateBtn ml10"
            @click="jumpToBatch('BATCH_CLOSE')"
          >
            {{ $t(`批量关闭`) }}
          </a-button>
          <!-- <a-button
            :disabled="selectedRowKeys.length === 0"
            type="primary"
            class="operateBtn ml10"
            @click="printPicklist"
          >
            {{ $t(`打印拣货单`) }}
          </a-button> -->
          <a-button
            :disabled="selectedRowKeys.length === 0"
            type="primary"
            :loading="reminderOrderLoading"
            class="operateBtn ml10"
            @click="reminderOrder"
          >
            {{ $t(`加急`) }}
          </a-button>
          <a-button
            :disabled="selectedOutfStockRows.length === 0"
            type="primary"
            class="operateBtn ml10"
            @click="createPurchaseRequest"
          >
            {{ $t(`创建采购申请单`) }}
          </a-button>

          <div
            style="margin-left: auto; cursor: pointer"
            v-if="searchResultText"
            @click="showOrderSnModal"
          >
            <a-icon type="info-circle" class="text-warning" />
            {{ searchResultText }}
          </div>

          <div
            class="text-link"
            v-if="saleOrderList.length > 0"
            style="margin-left: auto; cursor: pointer"
            @click="expandAll"
          >
            {{ $t('展开全部') }}
          </div>
        </ul>
        <a-table
          :scroll="{ x: 2000, y: tableHeight }"
          :bordered="false"
          :pagination="showPage"
          style="margin-top: 10px"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          rowKey="orderSn"
          :columns="columns"
          :expandIconAsCell="false"
          :expandIconColumnIndex="1"
          :data-source="saleOrderList"
          :expandedRowKeys.sync="expandedRowKeys"
        >
          <!-- OMS订单号 -->
          <template slot="OMSOrderNumber" slot-scope="text, record">
            <a
              class="detail-link"
              :class="{ visited: hasVisited(record) }"
              @click="jumpDetails(record)"
            >
              {{ record.orderSn }}
            </a>
            <a @click="cloneOrderSn(record.orderSn)" class="ml5">
              <a-icon class="text-main" type="copy" />
            </a>
            <p>{{ record.platformOrderId }}</p>
            <div>
              <ul v-if="record.orderProcess">
                <a-tag
                  color="red"
                  v-if="
                    constants.WAIT_CHECK_STATUS.includes(record.orderProcess.financeCheckStatus)
                  "
                >
                  {{ $t(`待财审`) }}
                </a-tag>
              </ul>
              <ul v-if="record.orderProcess">
                <a-tag
                  color="red"
                  v-for="item in record.orderProcess.exceptionTagList"
                  :key="item.value"
                >
                  {{ item.value }}
                </a-tag>
              </ul>
            </div>
          </template>

          <!-- 收件人信息 -->
          <template slot="RecipientInformation" slot-scope="text, record">
            <p>{{ record.receiverName }}</p>
            <OmitText :text="record.buyer" />
            <p>{{ record.receiverMobile }}</p>
          </template>

          <!-- 店铺 -->
          <template slot="ShopName" slot-scope="text, record">
            <p>{{ record.shop.shopName }}</p>
          </template>

          <!-- 应收金额 /付款状态 -->
          <template slot="AmountReceivable" slot-scope="text, record">
            <span style="margin-right: 5px">{{ toThousandAmount(record.totalFee) }}</span>
            <a-tag color="red" v-if="record.payStatus == 'UNPAID'">{{ $t('待付款') }}</a-tag>
            <a-tag v-else color="orange">{{ payStatusMapping[record.payStatus] }}</a-tag>
            <a-tag size="small" color="blue" v-if="record.payType">{{ record.payType }}</a-tag>
          </template>

          <!-- 订单数量 -->
          <template slot="OrderCount" slot-scope="text, record">
            {{ record.skuType }}
          </template>

          <!-- 订单类型 -->
          <template slot="OrderTypeValue" slot-scope="text, record">
            <div>{{ record.orderTypeValue }}</div>
            <div v-if="record.orderTags.length > 0">
              {{ getOrderSubType(record) }}
            </div>
          </template>

          <!-- 订单时间 -->
          <template slot="OrderTime" slot-scope="text, record">
            <p>
              <span class="gray">{{ $t(`下`) }}</span>
              {{ record.platformOrderTime }}
            </p>
            <p>
              <span class="gray">{{ $t(`付`) }}</span>
              {{ record.paytime }}
            </p>
            <p>
              <span class="gray">{{ $t(`创`) }}</span>
              {{ record.createTime }}
            </p>
            <p v-if="record.orderProcess?.checkTime">
              <span class="green">{{ $t(`审`) }}</span>
              {{ record.orderProcess.checkTime }}
              {{ record.orderProcess.checkName }}
            </p>
            <p v-if="record.orderProcess?.pushWmsTime">
              <span class="green">{{ $t(`推`) }}</span>
              {{ record.orderProcess.pushWmsTime }}
            </p>
            <p v-if="record.shippingTime">
              <span class="green">{{ $t(`发`) }}</span>
              {{ record.shippingTime }}
            </p>
            <p v-if="record.receiveTime">
              <span class="green">{{ $t(`签`) }}</span>
              {{ record.receiveTime }}
            </p>
          </template>

          <!-- 订单状态 -->
          <template slot="OrderStatus" slot-scope="text, record">
            <a-popover v-if="record.latestDeliveryTime && timeLeft(record.latestDeliveryTime)">
              <template slot="content">
                <p>{{ $t('在T之前发货，以避免取消和处罚', { T: record.latestDeliveryTime }) }}</p>
              </template>
              <a-tag color="red">
                {{ timeLeft(record.latestDeliveryTime) }}
              </a-tag>
            </a-popover>
            <!-- 状态太乱 交给后端控制 前端只负责展示 orderStatusValue -->
            <p>{{ record.orderStatusValue }}</p>
          </template>

          <!-- 仓库 -->
          <template slot="Warehouse" slot-scope="text, record">
            {{ record.warehouse?.warehouseName }}
          </template>

          <!-- 预计到货时间 -->
          <template slot="latestDeliveryTime" slot-scope="record">
            <template v-if="record.editLatestDeliveryTime">
              <DatePicker
                id="latestDeliveryTime"
                @choose="
                  (data) => {
                    chooseLatestDeliveryTime(record, data)
                  }
                "
                :init-date-time="record.latestDeliveryTime"
                :needhms="false"
              ></DatePicker>
              <a-icon
                type="check-circle"
                class="text-link mr10 ml6"
                @click="modifyDeliverTime(record)"
              />
              <a-icon type="close-circle" class="text-link" @click="cancelModify(record)" />
            </template>
            <template v-else>
              <span>{{ getDate(record.latestDeliveryTime) }}</span>
              <a-icon
                class="text-link ml6"
                type="edit"
                @click="editTimeHandle(record)"
                v-if="record.orderStatus === 'TO_REVIEW'"
              />
            </template>
          </template>

          <!-- 快递-->
          <template slot="Express" slot-scope="text, record">
            <a-tag v-if="record.collectMethod">
              {{ $t(record.collectMethod) }}
            </a-tag>
            <p v-if="record.shipping">
              {{ record.shipping.shippingName }}
            </p>
            <span v-for="(item, index) in record.packages" :key="index">
              {{ item.logisticsNumber }}
            </span>
            <p>{{ record.waybillNo }}</p>
          </template>

          <p slot="expandedRowRender" slot-scope="record" style="margin: 0">
            <a-table
              :pagination="showPage"
              size="small"
              style="width: 50%; padding: 10px 50px"
              :columns="columnSon"
              :dataSource="record.orderGoodses"
              :rowKey="(record, index) => index"
            >
              <!-- 商家编码 -->
              <template slot="ItemMerchantCode" slot-scope="text, record">
                {{ record.sku.skuCode }}
                <a-tag color="orange" v-if="orderTypeFilter[record.skuType]">
                  {{ orderTypeFilter[record.skuType] }}
                </a-tag>
              </template>
              <!-- 商品名称-->
              <template slot="ProductName" slot-scope="text, record">
                {{ record.sku.skuName }}
              </template>
              <!-- 数量 -->
              <template slot="Quantity" slot-scope="text, record">
                {{ record.number }} {{ record.orderUnit }}
              </template>
              <!-- 实际发货数量 -->
              <template slot="ActualNumber" slot-scope="text, record">
                <span>{{ record.actualNumber }} {{ record.orderUnit }}</span>
              </template>
            </a-table>

            <a-row style="width: 50%; padding: 10px 50px">
              <a-col :span="10">{{ $t(`顾客留言`) }}：{{ record.buyerMemo }}</a-col>
              <a-col :span="10">{{ $t(`卖家留言`) }}：{{ record.sellerMemo }}</a-col>
            </a-row>
          </p>
        </a-table>
        <div class="table-footer">
          <Pagination
            :offset="formData.offset"
            :length="formData.length"
            :total-count="pageData.totalCount"
            @paginate="paginate"
          />
        </div>
      </div>
    </div>
    <!-- 结束弹出框 -->
    <Printinfo
      @cancel="cancelPrint"
      v-if="printList"
      :print-list="printList"
      :visible="printVisible"
    />
    <OrderSnModal ref="orderSnModal" :searchType="selectType" :list="notExistSn" />
    <a-modal
      v-model="reminderOrderModalVisible"
      width="600px"
      :title="$t('加急')"
      :footer="null"
      @cancel="
        () => {
          reminderOrderModalVisible = false
        }
      "
    >
      <template v-if="successOrderSns.length > 0">
        <div class="infoTitle">{{ $t('加急成功列表') }}</div>
        <div class="order-sn-text" v-for="(item, index) in successOrderSns" :key="index">
          {{ item }}
        </div>
      </template>
      <template v-if="failOrderSns.length > 0">
        <div class="infoTitle">{{ $t('加急失败列表') }}</div>
        <a-button type="primary" class="mb10" @click="cloneOrderSn(failOrderSns.join(' '))">
          {{ $t('复制订单号') }}
        </a-button>
        <div class="order-sn-text" v-for="(item, index) in failOrderSns" :key="index">
          {{ item }}
        </div>
      </template>
    </a-modal>
    <a-modal
      v-model="exportShippingVisible"
      width="600px"
      :title="$t('导出订货单')"
      :confirmLoading="exportShippingLoading"
      @ok="exportShippingList"
      @cancel="
        () => {
          exportShippingVisible = false
        }
      "
    >
      <a-form>
        <a-form-item :label="$t('店铺名称')">
          <a-radio-group v-model="shopFullName">
            <a-radio :value="true">{{ $t('全称') }}</a-radio>
            <a-radio :value="false">{{ $t('简称') }}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item :label="$t('导出单位')">
          <a-radio-group v-model="unitType">
            <a-radio value="orderUnit">{{ $t('以库存单位导出') }}</a-radio>
            <a-radio value="rawUnit">{{ $t('以实际订货单位导出') }}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item :label="$t('导出排序')">
          <a-radio-group v-model="sortType">
            <a-radio value="shopSort">{{ $t('以店铺排序导出') }}</a-radio>
            <a-radio value="routeSort">{{ $t('以线路导出') }}</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { CheckIsInstall } from "@/common/common.js"
import { copy, handleOrderIds, judgeOMSOrderSn, timeLeft } from "@common"
import debounce from "@common/debounce"
import deepClone from "@common/deepClone"
import { getDateStr } from "@common/getDateStr"
import DatePicker from "@component/datePicker"
import DateRangePicker from "@component/dateRangePicker"
import OmitText from "@component/omitText"
import OrderSnModal from "@component/orderSnModal"
import Pagination from "@component/pagination"
import SearchShipping from "@component/searchShipping"
import SearchSku from "@component/searchSku"
import SearchWarehouse from "@component/searchWarehouse"
import SelectMutiShop from "@component/selectMutiShop"
import SelectOrderStatus from "@component/selectOrderStatus"
import SelectOrderSubType from "@component/selectOrderSubType"
import SelectOrderType from "@component/selectOrderType"
import api from "@service/api"
import http from "@service/axios"
import { mapGetters, mapState } from "vuex"
import Printinfo from "./Printinfo.vue"

export default {
	name: "saleOrders",
	activated() {
		const query = this.$route.query
		if (query?.order_sn) {
			this.formData.orderSns = query?.order_sn
		} else if (query?.isBigCakeOrder) {
			this.formData.statusType = "waitCheck"
			this.formData.orderTags = [{ key: "BIG_CAKE_ORDER", type: "orderSubType" }]
			this.formData.startPayTime = undefined
			this.formData.endPayTime = undefined
		}
		this.clearSelectKeys()
		this.searchSaleOrders()
	},
	mounted() {
		this.formData.startPayTime = getDateStr(-7, true)
		this.formData.endPayTime = `${getDateStr(1, false)} 00:00:00`
		this.formData.startOrderTime = undefined //默认是没有值的
		this.formData.endOrderTime = undefined //默认是没有值的
	},
	computed: {
		...mapState(["lang", "shopList", "orderSubTypeMapping", "goodsTypeMapping", "saleTypeMapping"]),
		...mapGetters(["currentShop"]),
		orderSnTypeList() {
			return this.constants.ORDER_SN_TYPE
		},
		country() {
			return this.$store.getters.country
		},
		statusListTop() {
			if (this.isStore) {
				return [
					{
						statusName: this.$t("全部"), //全部
						status: undefined,
					},
					{
						statusName: this.$t("待审核"), //"待审核",
						status: "waitCheck",
					},
					{
						statusName: this.$t("待推送"), //"待推送",
						status: "waitPush",
					},
					{
						statusName: this.$t("已推送"), //"已推送",
						status: "pushed",
					},
					{
						statusName: this.$t("已拣货"), //"已拣货",
						status: "picked",
						tips: this.$t("商品已从库位下架"),
					},
					{
						statusName: this.$t("待发货"), //"待发货",
						status: "unship",
						tips: this.$t("包含待审核、待推送、已推送、已拣货状态"),
					},
					{
						statusName: this.$t("已配货"), //"已配货",
						status: "opreated",
						tips: this.$t("商品已完成分拣，商品放到篮子里"),
					},
					{
						statusName: this.$t("已签收"), //"已签收",
						status: "received",
					},
					{
						statusName: this.$t("已作废"), //"已作废",
						status: "canceled",
					},
					{
						statusName: this.$t("已挂起"), //"已挂起",
						status: "SUSPEND",
					},
					{
						statusName: this.$t("已关闭"), //"已关闭",
						status: "closed",
					},
				]
			}
			return [
				{
					statusName: this.$t("全部"), //全部
					status: undefined,
				},
				{
					statusName: this.$t("待预定"), //"待预定",
					status: "waitReserve",
				},
				{
					statusName: this.$t("待审核"), //"待审核",
					status: "waitCheck",
				},
				{
					statusName: this.$t("待获取面单"), //"待获取面单",
					status: "pendingWaybill",
				},
				{
					statusName: this.$t("待推送"), //"待推送",
					status: "waitPush",
				},
				{
					statusName: this.$t("已推送"), //"已推送",
					status: "pushed",
				},
				{
					statusName: this.$t("已拣货"), //"已拣货",
					status: "picked",
				},
				{
					statusName: this.$t("待发货"), //"待发货",
					status: "unship",
					tips: this.$t("包含待审核、待推送、已推送、已拣货状态"),
				},
				{
					statusName: this.$t("已配货"), //"已配货",
					status: "opreated",
				},
				{
					statusName: this.$t("已打包"), //"已打包",
					status: "packed",
				},
				{
					statusName: this.$t("已出库"), //"已出库",
					status: "handed_over",
					tips: this.$t("实物已出仓库"),
				},
				{
					statusName: this.$t("已揽收"), //"已揽收",
					status: "collected",
				},
				{
					statusName: this.$t("已签收"), //"已签收",
					status: "received",
				},
				{
					statusName: this.$t("已拒收"), //"已拒收",
					status: "rejected",
				},
				{
					statusName: this.$t("已作废"), //"已作废",
					status: "canceled",
				},
				{
					statusName: this.$t("已挂起"), //"已挂起",
					status: "SUSPEND",
				},
			]
		},
		payStatusMapping() {
			return {
				PAID: this.$t("已付款"), //"已付款",
				PART_PAID: this.$t("部分付款"), //"部分付款",
			}
		},
		columns() {
			const list = [
				{
					title: this.$t("OMS订单号"),
					key: "OMSOrderNumber",
					width: 230,
					scopedSlots: { customRender: "OMSOrderNumber" },
				},
				{
					title: this.$t("收件人信息"),
					key: "RecipientInformation",
					scopedSlots: { customRender: "RecipientInformation" },
				},
				{
					title: this.$t("店铺"),
					key: "ShopName",
					scopedSlots: { customRender: "ShopName" },
				},
				{
					title: this.$t("应收金额"),
					key: "AmountReceivable",
					scopedSlots: { customRender: "AmountReceivable" },
				},
				{
					title: this.$t("订单数量"),
					key: "OrderCount",
					scopedSlots: { customRender: "OrderCount" },
				},
				{
					title: this.$t("订单类型"),
					dataIndex: "orderTypeValue",
					scopedSlots: { customRender: "OrderTypeValue" },
				},
				{
					title: this.$t("订单时间"),
					key: "OrderTime",
					width: 230,
					scopedSlots: { customRender: "OrderTime" },
				},
				{
					title: this.$t("订单状态"),
					key: "OrderStatus",
					scopedSlots: { customRender: "OrderStatus" },
				},
				{
					title: this.$t("快递"),
					key: "Express",
					scopedSlots: { customRender: "Express" },
				},
				{
					title: this.$t("仓库"),
					key: "Warehouse",
					scopedSlots: { customRender: "Warehouse" },
				},
				{
					title: this.$t("卖家备注"),
					dataIndex: "sellerMemo",
					width: 200,
				},
				{
					title: this.$t("买家备注"),
					dataIndex: "buyerMemo",
					width: 200,
				},
			]
			if (this.isStore) {
				list.splice(-3, 0, {
					title: this.$t("预计到货时间"),
					scopedSlots: { customRender: "latestDeliveryTime" },
				})
				list.splice(-1, 0, ...list.splice(1, 1))
				list.splice(1, 0, ...list.splice(-1, 1))
			}
			return list
		},
		columnSon() {
			const list = [
				{
					title: this.$t("商家编码"),
					key: "ItemMerchantCode",
					scopedSlots: { customRender: "ItemMerchantCode" },
				},
				{
					title: this.$t("商品名称"),
					key: "ProductName",
					scopedSlots: { customRender: "ProductName" },
				},
				{
					title: this.$t("规格"),
					dataIndex: "attributeValue",
				},
				{
					title: this.$t("数量"),
					key: "Quantity",
					scopedSlots: { customRender: "Quantity" },
				},
				{
					title: this.$t("实际发货数量"),
					key: "ActualNumber",
					scopedSlots: { customRender: "ActualNumber" },
				},
			]
			if (this.isStore) {
				list.push(
					...[
						{
							title: this.$t("商品类型"),
							dataIndex: "goodsType",
							customRender: (text) => this.goodsTypeMapping[text],
						},
						{
							title: this.$t("销售类型"),
							dataIndex: "saleType",
							customRender: (text) => this.saleTypeMapping[text],
						},
					]
				)
			}
			return list
		},
		tableHeight() {
			return (window.innerHeight - 520) * this.$store.state.rate
		},
		orderTypeFilter() {
			return this.constants.ORDER_TYPE_MAPPING
		},
		timeoutSituationList() {
			return [
				{
					name: this.$t("即将逾期订单"),
					code: "ATBSLA",
					tips: this.$t("距离最晚发货时间小于24小时，请确保按时发货！"),
				},
				{
					name: this.$t("已逾期订单"),
					code: "SLAB",
					tips: this.$t("请立即发货，以防这些订单因超期而被平台取消和处罚！"),
				},
				{
					name: this.$t("拣货超时"),
					code: "pickException",
					tips: this.$t("订单推送仓库24小时后，仓库还未完成拣货"),
					isStatus: 1,
				},
				{
					name: this.$t("揽收超时"),
					code: "collectedException",
					tips: this.$t("订单仓库出库24小时后，快递还未完成揽收"),
					isStatus: 1,
				},
				{
					name: this.$t("配送超时"),
					code: "deliveryException",
					tips: this.$t("包裹快递揽收72小时后，还未完成配送"),
					isStatus: 1,
				},
			]
		},
		isStore() {
			return this.$store.getters.isStore
		},
	},
	data() {
		return {
			printList: [],
			printVisible: false,
			waitCheckedOrderSns: [],
			closedOrderSns: [],
			showPage: false,
			selectedRowKeys: [], // Check here to configure the default column
			selectedRows: [],
			selectedOutfStockRows: [],
			loading: false,
			openSearchDiv: false,
			pageData: {
				totalCount: undefined,
			},
			formData: {
				payType: undefined,
				partyId: undefined,
				offset: 0,
				length: 10,
				orderSns: undefined,
				shopCodeList: [],
				orderType: undefined,
				orderStatusList: [],
				payStatus: undefined,
				buyer: undefined,
				receiverName: undefined,
				receiverPhone: undefined,
				receiver_province: undefined,
				receiver_city: undefined,
				outerCode: undefined,
				skuId: undefined,
				skuCode: undefined,
				isGift: undefined,
				startOrderTime: undefined,
				endOrderTime: undefined,
				startPayTime: undefined,
				endPayTime: undefined,
				startReceiveTime: undefined,
				endReceiveTime: undefined,
				orderStatus: undefined,
				warehouseCode: undefined,
				warehouseType: undefined,
				shippingCode: undefined,
				hasMemo: undefined, //ALL_MEMO/买卖家备注搜索，SELLER_MEMO/卖家备注，BUYER_MEMO/买家备注
				memo: undefined, //备注内容
				statusType: undefined,
				abnormalType: undefined, //异常状态值 前端状态 最终传值还是statusType
				startShippingTime: undefined,
				endShippingTime: undefined,
				shortageStatus: undefined, //是否缺货
				inventoryStatus: undefined, //预定状态
				isCustomized: undefined, //是否定制商品
				timeoutSituation: undefined, //履约SLA
				startLatestDeliveryTime: undefined,
				endLatestDeliveryTime: undefined,
			},
			saleOrderList: [],
			selectedOrderList: [],
			visitedList: [],
			mergeLoading: false,
			notExistSn: [],
			selectType: "orderNo",
			searchResultText: undefined,
			successOrderSns: [],
			failOrderSns: [],
			reminderOrderModalVisible: false,
			reminderOrderLoading: false,
			isStatus: false,
			statusListBottom: [],
			expandedRowKeys: [],
			shopFullName: false,
			unitType: "orderUnit",
			sortType: "shopSort",
			exportShippingVisible: false,
			orderSubTypeList: [],
			exportShippingLoading: false,
		}
	},
	components: {
		SearchSku,
		SearchShipping,
		DateRangePicker,
		Pagination,
		SearchWarehouse,
		SelectMutiShop,
		Printinfo,
		OrderSnModal,
		OmitText,
		SelectOrderType,
		SelectOrderSubType,
		DatePicker,
		SelectOrderStatus,
	},
	methods: {
		timeLeft,
		reset() {
			for (const item in this.formData) {
				this.formData[item] = undefined
			}
			this.formData.startPayTime = getDateStr(-7, true)
			this.formData.endPayTime = `${getDateStr(1, false)} 00:00:00`
			this.formData.offset = 0
			this.formData.length = 10
			this.formData.shopCodeList = []
			this.$refs.searchSku.clear()
			console.log(this.$refs.dateRangePickerRef)
			this.$nextTick(() => this.searchSaleOrderList())
		},
		exportSaleOrderList() {
			const currentShop = JSON.parse(localStorage.getItem("currentShop"))
			const mergeCode = currentShop?.uniqueCode
			const appCode = localStorage.getItem("appCode")
			const data = deepClone(this.formData)
			let orderSnType
			if (this.selectedRowKeys.length) {
				data.orderSns = this.selectedRowKeys
				orderSnType = this.orderSnTypeList[1] //表格中有勾选就写死orderSn
			} else if (data.orderSns) {
				if (this.selectType === this.orderSnTypeList[2]) {
					orderSnType = this.orderSnTypeList[2]
				} else {
					orderSnType = judgeOMSOrderSn(data.orderSns, this.orderSnTypeList)
					if (!orderSnType) {
						this.$message.error(this.$t("导出内容不能同时包含平台订单号和OMS订单号"))
						return
					}
				}
				data.startPayTime = undefined
				data.endPayTime = undefined
				data.orderSns = handleOrderIds(data.orderSns)
			} else {
				data.orderSns = []
			}
			if (data.abnormalType) {
				data.statusType = data.abnormalType
			}
			data.partyId = this.$store.getters.partyId
			data.merchantCode = localStorage.getItem("merchantCode")
			data.orderSnType = orderSnType
			if (data.shopCodeList.length === 0) {
				data.shopCodeList = this.$store.state.shopList.map((item) => ({
					platform: item.platform,
					shopCode: item.shopCode,
				}))
			}
			data.offset = undefined
			data.length = undefined
			http({
				url: api.createTask,
				data: {
					bookCode: "orderListExport",
					group: "",
					mergeCode,
					merchantShopName: currentShop?.name,
					appCode,
					params: { data },
				},
				type: "post",
				hasLoading: false,
				success: (res) => {
					// console.log(res)
					if (res.result.task_id) {
						this.$message.success(this.$t("创建导出任务成功,请前往【数据中心/下载中心】下载"))
					} else {
						this.$message.error(this.$t("创建导出任务失败"))
					}
				},
			})
		},
		mergeOrders: debounce(async function () {
			if (!this.selectedOrderList.length) {
				this.$message.warning(this.$t("请选择订单！"))
				return
			}
			this.mergeLoading = true
			await http({
				url: api.mergeOrders,
				type: "post",
				hasLoading: true,
				data: {
					data: {
						orderIds: this.selectedOrderList,
					},
				},
				success: () => {
					this.$message.success(this.$t("合并成功"))
					this.searchSaleOrders()
				},
				fail: (res) => {
					this.$message.error(res.subMsg || res.msg)
				},
			})
			this.mergeLoading = false
		}),
		jumpToBatch(taskType) {
			let orderSns = []
			switch (taskType) {
				case "BATCH_ADD_GIFT":
				case "BATCH_DELETE_GIFT":
				case "BATCH_CHECK":
					orderSns = this.waitCheckedOrderSns?.join(",")
					break
				case "BATCH_REVERT_WAIT_CHECK":
					orderSns = this.closedOrderSns?.join(",")
					break
				default:
					orderSns = this.selectedRowKeys?.join(",")
			}
			this.$router.push({
				path: "./createBatchCheckOrderJob",
				query: { orderSns, taskType },
			})
		},
		//一键复制功能
		cloneOrderSn(text) {
			copy(text)
		},
		hasVisited(record) {
			return this.visitedList.includes(record.orderSn)
		},
		jumpDetails(record) {
			this.visitedList.push(record.orderSn)
			this.$addPane({
				view: `orderDetails/${record.orderSn}`,
				name: "订单详情",
				params: {
					orderId: record.orderId,
					orderSn: record.orderSn,
				},
				route: `/orderManage/omsOrderDetail/orderDetails/${record.orderId}`,
			})
		},
		onSelectChange(selectedRowKeys, selectedRows) {
			const selectedOrderList = []
			const waitCheckedOrderSns = []
			const selectedOutfStockRows = []
			const closedOrderSns = [] //已作废的订单列表 用来批量恢复待审核
			for (const item of selectedRows) {
				selectedOrderList.push(item.orderId)
			}
			selectedRows.forEach((e) => {
				if (this.constants.WAIT_CHECK_STATUS.includes(e.orderStatus)) {
					waitCheckedOrderSns.push(e.orderSn)
				} else if (this.constants.CLOSE_ORDER_STATUSES.includes(e.orderStatus)) {
					closedOrderSns.push(e.orderSn)
				}
				//过滤缺货且销售类型是采购销售或备货销售的orderSn
				const hasOutStock = e.orderGoodses.findIndex((item) => item.outStock && ["PURCHASE_SALE", "STOCK_UP_FOR_SALE"].includes(item.saleType)) > -1
				if (hasOutStock) {
					selectedOutfStockRows.push(e)
				}
			})
			this.waitCheckedOrderSns = waitCheckedOrderSns
			this.closedOrderSns = closedOrderSns
			this.selectedRowKeys = selectedRowKeys
			this.selectedRows = selectedRows
			this.selectedOrderList = selectedOrderList
			this.selectedOutfStockRows = selectedOutfStockRows
		},
		more() {
			this.openSearchDiv = !this.openSearchDiv
		},
		gotoOrderDetail(orderId) {
			this.$router.push({
				name: "orderDetails",
				params: {
					orderId: orderId,
				},
			})
		},
		clearSelectKeys() {
			this.waitCheckedOrderSns = []
			this.closedOrderSns = []
			this.selectedRowKeys = []
			this.selectedRows = []
			this.selectedOrderList = []
		},
		paginate(data) {
			this.clearSelectKeys()
			this.formData.offset = data.offset
			this.formData.length = data.length
			this.searchSaleOrders()
		},
		setDateHandle(name, date) {
			this.formData[`start${name}Time`] = date.start_time
			this.formData[`end${name}Time`] = date.end_time
		},
		selectedSku(data) {
			this.formData.skuId = data?.skuId
			this.formData.skuCode = data?.skuCode
		},
		setSearchStatus(status) {
			this.clearSelectKeys()
			this.formData.abnormalType = undefined
			this.formData.statusType = status
			this.searchSaleOrders()
		},
		searchSaleOrderList() {
			this.formData.offset = 0
			this.clearSelectKeys()
			this.searchSaleOrders()
		},
		searchSaleOrders() {
			const data = deepClone(this.formData)
			if (data.orderSns) {
				let orderSnType
				if (this.selectType === this.orderSnTypeList[2]) {
					orderSnType = this.orderSnTypeList[2]
				} else {
					orderSnType = judgeOMSOrderSn(data.orderSns, this.orderSnTypeList)
					if (!orderSnType) {
						this.$message.error(this.$t("搜索内容不能同时包含平台订单号和OMS订单号"))
						return
					}
				}
				data.orderSnType = orderSnType
				data.startPayTime = undefined
				data.endPayTime = undefined
				data.orderSns = handleOrderIds(data.orderSns)
			} else {
				data.orderSns = []
			}
			if (data.memo && !data.hasMemo) {
				this.formData.hasMemo = data.hasMemo = "ALL_MEMO"
			}
			if (this.isStatus) {
				data.statusType = data.timeoutSituation
				data.timeoutSituation = undefined
			}
			if (data.abnormalType) {
				this.formData.statusType = undefined
				data.statusType = data.abnormalType
			}
			if (data.orderStatusList?.length > 0) {
				this.formData.statusType = undefined
				data.statusType = undefined
			}
			this.getSaleOrderExceptionTagNum(data)
			http({
				url: api.getSaleOrderList,
				type: "post",
				data: { data },
				hasLoading: true,
				success: (res) => {
					const result = res.data || res.result
					this.saleOrderList = result?.content
					const query = this.$route.query
					this.notExistSn = result.notExistSn || []
					if (data.orderSns.length && result.notExistSn?.length) {
						this.searchResultText = this.$t("共X条记录，Y条记录未找到", {
							X: data.orderSns.length,
							Y: result.notExistSn.length,
						})
					} else {
						this.searchResultText = undefined
					}
					if (query?.order_sn && query?.type === "xs") {
						this.saleOrderList.forEach((item) => {
							if (item.orderType === "SALE") {
								this.jumpDetails(item)
							}
						})
					} else if (query?.order_sn) {
						this.saleOrderList.forEach((item) => {
							if (item.orderType === "EXCHANGE") {
								this.jumpDetails(item)
							}
						})
					}
					this.pageData.totalCount = res.data?.totalCount || res.result?.totalCount || 0
				},
				fail: (res) => {
					console.log(res)
					if (String(res.code) === "500") {
						this.$confirm({
							title: res.msg,
							content: this.$t("是否去创建店铺页面"),
							onOk: () => {
								window.location.href = "/shop_web/shopManage"
							},
						})
					}
				},
			})
		},
		getSaleOrderExceptionTagNum(data) {
			const params = deepClone(data)
			params.statusType = undefined
			http({
				url: api.getSaleOrderExceptionTagNum,
				data: { data: params },
				success: (res) => {
					const exceptionTagNum = res.result.exceptionTagNum
					const list = [
						{
							statusName: this.$t("全部"), //全部
							status: "allException",
						},
						{
							statusName: this.$t("审核回传失败"), //"审核回传失败",
							status: "approveNotifyFail",
						},
						{
							statusName: this.$t("申请退款"), //"申请退款",
							status: "userPendingRefund",
						},
						{
							statusName: this.$t("获取面单失败"), //"获取面单失败",
							status: "waybillFail",
						},
						{
							statusName: this.$t("推送失败"), //"推送失败"
							status: "pushFail",
						},
						{
							statusName: this.$t("取消失败"), //"取消失败"
							status: "cancelFail",
						},
						{
							statusName: this.$t("缺货"), //"缺货"
							status: "stockout",
						},
						{
							statusName: this.$t("改地址失败"), //"改地址失败"
							status: "updateAddressFail",
						},
						{
							statusName: this.$t("待拦截"), //"待拦截"
							status: "waitIntercept",
						},
						{
							statusName: this.$t("回传失败"), //"回传失败"
							status: "rtsFail",
						},
						{
							statusName: this.$t("平台改商品失败"), //"平台改商品失败"
							status: "platformUpdateGoodsFail",
						},
						{
							statusName: this.$t("已加急"),
							status: "reminder",
						},
						{
							statusName: this.$t("商品价格预警"),
							status: "LESS_THAN_MIN_PRICE",
						},
					]

					list.forEach((item) => {
						item.num = exceptionTagNum[item.status] || 0
					})

					this.statusListBottom = list
				},
			})
		},
		showOrderSnModal() {
			this.$refs.orderSnModal.open()
		},
		enterPress() {
			const e = event || window.event
			if (e.keyCode === 13) {
				this.searchSaleOrders()
			}
		},
		batchCopy(name) {
			const str = this.selectedRows.map((item) => item[name]).join(",")
			copy(str)
		},
		printPicklist() {
			this.printList = []
			const arr = []
			this.saleOrderList.forEach((item) => {
				if (this.selectedRowKeys.indexOf(item.orderSn) > -1) {
					item.totalSku = 0
					item.orderGoodses.forEach((otem) => {
						item.totalSku = item.totalSku + otem.number
					})
					arr.push(item)
				}
			})
			this.printList = arr
			if (CheckIsInstall()) {
				this.printVisible = true
			} else {
				this.$confirm({
					title: "Tips",
					content: (h) => {
						return h(
							"a",
							{
								props: {
									href: "https://oss-preprod.oss-ap-southeast-1.aliyuncs.com/training_gNNdeCH1jK6_rGh6/ULTRA_WMS/h398X-ot6nlZO69dplqBK-49-1680588020781-CLodop_Setup_for_Win64NT_6.571EN.exe",
									target: "_blank",
								},
							},
							"click to download the PrintTool"
						)
					},
				})
			}
		},
		cancelPrint() {
			this.printVisible = false
		},
		changeTag() {
			this.formData.payType = this.formData.payType === "COD" ? undefined : "COD"
			this.$nextTick(() => {
				this.searchSaleOrders()
			})
		},
		changeTag2() {
			this.formData.orderType = this.formData.orderType === "PRESALE" ? undefined : "PRESALE"
			this.$nextTick(() => {
				this.searchSaleOrders()
			})
		},
		changeTag3() {
			this.formData.statusType = this.formData.statusType === "reserved_no_outbound" ? undefined : "reserved_no_outbound"
			this.$nextTick(() => {
				this.searchSaleOrders()
			})
		},
		changeTimeoutSituation({ code, isStatus }) {
			if (isStatus) {
				this.isStatus = true
				this.formData.statusType = undefined
				this.formData.abnormalType = undefined
			} else {
				this.isStatus = false
			}
			if (this.formData.timeoutSituation === code) {
				this.formData.timeoutSituation = undefined
			} else {
				this.formData.timeoutSituation = code
			}
			this.$nextTick(() => {
				this.searchSaleOrders()
			})
		},
		setAbnormalType(status) {
			this.formData.statusType = undefined
			if (this.isStatus) {
				this.formData.timeoutSituation = undefined
				this.isStatus = false
			}
			if (this.formData.abnormalType === status) {
				this.formData.abnormalType = undefined
			} else {
				this.formData.abnormalType = status
			}
			this.clearSelectKeys()
			this.$nextTick(() => {
				this.searchSaleOrders()
			})
		},
		changeShopHandle(list) {
			this.formData.shopCodeList = list.map((item) => ({
				platform: item.platform,
				shopCode: item.code || item.shopCode,
			}))
		},
		async reminderOrder() {
			this.reminderOrderLoading = true
			const res = await http({
				url: api.reminderOrder,
				data: {
					orderIds: this.selectedOrderList,
				},
			})
			this.reminderOrderLoading = false
			if (res.success) {
				this.successOrderSns = res.result.successOrderSns
				this.failOrderSns = res.result.failOrderSns
				this.reminderOrderModalVisible = true
			}
		},
		createPurchaseRequest() {
			const warehouseCode = this.selectedOutfStockRows[0].warehouse.warehouseCode
			const hasDiff = this.selectedOutfStockRows.findIndex((item) => item.warehouse.warehouseCode !== warehouseCode)
			if (hasDiff > -1) {
				return this.$message.warning(this.$t("多个仓库无法快速创建采购申请单"))
			}
			const orderSns = this.selectedOutfStockRows.map((item) => item.orderSn).join(",")
			window.open(`/purchase/createPurchaseRequest?orderSns=${orderSns}`)
			// this.$router.push({
			//   path: '/purchase/createPurchaseRequest',
			//   query: {
			//     orderSns: this.selectedOutfStockRows.map((item) => item.orderSn).join(','),
			//   },
			// })
		},
		expandAll() {
			const expandedRowKeys = this.saleOrderList.map((item) => item.orderSn)
			this.expandedRowKeys = expandedRowKeys
		},
		getOrderSubType(record) {
			const item = record.orderTags.find((el) => el.type === "orderSubType")
			return this.orderSubTypeMapping[item?.key]
		},
		selectOrderTag(val) {
			this.formData.orderTags = val?.map((item) => ({
				type: "orderSubType",
				key: item,
			}))
		},
		getDate(date) {
			return date?.split(" ")?.[0]
		},
		editTimeHandle(record) {
			this.$set(record, "latestDeliveryTimeNew", record.latestDeliveryTime)
			this.$set(record, "editLatestDeliveryTime", true)
		},
		chooseLatestDeliveryTime(record, data) {
			this.$set(record, "latestDeliveryTimeNew", data.data)
		},
		async modifyDeliverTime(record) {
			await http({
				url: api.modifyDeliverTime,
				type: "post",
				hasLoading: true,
				data: { orderId: record.orderId, deliverTime: record.latestDeliveryTimeNew },
			})
			this.$set(record, "latestDeliveryTime", record.latestDeliveryTimeNew)
			this.$set(record, "editLatestDeliveryTime", false)
		},
		cancelModify(record) {
			this.$set(record, "editLatestDeliveryTime", false)
			// this.$set(record, 'latestDeliveryTime', data.data)
		},
		showExportShippingModal() {
			if (!this.selectedRowKeys.length) {
				this.$message.warning(this.$t("至少选择一项"))
				return
			}
			this.shopFullName = (localStorage.getItem("shopFullName") && JSON.parse(localStorage.getItem("shopFullName"))) || false
			this.exportShippingVisible = true
		},
		async exportShippingList() {
			this.exportShippingLoading = true
			try {
				//获取网关导出key
				const res = await http({
					url: api.getDownloadKey,
					data: {},
				})
				//获取order导出key
				const res2 = await http({
					url: api.getOrderDownloadParamKey,
					data: {
						params: {
							data: this.selectedRowKeys.join(","),
							merchantCode: localStorage.getItem("merchantCode"),
							buId: this.$store.state.userSession?.currentBu?.partyId,
							unitType: this.unitType,
							sortType: this.sortType,
							shopFullName: this.shopFullName,
						},
					},
				})
				if (res.result && res2.result) {
					const list = []
					list.push(`auth-download-key=${res.result}`)
					list.push(`paramKey=${res2.result}`)
					localStorage.setItem("shopFullName", this.shopFullName)
					const url = `${api.exportShippingList}?${list.join("&")}`
					window.open(url)
					this.exportShippingVisible = false
				} else {
					this.$message.error("导出失败")
				}
			} catch (e) {
				this.$message.error("导出失败")
			}
			this.exportShippingLoading = false
		},
		exportNoOrderShopList: debounce(async () => {
			const res = await http({
				url: api.getDownloadKey,
				data: {},
			})
			const url = `${api.exportNoOrderShopList}?auth-download-key=${res.result}`
			window.open(url)
		}),
		changeOrderType(val, list) {
			this.orderSubTypeList = list
		},
		changeOrderStatusList(list) {
			this.formData.orderStatusList = list
		},
	},
}
</script>

<style scoped lang="scss">
.sales-order {
  .content {
    min-height: auto;
  }
  .tag-select {
    &-item {
      margin-bottom: 12px;
      .label {
        font-size: 14px;
      }
    }
  }
}

.form-group .date-input {
  width: 300px !important;
}

.sale-orders-search-form .form-group {
  margin-right: 20px;
  margin-bottom: 10px;
}

.sale-orders-search-form label:not(.radio-wrap) {
  margin-right: 5px;
  width: 4.5em;
  text-align: right;
}

.ant-table-expand-icon-th {
  display: none !important;
}

.radio-wrap,
input[type='checkbox'] {
  cursor: pointer;
  margin: 0px;
}

.radio-wrap input[type='radio'] {
  margin: 0px;
}

#btn-search {
  margin-left: 10.5em;
}

/*.btn {
	padding-left: 23px;
	padding-right: 23px;
}*/
.btn i.fa {
  margin-right: 0.5em;
}

.action-list {
  display: flex;
  align-items: center;
  background-color: $white;
  flex-wrap: wrap;
  li {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .remark-select {
    margin-left: auto;
  }
}

.user-icon,
.phone-icon {
  margin-right: 0.5em;
  color: #666;
}

.box-icon {
  float: left;
  font-size: 10px;
  border-radius: 5px;
  padding: 1px 3px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.box-icon.gray {
  color: #666;
  border: 1px solid #666;
}

.box-icon.orange {
  color: #eea236;
  border: 1px solid #eea236;
}

.box-icon.red {
  color: #d9534f;
  border: 1px solid #d9534f;
}

.circle-icon {
  -webkit-text-size-adjust: none;
  position: relative;
  /*top: -2px;*/
  /*display: inline-block;*/
  font-size: 10px;
  border-radius: 100%;
  padding: 0px 2px;
  margin-right: 1px;
}

.circle-icon.gray {
  color: #666;
  border: 1px solid #666;
}

.circle-icon.green {
  color: #4cae4c;
  border: 1px solid #4cae4c;
}

.closed {
  color: #a6a6a6;
}

.popup-wrap-zn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popup-content {
  position: absolute;
  top: 15%;
  left: 50%;
  width: 510px;
  /*min-height: 366px;*/
  margin-left: -230px;
  background-color: $white;
  border-radius: 5px;
  z-index: 999;
}

.myRow {
  margin-bottom: 10px;
  margin-left: 5px;
}

.control-label {
  text-align: right;
}

.popup-title {
  text-align: left;
  border-bottom: 1px solid #d2d6de;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 0;
}

.popup-main {
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 15px;
}

.popup-footer {
  width: 100%;
  text-align: right;
  padding: 10px;
  border-top: 1px solid #d2d6de;
}

.buyer {
  display: inline-block;
  max-width: 224px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
}

.table th {
  text-align: left;
}

p {
  margin-bottom: 0px;
}

.highSearchBar {
  text-align: center;
  height: 1px;
  background: $border-color-light;
}

.showAndHide {
  width: 100px;
  padding: 8px 10px;
  background: $white;
  cursor: pointer;
  color: $primary-color;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  border: 1px solid $border-color-light;
  border-top: 0;
}

.ht0 {
  height: 0;
}

.htAuto {
  height: 170px;
}

.showMore {
  overflow: hidden;
  transition: height 0.5s;
}

.operateBtn {
  font-size: 13px;
  padding: 0px 5px;
  min-width: 60px;

  &.warning {
    background-color: #faad14;
    border-color: #faad14;
  }

  &.success {
    background-color: #52c41a;
    border-color: #52c41a;
  }
}

.sales-order ::v-deep .ant-table-thead > tr > th,
.sales-order ::v-deep .ant-table-tbody > tr > td {
  padding: 8px;
}

.detail-link {
  color: $primary-color;
  &.visited {
    color: red; /*已访问的链接颜色*/
  }
}
.tag-select-item {
  flex-wrap: wrap;
}
</style>

<style scoped>
.sales-order ::v-deep .ant-tabs-bar {
  margin-bottom: 2px;
  border-bottom: none;
}
.sales-order ::v-deep .abnormal-tab .ant-tabs-tab {
  min-width: 100px;
  text-align: center;
}
.sales-order .tag-select ::v-deep .ant-tag {
  font-size: 13px;
  cursor: pointer;
  margin-right: 12px;
}
.sales-order ::v-deep .ant-badge-count {
  min-width: 16px;
  height: 16px;
  font-size: 10px;
  line-height: 16px;
  padding: 0;
}

.showMore ::v-deep .ant-col-4 {
  margin-bottom: 10px;
}

.showMore ::v-deep .ant-col-8 {
  margin-bottom: 10px;
}
</style>
