<template>
  <a-select
    allowClear
    :mode="mode"
    show-search
    :maxTagCount="4"
    :placeholder="_selfPlaceholder"
    :disabled="_selfDisabled"
    v-model="selectedValue"
    :filter-option="filterOption"
    @change="onChange"
  >
    <a-select-option v-for="(item, index) in option" :value="item.orderType" :key="index">
      {{ item.description }}
    </a-select-option>
  </a-select>
</template>

<script>
import api from "../service/api"
import http from "../service/axios"
export default {
	mounted() {
		this.getList()
	},
	props: {
		placeholder: {},
		disabled: {},
		code: {},
		mode: {
			type: String,
			default: "",
		},
		needFilter: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			selectedValue: undefined,
			option: [],
		}
	},
	computed: {
		_selfPlaceholder() {
			return this.placeholder || this.$t("请选择订单类型")
		},
		_selfDisabled() {
			return this.disabled || false
		},
	},
	methods: {
		async getList() {
			const res = await http.post(api.getOrdetTypeList, {})
			let list = res.result
			if (this.needFilter) {
				//录单去掉换货和补寄类型
				list = list.filter((item) => item.orderType !== "EXCHANGE" && item.orderType !== "SHIP_ONLY")
			}
			this.option = list
			if (this.code !== undefined) {
				this.onChange(this.code)
			}
		},
		onChange(value) {
			this.selectedValue = value
			this.$emit("update:code", value)
			const subTypeList = this.option.find((item) => item.orderType === value)?.subTypeList || []
			this.$emit("change", value, subTypeList)
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
	},
	watch: {
		code(val) {
			this.selectedValue = val
		},
	},
}
</script>
