export default {
	partyId: (state) => state.userSession?.currentBu?.partyId || state.userSession?.buList?.[0]?.id,
	loadingTips: (state) => {
		const list = [...(state.loading_tips[state.loading_type] || []), ...state.loading_tips.common]
		const length = list.length
		const num = random(length)
		return list[num]
	},
	currentShop: (state) => state.currentShop || {},
	shopCode: (state) => state.currentShop?.uniqueCode || "",
	country: (state) => state.currentShop?.country || "",
	platformMapping: (state) => listToMap(state.nowPlatformList),
	merchantCode: (state) => state.userSession?.merchantCode,
	isStore: (state) => state.currentShop?.shopType === "STORE",
	microApplicationList(state) {
		return state.microApplicationList
	},
}

function listToMap(list) {
	const obj = {}
	list.forEach((item) => {
		obj[item.code] = item.text
	})
	return obj
}

function random(len) {
	return Math.floor(Math.random() * len)
}
