import { getLodop } from "@/assets/LodopFuncs"
import { getDateStr } from "@/common/getDateStr"
export const CheckIsInstall = () => {
	try {
		const LODOP = getLodop()
		if (LODOP.VERSION) {
			// if (LODOP.CVERSION)
			//   alert(
			//     "当前有WEB打印服务C-Lodop可用!\n C-Lodop版本:" +
			//       LODOP.CVERSION +
			//       "(内含Lodop" +
			//       LODOP.VERSION +
			//       ")"
			//   );
			// else alert("本机已成功安装了Lodop控件！\n 版本号:" + LODOP.VERSION);
			return true
		}
		// return false;
	} catch (err) {
		console.log("err: ", err)
	}
}
Number.prototype.toFixed = function (len) {
	//toFixed太恶心了，改一下
	const temp = 10 ** len
	return Math.round(this * temp) / temp
}
String.prototype.toFixed = function (n) {
	//给String添加toFixed函数，将字符串转为浮点数并保留n位小数
	return this ? Number.parseFloat(this).toFixed(n) : ""
}

const Common = {}
/*定义了一些全局参数*/
Common.currentSession = {} //当前的回话, currentSession中包含了当前的party, 用户名称，等信息
Common.currentIdaasApp = JSON.parse(localStorage.getItem("currentIdaasApp") || "{}") //Idaas开通的app列表

Common.Loading = function (param) {
	const newParam = param || {}
	this.css = newParam.css || {}
	this.target = $(newParam.target || document.body)
	this.size = newParam.size || "tiny"
	this.layer = $("<table class='loading_container'><tbody><tr><td align='center'></td></tr></tbody></table>")
	this.loadingPane = $("<div><div>")
	this.loadingPane.addClass(`loading_${this.size}`)
	this.layer.find("td").append('<i class="fa fa-spinner fa-pulse fa-2x fa-fw" style="color:#3697DE;"></i>')
}

Common.Loading.positionSelectRight = (target, size) => {
	const offset = target.offset()
	const top = offset.top
	const left = offset.left
	const width = target.innerWidth()
	const height = target.innerHeight()
	const preWidth = size.width
	const preHeight = size.height
	return {
		top: `${top + (height - preHeight) / 2}px`,
		left: `${left + width - preWidth - 15}px`,
		width: preWidth,
		height: preHeight,
	}
}

Common.Loading.positionLayerCenter = () => ({
	top: "0px",
	left: "0px",
	width: "100%",
	height: "100%",
	bottom: "0px",
	right: "0px",
})

Common.Loading.positionCenter = (target, size) => {
	const offset = target.offset()
	const top = offset.top
	const left = offset.left
	const width = target.innerWidth()
	const height = target.innerHeight()
	const preWidth = size.width
	const preHeight = size.height
	return {
		top: `${top + (height - preHeight) / 2}px`,
		left: `${left + (width - preWidth) / 2}px`,
		width: preWidth,
		height: preHeight,
	}
}

Common.Loading.positionLeft = (target, size) => {
	const offset = target.offset()
	const top = offset.top
	const left = offset.left
	// let width = target.innerWidth()
	const height = target.innerHeight()
	const preWidth = size.width
	const preHeight = size.height
	return {
		top: `${top + (height - preHeight) / 2}px`,
		left: `${left}px`,
		width: preWidth,
		height: preHeight,
	}
}

Common.Loading.positionRight = (target, size) => {
	const offset = target.offset()
	const top = offset.top
	const left = offset.left
	const width = target.innerWidth()
	const height = target.innerHeight()
	const preWidth = size.width
	const preHeight = size.height
	return {
		top: `${top + (height - preHeight) / 2}px`,
		left: `${left + width - preWidth}px`,
		width: preWidth,
		height: preHeight,
	}
}

Common.Loading.positionTop = (target, size) => {
	const offset = target.offset()
	const top = offset.top
	const left = offset.left
	const width = target.innerWidth()
	// let height = target.innerHeight()
	const preWidth = size.width
	const preHeight = size.height
	return {
		top: `${top}px`,
		left: `${left + (width - preWidth) / 2}px`,
		width: preWidth,
		height: preHeight,
	}
}

Common.Loading.positionBottom = (target, size) => {
	const offset = target.offset()
	const top = offset.top
	const left = offset.left
	const width = target.innerWidth()
	const height = target.innerHeight()
	const preWidth = size.width
	const preHeight = size.height
	return {
		top: `${top + height - preHeight}px`,
		left: `${left + (width - preWidth) / 2}px`,
		width: preWidth,
		height: preHeight,
	}
}

Common.Loading.size = {
	tiny: {
		width: 16,
		height: 16,
	},
	small: {
		width: 32,
		height: 32,
	},
	large: {
		width: 128,
		height: 128,
	},
}

Common.Loading.prototype = {
	show: function (css) {
		let cssFn = null
		if (typeof css === "string") {
			if (css === "left") {
				cssFn = Common.Loading.positionLeft
			} else if (css === "right") {
				cssFn = Common.Loading.positionRight
			} else if (css === "top") {
				cssFn = Common.Loading.positionTop
			} else if (css === "bottom") {
				cssFn = Common.Loading.positionBottom
			} else {
				cssFn = Common.Loading.positionLayerCenter
			}
		} else {
			cssFn = css || Common.Loading.positionLayerCenter
		}
		const layer = this.layer
		const pos = cssFn.call(this, this.target, Common.Loading.size[this.size])
		layer.css(pos)
		layer.appendTo($(document.body))
	},

	hidden: function () {
		this.layer.remove()
	},
}

Common.TreeView = function (param) {
	const newParam = param || {}
	this.param = newParam
	this.target = $(newParam.target || document.body)
	this.width = newParam.width
	this.height = newParam.height
	this.maxHeight = newParam.maxHeight || 400
	this.minHeight = newParam.minHeight || 200
	this.view = $("<div style='z-index:1000;overflow: auto;background:#FFFFFF;position:absolute;border:1px solid #DDDDDD'></div>")
	if (newParam.fixed) {
		//		this.view.css('position', 'fixed');
	}
	this.autoHiddenFn = (event) => {
		const ele = event.target || event.srcElement
		if ($(ele).is(this.target)) return
		this.hidden()
	}

	if (param.clear) {
		this.clearButton = $("<button style='float:right' class='btn-danger'>清  除</button>")
		$("<div style='padding:5px; border-top:1px solid #DDDDDD'></div>").append(this.clearButton).appendTo(this.view)
	}
}

Common.TreeView.downPosition = function (target) {
	const offset = target.offset()
	const top = offset.top
	const left = offset.left
	const width = target.innerWidth()
	const height = target.outerHeight()
	return {
		top: top + height,
		left: left,
		width: width,
		height: this.height,
		maxHeight: this.maxHeight,
		minHeight: this.minHeight,
	}
}
Common.TreeView.prototype.show = function (position) {
	if (this.showed) return
	this.showed = true
	this.treeView = $(`<ul class='ztree' id='${this.param.id}'>`)
	this.view.prepend(this.treeView)
	this.autoHidden = this.param.autoHidden !== false
	this.view.click("focus", (event) => {
		event.stopPropagation()
	})
	const treeObj = $.fn.zTree.init(
		this.treeView,
		{
			async: this.param.async,
			callback: this.param.callback,
			check: this.param.check,
			data: this.param.keyMap,
			edit: this.param.edit,
			view: this.param.view,
		},
		this.param.data
	)
	this.funcs = {}
	for (const funcName in treeObj) {
		const func = treeObj[funcName]
		if (!func) continue
		this.funcs[funcName] = func
		if (func instanceof Function) {
			this[funcName] = () => {
				alert(funcName)
			}
		}
	}
	let positionFn = null
	if (position === "bottom") {
		positionFn = Common.TreeView.downPosition
	} else {
		positionFn = position || Common.TreeView.downPosition
	}
	const pos = positionFn.call(this, this.target)
	this.view.css(pos)
	this.view.appendTo($(document.body))
	if (this.autoHidden) {
		$(document.body).bind("click", this.autoHiddenFn)
	}
	if (this.clearButton) {
		this.clearButton.click(() => {
			this.clear()
		})
	}
}
Common.TreeView.prototype.clear = function () {
	if (this.param?.callback?.onClear) {
		this.param.callback.onClear.call(this)
	}
}

Common.TreeView.prototype.hidden = function () {
	this.showed = false
	this.treeView.remove()
	this.view.remove()
	if (this.autoHidden) {
		$(document.body).unbind("click", this.autoHiddenFn)
	}
}

/**
 * 假如已经选中了某个值:
 * 1. 聚焦时，全选所有
 * 3. 键入任何值时，都会删除所选的值
 * 假如没有选中某个值，点击删除按钮时，正常删除内容
 */
Common.bindInputDownmenuClearEvent = (input, checkChoosedFn, clearFn) => {
	const $input = $(input)
	$input.bind("click", function () {
		if (checkChoosedFn.call(this)) {
			//假如已经选中了
			$input.select() //选中所有的内容
		}
	})
	$input.bind("focus", function () {
		if (checkChoosedFn.call(this)) {
			//假如已经选中了
			$input.select() //选中所有的内容
		}
	})
	$input.bind("keydown", function (event) {
		if (Common.isInputChangeKeyCode(event.keyCode)) {
			if (checkChoosedFn.call(this)) {
				//已经选中了
				clearFn.call(this) //则清除已经选中的内容
				$input.val("") //并且内容设为空
			}
		}
	})
}

Common.bindAutoSelect = (input, showFn) => {
	const $input = $(input)
	$input.bind("keydown", (event) => {
		if ($input.attr("searched")) return
		if (event.keyCode === 40) {
			//向下箭头或者回车键
			showFn() //展现
		}
	})
}

Common.isInputChangeKeyCode = (keyCode) => {
	// console.log(keyCode + " type:" + typeof keyCode);
	return (
		keyCode === 8 || // back space
		keyCode === 46 || //delete按钮
		keyCode === 32 || //空格
		(keyCode >= 186 && keyCode <= 222) || //？等按钮
		(keyCode >= 65 && keyCode <= 89) || //字母
		(keyCode >= 48 && keyCode <= 57)
	) //数字
}

Common.Privileges = function () {
	this.up = {}
}

Common.Privileges.prototype = {
	init: async function (url) {
		if (this.inited) return
		this.inited = true
		await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (!data.success) {
					this.inited = false
					// alert('权限请求失败，请重新刷新页面')
				}
				const ps = data.data
				if (ps) {
					for (let i = 0; i < ps.length; i++) {
						this.up[ps[i]] = true
					}
				}
			})
	},

	cancelInit: function () {
		this.inited = false
	},

	hasUp: () => {
		return true //权限先放开
		// var ps = arguments
		// if (!ps || ps.length == 0) return false
		// return !!this.up[ps[0]]
	},

	containsUp: function (...args) {
		const r = this.hasUp.apply(this, args)
		return !r || r.length <= 0
	},
}

Common.priv = new Common.Privileges()

Common.guid = (path) => {
	const time = getDateStr(0, true).replace(/\D/g, "")
	const userAccount = path?.includes("oms_api") ? "" : localStorage.getItem("userAccount")
	const s4 = () =>
		Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1)
	return `rqname${userAccount}${time}${s4()}`
}

Common.md5 = (strin) => {
	/*
	 * Configurable variables. You may need to tweak these to be compatible with
	 * the server-side, but the defaults work in most cases.
	 */
	const hexcase = 0 /* hex output format. 0 - lowercase; 1 - uppercase        */
	// var b64pad = '' /* base-64 pad character. "=" for strict RFC compliance   */
	const chrsz = 8 /* bits per input character. 8 - ASCII; 16 - Unicode      */

	/*
	 * These are the functions you'll usually want to call
	 * They take string arguments and return either hex or base-64 encoded strings
	 */
	function hex_md5(s) {
		return binl2hex(core_md5(str2binl(s), s.length * chrsz))
	}
	// function b64_md5(s) {
	//   return binl2b64(core_md5(str2binl(s), s.length * chrsz))
	// }
	// function str_md5(s) {
	//   return binl2str(core_md5(str2binl(s), s.length * chrsz))
	// }
	// function hex_hmac_md5(key, data) {
	//   return binl2hex(core_hmac_md5(key, data))
	// }
	// function b64_hmac_md5(key, data) {
	//   return binl2b64(core_hmac_md5(key, data))
	// }
	// function str_hmac_md5(key, data) {
	//   return binl2str(core_hmac_md5(key, data))
	// }

	/*
	 * Perform a simple self-test to see if the VM is working
	 */
	// function md5_vm_test() {
	//   return hex_md5('abc') == '900150983cd24fb0d6963f7d28e17f72'
	// }

	/*
	 * Calculate the MD5 of an array of little-endian words, and a bit length
	 */
	function core_md5(x, len) {
		/* append padding */
		x[len >> 5] |= 0x80 << (len % 32)
		x[(((len + 64) >>> 9) << 4) + 14] = len

		let a = 1732584193
		let b = -271733879
		let c = -1732584194
		let d = 271733878

		for (let i = 0; i < x.length; i += 16) {
			const olda = a
			const oldb = b
			const oldc = c
			const oldd = d

			a = md5_ff(a, b, c, d, x[i + 0], 7, -680876936)
			d = md5_ff(d, a, b, c, x[i + 1], 12, -389564586)
			c = md5_ff(c, d, a, b, x[i + 2], 17, 606105819)
			b = md5_ff(b, c, d, a, x[i + 3], 22, -1044525330)
			a = md5_ff(a, b, c, d, x[i + 4], 7, -176418897)
			d = md5_ff(d, a, b, c, x[i + 5], 12, 1200080426)
			c = md5_ff(c, d, a, b, x[i + 6], 17, -1473231341)
			b = md5_ff(b, c, d, a, x[i + 7], 22, -45705983)
			a = md5_ff(a, b, c, d, x[i + 8], 7, 1770035416)
			d = md5_ff(d, a, b, c, x[i + 9], 12, -1958414417)
			c = md5_ff(c, d, a, b, x[i + 10], 17, -42063)
			b = md5_ff(b, c, d, a, x[i + 11], 22, -1990404162)
			a = md5_ff(a, b, c, d, x[i + 12], 7, 1804603682)
			d = md5_ff(d, a, b, c, x[i + 13], 12, -40341101)
			c = md5_ff(c, d, a, b, x[i + 14], 17, -1502002290)
			b = md5_ff(b, c, d, a, x[i + 15], 22, 1236535329)

			a = md5_gg(a, b, c, d, x[i + 1], 5, -165796510)
			d = md5_gg(d, a, b, c, x[i + 6], 9, -1069501632)
			c = md5_gg(c, d, a, b, x[i + 11], 14, 643717713)
			b = md5_gg(b, c, d, a, x[i + 0], 20, -373897302)
			a = md5_gg(a, b, c, d, x[i + 5], 5, -701558691)
			d = md5_gg(d, a, b, c, x[i + 10], 9, 38016083)
			c = md5_gg(c, d, a, b, x[i + 15], 14, -660478335)
			b = md5_gg(b, c, d, a, x[i + 4], 20, -405537848)
			a = md5_gg(a, b, c, d, x[i + 9], 5, 568446438)
			d = md5_gg(d, a, b, c, x[i + 14], 9, -1019803690)
			c = md5_gg(c, d, a, b, x[i + 3], 14, -187363961)
			b = md5_gg(b, c, d, a, x[i + 8], 20, 1163531501)
			a = md5_gg(a, b, c, d, x[i + 13], 5, -1444681467)
			d = md5_gg(d, a, b, c, x[i + 2], 9, -51403784)
			c = md5_gg(c, d, a, b, x[i + 7], 14, 1735328473)
			b = md5_gg(b, c, d, a, x[i + 12], 20, -1926607734)

			a = md5_hh(a, b, c, d, x[i + 5], 4, -378558)
			d = md5_hh(d, a, b, c, x[i + 8], 11, -2022574463)
			c = md5_hh(c, d, a, b, x[i + 11], 16, 1839030562)
			b = md5_hh(b, c, d, a, x[i + 14], 23, -35309556)
			a = md5_hh(a, b, c, d, x[i + 1], 4, -1530992060)
			d = md5_hh(d, a, b, c, x[i + 4], 11, 1272893353)
			c = md5_hh(c, d, a, b, x[i + 7], 16, -155497632)
			b = md5_hh(b, c, d, a, x[i + 10], 23, -1094730640)
			a = md5_hh(a, b, c, d, x[i + 13], 4, 681279174)
			d = md5_hh(d, a, b, c, x[i + 0], 11, -358537222)
			c = md5_hh(c, d, a, b, x[i + 3], 16, -722521979)
			b = md5_hh(b, c, d, a, x[i + 6], 23, 76029189)
			a = md5_hh(a, b, c, d, x[i + 9], 4, -640364487)
			d = md5_hh(d, a, b, c, x[i + 12], 11, -421815835)
			c = md5_hh(c, d, a, b, x[i + 15], 16, 530742520)
			b = md5_hh(b, c, d, a, x[i + 2], 23, -995338651)

			a = md5_ii(a, b, c, d, x[i + 0], 6, -198630844)
			d = md5_ii(d, a, b, c, x[i + 7], 10, 1126891415)
			c = md5_ii(c, d, a, b, x[i + 14], 15, -1416354905)
			b = md5_ii(b, c, d, a, x[i + 5], 21, -57434055)
			a = md5_ii(a, b, c, d, x[i + 12], 6, 1700485571)
			d = md5_ii(d, a, b, c, x[i + 3], 10, -1894986606)
			c = md5_ii(c, d, a, b, x[i + 10], 15, -1051523)
			b = md5_ii(b, c, d, a, x[i + 1], 21, -2054922799)
			a = md5_ii(a, b, c, d, x[i + 8], 6, 1873313359)
			d = md5_ii(d, a, b, c, x[i + 15], 10, -30611744)
			c = md5_ii(c, d, a, b, x[i + 6], 15, -1560198380)
			b = md5_ii(b, c, d, a, x[i + 13], 21, 1309151649)
			a = md5_ii(a, b, c, d, x[i + 4], 6, -145523070)
			d = md5_ii(d, a, b, c, x[i + 11], 10, -1120210379)
			c = md5_ii(c, d, a, b, x[i + 2], 15, 718787259)
			b = md5_ii(b, c, d, a, x[i + 9], 21, -343485551)

			a = safe_add(a, olda)
			b = safe_add(b, oldb)
			c = safe_add(c, oldc)
			d = safe_add(d, oldd)
		}
		return Array(a, b, c, d)
	}

	/*
	 * These functions implement the four basic operations the algorithm uses.
	 */
	function md5_cmn(q, a, b, x, s, t) {
		return safe_add(bit_rol(safe_add(safe_add(a, q), safe_add(x, t)), s), b)
	}
	function md5_ff(a, b, c, d, x, s, t) {
		return md5_cmn((b & c) | (~b & d), a, b, x, s, t)
	}
	function md5_gg(a, b, c, d, x, s, t) {
		return md5_cmn((b & d) | (c & ~d), a, b, x, s, t)
	}
	function md5_hh(a, b, c, d, x, s, t) {
		return md5_cmn(b ^ c ^ d, a, b, x, s, t)
	}
	function md5_ii(a, b, c, d, x, s, t) {
		return md5_cmn(c ^ (b | ~d), a, b, x, s, t)
	}

	/*
	 * Calculate the HMAC-MD5, of a key and some data
	 */
	// function core_hmac_md5(key, data) {
	//   var bkey = str2binl(key)
	//   if (bkey.length > 16) bkey = core_md5(bkey, key.length * chrsz)

	//   var ipad = Array(16),
	//     opad = Array(16)
	//   for (var i = 0; i < 16; i++) {
	//     ipad[i] = bkey[i] ^ 0x36363636
	//     opad[i] = bkey[i] ^ 0x5c5c5c5c
	//   }

	//   var hash = core_md5(ipad.concat(str2binl(data)), 512 + data.length * chrsz)
	//   return core_md5(opad.concat(hash), 512 + 128)
	// }

	/*
	 * Add integers, wrapping at 2^32. This uses 16-bit operations internally
	 * to work around bugs in some JS interpreters.
	 */
	function safe_add(x, y) {
		const lsw = (x & 0xffff) + (y & 0xffff)
		const msw = (x >> 16) + (y >> 16) + (lsw >> 16)
		return (msw << 16) | (lsw & 0xffff)
	}

	/*
	 * Bitwise rotate a 32-bit number to the left.
	 */
	function bit_rol(num, cnt) {
		return (num << cnt) | (num >>> (32 - cnt))
	}

	/*
	 * Convert a string to an array of little-endian words
	 * If chrsz is ASCII, characters >255 have their hi-byte silently ignored.
	 */
	function str2binl(str) {
		const bin = Array()
		const mask = (1 << chrsz) - 1
		for (let i = 0; i < str.length * chrsz; i += chrsz) bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (i % 32)
		return bin
	}

	/*
	 * Convert an array of little-endian words to a string
	 */
	// function binl2str(bin) {
	//   var str = ''
	//   var mask = (1 << chrsz) - 1
	//   for (var i = 0; i < bin.length * 32; i += chrsz)
	//     str += String.fromCharCode((bin[i >> 5] >>> i % 32) & mask)
	//   return str
	// }

	/*
	 * Convert an array of little-endian words to a hex string.
	 */
	function binl2hex(binarray) {
		const hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef"
		let str = ""
		for (let i = 0; i < binarray.length * 4; i++) {
			str += hex_tab.charAt((binarray[i >> 2] >> ((i % 4) * 8 + 4)) & 0xf) + hex_tab.charAt((binarray[i >> 2] >> ((i % 4) * 8)) & 0xf)
		}
		return str
	}

	/*
	 * Convert an array of little-endian words to a base-64 string
	 */
	// function binl2b64(binarray) {
	//   var tab = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
	//   var str = ''
	//   for (var i = 0; i < binarray.length * 4; i += 3) {
	//     var triplet =
	//       (((binarray[i >> 2] >> (8 * (i % 4))) & 0xff) << 16) |
	//       (((binarray[(i + 1) >> 2] >> (8 * ((i + 1) % 4))) & 0xff) << 8) |
	//       ((binarray[(i + 2) >> 2] >> (8 * ((i + 2) % 4))) & 0xff)
	//     for (var j = 0; j < 4; j++) {
	//       if (i * 8 + j * 6 > binarray.length * 32) str += b64pad
	//       else str += tab.charAt((triplet >> (6 * (3 - j))) & 0x3f)
	//     }
	//   }
	//   return str
	// }
	return hex_md5(strin)
}
global.Common = Common
export default Common
