<template>
  <div>
    <a-result status="404" :title="$t('系统正在发布中，请稍等') + '…'">
      <template v-slot:extra>
        <a-button type="primary" @click="goBack">{{ $t('返回') }}</a-button>
      </template>
    </a-result>
  </div>
</template>

<script>
import { Result } from "ant-design-vue"
import { mapMutations } from "vuex"

export default {
	components: {
		"a-result": Result,
	},
	beforeRouteEnter(to, from, next) {
		next(() => {
			// console.log('from', from)
			const fromUrl = localStorage.getItem("fromUrl")
			if (!fromUrl) {
				setTimeout(() => {
					localStorage.setItem("fromUrl", from.fullPath)
				}, 200)
			}
		})
	},
	mounted() {
		const fromUrl = localStorage.getItem("fromUrl")
		if (fromUrl) {
			this.$router.push(fromUrl)
			localStorage.removeItem("fromUrl")
		}
		this.setState({
			loading: false,
		})
	},
	activated() {
		this.setState({
			loading: false,
		})
	},
	methods: {
		...mapMutations(["setState"]),
		goBack() {
			history.go(-1)
			localStorage.removeItem("fromUrl")
		},
	},
}
</script>
