import { render, staticRenderFns } from "./orderSplit.vue?vue&type=template&id=6433d819&scoped=true&"
import script from "./orderSplit.vue?vue&type=script&lang=js&"
export * from "./orderSplit.vue?vue&type=script&lang=js&"
import style0 from "./orderSplit.vue?vue&type=style&index=0&id=6433d819&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6433d819",
  null
  
)

export default component.exports