<template>
  <a-select
    allowClear
    show-search
    style="width: 100%"
    :dropdownMatchSelectWidth="false"
    :value="value"
    :filter-option="filterOption"
    :placeholder="_selfPlaceholder"
    @change="selectRegion"
    :not-found-content="loading ? undefined : null"
    :disabled="disabled"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option
      :value="item.region_id"
      :key="item.region_id"
      v-for="item in regionList"
      :option="item"
    >
      {{ item.display_name }}
    </a-select-option>
  </a-select>
</template>

<script>
import deepClone from "@/common/deepClone"
import api from "../service/api"
import http from "../service/axios"

export default {
	props: ["placeholder", "disabled", "regionType", "parentId", "defaultRegionId"],
	data() {
		return {
			regionList: [],
			value: undefined,
			choosedData: null,
			loading: false,
		}
	},
	computed: {
		_selfPlaceholder() {
			return this.placeholder || ""
		},
	},
	methods: {
		fetchRegionList() {
			this.loading = true
			http({
				url: api.getRegionList,
				type: "post",
				data: {
					regionType: this.regionType,
					parentId: this.parentId,
				},
				success: (res) => {
					this.regionList = res.result
					this.loading = false
				},
			})
		},
		selectRegion(value, option) {
			this.value = value
			this.choosedData = option?.data?.attrs?.option
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
	},
	watch: {
		defaultRegionId: {
			immediate: true,
			handler(val) {
				this.value = val
			},
		},
		choosedData(val) {
			this.$emit("selected", deepClone(val || {}))
		},
		parentId: {
			immediate: true,
			handler(val) {
				if (val) {
					this.fetchRegionList()
				}
			},
		},
	},
}
</script>
