<template>
  <div class="signle-order-generation">
    <section class="fixContent" :style="{ height: scrollHeight }">
      <a-form-model :model="formdata" ref="ruleForm" style="width: 100%">
        <div class="content">
          <div class="infoTitle">
            <i class="leftLine"></i>
            {{ $t('基本信息') }}
          </div>
          <div>
            <a-form-model-item
              :colon="showColon"
              :label="$t(`店铺`)"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              style="margin-right: 4%"
              required
            >
              <SelectShop
                :code="formdata.shopCode"
                :init="true"
                @selected="selectedShop"
              ></SelectShop>
            </a-form-model-item>

            <a-form-model-item
              :colon="showColon"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              style="margin-right: 4%"
            >
              <label class="control-label" style="display: none">{{ $t('原始订单号') }}</label>
              <template slot="label">
                <span>
                  {{ $t('原始订单号') }}
                </span>
              </template>
              <!-- 原始订单 -->
              <div>
                <a-input
                  allowClear
                  type="text"
                  v-model.trim="formdata.platformOrderId"
                  pattern="^[\w\u4e00-\u9fa5-]{0,32}$"
                  :title="$t(`原始订单号`)"
                />
              </div>
            </a-form-model-item>

            <a-form-model-item
              :colon="showColon"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              style="margin-right: 4%"
              :label="$t(`用户ID`)"
            >
              <!-- 用户ID -->
              <div>
                <a-input
                  allowClear
                  type="text"
                  v-model.trim="formdata.buyer"
                  :title="$t(`该字段不能有空`)"
                />
              </div>
            </a-form-model-item>

            <a-form-model-item
              :colon="showColon"
              :label="$t(`收件姓名`)"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              required
            >
              <!-- 收件人姓名 -->
              <div>
                <a-input
                  allowClear
                  type="text"
                  v-model.trim="formdata.receiverName"
                  :title="$t(`该字段不能有空`)"
                />
              </div>
            </a-form-model-item>
          </div>
          <div>
            <a-form-model-item
              :colon="showColon"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              style="margin-right: 4%"
              :label="$t(`手机号`)"
            >
              <!-- 手机号 -->
              <div>
                <a-input
                  allowClear
                  type="text"
                  v-model.trim="formdata.receiverMobile"
                  pattern="^\S+$"
                />
              </div>
            </a-form-model-item>

            <a-form-model-item
              :colon="showColon"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              style="margin-right: 4%"
              :label="$t(`电话`)"
            >
              <!-- 电话 -->
              <div>
                <a-input
                  allowClear
                  type="text"
                  v-model.trim="formdata.receiverPhone"
                  pattern="^[0-9-]+$"
                />
              </div>
            </a-form-model-item>

            <a-form-model-item
              :colon="showColon"
              :required="formdata.country !== 'CN'"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              :label="$t(`邮编`)"
              style="margin-right: 4%"
            >
              <!-- 邮箱 -->
              <div>
                <a-input
                  allowClear
                  type="text"
                  v-model.trim="formdata.receiverZip"
                  pattern="^[0-9]+$"
                />
              </div>
            </a-form-model-item>
            <a-form-model-item
              :colon="showColon"
              required
              :label="$t(`支付方式`)"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
            >
              <SelectPayment
                style="width: 100%"
                :default-payment="formdata.payId"
                disabled
                @selected="selectedPayment"
              />
            </a-form-model-item>
          </div>
          <div>
            <a-form-model-item
              :colon="showColon"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              :label="$t(`省`)"
              required
              style="margin-right: 4%"
            >
              <SelectRegion
                :placeholder="$t(`省`)"
                region-type="1"
                :parent-id="parentId"
                style="width: 100%"
                :default-region-id="formdata.receiverProvinceId"
                @selected="selectedProvince"
              />
            </a-form-model-item>
            <a-form-model-item
              :colon="showColon"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              :label="$t(`市`)"
              required
              style="margin-right: 4%"
            >
              <SelectRegion
                :placeholder="$t(`市`)"
                region-type="2"
                :parent-id="formdata.receiverProvinceId"
                :default-region-id="formdata.receiverCityId"
                @selected="selectedCity"
              />
            </a-form-model-item>
            <a-form-model-item
              :colon="showColon"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              :label="$t(`区`)"
              required
              style="margin-right: 4%"
            >
              <SelectRegion
                :placeholder="$t(`区`)"
                region-type="3"
                :parent-id="formdata.receiverCityId"
                :default-region-id="formdata.receiverDistrictId"
                @selected="selectedDistrict"
              />
            </a-form-model-item>
            <a-form-model-item
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              :label="$t(`街道`)"
            >
              <SelectRegion
                :placeholder="$t(`街道`)"
                region-type="4"
                :parent-id="formdata.receiverDistrictId || formdata.receiverCityId"
                :default-region-id="formdata.receiverStreetId"
                @selected="selectedStreet"
              />
            </a-form-model-item>
          </div>
          <div>
            <!-- 详细地址 -->
            <a-form-model-item
              :colon="showColon"
              :label="$t(`具体地址`)"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              required
              style="margin-right: 4%"
            >
              <a-input
                allowClear
                type="text"
                v-model.trim="formdata.receiverAddress"
                required
                pattern=".*"
              />
            </a-form-model-item>
            <!-- 应用赠品规则 -->
            <a-form-model-item
              :colon="showColon"
              :label="$t(`是否使用赠品规则`)"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              style="margin-right: 4%"
            >
              <a-select v-model="formdata.isAutoGift">
                <a-select-option :value="1">{{ $t(`是`) }}</a-select-option>
                <a-select-option :value="0">{{ $t(`否`) }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- 是否bTob -->
            <a-form-model-item
              :colon="showColon"
              :label="$t(`订单类型`)"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              style="margin-right: 4%"
            >
              <SelectOrderType
                :needFilter="true"
                ref="orderTypeRef"
                :code.sync="formdata.orderType"
                @change="changeOrderType"
              />
            </a-form-model-item>
            <!-- 订单子类型 -->
            <a-form-model-item
              v-if="isStore && orderSubTypeList.length > 0"
              :colon="showColon"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              required
              :label="$t(`订单子类型`)"
              style="margin-right: 4%"
            >
              <SelectOrderSubType
                :list="orderSubTypeList"
                :defaultType="formdata.orderTag"
                @change="selectOrderTag"
              />
            </a-form-model-item>
            <!-- 分销商名称 -->
            <a-form-model-item
              required
              v-if="showDistributorSelect"
              :colon="showColon"
              :label="$t(`分销商名称`)"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
            >
              <SelectDistributor
                :shopCode="formdata.shopCode"
                :platform="formdata.platform"
                :name.sync="formdata.distributorName"
              />
            </a-form-model-item>
            <!-- 发货方式 -->
            <a-form-model-item
              :colon="showColon"
              :label="$t(`发货方式`)"
              :style="{
                display: 'inline-block',
                width: 'calc(21%)',
                marginRight: '4%',
              }"
            >
              <DeliveryTypeSelect :value.sync="formdata.deliveryType" />
            </a-form-model-item>
            <!-- 付款时间 -->
            <a-form-model-item
              :colon="showColon"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              :label="$t(`付款时间`)"
              style="margin-right: 4%"
            >
              <date-picker
                allowClear
                id="payTime"
                v-model="formdata.payTime"
                @choose="choosePaytime"
                :init-date-time="formdata.payTime"
                :needhms="true"
              ></date-picker>
            </a-form-model-item>
            <!-- 拍下时间 -->
            <a-form-model-item
              :colon="showColon"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              required
              :label="$t(`平台下单时间`)"
              style="margin-right: 4%"
            >
              <date-picker
                allowClear
                id="platformOrderTime"
                v-model="formdata.platformOrderTime"
                @choose="choosePlatformOrderTime"
                :init-date-time="formdata.platformOrderTime"
                :needhms="true"
              ></date-picker>
            </a-form-model-item>
            <!-- 最晚发货时间 -->
            <a-form-model-item
              v-if="isStore"
              :colon="showColon"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              required
              :label="$t(`预计到货时间`)"
              style="margin-right: 4%"
            >
              <date-picker
                allowClear
                id="latestDeliveryTime"
                v-model="formdata.latestDeliveryTime"
                @choose="chooseLatestDeliveryTime"
                :init-date-time="formdata.latestDeliveryTime"
                :needhms="false"
              ></date-picker>
            </a-form-model-item>
          </div>
        </div>
        <div class="content" style="min-height: 0">
          <div class="infoTitle">
            <i class="leftLine"></i>
            {{ $t('订单备注') }}
          </div>
          <div class="flex-ct mb10">
            <span class="mr6">{{ $t(`顾客留言`) }}</span>
            <a-input style="width: 80%" allowClear type="text" v-model.trim="formdata.buyerMemo" />
          </div>
          <div class="flex-ct">
            <span class="mr6">{{ $t(`卖家留言`) }}</span>
            <a-input style="width: 80%" allowClear type="text" v-model.trim="formdata.sellerMemo" />
          </div>
        </div>
        <div class="content" style="min-height: 0">
          <!-- 配送信息 -->
          <div class="infoTitle">
            <i class="leftLine"></i>
            {{ $t('配送信息') }}
          </div>
          <div>
            <a-form-model-item
              required
              :colon="showColon"
              :label="$t(`指定仓库`)"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              style="margin-right: 4%"
            >
              <!-- 指定仓库 -->
              <SearchWarehouse
                :oneSelect="true"
                :shopList="
                  formdata.shopCode
                    ? [
                        {
                          shopCode: formdata.shopCode,
                          platform: formdata.platform,
                        },
                      ]
                    : []
                "
                :disabled="!formdata.shopCode"
                :code="formdata.wmsWarehouseCode"
                @selected="selectedWarehouse"
              ></SearchWarehouse>
            </a-form-model-item>

            <a-form-model-item
              :required="formdata.deliveryType === 'EXPRESS'"
              :colon="showColon"
              :label="$t(`指定快递`)"
              :style="{ display: 'inline-block', width: 'calc(21%)' }"
              style="margin-right: 4%"
            >
              <!-- 指定快递 -->
              <SelectShipment
                :disabled="!formdata.wmsWarehouseCode"
                :shopCode="formdata.shopCode"
                :platform="formdata.platform"
                :wmsAppCode="formdata.wmsAppCode"
                :warehouseCode="formdata.wmsWarehouseCode"
                :defaultShippingCode="formdata.specifiedShippingAccount"
                @selected="selectedShipment"
              />
            </a-form-model-item>
          </div>
        </div>
        <div class="content">
          <!-- 商品信息 -->
          <div class="infoTitle">
            <i class="leftLine"></i>
            {{ $t('商品信息') }}
          </div>

          <a-form-model-item
            :colon="showColon"
            :style="{ display: 'inline-block', width: 'calc(46%)' }"
            :label="$t(`商品名称`)"
            required
            style="margin-right: 2%"
          >
            <SearchSku ref="searchSku" @selected="fetchSku" />
          </a-form-model-item>

          <a-form-model-item
            :colon="showColon"
            :style="{
              display: 'inline-block',
              width: 'calc(21%)',
            }"
            style="margin-top: 25px"
          >
            <a-button
              type="primary"
              style="display: block"
              @click.prevent="addGoodsToOrder"
              :disabled="addGoods.addGoodsDisabled"
            >
              <i class="iconfont icon-new" style="margin-right: 5px"></i>
              {{ $t(`添加`) }}
            </a-button>
          </a-form-model-item>

          <a-table
            :pagination="false"
            bordered
            :columns="columns"
            :dataSource="formdata.orderGoodses"
            :scroll="{ x: 1300 }"
            :rowKey="(record, index) => index"
            style="margin-top: 1%"
          >
            <!-- 商品信息 -->
            <template slot="ProductInfo" slot-scope="text, record">
              <div>{{ $t('名称') }}: {{ record.skuName }}</div>
              <div>{{ $t('编码') }}: {{ record.skuCode }}</div>
              <div>{{ $t('条码') }}: {{ record.barcode }}</div>
              <div v-if="record.skuStyleList?.length > 0">
                {{ $t('规格') }}: {{ getStyleName(record.skuStyleList) }}
              </div>
            </template>

            <!-- 单位 -->
            <template slot="Unit" slot-scope="text, record">
              <SelectUnit
                :skuCode="record.skuCode"
                :list="record.unitList"
                :code.sync="record.unit"
              />
            </template>
            <!-- 单价-->
            <template slot="UnitPrice" slot-scope="text, record">
              <a-input-number v-model.trim="record.price" :disabled="record.isGift" />
            </template>

            <!-- 数量 -->
            <template slot="Quantity" slot-scope="text, record">
              <a-input-number v-model.trim="record.num" />
            </template>

            <!-- 单品优惠-->
            <template slot="SingleProductDiscount" slot-scope="text, record">
              <a-input-number v-model.trim="record.discountFee" :disabled="record.isGift" />
            </template>

            <!-- 单品税费 -->
            <template slot="SingleProductTax" slot-scope="text, record">
              <a-input-number v-model.trim="record.taxFee" :disabled="record.isGift" />
            </template>

            <!-- 单品总金额 -->
            <template slot="SingleProductAmount" slot-scope="text, record">
              {{ calcSingleProductAmount(record) }}
            </template>

            <!-- 邮费 -->
            <template slot="PostFee">
              <a-input-number v-model="formdata.postFee" />
            </template>

            <!-- 商品总金额 -->
            <template slot="ProductTotalAmount">
              {{ totalAmount }}
            </template>

            <!-- 订单优惠 -->
            <template slot="OrderDiscountFee">
              <a-input-number v-model="formdata.discountFee" />
            </template>

            <!-- 应收金额 -->
            <template slot="ReceivableFee">
              {{ totalFee }}
            </template>

            <!-- 操作 -->
            <template slot="Action" slot-scope="text, record">
              <a href="#" @click.prevent="deleteGood(record)">{{ $t(`删除`) }}</a>
            </template>
          </a-table>
        </div>
      </a-form-model>
    </section>
    <div class="content" style="min-height: 0">
      <a-row type="flex" justify="center">
        <a-col class="mr25">
          <a-button @click="goBack">
            {{ $t('返回') }}
          </a-button>
        </a-col>
        <a-col class="mr25">
          <a-button @click="resetForm">
            {{ $t('重置') }}
          </a-button>
        </a-col>
        <a-col>
          <a-button type="primary" @click="onSubmit" :loading="submitLoading">
            {{ $t('创建新订单') }}
          </a-button>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { getDateStr, getNowTime } from "@/common/getDateStr"
import debounce from "@common/debounce"
import deepClone from "@common/deepClone"
import ThailandTimeToChinaTime from "@common/timeChange"
import datePicker from "@component/datePicker"
import DeliveryTypeSelect from "@component/deliveryTypeSelect"
import SearchSku from "@component/searchSku"
import SearchWarehouse from "@component/searchWarehouse"
import SelectDistributor from "@component/selectDistributor"
import SelectOrderSubType from "@component/selectOrderSubType"
import SelectOrderType from "@component/selectOrderType"
import SelectPayment from "@component/selectPayment"
import SelectRegion from "@component/selectRegion"
import SelectShipment from "@component/selectShipment"
import SelectShop from "@component/selectShop"
import SelectUnit from "@component/selectUnit"
import api from "@service/api"
import http from "@service/axios"
import { Modal } from "ant-design-vue"

export default {
	data() {
		return {
			showColon: false,
			showDistrictSelect: false,
			showStreetSelect: false,
			partyId: undefined,
			orderSn: undefined,
			loadOrder: false,
			buyerMemomodify: false,
			sellerMemomodify: false,
			addGoods: {},
			formdata: {
				partyId: undefined,
				platformOrderId: undefined,
				platform: "TAOBAO",
				shopCode: undefined,
				seller: undefined,
				shopName: undefined,
				statusId: "INV_STTS_AVAILABLE",
				platformOrderTime: undefined,
				// distributorPurchaseOrderId:"",
				platformDistributorId: undefined,
				buyer: undefined,
				orderType: "SALE",
				subOrderType: "DIRECT",
				sendConditionType: "PAID",
				payTime: undefined,
				pay: {
					payId: 1,
				},
				payId: 1,
				payment: 0,
				totalFee: 0,
				totalAmount: 0,
				discountFee: 0,
				taxFee: 0,
				postFee: 0,
				isAutoWarehouse: false,
				wmsWarehouseCode: undefined,
				wmsAppCode: undefined,
				warehouseChannel: undefined,
				shippingCode: undefined,
				isAutoGift: 0,
				expectedRepaymentTime: undefined,
				receiverProvince: undefined,
				receiverCity: undefined,
				receiverDistrict: undefined,
				receiverStreet: undefined,
				receiverProvinceId: undefined,
				receiverCityId: undefined,
				receiverDistrictId: undefined,
				receiverStreetId: undefined,
				receiverAddress: undefined,
				receiverName: undefined,
				receiverZip: undefined,
				receiverMobile: undefined,
				receiverPhone: undefined,
				buyerMemo: undefined,
				sellerMemo: undefined,
				currency: "RMB",
				orderGoodses: [],
				isDeclare: false,
				IdNumber: undefined,
				payNumber: undefined,
				handingFee: "0.00",
				deliveryType: "EXPRESS",
				distributorName: undefined, //分销商名称
				shopType: undefined,
				orderTag: undefined,
			},
			parentId: undefined,
			warehouseCode: undefined,
			submitLoading: false,
			shopCountry: "",
			countryRegular: {
				CN: /^1[3-9]\d{9}$/,
				China: /^1[3-9]\d{9}$/,
				MY: /^(01[0-9]|011|012|013|014|015|016|017|018|019)\d{7,8}$/,
				Malaysia: /^(01[0-9]|011|012|013|014|015|016|017|018|019)\d{7,8}$/,
				PH: /^09\d{9}$/,
				Philippines: /^09\d{9}$/,
				TH: /^0[689]\d{8}$/,
				Thailand: /^0[689]\d{8}$/,
				VN: /^(0[35789]\d{8})$/,
				th: /^0[689]\d{8}$/,
			},
			isForceCreate: false,
			orderSubTypeList: [],
		}
	},
	mounted() {
		this.resetHandle()
		this.$nextTick(() => {
			const omsOrderSn = this.$route.query?.omsOrderSn
			if (omsOrderSn) {
				this.orderSn = omsOrderSn
				this.fetchOrderInfo()
			} else {
				Object.keys(this.countryMap).forEach((key) => {
					if (key === this.country) {
						this.shopCountry = key
						this.parentId = this.countryMap[key]
					}
				})
				const currentShop = this.$store.state.currentShop
				this.formdata.shopCode = currentShop.shopCode
				this.selectedShop({ ...currentShop, code: currentShop.shopCode })
			}
		})
	},
	components: {
		datePicker,
		SelectShop,
		SelectUnit,
		SelectPayment,
		SelectRegion,
		SelectShipment,
		SearchSku,
		SelectOrderType,
		SearchWarehouse,
		SelectDistributor,
		DeliveryTypeSelect,
		SelectOrderSubType,
	},
	methods: {
		resetForm() {
			Modal.confirm({
				title: this.$t("重置表单"),
				content: this.$t("确认初始化表单？"),
				okText: this.$t("确认"),
				cancelText: this.$t("取消"),
				onOk: () => {
					this.resetHandle()
				},
			})
		},
		resetHandle() {
			Object.assign(this.$data, this.$options.data())
			this.$refs.searchSku.clear()
			this.$refs.orderTypeRef.onChange("SALE")
		},
		goBack() {
			this.$router.push("originalOrders")
		},
		fetchOrderInfo() {
			//加载订单
			http({
				url: api.getOrderDetail,
				type: "post",
				data: {
					data: {
						orderSn: this.orderSn,
					},
				},
				hasLoading: true,
				success: (res) => {
					const result = res.result
					for (const key in this.formdata) {
						if (result[key] !== undefined) {
							this.formdata[key] = result[key]
						}
					}
					this.formdata.payId = result.pay.payId
					this.formdata.payTime = result.paytime
					this.formdata.deliveryType = result.deliveryType?.toUpperCase()
					//店铺
					this.formdata.shopCode = result.shop.shopCode
					//仓库
					this.formdata.wmsAppCode = result.warehouse.warehouseType
					this.formdata.warehouseChannel = result.warehouse.warehouseType
					this.formdata.wmsWarehouseCode = result.warehouse.wmsWarehouseCode
					this.warehouseCode = `${result.warehouse.warehouseType}_${result.warehouse.wmsWarehouseCode}`
					//快递
					this.formdata.shippingCode = result.shipping?.shippingCode
					this.formdata.specifiedShippingAccount = result.shipping?.shippingAccountCode
					//地址
					this.formdata.receiverProvince = result.receiverProvince.regionName
					this.formdata.receiverProvinceId = result.receiverProvince.regionId
					this.formdata.receiverCity = result.receiverCity.regionName
					this.formdata.receiverCityId = result.receiverCity.regionId
					this.formdata.receiverDistrict = result.receiverDistrict.regionName
					this.formdata.receiverDistrictId = result.receiverDistrict.regionId
					this.formdata.receiverStreet = result.receiverStreet.regionName
					this.formdata.receiverStreetId = result.receiverStreet.regionId

					for (let i = 0; i < this.formdata.orderGoodses.length; i++) {
						const item = this.formdata.orderGoodses[i]
						item.skuCode = item.sku.skuCode
						item.barcode = item.sku.barcode
						item.skuName = item.sku.skuName
						item.skuStyleList = item.sku.skuStyleList
						this.$set(item, "num", item.number)
						this.queryInventory(item, item.sku.barcode)
					}
					this.formdata.payment = 0
					this.formdata.orderType = "SALE"
					this.loadOrder = true

					this.showStreetSelect = true
					this.showDistrictSelect = true
				},
			})
		},
		fetchSku(data) {
			if (!this.$isEmptyObject(data)) {
				this.addGoods = deepClone(data)
				this.addGoods.addGoodsDisabled = false
			}
		},
		async addGoodsToOrder() {
			if (!this.formdata.wmsWarehouseCode) {
				this.$message.warning(this.$t("请选择仓库"))
				return
			}
			//加载商品
			let addGood = true
			const goodAdd = {
				...this.addGoods,
				num: 1,
				availableInventory: undefined,
				discountFee: 0,
				taxFee: 0,
				productDate: undefined,
				batchNo: undefined,
				isGift: false,
				isGoodsGroup: false,
			}
			const saleUnit = this.addGoods.unitList.find((item) => item.saleUnit) || {}
			goodAdd.unit = saleUnit?.unitNameFull
			await this.queryInventory(goodAdd, this.addGoods.barcode)
			this.formdata.orderGoodses.forEach((item) => {
				if (goodAdd.skuCode === item.skuCode) {
					addGood = false
				}
			})
			if (addGood) {
				this.formdata.orderGoodses.push(goodAdd)
			} else {
				this.$warning(this.$t("该商品已经存在订单的商品列表中"), {
					timeout: 2000,
				})
				return false
			}
			this.addGoods = {}
		},
		// 查询barcode可用库存
		async queryInventory(goodAdd, barcode) {
			// 将选中的商品信息修改成数组格式
			const barcodeList = []
			barcodeList.push(barcode)
			const res = await http({
				url: api.skuStockQuerySkuStockForOms,
				type: "post",
				async: true,
				data: {
					warehouseCode: this.warehouseCode,
					barcodeList: barcodeList,
					platform: this.formdata.platform,
				},
			})
			const amount = res.result[barcode]?.availableQuantity || 0
			this.$set(goodAdd, "availableInventory", amount)
			return amount
		},
		deleteGood(data) {
			// this.formdata.orderGoodses.splice(data, 1);
			this.formdata.orderGoodses = this.formdata.orderGoodses.filter((item) => item.skuCode !== data.skuCode)
		},
		selectedProvince(data) {
			this.formdata.receiverProvinceId = data.region_id
			this.formdata.receiverProvince = data.display_name
			this.formdata.receiverCityId = undefined
			this.formdata.receiverCity = undefined
			this.formdata.receiverDistrictId = undefined
			this.formdata.receiverStreet = undefined
			this.formdata.receiverStreetId = undefined
			this.formdata.receiverDistrict = undefined
			this.showStreetSelect = false
			this.showDistrictSelect = false
		},
		selectedCity(data) {
			this.formdata.receiverCityId = data.region_id
			this.formdata.receiverCity = data.display_name
			this.formdata.receiverDistrictId = undefined
			this.formdata.receiverDistrict = undefined
			this.formdata.receiverStreetId = undefined
			this.formdata.receiverStreet = undefined
			this.showDistrictSelect = true
			this.showStreetSelect = false
		},
		selectedDistrict(data) {
			this.formdata.receiverDistrictId = data.region_id
			this.formdata.receiverDistrict = data.display_name
			this.formdata.receiverStreetId = undefined
			this.formdata.receiverStreet = undefined
			this.showStreetSelect = true
		},
		selectedStreet(data) {
			this.formdata.receiverStreetId = data.region_id
			this.formdata.receiverStreet = data.display_name
		},
		selectedPayment(data) {
			this.formdata.payId = data.data
		},
		selectedShop(data) {
			const isDiffShop = this.formdata.shopCode !== data.code
			if (data) {
				this.formdata.seller = data.code
				this.formdata.shopCode = data.code
				this.formdata.shopName = data.name
				this.formdata.platform = data.platform
				this.formdata.currency = data.currency
				this.formdata.partyId = data.partyId
				this.formdata.shopType = data.shopType
				this.formdata.country = data.country
				Object.keys(this.countryMap).forEach((key) => {
					if (key === data.country) {
						this.shopCountry = key
						this.parentId = this.countryMap[key]
					}
				})
				this.loadOrder = false
				if (this.isStore) {
					const shopAddress = data.shopAddress || {}
					this.formdata.receiverProvinceId = shopAddress.provinceId
					this.formdata.receiverProvince = shopAddress.province
					this.formdata.receiverCityId = shopAddress.cityId
					this.formdata.receiverCity = shopAddress.city
					this.formdata.receiverDistrictId = shopAddress.districtId
					this.formdata.receiverDistrict = shopAddress.district
					this.formdata.receiverAddress = shopAddress.address
					this.formdata.receiverName = data.supervision
					this.formdata.receiverMobile = data.phone
					this.formdata.platformOrderTime = getNowTime()
					//默认物流
					this.formdata.deliveryType = "LOGISTICS"
				}
			} else {
				this.formdata.seller = undefined
				this.formdata.shopCode = undefined
				this.formdata.shopName = undefined
				this.formdata.platform = undefined
				this.formdata.currency = "RMB"
				this.formdata.partyId = undefined
				this.formdata.shopType = undefined
			}
			if (isDiffShop) {
				this.formdata.distributorName = undefined
				this.formdata.wmsWarehouseCode = undefined
				this.formdata.wmsAppCode = undefined
				this.formdata.warehouseChannel = undefined
				this.formdata.shippingCode = undefined
				this.formdata.specifiedShippingAccount = undefined
			}
		},
		choosePaytime(data) {
			this.formdata.payTime = data.data
		},
		choosePlatformOrderTime(data) {
			this.formdata.platformOrderTime = data.data
		},
		chooseLatestDeliveryTime(data) {
			this.formdata.latestDeliveryTime = data.data
		},
		chooseProductDate(data) {
			const arr = data.componentId.split("_")
			const key = arr[1]
			this.formdata.orderGoodses[key].productDate = data.data
		},
		chooseExectedRepaymentTime(data) {
			this.formdata.expectedRepaymentTime = data.data
		},
		selectedWarehouse(data) {
			this.formdata.wmsWarehouseCode = data.wmsWarehouseCode
			this.formdata.wmsAppCode = data.wmsAppCode
			this.formdata.warehouseChannel = data.wmsAppCode
			this.warehouseCode = data.uniqueCode

			this.formdata.shippingCode = undefined
			this.formdata.specifiedShippingAccount = undefined
		},
		selectedShipment(data) {
			this.formdata.shippingCode = data.shippingCode
			this.formdata.specifiedShippingAccount = data.shippingAccountCode
		},
		onSubmit: debounce(function () {
			const formData = deepClone(this.formdata)
			if (!formData.receiverName) {
				this.$warning(this.$t("请输入") + this.$t("收件姓名"))
				return false
			}

			if (formData.country !== "CN" && !formData.receiverZip) {
				this.$warning(this.$t("请输入") + this.$t("邮编"))
				return false
			}

			if (!formData.receiverMobile && !formData.receiverPhone) {
				this.$warning(this.$t("手机号和电话不能同时为空！"))
				return false
			}
			if (formData.receiverMobile) {
				const res = this.countryRegular[this.shopCountry]?.test(formData.receiverMobile)
				if (!res) {
					this.$warning(this.countryMessage[this.shopCountry])
					return false
				}
			}

			if (!formData.receiverProvinceId) {
				this.$warning(this.$t("请选择省"))
				return false
			}

			if (!formData.receiverCityId) {
				this.$warning(this.$t("请选择市"))
				return false
			}

			if (!formData.receiverDistrictId) {
				this.$warning(this.$t("请选择区"))
				return false
			}

			if (!formData.receiverAddress) {
				this.$warning(this.$t("请输入") + this.$t("具体地址"))
				return false
			}

			if (!formData.payId) {
				this.$warning("必须输入支付方式")
				return false
			}

			if (this.showDistributorSelect && !formData.distributorName) {
				this.$warning(this.$t("请选择分销商"))
				return false
			}

			if (!this.formdata.platformOrderTime) {
				this.$warning(this.$t("请选择平台下单时间"))
				return false
			}

			if (this.isStore && !this.formdata.latestDeliveryTime) {
				this.$warning(this.$t("请选择预计到货时间"))
				return false
			}

			if (!this.formdata.wmsWarehouseCode) {
				this.$warning(this.$t("请选择仓库"))
				return
			}

			if (this.formdata.deliveryType === "EXPRESS" && !this.formdata.shippingCode) {
				this.$warning(this.$t("请选择快递"))
				return false
			}

			if (formData.payTime) {
				formData.payTime = ThailandTimeToChinaTime(formData.payTime)
			}

			formData.platformOrderTime = ThailandTimeToChinaTime(formData.platformOrderTime)

			formData.pay = {
				payId: formData.payId.toString(),
			}
			formData.pay = JSON.stringify(formData.pay)

			if (!this.showDistrictSelect) {
				formData.receiverDistrictId = formData.receiverStreetId
				formData.receiverDistrict = formData.receiverStreet
			}
			if (formData.orderGoodses.length === 0) {
				this.$warning(this.$t("请选择商品"))
				return false
			}
			formData.isAutoGift = !!formData.isAutoGift

			formData.totalFee = this.totalFee
			formData.totalAmount = this.totalAmount
			const submitOrderGoodses = formData.orderGoodses.map((item) => ({
				availableInventory: item.availableInventory,
				discountFee: item.discountFee,
				taxFee: item.taxFee,
				isGift: item.isGift,
				isGoodsGroup: item.isGoodsGroup,
				num: item.num,
				outerSkuId: item.skuCode,
				price: item.price,
				title: item.skuName,
				unit: item.unit,
			}))
			formData.orderGoodses = submitOrderGoodses
			const submitData = { data: formData }
			submitData.isForceCreate = this.isForceCreate
			this.submitLoading = true
			try {
				http({
					url: api.addOrder,
					type: "post",
					data: submitData,
					showErrorMessage: false,
					hasLoading: true,
					success: (res) => {
						this.submitLoading = false
						const result = res.data || res.result
						this.resetHandle()
						this.$success(this.$t("订单创建成功！原始订单号为：") + result.platformOrderId)
					},
					fail: (res) => {
						console.log("res: ", res)
						if (String(res.code) === "2000010") {
							//plymouth订单增加外部订单号，复制订单时提示是否多次创建
							this.$confirm({
								title: this.$t("警告"),
								content: this.$t("该原始订单已存在，是否重复创建？"),
								onOk: () => {
									this.isForceCreate = true
									this.onSubmit()
								},
								onCancel: () => {
									this.submitLoading = false
								},
							})
							return
						}
						this.submitLoading = false
						this.$error(res.subMsg || res.msg)
					},
				})
			} catch (err) {
				this.submitLoading = false
			}
		}, 800),
		calcSingleProductAmount(fgood) {
			const total = Number(fgood.price) * Number(fgood.num) + Number(fgood.taxFee) - Number(fgood.discountFee)
			return total.toFixed(2)
		},
		customCell(record, index) {
			return index === 0
				? {
						attrs: {
							rowSpan: this.formdata.orderGoodses.length,
						},
					}
				: {
						style: {
							display: "none",
						},
						attrs: {
							rowSpan: 0,
						},
					}
		},
		changeOrderType(val, list) {
			this.orderSubTypeList = list
			if (list.length > 0) {
				if (val === "SALE") {
					this.selectOrderTag("FINISHED_PRODUCT_ORDER")
				} else {
					this.selectOrderTag(list[0].orderType)
				}
			}
		},
		selectOrderTag(val) {
			//订单子类型 到货时间 订单T+2 补单T+1
			this.formdata.orderTag = val
			if (val.includes("REORDER")) {
				//补单
				this.formdata.latestDeliveryTime = getDateStr(1)
			} else {
				this.formdata.latestDeliveryTime = getDateStr(2)
			}
		},
		getStyleName(list) {
			const styleName = list.map((item) => item.styleValue)
			return styleName.join(";")
		},
	},
	computed: {
		showDistributorSelect() {
			return this.formdata.orderType === "DISTRIBUTOR"
		},
		country() {
			return this.$store.getters.country.toUpperCase()
		},
		countryMap() {
			return this.$store.state.countryMap
		},
		columns() {
			return [
				{
					title: this.$t("商品信息"),
					scopedSlots: { customRender: "ProductInfo" },
					key: "ProductInfo",
					width: 200,
				},
				{
					title: this.$t("单价"),
					scopedSlots: { customRender: "UnitPrice" },
					key: "UnitPrice",
				},
				{
					title: this.$t("数量"),
					scopedSlots: { customRender: "Quantity" },
					key: "Quantity",
				},
				{
					title: this.$t("单位"),
					scopedSlots: { customRender: "Unit" },
					key: "Unit",
				},
				{
					title: this.$t("可用库存"),
					dataIndex: "availableInventory",
					key: "availableInventory",
				},

				{
					title: this.$t("单品优惠"),
					scopedSlots: { customRender: "SingleProductDiscount" },
					key: "SingleProductDiscount",
				},
				{
					title: this.$t("单品税费"),
					scopedSlots: { customRender: "SingleProductTax" },
					key: "SingleProductTax",
				},
				{
					title: this.$t("单品总金额"),
					scopedSlots: { customRender: "SingleProductAmount" },
					key: "SingleProductAmount",
				},
				{
					title: this.$t("商品总金额"),
					scopedSlots: { customRender: "ProductTotalAmount" },
					key: "ProductTotalAmount",
					customCell: this.customCell,
				},
				{
					title: this.$t("邮费"),
					scopedSlots: { customRender: "PostFee" },
					key: "PostFee",
					customCell: this.customCell,
				},
				{
					title: this.$t("订单优惠"),
					scopedSlots: { customRender: "OrderDiscountFee" },
					key: "OrderDiscountFee",
					customCell: this.customCell,
				},
				{
					title: this.$t("应收金额"),
					scopedSlots: { customRender: "ReceivableFee" },
					key: "ReceivableFee",
					customCell: this.customCell,
				},
				{
					title: this.$t("操作"),
					scopedSlots: { customRender: "Action" },
					key: "Action",
				},
			]
		},
		totalAmount() {
			let totalAmount = 0
			if (this.formdata.orderGoodses.length > 0) {
				this.formdata.orderGoodses.forEach((fgood) => {
					if (fgood.taxFee === null) {
						fgood.taxFee = 0
					}
					totalAmount += Number(fgood.price) * Number(fgood.num) + Number(fgood.taxFee) - Number(fgood.discountFee)
				})
			}
			return totalAmount.toFixed(2)
		},
		totalFee() {
			return (this.totalAmount + Number(this.formdata.postFee) - Number(this.formdata.discountFee)).toFixed(2)
		},
		countryMessage() {
			return {
				CN: this.$t("手机号格式不正确,请检查是否有误,示例：13XXXXXXXXX"),
				China: this.$t("手机号格式不正确,请检查是否有误,示例：13XXXXXXXXX"),
				MY: this.$t("马来西亚手机号为01开头的10位或11位数字，请检查是否有误"),
				Malaysia: this.$t("马来西亚手机号为01开头的10位或11位数字，请检查是否有误"),
				PH: this.$t("菲律宾手机号为09开头的11位数字,请检查是否有误"),
				Philippines: this.$t("菲律宾手机号为09开头的11位数字,请检查是否有误"),
				TH: this.$t("泰国手机号为06、08、09开头的10位数字,请检查是否有误"),
				Thailand: this.$t("泰国手机号为06、08、09开头的10位数字,请检查是否有误"),
				VN: this.$t("越南手机号为03、05、07、08、09开头的10位数字,请检查是否有误"),
				th: this.$t("泰国手机号为06、08、09开头的10位数字,请检查是否有误"),
			}
		},
		isStore() {
			//是门店类型
			return this.$store.getters.isStore
		},
		scrollHeight() {
			return `${(window.innerHeight - 260) * this.$store.state.rate}px`
		},
	},
}
</script>

<style lang="scss" scoped>
.fixContent {
  padding: 0;
  // height: calc(100vh - 170px);
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  .content {
    margin-bottom: 10px !important;
  }
  &::v-deep .ant-form-item {
    margin: 0;
  }
}

.signle-order-generation {
  padding: 0;
  font-size: 12px;
  color: #666666;
  font-weight: normal;
}

.order-info {
  margin-right: 10px;
}

.btn-order {
  margin-top: 3px;
}

input[type='checkbox'],
input[type='radio'] {
  margin: 3px 0 0 -20px;
}

.button-div {
  text-align: center;
}

.message-board ul {
  /*margin-top: 10px;*/
  padding-left: 30px;
}

.message-board ul li {
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
}

.modify-img {
  margin-bottom: 1px;
  margin-left: 10px;
  width: 16px;
  height: 16px;
}

.table {
  margin-bottom: 0px;
}

.goods-amount {
  color: #3697de;
  text-align: center;
  height: 35px;
  line-height: 35px;
  font-weight: bold;
}

.goods-padding {
  padding-left: 7px;
  padding-right: 0px;
}

.input-goods {
  height: 23px;
  /*width: 60px;*/
  margin: auto auto;
  display: inline;
}

.message-input {
  margin-left: 5px;
  box-shadow: none;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 0;
}

.message-show {
  margin-left: 5px;
  color: #000;
}

.control-label {
  padding-right: 1px;
  padding-left: 1px;
}

i {
  font-size: 20px;
}

.delete-icon {
  color: #f86767;
}

.table td {
  vertical-align: middle;
  position: relative;
}

.not-editable {
  background-color: $white;
  border: 0;
}

.question {
  font-size: 12px;
  color: #999;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in below version 2.1.8 */ {
  opacity: 0;
}

.ant-input-number {
  width: 100%;
}
</style>
