import en_US from "ant-design-vue/lib/locale-provider/en_US"
import th_TH from "ant-design-vue/lib/locale-provider/th_TH"
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN"

export default {
	setState(state, data) {
		setState(state, data)
	},
	changeLang(state, lang) {
		let locale = zh_CN
		switch (lang) {
			case "zh":
				locale = zh_CN
				break
			case "en":
				locale = en_US
				break
			case "th":
				locale = th_TH
				break
			default:
				locale = zh_CN
				break
		}
		setState(state, {
			lang,
			locale,
		})
	},
	setOrderDetail(state, { orderId, data }) {
		state.orderDetailStateInfo[orderId] = data
	},
}

const setState = (state, data) => {
	for (const item in data) {
		state[item] = data[item]
		localStorage.setItem(item, typeof data[item] === "object" && data[item] !== null ? JSON.stringify(data[item]) : data[item])
	}
}
