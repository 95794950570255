<template>
  <div>
    <div class="content-wrapper">
      <section class="content">
        <a-row style="margin-bottom: 10px">
          <a-col :span="7" style="margin-right: 2%">
            <a-select
              :placeholder="$t(`请选择责任方`)"
              style="width: 100%"
              allowClear
              @change="handleChange"
            >
              <a-select-option value="BRAND">{{ $t('品牌方') }}</a-select-option>
              <a-select-option value="OPERATE">{{ $t('运营') }}</a-select-option>
              <a-select-option value="PURCHASE">{{ $t('采购') }}</a-select-option>
              <a-select-option value="WAREHOUSE">{{ $t('仓库') }}</a-select-option>
              <a-select-option value="CUSTOMER">{{ $t('顾客') }}</a-select-option>
              <a-select-option value="OTHER">{{ $t('其他') }}</a-select-option>
            </a-select>
          </a-col>
          <a-col :span="7" style="margin-right: 2%">
            <a-input :placeholder="$t(`具体原因`)" v-model="orderSplitVo.reason" allowClear />
          </a-col>
          <a-col :span="7">
            <a-select
              v-model="orderSplitVo.warehouseType"
              :placeholder="$t(`请选择`) + $t(`转仓方式`)"
              style="width: 100%"
            >
              <a-select-option value="1">{{ $t('转仓') }}</a-select-option>
              <a-select-option value="0">{{ $t('不转仓') }}</a-select-option>
            </a-select>
          </a-col>
        </a-row>
        <a-row v-if="orderSplitVo.warehouseType == '0'">
          <a-col :span="7" style="margin-right: 2%">
            <a-input
              :placeholder="$t(`拆单数量`)"
              v-model.number="orderSplitVo.splitOrdersNumber"
              allowClear
            />
          </a-col>
        </a-row>

        <label class="control-label mt4">{{ $t(`收件人`) }}{{ $t(`地址`) }}</label>
        <span class="control-label" style="text-align: left">
          {{ splitOrderDetail.receiverProvince.regionName }}
          {{ splitOrderDetail.receiverCity.regionName }}
          {{ splitOrderDetail.receiverDistrict.regionName }}
          {{ splitOrderDetail.receiverAddress }}
        </span>
        <div class="tips">{{ $t('颜色一样的商品不允许拆单') }}</div>
        <div class="order-goods-table" v-if="!previewShow">
          <div></div>
          <table class="table" v-if="orderSplitVo.warehouseType == '1'">
            <thead>
              <tr>
                <th style="min-width: 200px">
                  {{ $t(`商家编码`) }}
                </th>
                <th style="min-width: 100px">
                  {{ $t(`商品数量`) }}
                </th>
                <th style="min-width: 100px">
                  {{ $t(`商品分摊`) }}
                </th>
                <th style="min-width: 200px">{{ $t(`商品名称`) }}/{{ $t(`规格`) }}</th>
                <th style="min-width: 150px">SKU{{ $t(`数量`) }}</th>
                <th style="min-width: 100px">
                  {{ $t(`应收小计`) }}
                </th>
                <template v-for="item in warehouseList">
                  <th
                    v-for="item1 in item"
                    :class="{ 'back-yellow': item1.ye }"
                    :key="item1.warehouse.wmsWarehouseCode"
                    style="min-width: 150px"
                  >
                    {{ item1.warehouse.warehouseName }}
                  </th>
                </template>
                <th style="min-width: 150px">
                  {{ $t(`操作`) }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(item, index) in splitOrderGoodses" :key="index">
              <tr v-for="(item1, index1) in item" :key="index1" :data-order-type="item1.colorType">
                <td
                  v-if="item1.show"
                  :rowspan="item1.rowspan"
                  style="word-wrap: break-word; word-break: break-all; vertical-align: middle"
                >
                  <span v-if="item1.gift" class="gift-icon"></span>
                  <span :class="{ padLeft: item1.gift }">{{ item1.skuCode }}</span>
                  <p>{{ item1.name }}</p>
                  <p v-if="item1.group && !item1.group.allowSplit" class="gray">
                    ({{ $t('不可拆分') }})
                  </p>
                </td>
                <td v-if="item1.show" :rowspan="item1.rowspan" style="vertical-align: middle">
                  {{ item1.numShow }}
                </td>
                <td v-if="item1.show" :rowspan="item1.rowspan" style="vertical-align: middle">
                  {{ Number(item1.totalFee2).toFixed(2) }}
                </td>
                <td style="word-wrap: break-word; word-break: break-all; vertical-align: middle">
                  <template v-if="item1.sku">{{ item1.sku.skuName }}</template>
                  <br />
                  <a-select v-if="item1.sku.styleValue1" v-model="item1.sku.styleValue1.id">
                    <a-select-option v-if="!item1.editable" :value="item1.sku.styleValue1.id">
                      {{ item1.sku.styleValue1.value }}
                    </a-select-option>
                  </a-select>
                  <a-select v-if="item1.sku.styleValue2" v-model="item1.sku.styleValue2.id">
                    <a-select-option v-if="!item1.editable" :value="item1.sku.styleValue2.id">
                      {{ item1.sku.styleValue2.value }}
                    </a-select-option>
                  </a-select>
                  <a-select v-if="item1.sku.styleValue3" v-model="item1.sku.styleValue3.id">
                    <a-select-option v-if="!item1.editable" :value="item1.sku.styleValue3.id">
                      {{ item1.sku.styleValue3.value }}
                    </a-select-option>
                  </a-select>
                </td>
                <td style="vertical-align: middle">{{ item1.number }}</td>
                <td style="vertical-align: middle">{{ item1.totalFee }}</td>
                <template v-if="item1.summaryResults != undefined">
                  <template v-for="warehouseCodeList in item1.summaryResults">
                    <td
                      v-for="(warehouse, warehouseIndex) in warehouseCodeList"
                      style="vertical-align: middle"
                      :class="{
                        red: warehouse.availableQuantity < item1.number,
                      }"
                      :key="warehouse.warehouse.wmsWarehouseCode"
                    >
                      <input
                        type="radio"
                        :name="index + '_' + index1"
                        :id="index + '_' + index1 + '_' + warehouseIndex"
                        :value="warehouse.warehouse.wmsWarehouseCode"
                        v-model="item1.choosewarehouseCode"
                        @click="checked(index, index1)"
                      />
                    </td>
                  </template>
                </template>
                <template v-else>
                  <template v-for="warehouses in warehouseList">
                    <td
                      v-for="warehouse in warehouses"
                      :key="warehouse.warehouse.wmsWarehouseCode"
                    ></td>
                  </template>
                </template>
                <td style="vertical-align: middle">
                  <template v-if="(item1.group && item1.group.allowSplit) || !item1.group">
                    <a-input
                      v-if="item1.splitable"
                      type="text"
                      style="width: 50%"
                      :id="index + '_' + index1"
                    />
                    <a
                      href="#"
                      v-if="!item1.splitable"
                      @click.prevent="editsplitOrderGoodNum(index, index1)"
                    >
                      {{ $t(`分拆数量`) }}
                    </a>
                    <a
                      href="#"
                      v-if="item1.splitable"
                      @click.prevent="confirmsplitOrderGoodNum(index, index1)"
                    >
                      <i class="iconfont icon-save" style="color: #52c41a; margin-left: 5px"></i>
                    </a>
                    <a
                      href="#"
                      v-if="item1.splitable"
                      @click.prevent="cancelsplitOrderGoodNum(index, index1)"
                    >
                      <i class="iconfont icon-cancel" style="margin-left: 5px"></i>
                    </a>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table" v-if="orderSplitVo.warehouseType == '0'">
            <thead>
              <tr>
                <th style="min-width: 200px">
                  {{ $t(`商家编码`) }}
                </th>
                <th style="min-width: 100px">
                  {{ $t(`商品数量`) }}
                </th>
                <th style="min-width: 100px">
                  {{ $t(`商品分摊`) }}
                </th>
                <th style="min-width: 200px">{{ $t(`商品名称`) }}/{{ $t(`规格`) }}</th>
                <th style="min-width: 150px">SKU{{ $t(`数量`) }}</th>
                <th style="min-width: 100px">
                  {{ $t(`应收小计`) }}
                </th>
                <template v-for="(item, index) of orderSplitVo.splitOrdersNumber">
                  <th style="min-width: 150px" :key="index">
                    {{ `订单${item}` }}
                  </th>
                </template>
                <th style="min-width: 150px">
                  {{ $t(`操作`) }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(item, index) in splitOrderGoodses" :key="index">
              <tr v-for="(item1, index1) in item" :key="index1" :data-order-type="item1.colorType">
                <td
                  v-if="item1.show"
                  :rowspan="item1.rowspan"
                  style="word-wrap: break-word; word-break: break-all; vertical-align: middle"
                >
                  <span v-if="item1.gift" class="gift-icon"></span>
                  <span :class="{ padLeft: item1.gift }">{{ item1.skuCode }}</span>
                  <p>{{ item1.name }}</p>
                  <p v-if="item1.group && !item1.group.allowSplit" class="gray">
                    ({{ $t('不可拆分') }})
                  </p>
                </td>
                <td v-if="item1.show" :rowspan="item1.rowspan" style="vertical-align: middle">
                  {{ item1.numShow }}
                </td>
                <td v-if="item1.show" :rowspan="item1.rowspan" style="vertical-align: middle">
                  {{ Number(item1.totalFee2).toFixed(2) }}
                </td>
                <td style="word-wrap: break-word; word-break: break-all; vertical-align: middle">
                  <template v-if="item1.sku">{{ item1.sku.skuName }}</template>
                  <br />
                  <a-select v-if="item1.sku.styleValue1" v-model="item1.sku.styleValue1.id">
                    <a-select-option v-if="!item1.editable" :value="item1.sku.styleValue1.id">
                      {{ item1.sku.styleValue1.value }}
                    </a-select-option>
                  </a-select>
                  <a-select v-if="item1.sku.styleValue2" v-model="item1.sku.styleValue2.id">
                    <a-select-option v-if="!item1.editable" :value="item1.sku.styleValue2.id">
                      {{ item1.sku.styleValue2.value }}
                    </a-select-option>
                  </a-select>
                  <a-select v-if="item1.sku.styleValue3" v-model="item1.sku.styleValue3.id">
                    <a-select-option v-if="!item1.editable" :value="item1.sku.styleValue3.id">
                      {{ item1.sku.styleValue3.value }}
                    </a-select-option>
                  </a-select>
                </td>
                <td style="vertical-align: middle">{{ item1.number }}</td>
                <td style="vertical-align: middle">{{ item1.totalFee }}</td>
                <template>
                  <td
                    v-for="(warehouse, warehouseIndex) in orderSplitVo.splitOrdersNumber"
                    style="vertical-align: middle"
                    :key="warehouseIndex"
                  >
                    <a-radio-group v-model="item1.chooseOrdersId">
                      <a-radio
                        :value="warehouse"
                        :id="index + '_' + index1 + '_' + warehouseIndex"
                        @click="orderChecked(item1, warehouse)"
                      />
                    </a-radio-group>
                  </td>
                </template>
                <td style="vertical-align: middle">
                  <template v-if="(item1.group && item1.group.allowSplit) || !item1.group">
                    <a-input
                      v-if="item1.splitable"
                      type="text"
                      style="width: 50%"
                      :id="index + '_' + index1"
                    />
                    <a
                      href="#"
                      v-if="!item1.splitable"
                      @click.prevent="editsplitOrderGoodNum(index, index1)"
                    >
                      {{ $t(`分拆数量`) }}
                    </a>
                    <a
                      href="#"
                      v-if="item1.splitable"
                      @click.prevent="confirmsplitOrderGoodNum(index, index1)"
                    >
                      <i class="iconfont icon-save" style="color: #52c41a; margin-left: 5px"></i>
                    </a>
                    <a
                      href="#"
                      v-if="item1.splitable"
                      @click.prevent="cancelsplitOrderGoodNum(index, index1)"
                    >
                      <i class="iconfont icon-cancel" style="margin-left: 5px"></i>
                    </a>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
          <a-row
            style="font-size: 13px !important; margin-bottom: 10px"
            type="flex"
            justify="space-around"
          >
            <a-col :span="2">
              <label style="font-size: 13px !important">{{ $t(`邮费`) }}：</label>
              <span style="text-align: left">
                <span>{{ splitOrderDetail.postFee }}</span>
              </span>
            </a-col>
            <a-col :span="4">
              <label style="font-size: 13px !important">{{ $t(`总优惠金额`) }}：</label>
              <span style="text-align: left">
                <span>{{ splitOrderDetail.totalDiscountFee }}</span>
              </span>
            </a-col>
            <a-col :span="4">
              <label style="font-size: 13px !important">{{ $t(`应收总额`) }}：</label>
              <span style="text-align: left">
                <span>{{ splitOrderDetail.totalFee }}</span>
              </span>
            </a-col>
            <a-col :span="6">
              <label style="font-size: 13px !important">{{ $t(`快递方式`) }}：</label>
              <span style="text-align: left">
                {{ splitOrderDetail.shipping.shippingName }}
              </span>
            </a-col>
          </a-row>
        </div>

        <div class="col-md-12 no-padding form-horizontal preview-box" v-if="previewShow">
          <div class="form-group">
            <h5 class="preview">
              <a href="#" v-if="!previewEditable" @click.prevent="editPreview">
                {{ $t(`调整金额`) }}
                <i class="iconfont icon-a-theEditor" style="margin-left: 5px"></i>
              </a>
              <a href="#" v-if="previewEditable" @click.prevent="confirmeditPreview">
                <i class="iconfont icon-save" style="color: #52c41a; margin-left: 5px"></i>
              </a>
              <a href="#" v-if="previewEditable" @click.prevent="canceleditPreview">
                <i class="iconfont icon-cancel" style="margin-left: 5px"></i>
              </a>
            </h5>
          </div>
          <div
            v-for="(order, orderIndex) in previewOrderList"
            :key="orderIndex"
            class="preview-order-block"
          >
            <div class="form-group split-orders">
              <table class="table">
                <thead>
                  <tr>
                    <th style="min-width: 90px">
                      {{ $t(`商家编码`) }}
                    </th>
                    <th style="min-width: 90px">{{ $t(`商品数量`) }}</th>
                    <th style="min-width: 90px">
                      {{ $t(`商品分摊`) }}
                    </th>
                    <th style="min-width: 90px">
                      {{ $t(`商品名称`) }}
                    </th>
                    <!-- <th style="width: 5%;">拍下数</th> -->
                    <th style="min-width: 100px">SKU{{ $t(`数量`) }}</th>
                    <th style="min-width: 90px">
                      {{ $t(`可用`) }}
                    </th>
                    <th style="min-width: 80px">{{ $t(`单价`) }}</th>
                    <th style="min-width: 90px">
                      {{ $t(`商品优惠`) }}
                    </th>
                    <th style="min-width: 150px">
                      {{ $t(`订单优惠分摊`) }}
                    </th>
                    <th style="min-width: 90px">{{ $t(`商品关税`) }}</th>
                    <th style="min-width: 90px">{{ $t(`货款小计`) }}</th>
                    <th style="min-width: 90px">{{ $t(`应收小计`) }}</th>
                  </tr>
                </thead>
                <tbody v-for="(item, index) in order.orderGoodses" :key="index + 1">
                  <tr v-for="(item1, index1) in item" :key="item1.skuCode + index1">
                    <td
                      v-if="item1.show"
                      :rowspan="item1.rowspan"
                      style="word-wrap: break-word; word-break: break-all; vertical-align: middle"
                    >
                      <span v-if="item1.gift" class="gift-icon"></span>
                      <span :class="{ padLeft: item1.gift }">{{ item1.skuCode }}</span>
                      <p>{{ item1.name }}</p>
                    </td>
                    <td v-if="item1.show" :rowspan="item1.rowspan" style="vertical-align: middle">
                      {{ item1.numShow }}
                    </td>
                    <td v-if="item1.show" :rowspan="item1.rowspan" style="vertical-align: middle">
                      {{ Number(item1.totalFee2).toFixed(2) }}
                    </td>
                    <td style="word-wrap: break-word; word-break: break-all">
                      <template v-if="item1.sku">{{ item1.sku.skuName }}</template>
                    </td>
                    <!-- <td></td> -->
                    <td>{{ item1.number }}</td>
                    <td
                      :class="{
                        redText: item1.availableQuantity < item1.number,
                      }"
                    >
                      {{ item1.availableQuantity }}
                    </td>
                    <td>
                      <a-input
                        type="text"
                        v-model="item1.price"
                        :disabled="!previewEditable"
                        :class="{ 'not-editable': !previewEditable }"
                      />
                    </td>
                    <td>
                      <a-input
                        type="text"
                        v-model="item1.discountFee"
                        :disabled="!previewEditable"
                        :class="{ 'not-editable': !previewEditable }"
                      />
                    </td>
                    <td>
                      <a-input
                        type="text"
                        v-model="item1.divideDiscountFee"
                        :disabled="!previewEditable"
                        :class="{ 'not-editable': !previewEditable }"
                        @keyup="modifyDiscountFee(orderIndex, index, index1)"
                      />
                    </td>
                    <td>{{ item1.taxFee }}</td>
                    <td>{{ item1.goodsFee }}</td>
                    <td>{{ item1.totalFee }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div :key="orderIndex" class="mt4">
              <a-row
                type="flex"
                justify="space-between"
                style="font-size: 13px; margin-bottom: 10px"
              >
                <a-col :span="4">
                  <span>{{ $t(`邮费`) }}：</span>
                  <a-input
                    style="width: 60%"
                    v-model="order.postFee"
                    :disabled="!previewEditable"
                    @keyup="updatePostFee(orderIndex)"
                  />
                </a-col>
                <a-col :span="2" style="margin-top: 6px">
                  <span>{{ $t(`订单优惠`) }}：</span>
                  <span>{{ order.discountFee }}</span>
                </a-col>
                <a-col :span="3" style="margin-top: 6px">
                  <span>{{ $t(`总优惠金额`) }}：</span>
                  <span>{{ order.totalDiscountFee }}</span>
                </a-col>
                <a-col :span="3" style="margin-top: 6px">
                  <span>{{ $t(`应收总额`) }}：</span>
                  <span>{{ order.totalFee }}</span>
                </a-col>
                <a-col :span="4" style="margin-top: 6px">
                  <span>{{ $t(`仓库`) }}：</span>
                  <span v-if="order.warehouse">{{ order.warehouse?.warehouseName }}</span>
                </a-col>
                <a-col :span="6">
                  <span>{{ $t(`快递方式`) }}：</span>
                  <SelectShipment
                    :disabled="!previewEditable"
                    style="width: 60%"
                    :platform="splitOrderDetail.shop.platform"
                    :shopCode="splitOrderDetail.shop.shopCode || splitOrderDetail.shop.shopNick"
                    :wmsAppCode="warehouseMapping[order.warehouse.wmsWarehouseCode]?.wmsAppCode"
                    :warehouseCode="order.warehouse.wmsWarehouseCode"
                    :defaultShippingCode="order.shipping?.shippingAccountCode"
                    @selected="
                      (data) => {
                        selectedShipment(data, orderIndex)
                      }
                    "
                  />
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
        <div v-if="!previewShow">
          <div class="col-md-3 col-sm-3 col-xs-3 action-button"></div>
          <div>
            <a-divider style="width: 100%; height: 10px"></a-divider>
            <a-button type="primary" @click="preview" style="display: block; margin: 0 auto">
              {{ $t(`拆分预览`) }}
            </a-button>
          </div>
        </div>
        <div v-else>
          <a-divider style="height: 10px"></a-divider>
          <div style="text-align: center">
            <a-button type="primary" @click="confirmSplitOrder" :loading="submitLoading">
              {{ $t(`确认`) }}
            </a-button>
            <a-button @click="cancelPreview">
              {{ $t(`取消`) }}
            </a-button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import deepClone from "@common/deepClone"
import { dealWithOrderGoods } from "@common/orderGoodses"
import SelectShipment from "@component/selectShipment"
import api from "@service/api"
import http from "@service/axios"

export default {
	name: "orderSplit",
	data() {
		return {
			orderId: "",
			previewShow: false,
			previewEditable: false,
			splitOrderGoodses: [],
			warehouseList: [],
			allWarehouseCode: "",
			splitOrderDetail: {
				orderId: "",
				orderSn: "",
				partyId: "",
				sellerMemo: "",
				buyerMemo: "",
				receiverAddress: "",
				totalFee: "",
				discountFee: "",
				postFee: "",
				postDiscountFee: "",
				shop: {},
				distributor: {},
				receiverProvince: {
					regionId: "",
					regionName: "",
				},
				receiverCity: {
					regionId: "",
					regionName: "",
				},
				receiverDistrict: {
					regionId: "",
					regionName: "",
				},
				receiverStreet: {
					regionId: "",
					regionName: "",
				},
				shipping: {},
				warehouse: {},
				orderGoodses: [],
				packages: {},
				orderProcess: {},
				invoiceInfo: [],
			},
			previewOrderList: [],
			previewOrderListTemp: [],
			orderSplitVo: {
				orderId: "",
				splitType: "SAME_WAREHOUSE",
				dutyParty: "",
				warehouseType: "1",
				splitOrdersNumber: undefined,
				reason: "",
				orders: [],
			},
			warehouseMapping: {},
			submitLoading: false,
		}
	},
	mounted() {
		this.init()
	},
	components: {
		SelectShipment,
	},
	watch: {
		allWarehouseCode(newVal) {
			if (newVal) {
				this.chooseAllWarehouse()
			}
		},
	},
	methods: {
		init() {
			this.orderId = this.$route.params.orderId
			this.actionType = this.$route.params.actionType
			this.orderSplitVo.orderId = this.orderId
			this.getOrderDetail()
		},
		handleChange(value) {
			this.orderSplitVo.dutyParty = value
		},
		selectedShipment(data, index) {
			if (!this.$isEmptyObject(data)) {
				const shiping = {
					shippingCode: data.shippingCode,
					shippingName: data.data.shippingName,
					shippingAccountCode: data.shippingAccountCode,
					shippingAccountName: data.data.accountName,
				}
				this.previewOrderList[index].shipping = shiping
				this.previewOrderList.splice(0, 0)
			} else {
				this.previewOrderList.splice(0, 0)
			}
		},
		checked(index, index1) {
			// console.log('index, index1: ', index, index1)
			const val = $("input[name='all']:checked").val()
			const diffKey = this.splitOrderGoodses[index][index1].diffKey
			const oldChoosewarehouseCode = this.splitOrderGoodses[index][index1].oldChoosewarehouseCode
			const choosewarehouseCode = this.splitOrderGoodses[index][index1].choosewarehouseCode
			if (val !== undefined) {
				let chooseAll = true
				this.splitOrderGoodses.forEach((item) => {
					item.forEach(() => {
						if (item.choosewarehouseCode !== val) {
							chooseAll = false
						}
					})
				})
				if (!chooseAll) {
					this.allWarehouseCode = ""
					$("input[name='all']:checked").attr("checked", false)
				}
			}

			if (oldChoosewarehouseCode === choosewarehouseCode) {
				if (this.splitOrderGoodses[index][index1].group && !this.splitOrderGoodses[index][index1].group.allowSplit) {
					this.splitOrderGoodses[index].forEach((item) => {
						if (diffKey !== undefined && item.diffKey === diffKey) {
							item.choosewarehouseCode = ""
							item.oldChoosewarehouseCode = ""
						}
					})
				} else {
					this.splitOrderGoodses[index][index1].choosewarehouseCode = ""
					this.splitOrderGoodses[index][index1].oldChoosewarehouseCode = ""
				}
				this.splitOrderGoodses.splice(0, 0)
			} else {
				if (this.splitOrderGoodses[index][index1].group && !this.splitOrderGoodses[index][index1].group.allowSplit) {
					this.splitOrderGoodses[index].forEach((item) => {
						if (diffKey !== undefined && item.diffKey === diffKey) {
							item.choosewarehouseCode = choosewarehouseCode
							item.oldChoosewarehouseCode = choosewarehouseCode
						}
					})
					this.splitOrderGoodses.splice(0, 0)
				} else {
					this.splitOrderGoodses[index][index1].oldChoosewarehouseCode = choosewarehouseCode
				}
			}
		},
		orderChecked(item1, val) {
			if (item1.chooseOrdersId === val) {
				item1.chooseOrdersId = undefined
			}
		},
		getOrderDetail() {
			http({
				url: api.getOrderDetail,
				type: "post",
				data: {
					data: {
						orderId: this.orderId,
					},
				},
				hasLoading: true,
				success: (res) => {
					const result = res.data || res.result
					for (const i in this.splitOrderDetail) {
						if (result[i]) {
							this.splitOrderDetail[i] = result[i]
						}
					}
					let orderGoodses = deepClone(this.splitOrderDetail.orderGoodses)
					orderGoodses = orderGoodses.sort((a, b) => (a.diffKey < b.diffKey ? -1 : 1))
					const typeList = []
					orderGoodses.forEach((item) => {
						if (typeList.includes(item.diffKey)) {
							item.colorType = `type${typeList.indexOf(item.diffKey)}`
						} else {
							item.colorType = `type${typeList.length}`
							typeList.push(item.diffKey)
						}
					})
					this.splitOrderDetail.orderGoodses = orderGoodses
					this.getWarehouseMapping([
						{
							platform: result.shop.platform,
							shopCode: result.shop.shopCode,
						},
					])
					this.getOrderWarehouseInventory()
					// document.title = this.$t('拆分订单') + ' - ' + this.splitOrderDetail.orderSn
				},
			})
		},
		async getWarehouseMapping(shopList) {
			const res = await http({
				url: api.getWarehouseListByShop,
				type: "post",
				data: {
					merchantCode: localStorage.getItem("merchantCode"),
					shopUniqueList: shopList,
				},
			})
			const warehouseList = res.result
			const obj = {}
			warehouseList.forEach((item) => {
				obj[item.code] = item
			})
			this.warehouseMapping = obj
		},
		getOrderWarehouseInventory() {
			http({
				url: api.getOrderWarehouseInventorySummary,
				type: "post",
				data: {
					data: {
						orderId: this.orderId,
					},
				},
				hasLoading: true,
				success: (res) => {
					if (!this.$isEmptyObject(res.data)) {
						if (res.data.orderGoodsInventorys.length > 0) {
							res.data.orderGoodsInventorys.forEach((item) => {
								// item.summaryResults.sort(function (a, b) {
								//   var s = a.warehouse.wmsWarehouseCode.toLowerCase()
								//   var t = b.warehouse.wmsWarehouseCode.toLowerCase()
								//   if (s < t) return -1
								//   if (s > t) return 1
								// })

								const warehouseCodeListTemp = []
								item.summaryResults.forEach((item1) => {
									const warehouseCodeTemp = item1.warehouse.wmsWarehouseCode
									let exist = false
									let addIndex1 = 0

									warehouseCodeListTemp.forEach((item2, index2) => {
										item2.forEach((item3) => {
											if (item3.warehouse.wmsWarehouseCode === warehouseCodeTemp) {
												addIndex1 = index2
												exist = true
											}
										})
									})
									if (!exist) {
										const len = warehouseCodeListTemp.length
										warehouseCodeListTemp[len] = []
										warehouseCodeListTemp[len].push(item1)
									} else {
										warehouseCodeListTemp[addIndex1].push(item1)
									}
								})
								item.summaryResults = warehouseCodeListTemp
							})

							const orderGoods = deepClone(this.splitOrderDetail.orderGoodses)
							orderGoods.forEach((item, index) => {
								res.data.orderGoodsInventorys.forEach((item2) => {
									if (item.orderGoodsId === item2.orderGoodsId) {
										item2.summaryResults.forEach((warehouses) => {
											warehouses.forEach((warehouse) => {
												if (warehouse.availableQuantity < item.number) {
													warehouse.ye = false
												} else {
													warehouse.ye = true
												}
											})
										})
										this.$set(orderGoods[index], "summaryResults", item2.summaryResults)
										return false
									}
								})
							})
							this.splitOrderGoodses = orderGoods

							this.warehouseList = orderGoods[0].summaryResults
							this.warehouseList.forEach((warehouses, index) => {
								warehouses.forEach((warehouse, index1) => {
									let ye = true
									this.splitOrderGoodses.forEach((goods) => {
										ye = ye && goods.summaryResults[index][index1].ye
									})
									warehouse.ye = ye
								})
							})
						}
					}

					const choosewarehouseCode = this.splitOrderDetail.warehouse.wmsWarehouseCode || ""
					this.allWarehouseCode = choosewarehouseCode
					//订单商品
					const tempObj = dealWithOrderGoods(this.splitOrderGoodses, choosewarehouseCode)
					this.splitOrderGoodses = tempObj.orderGoods
					this.splitOrderDetail.totalSkuDiscountFee = tempObj.totalSkuDiscountFee
					this.splitOrderDetail.totalDiscountFee = (this.splitOrderDetail.discountFee + Number(tempObj.totalSkuDiscountFee)).toFixed(2)

					this.splitOrderDetail.postFee = this.splitOrderDetail.postFee === undefined ? 0.0 : this.splitOrderDetail.postFee.toFixed(2)
					this.splitOrderDetail.discountFee = this.splitOrderDetail.discountFee === undefined ? 0.0 : this.splitOrderDetail.discountFee.toFixed(2)
					this.splitOrderDetail.totalFee = this.splitOrderDetail.totalFee === undefined ? 0.0 : this.splitOrderDetail.totalFee.toFixed(2)
				},
			})
		},
		previewSameWarehouse() {
			const orders = []
			let SplitOrderVO = {}
			let OrderGoodsSplitVo = {}
			try {
				this.splitOrderGoodses.forEach((item) => {
					item.forEach((item1) => {
						item1.choosewarehouseCode = item1.choosewarehouseCode || ""
						OrderGoodsSplitVo = {
							orderGoodsId: item1.orderGoodsId,
							number: item1.number,
						}
						let exist = false
						orders.forEach((item2) => {
							if (item2.warehouseCode === item1.choosewarehouseCode) {
								item2.orderGoodses.push(OrderGoodsSplitVo)
								exist = true
							}
						})
						if (!exist) {
							SplitOrderVO = {
								warehouseChannel: this.warehouseMapping[item1.choosewarehouseCode]?.wmsAppCode,
								warehouseCode: item1.choosewarehouseCode,
								orderGoodses: [],
							}
							SplitOrderVO.orderGoodses.push(OrderGoodsSplitVo)
							orders.push(SplitOrderVO)
						}
					})
				})
			} catch (e) {
				return false
			}
			this.orderSplitVo.orders = orders
			return true
		},
		previewOrders() {
			const orders = []
			let SplitOrderVO = {}
			let OrderGoodsSplitVo = {}
			try {
				this.splitOrderGoodses.forEach((item) => {
					item.forEach((item1) => {
						item1.chooseOrdersId = item1.chooseOrdersId || ""
						OrderGoodsSplitVo = {
							orderGoodsId: item1.orderGoodsId,
							number: item1.number,
						}
						let exist = false
						orders.forEach((item2) => {
							if (item2.chooseOrdersId === item1.chooseOrdersId) {
								item2.orderGoodses.push(OrderGoodsSplitVo)
								exist = true
							}
						})
						if (!exist) {
							SplitOrderVO = {
								warehouseChannel: this.warehouseMapping[item1.choosewarehouseCode]?.wmsAppCode,
								warehouseCode: item1.choosewarehouseCode,
								orderGoodses: [],
								chooseOrdersId: item1.chooseOrdersId,
							}
							SplitOrderVO.orderGoodses.push(OrderGoodsSplitVo)
							orders.push(SplitOrderVO)
						}
					})
				})
			} catch (e) {
				console.log("e: ", e)
				return false
			}
			this.orderSplitVo.orders = orders
			return true
		},
		preview() {
			if (String(this.orderSplitVo.warehouseType) === "1") {
				let error = true
				if (this.previewSameWarehouse()) {
					error = false
				} else {
					return false
				}
				if (!error) {
					this.previewHttp()
				} else {
					this.$warning(this.$t("预览出错，请重试"))
				}
			} else {
				if (this.previewOrders()) {
					this.orderSplitVo.orders.forEach((item) => {
						item.chooseOrdersId = undefined
					})
					this.previewHttp()
				}
			}
		},
		previewHttp() {
			http({
				instance: this,
				url: api.splitOrderPreview,
				type: "post",
				hasLoading: true,
				data: {
					data: this.orderSplitVo,
				},
				success: (res) => {
					this.previewOrderList = res.data.orders

					this.previewOrderList.forEach((item) => {
						const tempObj = dealWithOrderGoods(item.orderGoodses)
						item.orderGoodses = tempObj.orderGoods
						item.totalSkuDiscountFee = tempObj.totalSkuDiscountFee
						item.totalSkuNum = tempObj.totalSkuNum
						item.orderGoodsNum = tempObj.orderGoodsNum
						item.originOrderSns = tempObj.originOrderSns
						item.totalDiscountFee = (Number(item.discountFee) + Number(tempObj.totalSkuDiscountFee)).toFixed(2)

						item.goodsFee = item.goodsFee === undefined ? 0.0 : Number(item.goodsFee).toFixed(2)
						item.postFee = item.postFee === undefined ? 0.0 : Number(item.postFee).toFixed(2)
						item.postDiscountFee = item.postDiscountFee === undefined ? 0.0 : Number(item.postDiscountFee).toFixed(2)
						item.discountFee = item.discountFee === undefined ? 0.0 : Number(item.discountFee).toFixed(2)
						item.totalFee = item.totalFee === undefined ? 0.0 : Number(item.totalFee).toFixed(2)
					})

					this.previewShow = true
				},
			})
		},
		cancelPreview() {
			this.previewShow = false
		},
		editsplitOrderGoodNum(val1, val2) {
			console.log(this.splitOrderGoodses[val1], this.splitOrderGoodses[val1][val2])
			this.splitOrderGoodses[val1][val2].splitable = true
			this.splitOrderGoodses[val1].splice(val2, 0)
		},
		confirmsplitOrderGoodNum(val1, val2) {
			let i = val2
			let rowspan = 0
			const goodTotalNum = this.splitOrderGoodses[val1][val2].number
			const splitGoodsNum = $(`#${val1}_${val2}`).val()
			if (Number(splitGoodsNum) >= Number(goodTotalNum) || Number(splitGoodsNum) <= 0 || !splitGoodsNum.match(/^\d+$/)) {
				this.$warning(this.$t("拆分数量必须是大于0且小于SKU数量的整数"))
				return false
			}
			this.splitOrderGoodses[val1][val2].splitable = false
			const splitGoods = Object.assign({}, this.splitOrderGoodses[val1][val2])
			splitGoods.number = Number(goodTotalNum) - Number(splitGoodsNum)
			splitGoods.totalFee = ((splitGoods.number / goodTotalNum) * splitGoods.totalFee).toFixed(2)
			this.splitOrderGoodses[val1][val2].number = splitGoodsNum
			this.splitOrderGoodses[val1][val2].totalFee = (this.splitOrderGoodses[val1][val2].totalFee - splitGoods.totalFee).toFixed(2)
			if (splitGoods.group === undefined) {
				rowspan = 1
				splitGoods.totalFee2 = ((splitGoods.number / goodTotalNum) * splitGoods.totalFee2).toFixed(2)
				this.splitOrderGoodses[val1][val2].totalFee2 = (this.splitOrderGoodses[val1][val2].totalFee2 - splitGoods.totalFee2).toFixed(2)
				splitGoods.numShow = Number(goodTotalNum) - Number(splitGoodsNum)
				this.splitOrderGoodses[val1][val2].numShow = splitGoodsNum
			} else {
				let breakPoint = false
				while (!breakPoint) {
					if (this.splitOrderGoodses[val1][i].show) {
						breakPoint = true
						rowspan = this.splitOrderGoodses[val1][i].rowspan + 1
						// rowspan ++;
					} else {
						i--
					}
				}
				this.splitOrderGoodses[val1][val2].show = false
			}
			this.splitOrderGoodses[val1].splice(val2, 0, splitGoods)
			this.splitOrderGoodses[val1][i].rowspan = rowspan
		},
		cancelsplitOrderGoodNum(val1, val2) {
			this.splitOrderGoodses[val1][val2].splitable = false
			this.splitOrderGoodses[val1].splice(val2, 0)
		},
		splitOrder() {
			this.submitLoading = true
			try {
				http({
					url: api.splitOrderPlus,
					type: "post",
					async: false,
					data: {
						data: this.orderSplitVo,
					},
					hasLoading: false,
					success: (res) => {
						if (res.data.orders.length > 0) {
							let html = ""
							res.data.orders.forEach((item, index) => {
								if (index !== 0) {
									html += "， "
								}
								html += `<a href='/orderManage/omsOrderDetail/orderDetails/${item.orderId}' target='_blank'>${item.orderSn}</a>`
							})
						}
						const msg = `${this.$t("拆分后订单为")}：`
						const title = this.$t("拆分成功")
						layer.open({
							type: 1,
							title: title,
							skin: "layui-layer-rim", //加上边框
							area: ["420px", "240px"], //宽高
							content: `<div style="margin: 10px 10px;"><p>${msg}</p><p>${html}</p></div>`,
							cancel: () => {
								this.$router.go(-1)
							},
						})
						this.submitLoading = false
					},
				})
			} catch (err) {
				console.log("err: ", err)
				this.submitLoading = false
			}
		},
		chooseAllWarehouse() {
			this.$nextTick(() => {
				this.splitOrderGoodses.forEach((item) => {
					item.forEach((item1) => {
						item1.choosewarehouseCode = this.allWarehouseCode
						item1.oldChoosewarehouseCode = this.allWarehouseCode
					})
				})
				this.splitOrderGoodses.splice(0, 0)
			})
		},
		confirmSplitOrder() {
			if (this.previewEditable) {
				this.$warning(this.$t("请先编辑完预览订单，再进行拆分"))
				return false
			}
			if (!this.orderSplitVo.dutyParty) {
				this.$warning(this.$t("请选择责任方"))
				return false
			}

			if (!this.dataTransfer()) {
				return false
			}
			this.splitOrder()
		},
		dataTransfer() {
			let msg = ""
			this.orderSplitVo.orders = []
			try {
				this.previewOrderList.forEach((item) => {
					console.log("item: ", item)
					const SplitOrderVO = {}
					if (!item.warehouse || !item.warehouse.wmsWarehouseCode) {
						msg = this.$t("仓库信息错误")
					} else {
						SplitOrderVO.warehouseCode = item.warehouse.wmsWarehouseCode
						SplitOrderVO.warehouseChannel = item.warehouse.warehouseType
					}

					if (!this.$store.getters.isStore) {
						if (!item.shipping || !item.shipping?.shippingAccountCode) {
							msg = this.$t("快递信息错误")
						} else {
							SplitOrderVO.shippingCode = item.shipping.shippingCode
							SplitOrderVO.shippingName = item.shipping.shippingName
							SplitOrderVO.shippingAccountCode = item.shipping.shippingAccountCode
							SplitOrderVO.shippingAccountName = item.shipping.shippingAccountName
						}
					}
					SplitOrderVO.splitOrderType = item.splitOrderType
					SplitOrderVO.postFee = item.postFee
					SplitOrderVO.postDiscountFee = item.postDiscountFee
					SplitOrderVO.discountFee = item.discountFee
					SplitOrderVO.totalFee = item.totalFee
					SplitOrderVO.splitOrderType = item.splitOrderType
					SplitOrderVO.orderGoodses = []

					item.orderGoodses.forEach((item1) => {
						item1.forEach((item2) => {
							const goodTemp = Object.assign({}, item2)
							goodTemp.orderGoodsId = item2.orderGoodsId
							SplitOrderVO.orderGoodses.push(goodTemp)
						})
					})

					this.orderSplitVo.orders.push(SplitOrderVO)
				})

				if (msg) {
					this.$warning(msg)
					return false
				}
			} catch (e) {
				console.log("e: ", e)
				return false
			}
			return true
		},
		editPreview() {
			this.previewOrderListTemp = deepClone(this.previewOrderList)
			this.previewEditable = true
		},
		confirmeditPreview() {
			if (!this.dataTransfer()) {
				return false
			}
			http({
				url: api.splitOrderCalculate,
				type: "post",
				hasLoading: true,
				data: {
					data: this.orderSplitVo,
				},
				success: (res) => {
					this.previewOrderList = res.data.orders
					this.previewOrderList.forEach((item) => {
						const tempObj = dealWithOrderGoods(item.orderGoodses)
						item.orderGoodses = tempObj.orderGoods
						item.totalSkuDiscountFee = tempObj.totalSkuDiscountFee
						item.totalSkuNum = tempObj.totalSkuNum
						item.orderGoodsNum = tempObj.orderGoodsNum
						item.originOrderSns = tempObj.originOrderSns
						item.totalDiscountFee = (Number(item.discountFee) + Number(tempObj.totalSkuDiscountFee)).toFixed(2)

						item.goodsFee = item.goodsFee === undefined ? 0.0 : Number(item.goodsFee).toFixed(2)
						item.postFee = item.postFee === undefined ? 0.0 : Number(item.postFee).toFixed(2)
						item.discountFee = item.discountFee === undefined ? 0.0 : Number(item.discountFee).toFixed(2)
						item.totalFee = item.totalFee === undefined ? 0.0 : Number(item.totalFee).toFixed(2)
					})

					this.previewEditable = false
				},
			})
		},
		canceleditPreview() {
			this.previewOrderList = deepClone(this.previewOrderListTemp)
			this.previewEditable = false
		},
		updatePostFee(index) {
			if (this.previewOrderList.length === 2) {
				if (
					!`${this.previewOrderList[index].postFee}`.match(/^(0(\.\d+)?)|([1-9]{1}\d*(\.(\d)+)?)$/) ||
					Number(this.previewOrderList[index].postFee) > Number(this.splitOrderDetail.postFee)
				) {
					this.$warning(this.$t("请按规定格式填写"))
					return false
				}
				if (index === 0) {
					this.previewOrderList[1].postFee = (Number(this.splitOrderDetail.postFee) - Number(this.previewOrderList[0].postFee)).toFixed(2)
				} else if (index === 1) {
					this.previewOrderList[0].postFee = (Number(this.splitOrderDetail.postFee) - Number(this.previewOrderList[1].postFee)).toFixed(2)
				}
				this.previewOrderList.splice(0, 0)
			}
		},
		modifyDiscountFee(orderIndex, index, index1) {
			if (!`${this.previewOrderList[orderIndex].orderGoodses[index][index1].divideDiscountFee}`.match(/^(0(\.\d+)?)|([1-9]{1}\d*(\.(\d)+)?)$/)) {
				this.$warning(this.$t("请按规定格式填写"))
				return false
			}
			let discountFee = 0
			this.previewOrderList[orderIndex].orderGoodses.forEach((goods) => {
				goods.forEach((good) => {
					discountFee += Number(good.divideDiscountFee)
				})
			})
			this.previewOrderList[orderIndex].discountFee = discountFee.toFixed(2)
		},
	},
}
</script>

<style lang="scss" scoped>
$blue: #3697de;
$gray: #ddd;

.fixed .content-wrapper,
.fixed .right-side {
  padding-top: 0 !important;
}

.content-wrapper,
.right-side,
.main-footer {
  margin-left: 0 !important;
}

.content {
  overflow: auto;
  background-color: $white;
  .tips {
    border-top: 1px solid #eee;
    padding: 6px 0;
    margin-top: 6px;
    font-size: 14px;
    font-weight: bold;
    color: $error-color;
  }
  .order-goods-table {
    overflow-x: auto;
    width: 100%;

    .table {
      margin-bottom: 10px;
    }
  }

  .order-split-modal {
    width: 1200px;
  }

  .edit-icon-size {
    font-size: 1.9rem;
  }

  .fa-check {
    color: #78bf2a;
  }

  .fa-close {
    color: #f86767;
  }

  label.control-label {
    padding-left: 0px;
    padding-right: 5px;
    font-size: 14px;
  }

  .action-buttons {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .action-buttons button {
    padding-right: 12px;
    padding-left: 12px;
    margin-left: 50px;
  }

  .action-buttons .btn-cancel {
    border-color: #367fa9;
  }

  i {
    font-size: 20px;
  }

  .table {
    margin-bottom: 0px;

    .back-yellow {
      background-color: yellow;
    }

    th {
      text-align: left;
      padding: 16px;
      font-size: 14px;
      font-weight: 700;
      overflow-wrap: break-word;
    }

    td {
      position: relative;
      padding: 16px;
      text-align: left;
      font-size: 14px;
      border-bottom: 1px solid #ccc;
    }

    .padLeft {
      padding-left: 16px;
    }
  }

  .action-button {
    text-align: center;
  }

  .reason {
    border-radius: 3px;
    box-shadow: none;
    border: 1px solid #d2d6de;
    width: 300px;
    margin-left: 20px;
  }

  .header {
    border-radius: 3px;
    box-shadow: none;
    border: 1px solid #ddd;
  }

  .header .form-group {
    margin-bottom: 6px;
  }

  .header-1 {
    padding-bottom: 4px;
    background-color: #ddd;
  }

  .preview-box .form-group:first-child {
    border-bottom: 1px solid #ddd;
  }

  .form-horizontal .form-group {
    margin-left: 0px;
    margin-right: 0px;
  }

  .preview-box {
    margin-top: 10px;
  }

  .radio-inline {
    margin-left: 10px;
  }

  .focus-radio {
    color: $blue;
  }

  .gray {
    color: $gray;
  }

  .redText {
    color: red;
  }

  .input-normal {
    border-radius: 3px;
    border: 1px solid #d2d6de;
    width: 100%;
  }

  .origin-order-info {
    .form-group {
      margin-bottom: 2px;
    }
  }
}

/* 在样式中定义不同类型订单的背景颜色 */
tr[data-order-type='type0'] {
  background-color: rgba(229, 115, 115, 0.3); /* 淡红色 */
}
tr[data-order-type='type1'] {
  background-color: rgba(129, 199, 132, 0.3); /* 淡绿色 */
}
tr[data-order-type='type2'] {
  background-color: rgba(100, 181, 246, 0.3); /* 淡蓝色 */
}
tr[data-order-type='type3'] {
  background-color: rgba(255, 183, 77, 0.3); /* 淡橙色 */
}
tr[data-order-type='type4'] {
  background-color: rgba(149, 117, 205, 0.3); /* 淡紫色 */
}
tr[data-order-type='type5'] {
  background-color: rgba(77, 182, 172, 0.3); /* 淡青色 */
}
tr[data-order-type='type6'] {
  background-color: rgba(255, 138, 101, 0.3); /* 淡橙红色 */
}
tr[data-order-type='type7'] {
  background-color: rgba(174, 213, 129, 0.3); /* 淡黄绿色 */
}
tr[data-order-type='type8'] {
  background-color: rgba(144, 164, 174, 0.3); /* 淡灰蓝色 */
}
tr[data-order-type='type9'] {
  background-color: rgba(255, 204, 128, 0.3); /* 淡橙黄色 */
}
</style>
