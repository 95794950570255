//心跳检测
export default {
	timeout: 30000, //30s发一次心跳
	timeoutObj: null,
	serverTimeoutObj: null,
	reset() {
		clearTimeout(this.timeoutObj)
		clearTimeout(this.serverTimeoutObj)
		return this
	},
	start(ws) {
		this.timeoutObj = setTimeout(() => {
			//这里发送一个心跳，后端收到后，返回一个心跳消息，
			//onmessage拿到返回的心跳就说明连接正常
			ws.send("ping")
			this.serverTimeoutObj = setTimeout(() => {
				//如果超过一定时间还没重置，说明后端主动断开了
				ws.close() //如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
			}, this.timeout)
		}, this.timeout)
	},
}
