import i18n from "@/utils/lang"
import { message } from "ant-design-vue"

function isEmptyObject(obj) {
	// 检查参数是否为对象
	if (typeof obj !== "object" || obj === null) {
		return true
	}

	// 检查对象是否有可枚举的属性
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			return false
		}
	}

	return true
}

function copy(data) {
	const url = data
	const oInput = document.createElement("input")
	oInput.value = url
	document.body.appendChild(oInput)
	oInput.select() // 选择对象;
	document.execCommand("Copy") // 执行浏览器复制命令
	message.success(i18n.t("已复制"))
	oInput.remove()
}

//将逗号、空格隔开的订单字符串变成订单数组
function handleOrderIds(ids) {
	const arr = ids
		.replaceAll(",", " ")
		.split(" ")
		.filter((item) => item)
	return arr
}

//校验订单号类型
function judgeOMSOrderSn(orderSns, list) {
	let type //platformOrderSn 原始订单号, orderSn OMS订单号, undefined 混合了，报错
	let platformNum = 0
	let orderNum = 0
	const arr = handleOrderIds(orderSns)
	arr.forEach((item) => {
		if (item.startsWith("XS")) {
			orderNum++
		} else {
			platformNum++
		}
	})
	if (platformNum * orderNum === 0) {
		if (platformNum === 0 && orderNum > 0) {
			type = list[1]
		}
		if (platformNum > 0 && orderNum === 0) {
			type = list[0]
		}
	} else {
		type = undefined
	}
	return type
}

function toThousandAmount(num) {
	num = num || ""
	num = num.toString().split(".") // 分隔小数点
	const arr = num[0].split("").reverse() // 转换成字符数组并且倒序排列
	let res = []
	for (let i = 0, len = arr.length; i < len; i++) {
		if (i % 3 === 0 && i !== 0) {
			res.push(",") // 添加分隔符
		}
		res.push(arr[i])
	}
	res.reverse() // 再次倒序成为正确的顺序
	if (num[1]) {
		// 如果有小数的话添加小数部分
		res = res.join("").concat(`.${num[1]}`)
	} else {
		res = res.join("")
	}
	return res
}

function timeLeft(targetTime) {
	const now = new Date() // 当前时间
	const target = new Date(targetTime) // 目标时间

	// 计算时间差（毫秒）
	let timeDiff = target - now

	let str
	const isTimeout = timeDiff < 0

	timeDiff = Math.abs(timeDiff)
	// 计算小时数和分钟数
	const hoursLeft = timeDiff / (1000 * 60 * 60)
	const minutesLeft = timeDiff / (1000 * 60)

	if (hoursLeft >= 1) {
		str = `${hoursLeft.toFixed(1)} ${i18n.t("小时")}`
	} else {
		str = `${minutesLeft.toFixed(1)} ${i18n.t("分钟")}`
	}

	if (isTimeout) {
		str = i18n.t("逾期T", { T: str })
	}
	return str
}

function callFunctionPeriodically(func, interval) {
	let start = null

	function animate(timestamp) {
		if (!start) start = timestamp
		const progress = timestamp - start

		if (progress >= interval) {
			func()
			start = timestamp
		}

		requestAnimationFrame(animate)
	}

	requestAnimationFrame(animate)
}

export { isEmptyObject, copy, handleOrderIds, toThousandAmount, timeLeft, judgeOMSOrderSn, callFunctionPeriodically }

// function replaceEn(data1, data2) {
//   for (let item in data2) {
//     data2[item] = data1[item] || ''
//   }
//   return JSON.stringify(data2)
// }
