<template>
  <div class="order-detail">
    <div class="content" style="min-height: 0;">
      <a-tabs default-active-key="1" @change="changeTabs" class="top-tabs">
        <a-tab-pane key="OrderStatusFlow" :tab="$t(`订单状态流转`)"></a-tab-pane>
        <a-tab-pane key="BasicInformation" :tab="$t(`基本信息`)"></a-tab-pane>
        <a-tab-pane key="MessageInfo" :tab="$t(`订单备注`)"></a-tab-pane>
        <a-tab-pane key="DistributionInformation" :tab="$t(`配送信息`)"></a-tab-pane>
        <a-tab-pane key="ProductInfo" :tab="$t(`商品信息`)"></a-tab-pane>
      </a-tabs>
    </div>
    <div class="content-wrapper fixContent" style="padding-top: 10px">
      <div class="content-overflow">
        <div class="left-div scroll-div mr10" :style="{ height: scrollHeight }">
          <section class="content" style="min-height: 0">
            <div class="order-header">
              <div class="infoTitle" id="OrderStatusFlow">
                <i class="leftLine"></i>
                <span class="mr6">{{ $t('订单状态流转') }}</span>
                <a-icon type="sync" class="text-main" :spin="orderSpin" @click="refreshHandle" />
              </div>
              <a-row>
                <a-col :span="14">
                  <!-- 订单号-->
                  <div>
                    <label class="order-sn">{{ $t(`订单号`) }}:</label>
                    <label class="order-sn mr6" @click="copyOrderSn(orderDetail.orderSn)">
                      {{ orderDetail.orderSn }}
                      <a-icon class="text-main" type="copy" />
                      <template v-if="orderDetail.party">
                        ({{ orderDetail.party.partyName }})
                      </template>
                    </label>
                    <span
                      class="mr5"
                      :class="{
                        red: orderDetail.sendConditionType == 'SELL_ON_CREDIT',
                        gray: orderDetail.sendConditionType != 'SELL_ON_CREDIT',
                      }"
                    >
                      {{ sendConditionTypeMapping[orderDetail.sendConditionType] }}
                    </span>
                    <a-tag color="orange" v-if="orderDetail.orderType === 'PRESALE'">
                      {{ $t('预售') }}
                    </a-tag>
                    <a-tag v-if="orderDetail.orderStatusValue">
                      {{ orderDetail.orderStatusValue }}
                    </a-tag>
                    <a-tag color="orange" v-if="orderDetail.payStatusValue">
                      {{ orderDetail.payStatusValue }}
                    </a-tag>
                  </div>
                  <div @click="copyOrderSn(orderDetail.platformOrderIds?.join(','))">
                    {{ $t('原始订单号') }}:
                    {{ orderDetail.platformOrderIds?.join(',') }}
                    <a-icon class="text-main" type="copy" />
                  </div>
                </a-col>
                <a-col :span="10" style="display: flex; justify-content: flex-end">
                  <!-- 回传审核 -->
                  <a-button
                    v-if="hasReturnBtn"
                    type="primary"
                    :loading="approveLoading"
                    @click="sendRTSOrder('approve')"
                  >
                    {{ $t(`回传审核`) }}
                  </a-button>
                  <!-- 回传发货 -->
                  <a-button
                    v-if="hasReturnBtn"
                    type="primary"
                    :loading="outboundLoading"
                    @click="sendRTSOrder('outbound')"
                  >
                    {{ $t(`回传发货`) }}
                  </a-button>
                  <!-- 关闭订单 -->
                  <button
                    v-if="orderDetail.manualCloseButton && isStore"
                    class="dangerBtn"
                    :loading="closeLoading"
                    @click="manualCloseOrder"
                  >
                    {{ $t(`关闭订单`) }}
                    <a-popover>
                      <template slot="content">
                        <p>{{ $t(`关闭订单无法恢复，触发平台差异退款`) }}</p>
                      </template>
                      <a-icon type="question-circle"></a-icon>
                    </a-popover>
                  </button>
                  <!-- 代扣款 -->
                  <a-button
                    v-if="orderDetail.deductionButton"
                    type="primary"
                    class="mr10"
                    @click="deductionHandle"
                  >
                    {{ $t('代扣款') }}
                  </a-button>
                  <!-- 待拦截-->
                  <button
                    v-if="showInterceptedBtn"
                    class="dangerBtn"
                    :loading="interceptLoading"
                    @click="intercept"
                  >
                    {{ $t('拦截') }}
                    <a-popover>
                      <template slot="content">
                        <p>{{ $t(`出库前可以拦截，点完拦截，请联系仓库拦截包裹`) }}</p>
                      </template>
                      <a-icon type="question-circle"></a-icon>
                    </a-popover>
                  </button>
                  <!-- 待追回-->
                  <button
                    v-if="orderDetail.recoverButton"
                    class="dangerBtn"
                    type="primary"
                    @click="returnRefund"
                  >
                    {{ $t('追回') }}
                    <a-popover>
                      <template slot="content">
                        <p>{{ $t(`出库后只能追回，点完追回，请联系快递追回包裹`) }}</p>
                      </template>
                      <a-icon type="question-circle"></a-icon>
                    </a-popover>
                  </button>
                  <!-- 作废订单 -->
                  <button
                    v-if="orderDetail.cancelButton && priv('f_sale_order_close')"
                    class="dangerBtn"
                    :loading="cancelLoading"
                    :disabled="orderDetail.orderStatus == 'CANCELING'"
                    @click="showNoteModal('CANCEL')"
                  >
                    {{ $t(`作废订单`) }}
                    <a-popover>
                      <template slot="content">
                        <p>{{ $t(`出库前可以作废`) }}</p>
                      </template>
                      <a-icon type="question-circle"></a-icon>
                    </a-popover>
                  </button>
                  <a-button
                    v-if="
                      orderDetail.checkButton &&
                      (priv('f_sale_order_check') || priv('f_sale_order_force_check'))
                    "
                    type="primary"
                    :loading="reserveLoading"
                    :disabled="orderDetail.reserveStatus"
                    @click="checkOrder"
                  >
                    {{ orderDetail.reserveStatus ? this.$t('待预定') : this.$t(`审核通过`) }}
                  </a-button>
                  <a-button
                    v-if="orderDetail.warehouse.warehouseType === 'VIRTUAL'"
                    type="primary"
                    @click="deliveredOrder"
                  >
                    {{ $t('手动签收') }}
                  </a-button>
                  <!-- 恢复待审核-->
                  <a-button
                    v-if="orderDetail.revertButton"
                    :loading="revertWaitCheckLoading"
                    type="primary"
                    @click="revertWaitCheck"
                  >
                    {{ $t('恢复待审核') }}
                  </a-button>
                  <!-- 售后-->
                  <a-button
                    v-if="orderDetail.afterSaleButton"
                    type="danger"
                    class="dangerBtn"
                    @click="openAfterSaleModal"
                  >
                    {{ $t('售后') }}
                  </a-button>
                  <!-- 驳回-->
                  <button
                    v-if="orderDetail.rejectButton"
                    :loading="rejectLoading"
                    type="danger"
                    class="dangerBtn"
                    @click="rejectHandle"
                  >
                    {{ $t('驳回') }}
                    <a-popover>
                      <template slot="content">
                        <p>{{ $t(`出库前可以驳回`) }}</p>
                      </template>
                      <a-icon type="question-circle"></a-icon>
                    </a-popover>
                  </button>
                </a-col>
              </a-row>
              <div class="order-flow form-group">
                <!-- 进度条 -->
                <ProcessState2 v-if="stateData.length > 0" :statedata="stateData" />
              </div>

              <div class="order-action col-md-12 form-group" style="padding-top: 2px">
                <div class="form-group" style="margin-bottom: 4px">
                  <div
                    v-if="orderDetail.wmsOrder && orderDetail.wmsOrder.status == 'CANCEL_FAIL'"
                    class="col-md-1 control-label"
                  >
                    <a-popover>
                      <template slot="content">
                        {{ orderDetail.wmsOrder.pushNote }}
                      </template>
                      <span id="cancelFail" class="red">{{ $t(`取消异常`) }}</span>
                    </a-popover>
                  </div>
                </div>
                <div
                  v-if="
                    (constants.WAIT_CHECK_STATUS.includes(orderDetail.orderStatus) ||
                      orderDetail.orderStatus == 'CHECK_PASS') &&
                    orderDetail.shippingStatus == 'PENDING_WAYBILL'
                  "
                  class="form-group"
                  style="margin-bottom: 4px"
                >
                  <template v-if="constants.WAIT_CHECK_STATUS.includes(orderDetail.orderStatus)">
                    <div
                      v-for="item in checkUnableReasonList"
                      class="control-label col-md-6"
                      style="text-align: left"
                      :key="item.name"
                    >
                      <span
                        v-if="item.show"
                        @mouseover="showBookActiveTime(item.value)"
                        @mouseout="hiddenBookActiveTime(item.value)"
                        class="abnormal-tag"
                      >
                        {{ item.name }}
                      </span>
                    </div>
                    <div
                      v-if="
                        constants.WAIT_CHECK_STATUS.includes(orderDetail.orderStatus) &&
                        orderDetail.shippingStatus == 'PENDING_WAYBILL' &&
                        orderDetail.orderProcess.subOrderType == 'BOOK'
                      "
                      class="control-label col-md-4 no-padding"
                      style="text-align: right"
                    >
                      <div class="col-md-2 control-label" style="text-align: left">
                        <a
                          v-if="priv('f_sale_order_book_transform')"
                          href="#"
                          class="btn-a"
                          @click.prevent="releaseInventory"
                        >
                          {{ $t(`释放库存`) }}
                        </a>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="orderDetail.orderStatus == 'CHECK_PASS'">
                    <div
                      v-if="orderDetail.wmsOrder && orderDetail.wmsOrder.status == 'PUSH_FAIL'"
                      class="col-md-1 control-label"
                    >
                      <a-popover>
                        <template slot="content">
                          {{ orderDetail.wmsOrder.pushNote }}
                        </template>
                        <span id="cancelFail" class="red">{{ $t(`推送异常`) }}</span>
                      </a-popover>
                    </div>
                  </template>
                </div>
                <div
                  v-if="
                    orderDetail.orderProcess &&
                    orderDetail.orderProcess.isException &&
                    orderDetail.orderProcess.exceptionTagList.length > 0
                  "
                  class="form-group"
                  style="margin-bottom: 4px"
                >
                  <label class="control-label col-md-1">{{ $t(`异常标签`) }}</label>
                  <div class="control-label col-md-9 mt2" style="text-align: left">
                    <a-tag
                      v-for="(item, index) in orderDetail.orderProcess.exceptionTagList"
                      color="red"
                      :closable="showClose(item.key)"
                      @close="clearException(index, item.key, $event)"
                      :key="index"
                    >
                      {{ item.value }}
                    </a-tag>
                  </div>
                  <div
                    v-if="
                      priv('f_sale_order_force_check') &&
                      constants.WAIT_CHECK_STATUS.includes(orderDetail.orderStatus) &&
                      orderDetail.shippingStatus == 'PENDING_WAYBILL' &&
                      orderDetail.orderProcess.subOrderType != 'BOOK' &&
                      orderDetail.orderProcess.reserverStatus == 'RECEIVED' &&
                      !constants.WAIT_CHECK_STATUS.includes(
                        orderDetail.orderProcess.financeCheckStatus
                      )
                    "
                    class="col-md-1 control-label"
                    style="text-align: left"
                  >
                    <a href="#" class="btn-a" @click.prevent="forceCheckOrder">
                      {{ $t(`强制审核`) }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="content" style="min-height: 0">
            <div class="tabs-list-wrapper">
              <ul class="tabs-list">
                <li
                  v-for="tab in tabList"
                  :class="{ active: tab.active }"
                  @click="clickTab(tab)"
                  :key="tab.name"
                >
                  {{ tab.code }}
                </li>
              </ul>
            </div>
            <div class="tabs-content-wrapper">
              <component
                v-if="orderId && orderDetail.orderGoodses && orderDetail.orderGoodses.length > 0"
                @reflesh-order="getOrderDetail"
                @reflesh-actions="getOrderActionList"
                :is="currentView"
                keep-alive
                :order-id="orderId"
                :props-order-detail="orderDetail"
                :aftersale-type-list="aftersale_type_list"
                :aftersale-status-list="aftersale_status_list"
                :is_group="is_group"
              ></component>
            </div>
          </section>
        </div>
        <div class="right-div scroll-div" :style="{ height: scrollHeight }">
          <section class="content" style="margin: 0">
            <div class="formContentGoods" style="margin: 0; padding: 0 0 10px 0">
              <span class="mr4">{{ $t('操作日志') }}</span>
              <a-icon type="sync" class="text-main" :spin="logSpin" @click="refreshLogAction" />
            </div>
            <a-spin :spinning="logSpin">
              <ActionLog :list="orderActions" />
            </a-spin>
          </section>
        </div>
      </div>
      <AddNoteModal2
        v-if="noteAddModal.show"
        :can-empty="true"
        :add-note="noteAddModal.params.addNote"
        :loading="cancelLoading"
        @cancel="cancelMessageAdd"
        @confirm="confirmCommentAction"
      />
    </div>

    <a-modal v-model="afterSaleModalVisible" width="1000px" :title="$t(`售后信息`)" @ok="handleOk">
      <a-row>
        <a-col :span="17" style="margin-right: 6%">
          <!-- 售后类型 -->
          <label style="display: flex">
            <span style="color: red; font-size: 18px; margin-right: 5px">*</span>
            {{ $t(`售后类型`) }}
          </label>
          <a-select v-model="aftersale_type" style="width: 300px">
            <a-select-option
              v-for="(item, index) in aftersale_create_type_list"
              :key="index"
              :value="item.name"
            >
              {{ item.desc }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" style="margin-right: 6%; margin-top: 20px">
          <!-- 商品信息 -->
          <label style="display: flex">
            <span style="color: red; font-size: 18px; margin-right: 5px">*</span>
            {{ $t(`商品信息`) }}
          </label>
          <a-table
            :scroll="{ x: 1000, y: 500 }"
            style="margin-top: 1%"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            rowKey="orderId"
            :columns="afterSalesColumns"
            :expandIconAsCell="false"
            :expandIconColumnIndex="1"
            :data-source="saleOrderList"
            :pagination="false"
          >
            <!-- 商品名称 -->
            <template slot="ProductName" slot-scope="text, record">
              <p>{{ record.sku.skuName }}</p>
            </template>

            <!-- 商家编码 -->
            <template slot="SkuCode" slot-scope="text, record">
              <p>{{ record.skuCode }}</p>
            </template>

            <!-- 单品名 -->
            <template slot="GoodsItemName" slot-scope="text, record">
              <p>{{ record.sku.skuName }}</p>
            </template>

            <!-- 商品条码 -->
            <template slot="ProductBarcode" slot-scope="text, record">
              <span v-if="record.sku" class="barcode">
                {{ record.sku.barcode }}
              </span>
            </template>

            <!-- 商品分摊 -->
            <template slot="CommoditySharing" slot-scope="text, record">
              {{ record.totalFee }}
            </template>

            <!-- 换货商品 -->
            <template slot="ExchangeTitle">
              <span style="color: red; margin-right: 5px">*</span>
              {{ $t(`换货商品`) }}
            </template>
            <template slot="ExchangeProduct" slot-scope="text, record">
              <SearchSku
                :isGroup="false"
                style="width: 200px"
                @selected="
                  (data) => {
                    handleChangeGoods(data, record)
                  }
                "
              />
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-modal>

    <!-- 追回填写快递单号 -->
    <a-modal
      v-model="returnRefundVisible"
      :title="$t('追回快递单号')"
      :confirmLoading="returnRefundLoading"
      @ok="returnRefundOk"
    >
      <a-input v-model="returnWillBillNo" :placeholder="$t('请填写追回的快递单号')" />
    </a-modal>

    <!-- 代扣款填写金额备注 -->
    <a-modal
      v-model="deductionVisible"
      :title="$t('追回快递单号')"
      :confirmLoading="deductionLoading"
      @ok="deductionOk"
    >
      <a-form>
        <a-form-item :label="$t('扣款金额')" required>
          <a-input-number
            style="width: 100%"
            v-model="deductionAmount"
            :placeholder="$t('请填写扣款金额')"
          />
        </a-form-item>
        <a-form-item :label="$t('备注')">
          <a-input v-model="deductionRemark" :placeholder="$t('请填写备注')" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { copy } from "@common"
import debounce from "@common/debounce"
import deepClone from "@common/deepClone"
import { dealWithOrderGoods } from "@common/orderGoodses"
import ActionLog from "@component/actionLog"
import AddNoteModal2 from "@component/addNoteModal2"
import datePicker from "@component/datePicker"
import overlay from "@component/overlay"
import ProcessState2 from "@component/processState2"
import SearchSku from "@component/searchSku"
import api from "@service/api"
import http from "@service/axios"
import { mapMutations, mapState } from "vuex"
import orderAfterSaleDetail from "./orderAfterSaleDetail"
import orderInfoDetail from "./orderInfoDetail"
import orderTrackingDetail from "./orderTrackingDetail"

export default {
	name: "orderDetails",
	data() {
		return {
			noteAddModal: {
				show: false,
				params: {
					type: "",
					note: "",
					addNote: "",
				},
			},
			is_group: false, //是否是组合商品订单
			currentView: "orderInfoDetail",
			orderId: "",
			showOverlay: false,
			orderDetail: {
				wmsCreateStatus: "",
				originOrderSns: [],
				platformOrderIds: [],
				shop: {},
				distributor: {},
				receiverProvince: {
					regionId: "",
					regionName: "",
				},
				receiverCity: {
					regionId: "",
					regionName: "",
				},
				receiverDistrict: {
					regionId: "",
					regionName: "",
				},
				receiverStreet: {
					regionId: "",
					regionName: "",
				},
				shipping: {},
				warehouse: {},
				waybillNo: "",
				pay: {},
				orderGoodses: [],
				packages: {},
				orderProcess: {},
				invoiceInfo: [],
			},
			stateData: [],
			checkUnableReasonList: [],
			orderActions: [],
			orginOrderSn: [],
			bookActiveTime: {
				show: false,
				style: "",
			},
			trackingInfo: {},
			isFirst: true,
			afterSaleModalVisible: false,
			saleOrderList: [],
			selectedRowKeys: [],
			selectedRows: [],
			aftersale_type_list: [],
			aftersale_create_type_list: [],
			aftersale_status_list: [],
			aftersale_type: "",
			goodsData: [],
			lastFetchId: 0,
			order_sn: "",
			pushLoading: false,
			cancelLoading: false,
			closeLoading: false,
			orderSpin: false,
			logSpin: false,
			returnRefundVisible: false,
			returnRefundLoading: false,
			returnWillBillNo: undefined,
			reserveLoading: false,
			interceptLoading: false,
			revertWaitCheckLoading: false,
			rejectLoading: false,
			deductionVisible: false,
			deductionLoading: false,
			deductionAmount: "",
			deductionRemark: "",
			approveLoading: false,
			outboundLoading: false,
		}
	},
	activated() {
		this.init()
	},
	beforeRouteUpdate(to, from, next) {
		console.log("routerUpdate")
		Object.assign(this.$data, this.$options.data())
		this.$nextTick(() => {
			this.init()
		})
		next()
	},
	beforeRouteLeave(to, from, next) {
		next((vm) => {
			vm.clear()
		})
	},
	computed: {
		...mapState(["currentShop", "panes"]),
		sendConditionTypeMapping() {
			return {
				PAID: this.$t("先款后货"),
				COD_SHIP: "COD",
				SELL_ON_CREDIT: this.$t("赊销"),
			}
		},
		tabList() {
			return [
				{
					name: "订单详情",
					code: this.$t("订单详情"),
					view: "orderInfoDetail",
					active: true,
				},
				{
					name: "物流",
					code: this.$t("物流"),
					view: "orderTrackingDetail",
					active: false,
				},
				{
					name: "售后",
					code: this.$t("售后"),
					view: "orderAfterSaleDetail",
					active: false,
				},
			]
		},
		afterSalesColumns() {
			return [
				{
					title: this.$t("商品名称"),
					scopedSlots: { customRender: "ProductName" },
					key: "ProductName",
					width: 100,
					hidden: true,
				},
				{
					title: this.$t("商家编码"),
					scopedSlots: { customRender: "SkuCode" },
					key: "SkuCode",
					width: 100,
					hidden: true,
				},
				{
					title: this.$t("单品名"),
					scopedSlots: { customRender: "GoodsItemName" },
					key: "GoodsItemName",
					width: 100,
					hidden: true,
				},
				{
					title: this.$t("商品条码"),
					scopedSlots: { customRender: "ProductBarcode" },
					key: "ProductBarcode",
					width: 100,
					hidden: true,
				},
				{
					title: this.$t("商品分摊"),
					scopedSlots: { customRender: "CommoditySharing" },
					key: "CommoditySharing",
					width: 50,
					hidden: true,
				},
				{
					scopedSlots: { customRender: "ExchangeProduct", title: "ExchangeTitle" },
					key: "ExchangeProduct",
					width: 190,
					hidden: this.aftersale_type === "EXCHANGE",
				},
			].filter((v) => v.hidden)
		},
		showInterceptedBtn() {
			return this.orderDetail.intercepted
		},
		isStore() {
			//是门店类型
			return this.$store.getters.isStore
		},
		scrollHeight() {
			return `${(window.innerHeight - 300) * this.$store.state.rate}px`
		},
		hasReturnBtn() {
			return this.orderActions.findIndex((item) => item.subType === "update_pay_status_success") > -1
		},
	},
	components: {
		orderInfoDetail,
		orderTrackingDetail,
		orderAfterSaleDetail,
		ProcessState2,
		datePicker,
		overlay,
		AddNoteModal2,
		SearchSku,
		ActionLog,
	},
	methods: {
		...mapMutations(["setState"]),
		async init() {
			this.orderId = this.$route.params.orderId
			this.order_sn = this.$route?.query?.orderSn
			if (this.orderId) {
				this.getOrderDetail()
			} else {
				await this.getOrderDetail()
			}
			this.getOrderActionList()
		},
		intercept: debounce(function () {
			this.$confirm({
				title: this.$t("拦截"),
				content: this.$t("是否拦截订单?"),
				onOk: () => {
					this.interceptLoading = true
					http({
						url: api.interceptOrder,
						type: "post",
						data: {
							data: {
								afterSaleType: "INTERCEPT",
								orderId: this.orderId,
							},
						},
						hasLoading: true,
						success: (res) => {
							this.interceptLoading = false
							this.$message.success(res.msg)
							this.getOrderDetail()
							this.getOrderActionList()
						},
					})
				},
			})
		}),
		sendRTSOrder: debounce(function (type) {
			this[`${type}loading`] = true
			http({
				url: api.sendRTSOrder,
				type: "post",
				data: {
					type,
					orderSn: this.orderDetail.orderSn,
				},
				hasLoading: true,
				success: (res) => {
					this[`${type}loading`] = true
					if (res.success) {
						const msg = type === "approve" ? this.$t("回传审核成功") : this.$t("回传发货成功")
						this.$message.success(msg)
						this.getOrderDetail()
						this.getOrderActionList()
					}
				},
				fail: (res) => {
					this[`${type}loading`] = true
					this.$message.error(res.msg)
				},
			})
		}),
		manualCloseOrder: debounce(function () {
			this.$confirm({
				title: this.$t("关闭订单"),
				content: this.$t("关闭订单无法恢复，触发平台差异退款，是否关闭订单?"),
				onOk: () => {
					this.closeLoading = true
					http({
						url: api.manualCloseOrder,
						type: "post",
						data: {
							orderId: this.orderId,
						},
						hasLoading: true,
						success: (res) => {
							this.closeLoading = false
							this.$message.success(res.msg)
							this.getOrderDetail()
							this.getOrderActionList()
						},
					})
				},
			})
		}),
		async returnRefund() {
			this.returnRefundVisible = true
		},
		async returnRefundOk() {
			this.returnRefundLoading = true
			try {
				const res = await http({
					url: api.interceptOrder,
					type: "post",
					data: {
						data: {
							afterSaleType: "RETURN_REFUND",
							orderId: this.orderId,
							returnWillBillNo: this.returnWillBillNo,
						},
					},
				})
				this.$message.success(res.msg)
				this.returnRefundLoading = false
				this.returnRefundVisible = false
				this.getOrderDetail()
				this.getOrderActionList()
			} catch (err) {
				this.returnRefundLoading = false
				console.log(err)
			}
		},
		handleChangeGoods(data, record) {
			record.exchangeSku = data.skuCode
		},
		getAftersaleOrderAllStatus() {
			http({
				url: api.aftersaleOrderAllStatus,
				type: "post",
				data: {},
				hasLoading: false,
				success: (res) => {
					this.aftersale_type_list = res.result.select_map.aftersale_type
					this.aftersale_create_type_list = res.result.select_map.aftersale_type.filter(
						(item) => !["INTERCEPT", "CANCELLATION"].includes(item.name) //不允许创建拦截单和取消单
					)
					this.aftersale_status_list = res.result.select_map.aftersale_status
				},
			})
		},
		openAfterSaleModal() {
			this.getAftersaleOrderAllStatus()
			this.handleTableDetail()
			this.afterSaleModalVisible = true
		},
		handleOk() {
			if (!this.aftersale_type || this.selectedRows.length === 0) {
				this.$warning(this.$t("请选择售后类型和售后商品"))
				return
			}
			let noEx = false
			this.selectedRows.forEach((item) => {
				if (!item.exchangeSku) {
					noEx = true
				}
			})
			if (this.aftersale_type === "EXCHANGE" && noEx) {
				this.$warning(this.$t("请选择换货商品"))
				return
			}
			this.selectedRows.forEach((item) => {
				item.goodsData = undefined
			})

			const obj = {
				aftersale_type: this.aftersale_type,
				aftersaleProducts: this.selectedRows,
				receiverMobile: this.orderDetail.receiverMobile,
				shopNick: this.orderDetail.shop.shopNick || this.orderDetail.shop.shopCode,
				platformOrderId: this.orderDetail.platformOrderId,
				buyer: this.orderDetail.buyer,
				erpOrderSn: this.orderDetail.orderSn,
				country: this.currentShop.country,
				countryId: this.orderDetail.receiverProvince?.parent?.regionId || "",
				receiverProvince: this.orderDetail.receiverProvince?.regionName || "",
				receiverProvinceId: this.orderDetail.receiverProvince?.regionId || "",
				receiverCity: this.orderDetail.receiverCity.regionName || "",
				receiverCityId: this.orderDetail.receiverCity.regionId || "",
				receiverDistrict: this.orderDetail.receiverDistrict.regionName || "",
				receiverDistrictId: this.orderDetail.receiverDistrict.regionId || "",
				receiverName: this.orderDetail.receiverName || "",
				receiverPhoneNumber: this.orderDetail.receiverPhone || "",
				receiverZip: this.orderDetail.receiverZip || "",
				receiverAddress: this.orderDetail.receiverAddress || "",
				labelFile: this.orderDetail.labelFile,
			}

			localStorage.setItem("aftersaleObj", JSON.stringify(obj))
			// window.location.href = location.origin + '/aftersale_web/aftersale/create'
			this.$addPane({
				view: "aftersale_web/aftersale/create",
				name: "创建售后",
				params: {},
				route: "/aftersale_web/aftersale/create",
			})
			this.afterSaleModalVisible = false
			this.aftersale_type = null
			this.selectedRowKeys = []
			this.selectedRows = []
		},
		onSelectChange(selectedRowKeys, selectedRows) {
			this.selectedRows = selectedRows
			this.selectedRowKeys = selectedRowKeys
		},
		handleTableDetail() {
			this.saleOrderList = []
			for (const item of this.orderDetail.orderGoodses) {
				const ids = this.orderDetail.platformOrderIds?.toString()
				item.forEach((e) => {
					e.skuCode = e.sku.skuCode
					e.orderSn = ids
					e.orderWeight = this.orderDetail.orderWeight
					e.totalSkuDiscountFee = this.orderDetail.totalSkuDiscountFee
					e.orderGoodsNum = this.orderDetail.orderGoodsNum
					e.totalSkuNum = this.orderDetail.totalSkuNum
					e.goodsFee = this.orderDetail.goodsFee
					e.postDiscountFee = this.orderDetail.postDiscountFee
					e.feeNum = (Number(this.orderDetail.totalFee) - Number(this.orderDetail.postFee)).toFixed(2)
					e.fenxiaoTotalFee = Number(this.orderDetail.fenxiaoTotalFee).toFixed(2)
					e.subPlatformOrderSn = e?.originOrderGoods?.platformOrderGoodsId
				})
				this.saleOrderList.push(...item)
			}
		},
		clear() {
			this.orderId = ""
			this.orderDetail.orderSns = ""
			this.orderDetail.originOrders = ""
		},
		changeTabs(value) {
			document.querySelector(`#${value}`).scrollIntoView(true)
		},
		clearOrderDetail() {
			this.orderDetail = {
				originOrderSns: [],
				shop: {},
				distributor: {},
				receiverProvince: {
					regionId: "",
					regionName: "",
				},
				receiverCity: {
					regionId: "",
					regionName: "",
				},
				receiverDistrict: {
					regionId: "",
					regionName: "",
				},
				receiverStreet: {
					regionId: "",
					regionName: "",
				},
				shipping: {},
				warehouse: {},
				waybillNo: "",
				pay: {},
				orderGoodses: [],
				packages: {},
				orderProcess: {},
				invoiceInfo: [],
			}
		},
		async getOrderDetail() {
			this.clearOrderDetail()
			const res = await http({
				url: api.getOrderDetail,
				type: "post",
				data: {
					data: {
						orderId: this.orderId,
						orderSn: this.order_sn,
					},
				},
				hasLoading: true,
			})

			const orderDetailInfo = {
				...this.orderDetail,
			}

			const result = res.data || res.result
			for (const i in result) {
				orderDetailInfo[i] = result[i]
			}

			orderDetailInfo.shipping = orderDetailInfo.shipping || {}

			if (!this.orderId) {
				// history.replaceState(
				//   {},
				//   null,
				//   '/orderManage/omsOrderDetail/orderDetails/' + orderDetailInfo.orderId
				// )
				const panes = deepClone(this.panes)
				const paneItem = panes.find((item) => this.order_sn === item.params?.orderSn)
				if (paneItem) {
					paneItem.params.orderId = orderDetailInfo.orderId
					this.setState({ panes })
				}
				this.orderId = orderDetailInfo.orderId
			}
			if (orderDetailInfo.packages && orderDetailInfo.packages.length > 0 && orderDetailInfo.packages[0].elseInfo) {
				const elseInfoObj = JSON.parse(orderDetailInfo.packages[0].elseInfo)
				orderDetailInfo.packages.forEach((item) => {
					item.elseInfoObj = elseInfoObj
				})
			}
			this.stateData = [] //刷新进度条
			//订单流程图
			this.getOrderProcess(orderDetailInfo)

			//订单无法审核列表
			this.getCheckUnableReasonList(orderDetailInfo)

			//订单商品
			const tempObj = dealWithOrderGoods(orderDetailInfo.orderGoodses)

			orderDetailInfo.orderGoodses = tempObj.orderGoods
			orderDetailInfo.totalSkuDiscountFee = tempObj.totalSkuDiscountFee
			orderDetailInfo.totalSkuNum = tempObj.totalSkuNum
			orderDetailInfo.orderGoodsNum = tempObj.orderGoodsNum
			orderDetailInfo.originOrderSns = tempObj.originOrderSns
			orderDetailInfo.originOrders = tempObj.originOrders
			this.is_group = tempObj.is_group
			orderDetailInfo.totalDiscountFee = (orderDetailInfo.discountFee + Number(tempObj.totalSkuDiscountFee)).toFixed(2)

			orderDetailInfo.goodsFee = orderDetailInfo.goodsFee?.toFixed(2) || 0
			orderDetailInfo.postFee = orderDetailInfo.postFee?.toFixed(2) || 0
			orderDetailInfo.discountFee = orderDetailInfo.discountFee?.toFixed(2) || 0
			orderDetailInfo.taxFee = orderDetailInfo.taxFee?.toFixed(2) || 0
			orderDetailInfo.totalFee = orderDetailInfo.totalFee?.toFixed(2) || 0
			orderDetailInfo.totalAmount = orderDetailInfo.totalAmount?.toFixed(2) || 0

			$("title").text(orderDetailInfo.orderSn)
			const orderGoodses = orderDetailInfo.orderGoodses
			orderDetailInfo.orderGoodses = []
			this.$nextTick(() => {
				orderDetailInfo.orderGoodses = orderGoodses
				this.orderDetail = orderDetailInfo
			})
		},
		async getOrderActionList() {
			const res = await http({
				url: api.getOrderActionList,
				type: "post",
				data: {
					data: {
						orderId: this.orderId,
						orderSn: this.order_sn,
						type: "",
					},
				},
			})
			const actionList = res.data || res.result
			actionList.forEach((item) => {
				item.content = item.content.replace(/#/g, "")
			})
			this.orderActions = actionList
		},
		getCheckUnableReasonList(info) {
			if (info.orderProcess) {
				this.checkUnableReasonList = [
					{
						value: "shortageStatus",
						name: this.$t("缺货"),
						show: !!(info.orderProcess.shortageStatus === "ALL_SHORTAGE" || info.orderProcess.shortageStatus === "PART_SHORTAGE"),
					},
					{
						value: "FinanceCheckStatus",
						name: this.$t("待财审"),
						show: !!this.constants.WAIT_CHECK_STATUS.includes(info.orderProcess.financeCheckStatus),
					},
					{
						value: "bookSubOrderType",
						name: this.$t("预订单"),
						show: info.orderProcess.subOrderType === "BOOK",
					},
					{
						value: "subOrderType",
						name: this.$t("缺货单"),
						show: info.orderProcess.subOrderType === "WAIT_GOODS",
					},
				]
			} else {
				this.checkUnableReasonList = []
			}
		},
		getOrderProcess(info) {
			this.stateData = [
				{
					//下单
					name: "下单",
					time: info.platformOrderTime,
				},
				{
					//付款
					name: "付款",
					time: info.paytime,
				},
				{
					//创建
					name: "创建",
					time: info.createTime,
				},
				{
					//审核
					name: "审核",
					time: info.orderProcess?.checkTime,
				},
				{
					//推送
					name: "推送",
					time: info.orderProcess?.pushWmsTime || info.wmsOrder?.pushTime,
				},
				{
					//拣货
					name: "拣货",
					time: info.orderProcess?.pickedTime,
				},
				{
					//配货
					name: "配货",
					time: info.orderProcess?.shippingTime || info.shippingTime,
				},
				{
					//已揽收
					name: "揽收",
					time: info.orderProcess?.collectedTime,
				},
			]
		},
		clickTab(tab) {
			if (tab && !tab.active) {
				this.select(tab)
			}
		},
		select(tab) {
			if (!tab) {
				return
			}
			this.currentView = tab.view
			if (tab.view === "orderAfterSaleDetail") {
				this.getAftersaleOrderAllStatus()
			}
			this.$set(tab, "active", true)
			this.tabList.forEach((ftab) => {
				if (ftab.name !== tab.name) {
					this.$set(ftab, "active", false)
				}
			})
		},
		async cancelOrder() {
			this.cancelLoading = true
			try {
				await http({
					url: api.cancelOrder,
					type: "post",
					data: {
						data: {
							orderId: this.orderId,
							actionNote: this.noteAddModal.params.note,
						},
					},
					hasLoading: false,
					success: (res) => {
						this.closeCancelModal(res.msg)
					},
					fail: (res) => {
						this.closeCancelModal(res.msg)
					},
				})
				this.cancelLoading = false
			} catch (err) {
				this.closeCancelModal(err)
				this.cancelLoading = false
			}
		},
		closeCancelModal(msg) {
			this.$success(msg)
			this.getOrderDetail()
			//刷新操作记录
			this.getOrderActionList()
			this.noteAddModal.show = false
		},
		checkOrder: debounce(function () {
			if (this.isStore) {
				const date = this.orderDetail.latestDeliveryTime.split(" ")[0]
				//门店不允许审核后天及之后的单子
				if (new Date(date) - new Date() > 24 * 3600 * 1000) {
					this.$message.warning("审核订单为后天及之后到货，如需明天到货，请先修改预计到货时间")
					return
				}
			}
			this.reserveLoading = true
			http({
				url: api.checkOrders,
				type: "post",
				data: {
					data: {
						orderIds: [this.orderId],
					},
				},
				hasLoading: false,
				success: (res) => {
					this.reserveLoading = false
					this.$success(res?.data?.msg || res?.msg)
					setTimeout(() => {
						this.getOrderDetail()
						//刷新操作记录
						this.getOrderActionList()
					}, 1500)
				},
				fail: (res) => {
					this.$error(res.msg)
					this.reserveLoading = false
				},
			})
		}),
		deliveredOrder: debounce(function () {
			http({
				url: api.deliveredOrder,
				type: "post",
				data: {
					data: {
						orderId: this.orderId,
					},
				},
				hasLoading: false,
				success: (res) => {
					this.$success(res.msg)
					//刷新操作记录
					this.getOrderActionList()
					this.getOrderDetail()
				},
			})
		}),
		// 添加提示
		revertWaitCheck: debounce(function () {
			this.revertWaitCheckLoading = true
			http({
				url: api.revertWaitCheck,
				type: "post",
				data: {
					data: {
						orderId: this.orderId,
					},
				},
				hasLoading: false,
				success: (res) => {
					this.revertWaitCheckLoading = false
					this.$success(res.msg)
					//刷新操作记录
					this.getOrderActionList()
					this.getOrderDetail()
				},
				fail: (res) => {
					this.$error(res.msg)
					this.revertWaitCheckLoading = false
				},
			})
		}),
		forceCheckOrder() {
			http({
				url: api.forceCheckOrders,
				type: "post",
				data: {
					data: {
						orderIds: [this.orderId],
					},
				},
				hasLoading: false,
				success: (res) => {
					this.$success(res.msg)

					this.getOrderDetail()

					this.orderDetail.orderProcess.exceptionTagList = []

					//刷新操作记录
					this.getOrderActionList()
				},
			})
		},
		urgentShipping() {
			http({
				type: "post",
				hasLoading: true,
				url: api.urgentDeliver,
				data: {
					data: {
						orderId: this.orderId,
					},
				},
				success: (res) => {
					this.$success(res.msg)

					this.getOrderDetail()
					//刷新操作记录
					this.getOrderActionList()
				},
			})
		},
		changeSubOrderType(data) {
			http({
				url: api.changeOrdersType,
				type: "post",
				data: {
					data: {
						orderIds: [this.orderId],
						subOrderType: data,
					},
				},
				hasLoading: false,
				success: (res) => {
					this.$success(res.msg)
					this.orderDetail.orderProcess.subOrderType = data
					this.getCheckUnableReasonList(this.orderDetail)

					//刷新操作记录
					this.getOrderActionList()
				},
			})
		},
		chooseBooktime(data) {
			if (this.orderDetail.bookActivateTime === data.data) {
				return false
			}
			const obj = {
				orderId: this.orderDetail.orderId,
				bookActivateTime: data.data,
				releaseInventory: "",
			}
			this.confirmModifyBook(obj)

			//刷新操作记录
			this.getOrderActionList()
		},
		releaseInventory() {
			const obj = {
				orderId: this.orderDetail.orderId,
				bookActivateTime: "",
				releaseInventory: true,
			}
			this.confirmModifyBook(obj)
			this.orderDetail.orderProcess.reserverStatus = "UNRESERVED"
		},
		showBookActiveTime(data) {
			if (data === "bookSubOrderType") {
				const x = `${event.clientX}px`
				const y = `${event.clientY}px`
				this.bookActiveTime.style = `top: ${y}; left: ${x}`
				this.bookActiveTime.show = true
			}
		},
		hiddenBookActiveTime() {
			this.bookActiveTime.show = false
		},
		confirmModifyBook(data) {
			http({
				url: api.modifyOrderBook,
				type: "post",
				data: {
					data: data,
				},
				hasLoading: false,
				success: (res) => {
					this.$success(res.msg)

					//刷新操作记录
					this.getOrderActionList()
				},
			})
		},
		clearException(index, data, e) {
			e.preventDefault()
			this.$confirm({
				title: this.$t("提示"),
				content: this.$t("确定删除该异常标签"),
				onOk: async () => {
					const res = await http({
						url: api.clearExceptionTag,
						type: "post",
						data: {
							data: {
								orderId: this.orderDetail.orderId,
								exceptionTag: data,
							},
						},
					})
					this.$success(res.msg)
					this.orderDetail.orderProcess.exceptionTagList.splice(index, 1)
					//刷新操作记录
					this.getOrderActionList()
				},
			})
		},
		showNoteModal(type) {
			this.noteAddModal.show = true
			this.noteAddModal.params.type = type
			this.noteAddModal.params.note = ""
			this.noteAddModal.params.addNote = ""
		},
		cancelMessageAdd() {
			this.noteAddModal.show = false
		},
		confirmCommentAction(data) {
			this.noteAddModal.params.note = data.note
			if (this.noteAddModal.params.type === "CANCEL" || this.noteAddModal.params.type === "PRESALE_CANCEL") {
				this.cancelOrder()
			}
		},
		async pushToWms() {
			this.pushLoading = true
			await http({
				url: api.orderPushByHand,
				type: "post",
				data: {
					data: {
						orderIds: [Number(this.orderId)],
						pushToWms: !!(
							this.orderDetail.shippingStatus === "WAYBILL_SUCCESS" &&
							(this.orderDetail.wmsCreateStatus === "INIT" || this.orderDetail.wmsCreateStatus === "PUSH_FAIL")
						),
					},
				},
				hasLoading: false,
				success: (res) => {
					this.$success(res.msg)
					this.getOrderDetail()
					//刷新操作记录
					this.getOrderActionList()
				},
			})
			this.pushLoading = false
		},
		//驳回操作
		rejectHandle: debounce(function () {
			this.$confirm({
				title: this.$t("驳回"),
				content: this.$t("是否驳回订单?"),
				onOk: async () => {
					this.rejectLoading = true
					try {
						const res = await http({
							url: api.rejectOrders,
							type: "post",
							data: {
								data: {
									orderIds: [+this.orderId],
								},
							},
							hasLoading: true,
						})
						this.rejectLoading = false
						if (res?.data?.success) {
							this.$success(res.msg || this.$t("操作成功"))
							this.getOrderDetail()
							//刷新操作记录
						} else {
							this.$success(res?.data?.msg || this.$t("操作失败"))
						}
						this.getOrderActionList()
					} catch (err) {
						this.rejectLoading = false
						console.log(err)
					}
				},
			})
		}),
		//代扣款操作
		deductionHandle() {
			this.deductionVisible = true
			this.deductionAmount = ""
			this.deductionRemark = ""
		},
		deductionOk: debounce(function () {
			if (!this.deductionAmount) {
				return this.$message.warning(this.$t("请输入扣款金额"))
			}
			this.deductionLoading = true
			http({
				url: api.addDeduction,
				type: "post",
				data: {
					orderId: this.orderId,
					amount: this.deductionAmount,
					remark: this.deductionRemark,
				},
				hasLoading: true,
				success: () => {
					this.deductionLoading = false
					this.deductionVisible = false
					this.$message.success(this.$t("扣款操作成功"))
					this.getOrderDetail()
					this.getOrderActionList()
				},
				fail: (res) => {
					this.deductionLoading = false
					this.$message.error(res.msg)
				},
			})
		}),
		copyOrderSn(text) {
			copy(text)
		},
		refreshHandle() {
			this.refreshLogAction()
			this.refreshOrderDetail()
		},
		async refreshLogAction() {
			this.logSpin = true
			await this.getOrderActionList()
			this.logSpin = false
		},
		async refreshOrderDetail() {
			this.orderSpin = true
			await this.getOrderDetail()
			this.orderSpin = false
		},
		showClose(item) {
			return item !== "缺货" && item !== "Out of stock" //缺货标签不允许手动删除
		},
	},
}
</script>

<style scoped lang="scss">
.fixed .content-wrapper,
.fixed .right-side {
  padding-top: 0 !important;
}

.content-wrapper,
.right-side,
.main-footer {
  margin-left: 0 !important;
}

.content {
  overflow: auto;
}

.content-overflow {
  width: 100%;
  overflow: auto;
  display: flex;
}
.order-detail {
  .fixContent {
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
  .left-div {
    width: 75%;
    .content {
      margin-bottom: 10px !important;
    }
  }
  .right-div {
    width: 24%;
    background-color: transparent;
    font-size: 12px;
  }
  .scroll-div {
    // height: calc(100vh - 210px);
    overflow-y: auto;
  }
}

.order-header {
  background-color: $white;
  width: 100%;
  float: left;
}

.order-status {
  padding: 5px 5px;
  border-bottom: solid 1px #d2d6de;
}

.order-status label,
.order-status button {
  margin-left: 10px;
}

.order-sn {
  font-weight: bold;
  font-size: 15px;
}

.pay-time {
  color: #aaa;
  font-size: 11px;
}

.order-btn button {
  margin-left: 20px;
}

.order-flow {
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  width: 100%;
  vertical-align: middle;
  float: left;
}

.order-action {
  border-top: solid 1px #d2d6de;
}

.tabs-list-wrapper {
  width: 100%;
  float: left;
  margin-top: 10px;
}

.tabs-list {
  width: 100%;
  float: left;
  padding-left: 0px;
  border-bottom: 1px solid #e2e2e2;
}

.tabs-list li {
  float: left;
  font-size: 14px;
  margin-bottom: -1px;
  margin-right: 5px;
  padding: 5px 20px;
  text-align: center;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  background-color: #fafafa;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.tabs-list li.active {
  color: $primary-color;
  background-color: $white;
  border-top: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2;
  border-left: 1px solid #e2e2e2;
  font-weight: 500;
  border-bottom: 1px solid transparent;
}

.tabs-content-wrapper {
  float: left;
  width: 100%;
  background-color: $white;
}

.order-process-time {
  color: #aaa;
  font-size: 11px;
}

.form-horizontal .form-group {
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}

.col-md-12,
.col-md-8,
.col-md-1,
.col-md-2,
.col-md-9 {
  padding-left: 1px;
  padding-right: 1px;
}

.book-modal {
  width: 350px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: $white;
  margin: 200px auto;
  float: none;
  opacity: 1;
  padding: 20px 10px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90%;
  z-index: 1002;
  border: 1px solid #797979;
  /*border-radius: 4px;*/
}

.book-active-time {
  width: 165px;
  position: fixed;
  left: 0;
  top: 0;
  background-color: $white;
  float: none;
  opacity: 1;
  padding: 10px 10px;
  z-index: 1002;
  border: 1px solid #797979;
  border-radius: 4px;
  color: #797979;
  -webkit-box-shadow: 0 0 4px 4px rgba(121, 121, 121, 0.3);
  -moz-box-shadow: 0 0 4px 4px rgba(121, 121, 121, 0.3);
  box-shadow: 0 0 4px 4px rgba(121, 121, 121, 0.3);
}

.action-buttons {
  margin-top: 10px;
}

.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6 {
  padding-left: 1px;
  padding-right: 1px;
}

.iconfont {
  font-size: 14px;
  color: #666;
  cursor: pointer;
}

.btn-a {
  border-bottom: 1px solid;
  color: $primary-color;
}

/*label {
	overflow:hidden;
	white-space:nowrap;
}*/

.action-log li {
  border-left: 1px solid #d9d9d9;
  padding-bottom: 16px;
}

.action-log li:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #d9d9d9;
  border: 2px solid $white;
  margin-left: -5px;
}

.action-info {
  margin-left: 8px;
}

.action-creator {
  margin-left: 12px;
  color: #a6a6a6;
}

.gray {
  color: #999;
}

#pushFail {
  cursor: pointer;
}

.order-action i {
  color: #999;
  font-size: 12px;
}

.question {
  font-size: 12px;
  color: #999;
  cursor: pointer;
}

.formContentGoods {
  /* padding: 10px 15px; */
  margin-bottom: 16px;
  background-color: white;
  color: #333333;
  font-size: 14px;
}

p {
  margin: 0;
}
.top-tabs ::v-deep .ant-tabs-bar {
  margin: 0;
}
.primaryBtn {
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  margin-right: 10px;
  font-size: 14px;
  height: 32px;
}
.dangerBtn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  margin-right: 10px;
}
</style>
