<template>
  <a-select
    :disabled="diasbled"
    style="width: 100%"
    v-model="unitCode"
    @change="handleChange"
    @focus="getUnitList"
  >
    <a-select-option v-for="(item, index) in unitList" :key="index" :value="item.unitNameFull">
      {{ item.unitNameFull }}
    </a-select-option>
  </a-select>
</template>
<script>
import api from "@service/api"
import http from "@service/axios"
export default {
	props: {
		diasbled: {
			type: Boolean,
			default: false,
		},
		skuCode: {
			type: String,
		},
		code: {
			type: String,
		},
		list: {
			type: Array,
			default: () => [],
		},
	},
	watch: {
		code(newVal) {
			this.setDefault(newVal)
		},
		list: {
			immediate: true,
			handler(newVal) {
				if (newVal.length > 0) {
					this.unitList = newVal
					this.showDefault(newVal)
				}
			},
		},
	},
	data() {
		return {
			unitCode: undefined,
			unitList: [],
		}
	},
	mounted() {
		this.setDefault(this.code)
	},
	methods: {
		setDefault(newVal) {
			if (newVal && newVal !== "undefined") {
				this.unitCode = newVal
			} else {
				this.unitCode = undefined
			}
		},
		showDefault(list) {
			if (list.length === 1) {
				this.handleChange(list[0].unit)
			}
		},
		handleChange(value) {
			this.unitCode = value
			this.$emit("update:code", value)
			const data = this.unitList.find((item) => item.unitNameFull === value)
			this.$emit("change", data)
		},
		getUnitList() {
			if (this.unitList.length) return
			http({
				url: api.getSkuList,
				type: "post",
				data: {
					skuCode: this.skuCode,
				},
				hasLoading: false,
				success: (res) => {
					this.unitList = res.result.skuResVO[0]?.unitList || []
					this.showDefault()
				},
			})
		},
	},
}
</script>
