import { isEmptyObject } from "@/common"
import { deleteCookie, getCookie } from "@/common/cookie"
import { getDateStr, timestampToTime } from "@/common/getDateStr"
import i18n from "@/utils/lang"
import { Modal, message } from "ant-design-vue"
import axios from "axios"
import JSONBig from "json-bigint"
import { baseURL, orderURL, plymouthURL } from "../config/config"
import store from "../store"
// import router from '@/router.js'

let hasLogout = false

const Axios = axios.create({
	method: "POST",
	// timeout: 10000, //接口超时加上10s的限制
	withCredentials: true,
	transformResponse: [
		(data) => {
			return JSON.stringify(JSONBig.parse(data))
		},
	],
})

/**
 * 判断url是否需要替换成新的订单接口
 * @param {string} url
 * @returns
 */
const replaceURL = (url, config) => {
	let newUrl = url
	const replacements = [
		{ match: `${baseURL}oms_api/order/`, replacement: `${orderURL}order/` },
		{ match: `${baseURL}oms_api/order_action/`, replacement: `${orderURL}order_action/` },
		{ match: `${baseURL}oms_api/original_order/`, replacement: `${plymouthURL}original_order/` },
		{ match: `${baseURL}oms_api/inspection/task/`, replacement: `${orderURL}inspection/task/` },
	]
	if (!config.isOldOms) {
		if (config.isOriginal) {
			newUrl = url.replace(`${baseURL}oms_api/`, plymouthURL)
		} else if (config.isOmsOrder) {
			newUrl = url.replace(`${baseURL}oms_api/`, orderURL)
		} else {
			for (let i = 0; i < replacements.length; i++) {
				const { match, replacement } = replacements[i]
				if (url.includes(match)) {
					return url.replace(match, replacement)
				}
			}
		}
	}

	return newUrl
}

/**
 * 判断是否是时期格式
 * @param {string} date
 */
const isDate = (date) => {
	let is_date = false
	const pattern_zh = /[\u4E00-\u9FA5]+/ //判断是否有中文
	const pattern_en = /[A-Za-z]+/ //判断是否有英文
	const pattern_date = new RegExp(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/) //判断2020-10-10 10:10:10 是否存在
	if (
		!pattern_zh.test(date) &&
		!pattern_en.test(date) &&
		pattern_date.test(date) &&
		Number.isNaN(date) &&
		!Number.isNaN(Date.parse(date)) &&
		(date.length === 10 || date.length === 19)
	) {
		is_date = true
	}
	return is_date
}

/**
 * 时区处理 请求加1小时，返回减1小时
 * @param {date} date
 * @param {string} type req请求 res返回
 */
const handleDate = (date, type) => {
	const timezoneDiffBeijing = localStorage.getItem("timezoneDiffBeijing") || 0
	const time = +new Date(date) + timezoneDiffBeijing * 3600 * 1000 * (type === "req" ? 1 : -1)
	return timestampToTime(time)
}

/**
 * 时区处理 请求加1小时，返回减1小时
 * @param {object} data 对象
 * @param {string} type req请求 res返回
 */
const timeZoneProcessing = (data, type = "req") => {
	if (isEmptyObject(data) || !data) {
		return data
	}
	Object.keys(data).forEach((item) => {
		if (typeof data[item] === "object") {
			timeZoneProcessing(data[item], type)
		} else if (isDate(data[item])) {
			data[item] = handleDate(data[item], type)
		}
	})
	return data
}

Axios.interceptors.request.use(
	(config) => {
		// loding
		if (config.hasLoading) {
			store.commit("setState", {
				loading: true,
			})
		}

		//method
		config.method = config.type || "post"

		// path
		let path = config.url

		//requestId=rqname+用户名+当前时间(20230906120542)+4位guid()
		const requestId = guid(path)
		if (path.indexOf("?") > 0) {
			path = `${path}&requestId=${requestId}`
		} else {
			path = `${path}?requestId=${requestId}`
		}
		// partyId
		const partyId = store.getters.partyId

		if (partyId) {
			path = `${path}&cpid=${partyId}`
		}
		const language = localStorage.getItem("lang") || "zh"
		const language_mapping = {
			zh: "zh_CN",
			en: "en_US",
			th: "th_TH",
		}
		path += `&language=${language_mapping[language]}`
		const token = getCookie("d_session_id")
		config.headers.Authorization = token
		config.headers["Content-Type"] = config.contentType ?? "application/json; charset=UTF-8"
		if (config.responseType) {
			config.headers["Response-Type"] = config.responseType
		}

		if (config.type?.toLocaleUpperCase() === "GET") {
			for (const item in config.data) {
				path += `&${item}=${config.data[item]}`
			}
		}

		const data = config.data === undefined ? JSON.stringify({}) : config.contentType === false ? config.data : JSON.stringify(config.data)
		config.data = data instanceof FormData ? data : JSON.stringify(timeZoneProcessing(JSON.parse(data), "req"))

		config.url = path

		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

Axios.interceptors.response.use(
	(res) => {
		if (res.config.hasLoading) {
			store.commit("setState", {
				loading: false,
			})
		}

		const value = timeZoneProcessing(JSON.parse(res.data), "res")
		// console.log('value: ', value)
		if (String(value.code) === "200" || String(value.code) === "1000000") {
			//为了兼容之前的http写法
			if (res.config.success && typeof res.config.success === "function") {
				res.config.success(value)
			}
			return Promise.resolve(value)
		}

		if (+value.error_code === 5001) {
			//登录超时
			goToLogin()
			return
		}

		if (res.config.fail && typeof res.config.fail === "function") {
			res.config.fail(value)
			return
		}

		const err = value.subMsg || value.msg || value.message
		if (err) {
			message.error(err)
		}
	},
	(error) => {
		store.commit("setState", {
			loading: false,
		})
		const response = error.response
		console.log("error: ", response)
		try {
			if (response.config.fail && typeof response.config.fail === "function") {
				response.config.fail(JSON.parse(response.data))
				return
			}
		} catch (err) {
			console.log(err)
		}

		//请求超时
		if (error.code === "ECONNABORTED" || error.message.includes("timeout")) {
			Modal.warning({
				title: i18n.t("请求超时"),
				content: error.config.url + i18n.t("接口请求超时"),
			})
			return
		}

		//404 || 发布中
		// if (response?.status === 404 || error?.text?.includes('404')) {
		//   router.push({
		//     name: '404',
		//   })
		//   return
		// }

		try {
			const value = JSON.parse(response?.data)
			if (+value.error_code === 5001) {
				//登录超时
				goToLogin()
				return
			}
			const err = value.msg || value.subMsg
			if (err && err !== "null") {
				message.error(err)
			}
		} catch (err) {
			console.log(err)
		}
	}
)

function goToLogin() {
	if (hasLogout) return
	message.destroy()
	message.warning(i18n.t("登录超时"))
	hasLogout = true
	localStorage.clear()
	localStorage.setItem("pageCacheFullPath", location.pathname)
	setTimeout(() => {
		deleteCookie(() => {
			window.location.href = "/login"
		})
	}, 500)
}

export function guid() {
	const time = getDateStr(0, true).replace(/\D/g, "")
	const userAccount = localStorage.getItem("userAccount")
	const s4 = () =>
		Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1)
	return `rqname${userAccount}${time}${s4()}`
}

export default Axios

export { replaceURL }
