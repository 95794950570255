<template>
  <div class="pagination">
    <a-pagination
      size="small"
      v-model="currentPage"
      :total="+totalCount"
      show-size-changer
      show-quick-jumper
      :pageSizeOptions="pageList"
      @showSizeChange="pageSizeChanged"
      :show-total="(total) => `${$t('总计')} ${total} ${$t('条')} `"
    />
  </div>
</template>

<script>
import { Pagination } from "ant-design-vue"
export default {
	props: ["offset", "length", "pageNo", "totalCount", "pages", "id"],
	components: { "a-pagination": Pagination },
	data() {
		const pages = this.pages ? this.pages.split(",") : ["1", "10", "50", "100", "200", "500", "1000"]
		return {
			pageList: pages,
			stop: false,
		}
	},
	computed: {
		currentPage: {
			get() {
				return this.pageNo || this.offset / this.length + 1
			},
			set(val) {
				if (this.stop) {
					this.stop = false
					return
				}
				this.$emit("paginate", {
					offset: this.length * (val - 1),
					length: this.length,
					pageNo: val,
					pageSize: this.length,
				})
			},
		},
	},
	methods: {
		pageSizeChanged(current, pageSize) {
			if (this.currentPage !== 1) {
				this.stop = true
			}
			this.$emit("paginate", {
				offset: 0,
				length: pageSize,
				pageNo: 1,
				pageSize,
			})
		},
	},
}
</script>

<style scoped lang="scss">
.pagination {
  padding: 8px 0 4px;
}
</style>
