import i18n from "@/utils/lang"
import moment from "moment"
export default {
	loading: false, //加载全局spin Loading
	lang: localStorage.getItem("lang") || "zh",
	locale: getData("locale", {}),
	shopList: [], //店铺列表
	userInfo: getData("userInfo", {}),
	userSession: getData("userSession", {}),
	currentShop: getData("currentShop", {}),
	shopCode: "",
	nowPlatformList: [], //当前的平台列表，通过店铺来获取的。
	countryMap: {
		th: 1, //泰国
		TH: 1, //泰国
		Thailand: 1, //泰国
		VN: 2, //越南
		PH: 3, //菲律宾
		Philippines: 3, //菲律宾
		MY: 4, //马来西亚
		Malaysia: 4, //马来西亚
		CN: 6, //中国
		China: 6, //中国
	},
	exclude: null,
	panes: getData("panes", [{ view: "saleOrders" }]),
	shopPlatformList: getData("shopPlatformList", []),
	pagination_setting: {
		showSizeChanger: true,
		pageSizeOptions: ["10", "20", "30", "40"],
	},
	show_time_setting: {
		hideDisabledOptions: true,
		defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("11:59:59", "HH:mm:ss")],
	},
	dashboard_loading: false,
	loading_type: "",
	loading_tips: {
		aftersale_web: [
			"售后类型有退款、退货退款、换货、补寄、拦截、取消销售",
			"售后退款创建后，需经过先审核，再退款两步才可以完结。可以通过配置简化这两步操作",
		], //售后
		purchase: [
			"采购单是指企业在购买物资或服务时，向供应商发出的一种正式的采购文件，包括采购物资或服务的名称、数量、价格、付款方式、交货时间等具体信息。",
			"调拨单是指企业内部调配物资或者产品的一种单据，通常包括调拨物品的名称、数量、来源仓库、目标仓库等信息。",
			"采购具体流程如下：1.录入采购单；2.审核；3.入库；4.完成采购",
		], //采购
		distributionManagement: [], //分销
		distributionPlatform: [], //分销
		workorder_web: ["工单类型分为售后、缺陷和需求，其中售后又包含咨询、物流、库存、产品等等多个子分类"], //工单
		inventory_web: [
			"实际库存为仓库内现有的库存数量；虚拟库存为可用库存的基础上，增加的虚拟部分，使满足销售和订单下推",
			"可用库存=实际库存-OMS占用量-WMS占用量，意为当前可售卖的库存数量；可用库存高于安全库存上限则滞销，低于下限则缺货",
		], //库存
		report_web: ["报表系统是一种数据分析和呈现工具，它能够将企业或组织的数据进行收集、处理、分析、整理、统计并生成相应的报表。"], //task
		dashboard_web: ["Dashboard 是一种重要的数据可视化工具，帮助企业或组织更好地了解业务状况，制定决策和行动计划，促进团队协作和提高工作效率。"], //dashboard
		order: [], //wmshub
		campaign_web: [
			"Tip: Understanding the main campaign allows you to find your eligible campaign easily! For example - Main campaign: 11.11 sale Eligible campaigns: Special Invitations, Platform Campaign, Free Shipping, Voucher etc",
		], //活动管理
		common: [
			// '正常审核:系统会审核掉所有无异常标签符合条件的订单；<br/>强制审核:系统会清除订单可清除的所有异常标签后(不包括缺货和待财审)审核订单；<br/>按照审单配置审核:系统会审核掉所有符合审核配置条件且无异常标签的订单',
			// '预售订单已付定金即进入OMS，不能修改商品，订单审核后推入WMS提前打包，待付尾款后wms才能发货。如果订单作废，则订单不再允许走预售流程',
			// '订单一旦被设置为异常单，就无法审核，必须手动【转正常单】才能审核。可设置激活时间，到点自动转为正常单',
		], //公用
	},
	menus: getData("menus", []),
	orderDetailStateInfo: {},
	orderSubTypeMapping: {
		RAW_MATERIAL_REORDER: i18n.t("原料补单"),
		FINISHED_PRODUCT_REORDER: i18n.t("成品补单"),
		DESSERT_REORDER: i18n.t("甜品补单"),
		SEMI_FINISHED_PRODUCT_REORDER: i18n.t("半成品补单"),
		RAW_MATERIAL_ORDER: i18n.t("原物料订货"),
		FINISHED_PRODUCT_ORDER: i18n.t("成品订货"),
		DESSERT_ORDER: i18n.t("甜品订单"),
		SEMI_FINISHED_PRODUCT_ORDER: i18n.t("半成品订单"),
		TEA_BREAK_ORDER: i18n.t("茶歇订单"),
		BIG_CAKE_ORDER: i18n.t("大蛋糕订单"),
		KITCHEN_GIFT_PRODUCT: i18n.t("厨房赠送产品"),
		PRODUCTION_ORDER: i18n.t("生产订货"),
		//运营领用
		OPERATION_USE: i18n.t("运营领用"),
		//报废出库
		SCRAP_OUTBOUND: i18n.t("报废出库"),
		//办公领用
		OFFICE_USE: i18n.t("办公领用"),
		//研发领用
		RESEARCH_USE: i18n.t("研发领用"),
		//留样出库/检查抽样
		SAMPLE_OUTBOUND: i18n.t("留样出库/检查抽样"),
		//员工福利
		EMPLOYEE_WELFARE: i18n.t("员工福利"),
		//低值易耗品出库
		LOW_VALUE_CONSUMABLE_OUTBOUND: i18n.t("低值易耗品出库"),
		//设备出库
		EQUIPMENT_OUTBOUND: i18n.t("设备出库"),
		//办公家具出库
		OFFICE_FURNITURE_OUTBOUND: i18n.t("办公家具出库"),
		TRANSFER_ORDER: i18n.t("调拨订货"),
	},
	microApplicationList: new Map([]), // 已经注册的微应用列表
	show_micro: false, //展示子应用

	goodsTypeMapping: {
		GENERAL: i18n.t("成品"),
		SEMI_FINISHED_PRODUCT: i18n.t("半成品"),
		RAW_MATERIAL: i18n.t("原料"),
		VIRTUAL: i18n.t("虚拟商品"),
		GIFT: i18n.t("赠品"),
	},
	saleTypeMapping: {
		FRESHLY_MADE_AND_SOLD: i18n.t("现制现售"),
		STOCK_UP_FOR_SALE: i18n.t("备货销售"),
		PURCHASE_SALE: i18n.t("采购销售"),
	},
	rate: localStorage.getItem("zoomRate") || (window.devicePixelRatio >= 2 ? 3.1 / window.devicePixelRatio : 1),
}

function getData(name, defaultData) {
	const data = localStorage.getItem(name)
	if (data === undefined || data === "undefined" || data === null || data === "null") {
		return defaultData
	}
	try {
		return JSON.parse(data)
	} catch (err) {
		console.log(err)
	}
	return data
}
