<template>
  <div id="processState">
    <div
      class="stateItem"
      v-for="(stateItem, index) in statedata"
      :class="[{ firstItem: index === 0 }, { pass: index <= activeIndex }]"
      :key="index"
      :style="{
        width: stateItemWidth + 'px',
      }"
    >
      <span
        class="line"
        v-show="index !== 0"
        :style="{
          width: stateItemWidth + 'px',
          left: -stateItemWidth / 2 + 'px',
        }"
      ></span>
      <span
        class="lineAfter"
        :style="{
          width: stateItemWidth + 'px',
          left: -stateItemWidth / 2 + 'px',
        }"
        v-show="index !== 0 && index <= activeIndex"
      ></span>
      <div class="stateContent" :class="{ firstContent: index === 0 }">
        <nobr class="name">{{ $t(stateItem.name) }}</nobr>
        <div class="topIcon">
          <i class="topIconAfter"></i>
        </div>
        <div class="time">
          {{ stateItem.time | format(0) }}
          <br />
          {{ stateItem.time | format(1) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "@/common/debounce"
export default {
	props: ["statedata"],
	data() {
		return {
			activeIndex: 0,
			stateItemWidth: 130,
		}
	},
	mounted() {
		window.addEventListener(
			"resize",
			debounce(() => {
				this._selfStyle()
			}, 200)
		)
	},
	filters: {
		format(val, i) {
			if (val) {
				return val.split(" ")[i]
			}
			return ""
		},
	},
	watch: {
		statedata: {
			handler() {
				setTimeout(() => {
					this.getActiveState()
				}, 200)
			},
			immediate: true,
		},
	},
	methods: {
		_selfStyle() {
			this.stateItemWidth = Math.floor($("#processState").width() - 10) / this.statedata.length
		},
		getActiveState() {
			const index = this.statedata.findLastIndex((item) => item.time)
			this.activeIndex = index
			this.$nextTick(() => {
				this._selfStyle()
			})
		},
	},
}
</script>

<style scoped lang="scss">
#processState {
  font-size: 12px;
  padding-top: 10px;
  background: $white;
  display: flex;
  flex-wrap: nowrap;
}

.stateItem {
  position: relative;
  width: 100px;
  vertical-align: top;
  text-align: center;
}

.line {
  display: inline-block;
  position: absolute;
  top: 27px;
  left: -42px;
  width: 84px;
  height: 10px;
  background: #dcdcdc;
}

.lineAfter {
  display: inline-block;
  position: absolute;
  top: 30px;
  left: -50px;
  width: 100px;
  height: 4px;
  background: #dcdcdc;
  z-index: 99;
}

.stateContent {
  display: inline-block;
  position: relative;
  top: 0;
  text-align: center;
  z-index: 9;
}

.topIcon {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ddd;
  margin: 0 auto;
}

.topIconAfter {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 4px solid #ddd;
}

.name,
.time {
  color: #777;
  font-size: 12px;
  line-height: 20px;
}

.time {
  color: #777;
  font-size: 12px;
  line-height: 16px;
  margin-top: 2px;
}

.firstContent {
  left: 0;
}

.pass .topIcon {
  background-color: #1c9eff;
}

.pass .lineAfter {
  background: #1c9eff;
}

.name {
  color: #777;
}

.time p {
  margin: 0 0 3px 0;
}

#processState :deep .ant-steps-item-title {
  font-size: 14px !important;
}

#processState :deep .ant-steps-item-icon {
  font-size: 14px;
}
</style>
