<template>
  <a-select style="width: 100%" v-model="type" @change="change" :disabled="disabled">
    <a-select-option value="EXPRESS">{{ $t(`快递`) }}</a-select-option>
    <a-select-option value="LOGISTICS">{{ $t(`物流`) }}</a-select-option>
    <a-select-option value="SELF_PICKUP">{{ $t(`自提`) }}</a-select-option>
  </a-select>
</template>

<script>
export default {
	props: ["value", "disabled"],
	data() {
		return {
			type: undefined,
		}
	},
	methods: {
		change(value) {
			this.$emit("update:value", value)
		},
	},
	watch: {
		value: {
			immediate: true,
			handler(val) {
				this.type = val
			},
		},
	},
}
</script>
