<template>
  <div class="date-picker">
    <a-date-picker
      v-if="singleDatePicker"
      style="width: 100%"
      v-bind="propsValue"
      :placeholder="singleplaceholder"
      :value="value || defaultValue"
      @change="onChange"
      :disabled-date="disabledDate"
    />
    <a-range-picker
      v-else
      style="width: 100%"
      v-bind="propsValue"
      :placeholder="rangePlaceholder"
      :value="rangeValue || defaultValue"
      @change="onChange"
      :disabled-date="disabledDate"
    ></a-range-picker>
  </div>
</template>

<script>
import moment from "moment"
import { mapState } from "vuex"
export default {
	data() {
		return {
			value: null,
			rangeValue: null,
		}
	},
	computed: {
		...mapState(["lang", "locale"]),
		rangePlaceholder() {
			const startTime = `${this.placeholder} ${this.$t("开始时间")}`
			const endTime = `${this.placeholder} ${this.$t("结束时间")}`
			return this.placeholder ? [this.$t(`${startTime}`), this.$t(`${endTime}`)] : null
		},
		singleplaceholder() {
			return this.placeholder ? this.placeholder : this.$t("选择时间")
		},
		propsValue() {
			return {
				id: this.id,
				"show-time": {
					"default-value": this.singleDatePicker ? moment("00:00:00", "HH:mm:ss") : [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")],
				},
				locale: this.locale?.Calendar,
				ranges: this.ranges,
				// format: this.format,
				format: "YYYY-MM-DD HH:mm:ss",
			}
		},
		defaultValue() {
			let defaultValue = null
			if (this.singleDatePicker) {
				defaultValue = this.startTime ? moment(this.startTime) : null
			} else {
				if (this.startTime && this.endTime) {
					defaultValue = [moment(this.startTime), moment(this.endTime)]
				}
			}
			return defaultValue
		},
		ranges() {
			const ranges = {}
			ranges[this.$t("今日")] = [moment().startOf("days"), moment("23:59:59", "HH:mm:ss")]
			ranges[this.$t("昨日")] = [moment().subtract(1, "days").startOf("days"), moment().subtract(1, "days").endOf("days")]
			ranges[this.$t("最近7天")] = [moment().subtract(7, "days"), moment()]
			ranges[this.$t("最近30天")] = [moment().subtract(30, "days"), moment()]
			ranges[this.$t("本月")] = [moment().startOf("month"), moment().endOf("month")]
			ranges[this.$t("上个月")] = [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
			return ranges
		},
		format() {
			const format = this.lang === "zh" ? "YYYY-MM-DD HH:mm:ss" : this.locale?.Calendar.lang.dateTimeFormat
			return format
		},
	},
	props: ["startTime", "endTime", "placeholder", "id", "needhms", "singleDatePicker"],
	methods: {
		moment,
		onChange(m_value, value) {
			this.value = m_value
			this.rangeValue = m_value
			const data = this.singleDatePicker
				? value
				: {
						start_time: value[0],
						end_time: value[1],
					}
			this.$emit("get_date", data)
			this.$nextTick(() => {
				this.$emit("changeList", "speed")
			})
		},
		clear() {
			this.value = null
			this.rangeValue = null
		},
		disabledDate(current) {
			return current && current < moment().subtract(3, "months")
		},
	},
	watch: {
		startTime(val) {
			if (!val) {
				this.clear()
			}
		},
	},
}
</script>
<style scoped lang="scss">
.date-input {
  width: 100%;
  background-color: $white;
}
</style>
