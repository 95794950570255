<template>
  <div style="width: 100%">
    <a-timeline class="formContentGoods">
      <a-timeline-item v-for="(item, index) in list" :key="index">
        <div
          class="action-text"
          v-if="item.type == 'COMMENT'"
          v-html="item.content + '【' + $t(`添加备注`) + '】'"
          :style="{ color: item.color }"
        ></div>
        <div
          class="action-text"
          v-else
          v-html="showContent(item)"
          :style="{ color: item.color }"
        ></div>
        <div style="color: #999999">
          {{ item.createUser || item.creator?.name }} {{ item.createTime || item.actionTime }}
        </div>
      </a-timeline-item>
    </a-timeline>
  </div>
</template>

<script>
export default {
	props: ["list"],
	methods: {
		showContent(item) {
			const str = item.displayContent || item.content || ""
			return str.replaceAll("\n", "<br />")
		},
	},
}
</script>

<style scoped lang="scss">
.formContentGoods {
  /* padding: 10px 15px; */
  margin-bottom: 16px;
  background-color: white;
  color: #333333;
  font-size: 14px;
}
.action-text {
  word-wrap: break-word;
  word-break: break-all;
  display: inline-block;
}
</style>
