<template>
  <div class="content-wrapper">
    <section class="content suppManage content_yjlu">
      <div class="form-content">
        <a-button type="primary" @click="toCreateBatchCheckOrderJob" style="margin-bottom: 10px">
          <i class="iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t(`menu.createBatchCheckOrderJob`) }}
        </a-button>

        <h2 class="box-title">
          <i class="fa fa-navicon"></i>
          {{ $t(`批量审单`) }}
        </h2>

        <a-row style="margin-bottom: 1%" :gutter="16">
          <a-col :span="7">
            <!-- 任务id -->
            <a-input
              allowClear
              :placeholder="$t(`任务id`)"
              type="text"
              v-model.trim="searchData.taskJobId"
            />
          </a-col>
          <a-col :span="7">
            <!-- 任务类型 -->
            <a-select
              allowClear
              style="width: 100%"
              v-model="searchData.taskType"
              :placeholder="$t(`任务类型`)"
            >
              <!-- <a-select-option value disabled
                    >--{{ $t(`任务类型`) }}--</a-select-option
                  > -->
              <a-select-option v-for="item in constants.TASK_TYPE_ARRAY" :key="item" :value="item">
                {{ $t(item) }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="7">
            <!-- 任务状态 -->
            <a-select
              allowClear
              style="width: 100%"
              v-model="searchData.scheduleStatus"
              :placeholder="$t(`任务状态`)"
            >
              <a-select-option v-for="(item, index) in selectArray" :key="item" :value="index">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-col>
        </a-row>

        <a-row style="margin-bottom: 1%" :gutter="16">
          <a-col :span="7">
            <!-- 时间 -->
            <date-range-picker
              id="orderTime"
              :needhms="true"
              :placeholder="$t(`任务时间`)"
              @get_date="getOrderTime"
              :start-time="searchData.startCreateTime"
              :end-time="searchData.endCreateTime"
            ></date-range-picker>
          </a-col>
          <!-- 按钮 -->
          <template v-if="priv('f_batch_check_order_job_list')">
            <a-col :span="7">
              <a-button type="primary" @click="getTableList(true)">
                <i class="iconfont icon-search" style="margin-right: 5px"></i>
                {{ $t(`查询`) }}
              </a-button>
              <a-button @click="reset">
                <i class="iconfont icon-synchronize" style="margin-right: 5px"></i>
                {{ $t(`重置`) }}
              </a-button>
            </a-col>
          </template>
        </a-row>

        <div class="form-horizontal tableContent bgFFF" style="padding: 0px">
          <a-table
            :rowKey="(record, index) => index"
            :columns="columns"
            :dataSource="tableList"
            :pagination="showPage"
            :scroll="{ x: 1200 }"
          >
            <!-- 任务ID -->
            <template slot="TaskId" slot-scope="text, record">
              {{ record.taskJobId }}
            </template>

            <!-- 业务组 -->
            <template slot="BusinessGroup" slot-scope="text, record">
              {{ record.party.partyName }}
            </template>

            <!-- 任务类型 -->
            <template slot="TaskType" slot-scope="text, record">
              {{ $t(record.taskType) }}
            </template>

            <!-- 任务时间 -->
            <template slot="TaskTime" slot-scope="text, record">
              {{ record.createTime }}
            </template>

            <!-- 订单总数 -->
            <template slot="TotalNumberOfOrders" slot-scope="text, record">
              {{ record.totalNum }}
            </template>

            <!-- 未执行 -->
            <template slot="NotPerformed" slot-scope="text, record">
              {{ record.initNum }}
            </template>

            <!-- 已完成 -->
            <template slot="Success" slot-scope="text, record">
              {{ record.successNum }}
            </template>

            <!-- 失败 -->
            <template slot="failure" slot-scope="text, record">
              {{ record.failNum }}
            </template>

            <!-- 创建者 -->
            <template slot="Operator" slot-scope="text, record">
              {{ record.creator.name }}
            </template>
            <!-- 操作 -->
            <template slot="Action" slot-scope="text, record">
              <a @click="showJobDetailFun(record.taskJobId, record.taskType)" class="mr4">
                {{ $t(`任务明细`) }}
              </a>
              <a v-if="record.totalNum > 0" @click="onExport(record)" class="mr4">
                {{ $t(`导出`) }}
              </a>
              <a
                v-if="record.scheduleStatus == 'RUNNING'"
                @click="stopJob(record.taskJobId, index)"
              >
                {{ $t(`停止`) }}
              </a>
            </template>
          </a-table>
          <div class="table-footer clearfix">
            <pagination
              :offset="searchData.offset"
              :length="searchData.length"
              :total-count="totalCount"
              @paginate="paginate"
            ></pagination>
          </div>
        </div>
      </div>
    </section>
    <!-- 开始弹出框-查看 -->
    <a-modal
      width="900px"
      v-model="showJobDetail"
      :title="$t(jobDetail.taskType)"
      :showCancel="false"
      @ok="handleOk"
    >
      <div v-if="showJobDetail" class="contentBox">
        <div class="contentInfo">
          <div class="infoTitle">
            <i class="leftLine"></i>
            {{ $t('任务信息') }}
          </div>
          <a-row>
            <a-col class="leftCol" :span="leftCol">{{ $t(`业务组`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">
              {{ jobDetail.party ? jobDetail.party.partyName : '' }}
            </a-col>
            <a-col class="leftCol" :span="leftCol">{{ $t(`创建者`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">{{ jobDetail.creator?.name }}</a-col>
          </a-row>
          <a-row>
            <a-col class="leftCol" :span="leftCol">{{ $t(`任务时间`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">{{ jobDetail.createTime }}</a-col>
          </a-row>
          <a-row>
            <a-col class="leftCol" :span="leftCol">{{ $t(`备注`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">{{ jobDetail.actionNote }}</a-col>
          </a-row>
        </div>
        <div class="contentInfo">
          <div class="infoTitle">
            <i class="leftLine"></i>
            {{ $t('订单筛选条件') }}
          </div>
          <a-row>
            <a-col class="leftCol" :span="leftCol">{{ $t(`订单号`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">
              <div class="text-link" @click="copyHandle(jobDetail.orderSns)">
                {{ $t('复制订单号') }}
              </div>
              <span v-if="jobDetail.orderSns?.length > 0">
                <span v-for="(order, orderIndex) in jobDetail.orderSns" :key="order">
                  <span v-if="orderIndex > 0">,</span>
                  {{ order }}
                </span>
              </span>
              <span v-else>{{ $t(`无`) }}</span>
            </a-col>
            <a-col class="leftCol" :span="leftCol">{{ $t(`店铺`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">
              <span v-if="jobDetail.shops?.length > 0">
                <span v-for="(shop, shopIndex) in jobDetail.shops" :key="shopIndex">
                  <span v-if="shopIndex > 0">,</span>
                  {{ shop.shopName }}
                </span>
              </span>
              <span v-else>{{ $t(`无`) }}</span>
            </a-col>
          </a-row>
          <a-row>
            <a-col class="leftCol" :span="leftCol">{{ $t(`原始仓库`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">
              <span v-if="jobDetail.warehouses?.length > 0">
                <span
                  v-for="(warehouse, warehouseIndex) in jobDetail.warehouses"
                  :key="warehouseIndex"
                >
                  <span v-if="warehouseIndex > 0">,</span>
                  {{ warehouse.warehouseName }}
                </span>
              </span>
              <span v-else>{{ $t(`无`) }}</span>
            </a-col>
            <a-col class="leftCol" :span="leftCol">{{ $t(`订单省份`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">
              <span v-if="jobDetail.provinces?.length > 0">
                <span
                  v-for="(province, provinceIndex) in jobDetail.provinces"
                  :key="province.regionName"
                >
                  <span v-if="provinceIndex > 0">,</span>
                  {{ province.regionName }}
                </span>
              </span>
              <span v-else>{{ $t(`无`) }}</span>
            </a-col>
          </a-row>
          <a-row>
            <a-col class="leftCol" :span="leftCol">{{ $t(`商家编码`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">
              <span v-if="jobDetail.goodsGroups?.length > 0 || jobDetail.skus?.length > 0">
                <span
                  v-for="goodsGroups in jobDetail.goodsGroups"
                  :key="goodsGroups.goodsGroupCode"
                >
                  {{ goodsGroups.goodsGroupCode }},
                </span>
                <span v-for="(sku, skuIndex) in jobDetail.skus" :key="sku.skuCode">
                  <span v-if="skuIndex > 0">,</span>
                  {{ sku.skuCode }}
                </span>
              </span>
              <span v-else>{{ $t(`无`) }}</span>
            </a-col>
            <a-col class="leftCol" :span="leftCol">{{ $t(`最低金额`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">
              {{ jobDetail.lowestTotalFee || $t(`无`) }}
            </a-col>
          </a-row>
          <a-row>
            <a-col class="leftCol" :span="leftCol">{{ $t(`订单时间`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">
              <span v-if="jobDetail.startOrderTime">
                {{ jobDetail.startOrderTime }}
                <br />
                {{ jobDetail.endOrderTime }}
              </span>
              <span v-else>{{ $t(`无`) }}</span>
            </a-col>
            <a-col class="leftCol" :span="leftCol">{{ $t(`付款时间`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">
              <span v-if="jobDetail.startPayTime">
                {{ jobDetail.startPayTime }}
                <br />
                {{ jobDetail.endPayTime }}
              </span>
              <span v-else>{{ $t(`无`) }}</span>
            </a-col>
          </a-row>
          <a-row>
            <a-col class="leftCol" :span="leftCol">{{ $t('平台下单时间') }}：</a-col>
            <a-col class="rightCol" :span="rightCol">
              <span v-if="jobDetail.startPlatformOrderTime">
                {{ jobDetail.startPlatformOrderTime }}
                <br />
                {{ jobDetail.endPlatformOrderTime }}
              </span>
              <span v-else>{{ $t(`无`) }}</span>
            </a-col>
          </a-row>
          <a-row>
            <a-col class="leftCol" :span="leftCol">{{ $t(`原始快递`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">
              <span v-if="jobDetail.shippings?.length > 0">
                <span v-for="(shipping, shippingIndex) in jobDetail.shippings" :key="shippingIndex">
                  <span v-if="shippingIndex > 0">,</span>
                  {{ shipping.shippingName }}
                </span>
              </span>
              <span v-else>{{ $t(`无`) }}</span>
            </a-col>
            <a-col class="leftCol" :span="leftCol">{{ $t(`类型`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">
              <span>{{ isGoods[jobDetail.gift] || $t(`全部`) }}</span>
            </a-col>
          </a-row>
          <a-row>
            <a-col class="leftCol" :span="leftCol">{{ $t(`发货超时`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">
              <span>{{ deliveryTimedOutArray[jobDetail.deliveryTimedOut] || $t(`全部`) }}</span>
            </a-col>
            <a-col class="leftCol" :span="leftCol">{{ $t(`订单状态`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">
              {{ statusTypeArray[jobDetail.statusType] || $t(`全部`) }}
            </a-col>
          </a-row>
        </div>
        <div class="contentInfo">
          <div class="infoTitle">
            <i class="leftLine"></i>
            {{ $t('订单执行条件') }}
          </div>
          <a-row>
            <a-col class="leftCol" :span="leftCol">{{ $t(`执行数量`) }}：</a-col>
            <a-col class="rightCol" :span="rightCol">{{ jobDetail.totalNum || $t(`无`) }}</a-col>
            <div v-if="jobDetail.taskType == 'BATCH_CHANGE_WAREHOUSE'">
              <a-col class="leftCol" :span="leftCol">{{ $t(`指定仓库`) }}：</a-col>
              <a-col class="rightCol" :span="rightCol">
                {{ jobDetail.newWarehouse ? jobDetail.newWarehouse.warehouseName : $t(`无`) }}
              </a-col>
            </div>
            <div
              v-if="
                jobDetail.taskType == 'BATCH_CHANGE_SHIPPING' ||
                jobDetail.taskType == 'BATCH_CHANGE_WAREHOUSE'
              "
            >
              <a-col class="leftCol" :span="leftCol">{{ $t(`指定快递`) }}：</a-col>
              <a-col class="rightCol" :span="rightCol">
                {{ jobDetail.newShipping ? jobDetail.newShipping.shippingName : $t(`无`) }}
              </a-col>
            </div>
            <div v-if="jobDetail.taskType == 'BATCH_CHANGE_BOOK'">
              <a-col class="leftCol" :span="leftCol">{{ $t(`激活时间`) }}：</a-col>
              <a-col class="rightCol" :span="rightCol">
                {{ jobDetail.bookActivateTime ? jobDetail.bookActivateTime : $t(`无`) }}
              </a-col>
            </div>
            <div
              v-if="
                jobDetail.taskType == 'BATCH_CHANGE_BOOK' ||
                jobDetail.taskType == 'BATCH_CHANGE_WAREHOUSE' ||
                jobDetail.taskType == 'BATCH_CHANGE_SHIPPING'
              "
            >
              <a-col class="leftCol" :span="leftCol">{{ $t(`允许驳回`) }}：</a-col>
              <a-col class="rightCol" :span="rightCol">
                {{ jobDetail.reject ? yesOrNo[jobDetail.reject] : $t(`无`) }}
              </a-col>
            </div>
            <div v-if="jobDetail.taskType == 'BATCH_CHANGE_WAREHOUSE'">
              <a-col class="leftCol" :span="leftCol">{{ $t(`转入判断库存`) }}：</a-col>
              <a-col class="rightCol" :span="rightCol">
                {{ jobDetail.inventory ? yesOrNo[jobDetail.inventory] : $t(`无`) }}
              </a-col>
            </div>
            <div v-if="jobDetail.taskType == 'BATCH_CHANGE_BOOK'">
              <a-col class="leftCol" :span="leftCol">{{ $t(`释放库存`) }}：</a-col>
              <a-col class="rightCol" :span="rightCol">
                {{ jobDetail.releaseInventory ? yesOrNo[jobDetail.releaseInventory] : $t(`无`) }}
              </a-col>
            </div>
          </a-row>
        </div>
      </div>
    </a-modal>
    <!-- 结束弹出框-查看 -->
  </div>
</template>

<script>
import debounce from "@/common/debounce"
import { baseURL, orderURL } from "@/config/config"
import { copy } from "@common"
import dateRangePicker from "@component/dateRangePicker"
import pagination from "@component/pagination"
import api from "@service/api"
import http from "@service/axios"

export default {
	name: "batchOrderReview",
	components: {
		pagination,
		dateRangePicker,
	},
	data() {
		return {
			leftCol: 5,
			rightCol: 7,
			showPage: false,
			showJobDetail: false, //查看-显示任务详情
			searchData: {
				//任务查询
				taskJobId: "",
				startCreateTime: "",
				endCreateTime: "",
				taskType: undefined,
				scheduleStatus: undefined,
				offset: 0,
				length: 10,
			},
			jobDetail: {},
			selectArray: {
				READY: this.$t("待执行"),
				RUNNING: this.$t("执行中"),
				SUCCESS: this.$t("已完成"),
				STOPPED: this.$t("已停止"),
			},
			scheduleStatusArray: {
				READY: this.$t("待执行"),
				RUNNING: this.$t("执行中"),
				SUCCESS: this.$t("已完成"),
				STOPPED: this.$t("已停止"),
				STOPPING: this.$t("STOPPING"),
				ERROR: this.$t("错误"),
				RETRY: this.$t("RETRY"),
			},
			yesOrNo: {
				true: this.$t("是"),
				false: this.$t("否"),
			},
			tableList: [], //保存查询结果
			totalCount: "", //查询记录条数

			isGoods: {
				true: this.$t("商品"),
				false: this.$t("赠品"),
			},
			deliveryTimedOutArray: {
				24: `24${this.$t("小时")}${this.$t("到")}48${this.$t("小时")}`,
				48: `48${this.$t("小时")}${this.$t("到")}72${this.$t("小时")}`,
				72: `72${this.$t("小时")}${this.$t("以上")}`,
			},
			statusTypeArray: {
				canCheck: this.$t("可审核"),
				shortage: this.$t("缺货"),
				waitFinanceCheck: this.$t("待财审"),
				book: this.$t("预订单"),
				applyReturn: this.$t("申请退款"),
				reject: this.$t("已拒收"),
				pushFail: this.$t("推送失败"),
				pushInit: this.$t("待推送"),
				pushSuccess: this.$t("已推送"),
			},
			exportCsv: "",
		}
	},
	mounted() {
		let path = api.inspectionTaskOrderDetail
		path = path.replace(`${baseURL}oms_api/`, orderURL)
		this.exportCsv = path
	},
	activated() {
		this.getTableList()
	},
	methods: {
		reset() {
			for (const item in this.searchData) {
				this.searchData[item] = ""
			}
			this.searchData.offset = 0
			this.searchData.length = 10
			this.getTableList(true)
		},
		toCreateBatchCheckOrderJob() {
			this.$router.push("createBatchCheckOrderJob")
		},
		handleOk() {
			this.showJobDetail = false
		},
		getOrderTime(date) {
			this.searchData.startCreateTime = date.start_time
			this.searchData.endCreateTime = date.end_time
		},
		paginate(data) {
			this.searchData.offset = data.offset
			this.searchData.length = data.length
			this.getTableList()
		},
		getTableList(type) {
			if (type) {
				this.searchData.offset = 0
			}
			this.tableList = []
			this.totalCount = 0
			http({
				url: api.inspectionTaskList,
				type: "post",
				hasLoading: true,
				data: { data: this.searchData },
				success: (res) => {
					if (res.data.totalCount === 0) {
						return
					}
					this.tableList = res.data.content
					this.totalCount = res.data.totalCount
				},
			})
		},
		stopJob(taskJobId, index) {
			this.$confirm({
				title: this.$t("您确定停止该任务吗"),
				onOk: () => {
					http({
						url: api.inspectionTaskStop, //searchData
						type: "post",
						data: { data: { taskJobId: taskJobId } }, //
						success: (res) => {
							if (res.success) {
								this.tableList[index].scheduleStatus = res.data.scheduleStatus
								if (res.data.scheduleStatus === "STOPPED" || res.data.scheduleStatus === "STOPPING") {
									this.$success("任务停止成功!")
								} else {
									this.$error("任务停止失败！", { icon: 5, time: 2000 })
								}
							} else {
								this.$error("任务停止失败！", { icon: 5, time: 2000 })
							}
						},
					})
				},
			})
		},
		showJobDetailFun(taskJobId, taskType) {
			http({
				url: api.inspectionTaskDetail,
				data: {
					data: { taskJobId: taskJobId },
				},
				type: "post",
				success: (res) => {
					if (res.success) {
						this.jobDetail = res.data
						this.jobDetail.taskType = taskType
						this.showJobDetail = true
					} else {
						this.$error("查询失败", { icon: 2 })
					}
				},
			})
		},
		copyHandle(arr) {
			const str = arr.join(",")
			copy(str)
		},
		onExport: debounce(async function (record) {
			const res = await http({
				url: api.getDownloadKey,
				data: {},
			})
			const url = `${this.exportCsv}/${record.taskJobId}?auth-download-key=${res.result}`
			window.open(url)
		}),
	},
	computed: {
		columns() {
			return [
				{
					title: this.$t("任务id"),
					key: "TaskId",
					width: 80,
					scopedSlots: { customRender: "TaskId" },
				},
				{
					title: this.$t("业务组"),
					key: "BusinessGroup",
					scopedSlots: { customRender: "BusinessGroup" },
				},
				{
					title: this.$t("任务类型"),
					key: "TaskType",
					scopedSlots: { customRender: "TaskType" },
				},
				{
					title: this.$t("任务时间"),
					key: "TaskTime",
					width: 200,
					scopedSlots: { customRender: "TaskTime" },
				},
				{
					title: this.$t("订单总数"),
					key: "TotalNumberOfOrders",
					width: 100,
					scopedSlots: { customRender: "TotalNumberOfOrders" },
				},
				{
					title: this.$t("未执行"),
					key: "NotPerformed",
					width: 90,
					scopedSlots: { customRender: "NotPerformed" },
				},
				{
					title: this.$t("已完成"),
					key: "Success",
					width: 90,

					scopedSlots: { customRender: "Success" },
				},
				{
					title: this.$t("失败"),
					key: "failure",
					width: 90,

					scopedSlots: { customRender: "failure" },
				},
				{
					title: this.$t("创建者"),
					key: "Operator",
					width: 80,

					scopedSlots: { customRender: "Operator" },
				},
				{
					title: this.$t("备注"),
					dataIndex: "actionNote",
				},
				{
					title: this.$t("操作"),
					key: "Action",
					fixed: "right",
					scopedSlots: { customRender: "Action" },
				},
			]
		},
	},
}
</script>

<style lang="scss" scoped>
.pointer,
a {
  cursor: pointer;
}
.myPlaceholder input::-webkit-input-placeholder {
  /*WebKit browsers*/
  color: red;
}
input[type='radio'] {
  vertical-align: -2px;
  _vertical-align: -1px;
  margin-right: 2px;
}
.RUNNING,
.RETRY,
.STOPPING {
  color: #5690ed;
} /*执行中*/
.SUCCESS,
.ERROR {
  color: #4cb711;
} /*成功*/
.STOPPED {
  color: #e80000;
} /*失败*/
.myRowRight .col-sm-10 {
  padding-right: 5px;
}
td,
th {
  text-align: left !important;
}
.statusTd {
  text-align: left;
  padding-left: 25px;
}
.required:after {
  content: '*';
  color: #f00;
  margin: 0 0 0 3px;
}
.requiredNo:after {
  content: '*';
  color: $white;
  margin: 0 0 0 3px;
}
.fa-minus-square-o {
  color: red;
}
.form-inline .form-control {
  display: inline-block;
  width: 175px;
  vertical-align: middle;
}
.form-inline .control-label {
  margin-top: 6px;
}

.infoTitle {
  font-size: 16px !important;
  margin-bottom: 8px !important;
}
.leftCol {
  text-align: right;
  // margin: 4px 0;
}
.rightCol {
  // margin: 4px 0;
  color: #000000b8;
}
.contentInfo {
  margin-bottom: 12px;
}
.contentBox {
  font-size: 13px;
  color: #333333;
  line-height: 20px;
}
</style>
