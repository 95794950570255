<template>
  <div class="create-batch-check-order-job">
    <section class="content fixContent" :style="{ height: scrollHeight }">
      <a-form-model ref="ruleForm" :model="formData">
        <div class="infoTitle">
          <i class="leftLine"></i>
          {{ $t('任务类型') }}
        </div>
        <a-form-model-item v-bind="tempCol" :label="$t(`任务类型`)" required>
          <a-row :gutter="16">
            <a-col :span="8">
              <a-select
                v-model="formData.taskType"
                :placeholder="$t(`选择任务类型`)"
                @change="changeTaskType"
              >
                <a-select-option
                  v-for="item in constants.TASK_TYPE_ARRAY"
                  :key="item"
                  :value="item"
                >
                  {{ $t(item) }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>
        </a-form-model-item>
        <div class="infoTitle">
          <i class="leftLine"></i>
          {{ $t('订单筛选条件') }}
        </div>
        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-model-item :label="$t(`店铺`)">
              <SelectMutiShop ref="selectShopRef" @change="selectShopList"></SelectMutiShop>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item :label="$t(`订单状态`)" :required="userInputOrderSns ? false : true">
              <a-select style="width: 100%" v-model="formData.statusType">
                <a-select-option
                  v-for="(item, index) in taskTypeStatusArray[formData.taskType]"
                  :key="index"
                  :value="index"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item :label="$t(`原始仓库`)" :required="userInputOrderSns ? false : true">
              <SearchWarehouse
                mode="multiple"
                :shopList="formData.shopList"
                @change="selectOldWarehouse"
              ></SearchWarehouse>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-model-item :label="$t(`OMS订单号`)">
              <a-input
                v-model="userInputOrderSns"
                :placeholder="$t(`支持输入多个,英文逗号或空格分隔`)"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item :label="$t(`订单时间`)">
              <DateRangePicker
                id="orderTime"
                :needhms="true"
                @get_date="getOrderTime"
                :placeholder="$t(`订单时间`)"
                :start-time="formData.startOrderTime"
                :end-time="formData.endOrderTime"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item :label="$t(`平台下单时间`)">
              <DateRangePicker
                id="orderTime"
                :needhms="true"
                @get_date="getPlatformOrderTime"
                :placeholder="$t(`平台下单时间`)"
                :start-time="formData.startPlatformOrderTime"
                :end-time="formData.endPlatformOrderTime"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item :label="$t(`付款时间`)">
              <DateRangePicker
                ref="payTime"
                :needhms="true"
                @get_date="getPayTime"
                :start-time="formData.startPayTime"
                :end-time="formData.endPayTime"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item :label="$t(`单品`)">
              <a-select style="width: 100%" v-model="formData.oneSku">
                <a-select-option :value="1">{{ $t(`是`) }}</a-select-option>
                <a-select-option :value="0">{{ $t(`否`) }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-model-item :label="$t(`原始快递`)">
              <SearchShipping
                mode="multiple"
                :placeholder="$t(`快递方式搜索`)"
                :code.sync="formData.shippingCodeList"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item :label="$t(`订单省份`)">
              <SelectRegion
                :placeholder="$t(`省`)"
                :defaultRegionId="formData.provinceIds"
                :parentId="parentId"
                :regionType="1"
                style="width: 100%"
                @selected="changeProvince"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item :label="$t(`最晚发货时间`)">
              <DateRangePicker
                ref="deliveryTime"
                :needhms="true"
                @get_date="getDeliveryTime"
                :start-time="formData.startDeliveryTime"
                :end-time="formData.endDeliveryTime"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="8">
            <a-form-model-item :label="$t(`商品类型`)">
              <!-- <a-radio-group name="radioGroup" v-model="formData.isGift">
                <a-radio value="">{{ $t(`全部`) }}</a-radio>
                <a-radio value="0">{{ $t(`商品`) }}</a-radio>
                <a-radio value="1">{{ $t(`赠品`) }}</a-radio>
              </a-radio-group> -->
              <a-select style="width: 100%" v-model="formData.isGift">
                <a-select-option value="">
                  {{ $t(`全部`) }}
                </a-select-option>
                <a-select-option value="0">{{ $t(`商品`) }}</a-select-option>
                <a-select-option value="1">{{ $t(`赠品`) }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item :label="$t(`商品名称/商家编码`)">
              <div class="flex search-group">
                <SearchSku
                  ref="s_goodsOrGroup"
                  style="width: 100%"
                  @selected="searchGoodsOrGroup"
                />
                <a class="add" @click="addGoodsOrGroup">+{{ $t(`添加`) }}</a>
              </div>
              <div class="flex goods-list">
                <div
                  class="goods-item"
                  v-for="(item, index) in selectedGoodsOrGroupArray"
                  :key="index"
                >
                  {{ item.skuName }}&ensp;
                  <a-icon
                    type="minus-circle"
                    class="text-danger"
                    @click="selectedGoodsOrGroupArray.splice(index, 1)"
                  />
                </div>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <a-form-model>
        <a-row>
          <a-col :span="12">
            <a-form-model-item
              :label="$t(`订单数量`)"
              style="margin: 10px 0"
              v-if="formData.taskType"
            >
              <label>
                <span style="font-size: 20px; font-weight: 900; margin: 0 10px">
                  {{ orderCount.successNum }}
                </span>
              </label>
              <a-button type="primary" @click="getOrderCount">
                {{ $t(`查询订单数量`) }}
              </a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="orderCount.failOrderSns.length > 0">
          <a-col v-if="formData.taskType">
            <p>
              {{ $t(`不满足条件的订单号`) }}：
              <a-button type="primary" size="small" @click="cloneOrder(orderCount.failOrderSns)">
                {{ $t(`复制订单号`) }}
              </a-button>
            </p>
            <p style="width: 100%; word-wrap: break-word">
              {{ orderCount.failOrderSns.join(',') }}
            </p>
          </a-col>
        </a-row>

        <div class="infoTitle">
          <i class="leftLine"></i>
          {{ $t('改单执行条件') }}
        </div>

        <a-form-model-item
          v-bind="tempCol"
          :wrapper-col="{ span: 18 }"
          v-show="formData.taskType === 'BATCH_CHECK'"
          required
        >
          <template slot="label">
            <a-popover>
              <template slot="content">
                <p>{{ $t(`正常审核:系统会审核掉所有无异常标签符合条件的订单。`) }}</p>
                <p>
                  {{
                    $t(
                      `强制审核:系统会清除订单可清除的所有异常标签后(不包括缺货和待财审)审核订单。`
                    )
                  }}
                </p>
                <p>
                  {{ $t(`按照审单配置审核:系统会审核掉所有符合审核配置条件且无异常标签的订单。`) }}
                </p>
              </template>
              <span>{{ $t(`审核方式`) }}</span>
              <i class="fa-regular fa-circle-question"></i>
            </a-popover>
          </template>
          <a-radio-group name="checkType" v-model="formData.checkType">
            <a-radio value="NORMAL_CHECK">{{ $t(`正常审核`) }}</a-radio>
            <a-radio value="FORCE_CHECK">{{ $t(`强制审核`) }}</a-radio>
            <a-radio value="CHECK_BY_CONFIG">{{ $t(`按照审核配置审核`) }}</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item v-bind="tempCol" :label="$t(`执行数量`)" required>
          <a-input v-model="formData.totalNum" :placeholder="$t(`执行订单数量`)"></a-input>
        </a-form-model-item>
        <!-- 批量转仓 -->
        <template v-if="formData.taskType === 'BATCH_CHANGE_WAREHOUSE'">
          <a-form-model-item v-bind="tempCol" :label="$t(`转仓方式`)" :wrapper-col="{ span: 18 }">
            <a-radio-group name="changeWarehouse" v-model="formData.changeWarehouseType">
              <a-radio value="DESIGNATE_WAREHOUSE">{{ $t(`转入指定仓库`) }}</a-radio>
              <a-radio value="STRATEGY_WAREHOUSE">{{ $t(`按店铺转仓顺序自动转仓`) }}</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            v-bind="tempCol"
            :label="$t(`转入仓库`)"
            required
            v-show="formData.changeWarehouseType === 'DESIGNATE_WAREHOUSE'"
          >
            <SearchWarehouse
              ref="selectWarehouseRef"
              :shopList="formData.shopList"
              :placeholder="$t(`转入仓库得是订单所属店铺的可用仓库(店铺选仓)`)"
              @selected="selectWarehouse"
            ></SearchWarehouse>
          </a-form-model-item>
          <a-form-model-item v-bind="tempCol" :label="$t(`选择快递`)" :wrapper-col="{ span: 18 }">
            <a-radio-group name="changeShipping" v-model="formData.chooseShippingType">
              <a-radio
                v-if="formData.changeWarehouseType == 'DESIGNATE_WAREHOUSE'"
                value="DESIGNATE_SHIIPING"
              >
                {{ $t(`指定快递`) }}
              </a-radio>
              <a-radio value="BEST_SHIIPING">
                {{ $t(`最优快递`) }}
                <a-popover>
                  <template slot="content">
                    <p>{{ $t(`选择预估快递费用最低的快递`) }}</p>
                  </template>
                  <i class="fa-regular fa-circle-question"></i>
                </a-popover>
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            v-bind="tempCol"
            :label="$t(`指定快递`)"
            required
            v-show="formData.chooseShippingType === 'DESIGNATE_SHIIPING'"
          >
            <SearchShipping
              :placeholder="$t(`不指定走最优快递`)"
              :code.sync="formData.newShippingCode"
            />
          </a-form-model-item>
        </template>
        <!-- 批量转快递 -->
        <template v-if="formData.taskType === 'BATCH_CHANGE_SHIPPING'">
          <a-form-model-item v-bind="tempCol" :label="$t(`指定快递`)" required>
            <SearchShipping :placeholder="$t(`请选择快递`)" :code.sync="formData.newShippingCode" />
          </a-form-model-item>
        </template>
        <a-form-model-item v-bind="tempCol" :label="$t(`允许驳回`)" required v-if="showReject">
          <a-radio-group name="reject" v-model="formData.reject">
            <a-radio :value="true">{{ $t(`是`) }}</a-radio>
            <a-radio :value="false">{{ $t(`否`) }}</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <template v-if="formData.taskType === 'BATCH_CHANGE_BOOK'">
          <a-form-model-item v-bind="tempCol" :label="$t(`激活时间`)" required>
            <DateRangePicker
              id="activationTime"
              :singleDatePicker="true"
              :needhms="true"
              @get_date="setActivationTime"
              :start-time="formData.bookActivateTime"
            />
          </a-form-model-item>
        </template>
        <template v-if="formData.taskType === 'BATCH_SUSPEND'">
          <a-form-model-item v-bind="tempCol" :label="$t(`恢复待审核时间`)">
            <DateRangePicker
              :singleDatePicker="true"
              :needhms="true"
              @get_date="
                (date) => {
                  formData.suspendRevertTime = date
                }
              "
              :start-time="formData.suspendRevertTime"
            />
          </a-form-model-item>
        </template>
        <template
          v-if="
            formData.taskType === 'BATCH_CHANGE_WAREHOUSE' &&
            formData.changeWarehouseType == 'DESIGNATE_WAREHOUSE'
          "
        >
          <a-form-model-item v-bind="tempCol" :label="$t(`转入判断库存`)" required>
            <a-radio-group name="inventory" v-model="formData.inventory">
              <a-radio :value="true">{{ $t(`是`) }}</a-radio>
              <a-radio :value="false">{{ $t(`否`) }}</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </template>
        <template v-if="formData.taskType === 'BATCH_CHANGE_BOOK'">
          <a-form-model-item v-bind="tempCol" :label="$t(`释放库存`)" required>
            <a-radio-group name="releaseInventory" v-model="formData.releaseInventory">
              <a-radio :value="true">{{ $t(`是`) }}</a-radio>
              <a-radio :value="false">{{ $t(`否`) }}</a-radio>
            </a-radio-group>
          </a-form-model-item>
        </template>
        <!-- 批量增加赠品 -->
        <template v-if="formData.taskType === 'BATCH_ADD_GIFT'">
          <a-form-model-item
            v-bind="tempCol"
            :label="$t(`指定赠品`)"
            :wrapper-col="{ span: 18 }"
            required
          >
            <SearchSku @selected="selectGoodsChangeSku" />
          </a-form-model-item>
          <a-form-model-item v-bind="tempCol" :label="$t(`赠品数量`)" required>
            <a-input v-model.number="formData.itemList[0].num"></a-input>
          </a-form-model-item>
        </template>
        <!-- 批量删除赠品 -->
        <template v-if="formData.taskType === 'BATCH_DELETE_GIFT'">
          <a-form-model-item
            v-bind="tempCol"
            :label="$t(`指定赠品`)"
            :wrapper-col="{ span: 18 }"
            required
          >
            <SearchSku @selected="selectGoodsChangeSku" />
          </a-form-model-item>
        </template>
        <!-- 批量删除、添加商品 -->
        <template v-if="formData.taskType === 'BATCH_CHANGE_GOODS'">
          <a-form-model-item
            v-bind="tempCol"
            :label="$t(`删除商品`)"
            :wrapper-col="{ span: 18 }"
            required
          >
            <SearchSku @selected="selectDelGoods" />
          </a-form-model-item>
          <a-form-model-item
            v-bind="tempCol"
            :label="$t(`添加商品`)"
            :wrapper-col="{ span: 18 }"
            required
          >
            <SearchSku @selected="selectAddGoods" />
          </a-form-model-item>
        </template>
        <a-form-model-item v-bind="tempCol" :label="actionNoteLabel">
          <a-input v-model="formData.actionNote"></a-input>
        </a-form-model-item>
      </a-form-model>
    </section>
    <div class="content" style="min-height: 0">
      <a-row type="flex" justify="center">
        <a-col>
          <a-row type="flex" justify="start" :gutter="16">
            <a-button
              type="primary"
              @click="onSubmit"
              v-if="formData.taskType && orderCount.successNum > 0"
            >
              {{ $t(`创建任务`) }}
            </a-button>
            <a-button type="default" @click="resetForm">{{ $t(`重置`) }}</a-button>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { copy } from "@common"
import debounce from "@common/debounce"
import deepClone from "@common/deepClone"
import { getDateStr } from "@common/getDateStr"
import DateRangePicker from "@component/dateRangePicker"
import SearchShipping from "@component/searchShipping"
import SearchSku from "@component/searchSku"
import SearchWarehouse from "@component/searchWarehouse"
import SelectMutiShop from "@component/selectMutiShop"
import SelectRegion from "@component/selectRegion"
import api from "@service/api"
import http from "@service/axios"
import { mapState } from "vuex"
export default {
	name: "createBatchCheckOrderJob",
	components: {
		SelectMutiShop,
		SearchWarehouse,
		SearchShipping,
		DateRangePicker,
		SearchSku,
		SelectRegion,
	},
	data() {
		return {
			labelCol: { span: 8 },
			wrapperCol: { span: 16 },
			userInputOrderSns: "",
			formData: {
				taskType: undefined,
				shopList: [],
				partyId: undefined,
				statusType: undefined,
				warehouseList: [], //存放warehouseCode和warehouseType
				shippingCodeList: [],
				newWarehouseCode: undefined,
				warehouseChannel: undefined,
				presellType: undefined,
				newShippingCode: undefined,
				startPlatformOrderTime: undefined,
				endPlatformOrderTime: undefined,
				startOrderTime: undefined,
				endOrderTime: undefined,
				startPayTime: undefined, //付款时间开始
				endPayTime: undefined, //付款时间结束
				startEarnestTime: undefined, //付定金时间(开始)
				endEarnestTime: undefined, //付定金时间(结束)
				startBookActivateTime: undefined, //异常单释放时间(开始)
				endBookActivateTime: undefined, //异常单释放时间(结束)
				bookActivateTime: undefined, //激活时间
				startDeliveryTime: undefined, //最晚发货时间（开始）
				endDeliveryTime: undefined, //最晚发货时间（结束）
				totalNum: undefined,
				isGift: undefined,
				checkType: "NORMAL_CHECK",
				provinceIds: [],
				changeWarehouseType: "DESIGNATE_WAREHOUSE",
				chooseShippingType: "DESIGNATE_SHIIPING",
				reject: false,
				inventory: false,
				releaseInventory: false,
				actionNote: undefined,
				oneSku: 0,
				itemList: [
					{
						itemId: null,
						num: null,
						itemType: "GOODS",
					},
				],
			},
			orderCount: { successNum: "", failOrderSns: [] },
			selectedGoodsOrGroup: {},
			selectedGoodsOrGroupArray: [],
			parentId: undefined,
		}
	},
	mounted() {
		Object.keys(this.countryMap).forEach((key) => {
			if (key === this.country) {
				this.parentId = this.countryMap[key]
			}
		})
	},
	activated() {
		this.resetForm()
		this.$nextTick(() => {
			if (this.$route.query.orderSns) {
				this.userInputOrderSns = this.$route.query.orderSns.toString()
				this.formData.taskType = "BATCH_CHECK"
			} else {
				this.formData.taskType = ""
				this.userInputOrderSns = ""
			}
			this.formData.startOrderTime = getDateStr(-3, true)
			this.formData.endOrderTime = getDateStr(0, true)
			this.formData.startPlatformOrderTime = getDateStr(-3, true)
			this.formData.endPlatformOrderTime = getDateStr(0, true)
			Object.keys(this.countryMap).forEach((key) => {
				if (key === this.country) {
					this.parentId = this.countryMap[key]
				}
			})
			if (this.$route.query.taskType) {
				this.formData.taskType = this.$route.query.taskType
				this.getOrderCount()
			}
		})
	},
	computed: {
		...mapState(["userSession"]),
		country() {
			return this.$store.getters.country
		},
		countryMap() {
			return this.$store.state.countryMap
		},
		taskTypeStatusArray() {
			const allTypes = {
				//'批量作废',
				all: this.$t("全部"),
				waitCheck: this.$t("待审核"),
				waitReserve: this.$t("待预定"),
				pushInit: this.$t("待推送"),
				pushSuccess: this.$t("已推送"),
				canCheck: this.$t("可审核"),
				shortage: this.$t("缺货"),
				waitFinanceCheck: this.$t("待财审"),
				applyReturn: this.$t("申请退款"),
				pushFail: this.$t("推送失败"),
				waitGoods: this.$t("缺货单"),
			}
			return {
				BATCH_REJECT: {
					//'批量驳回',
					all: this.$t("全部"),
					applyReturn: this.$t("申请退款"),
					pushFail: this.$t("推送失败"),
					pushSuccess: this.$t("已推送"),
					pushInit: this.$t("待推送"),
				},
				BATCH_CANCEL: allTypes, //批量取消
				BATCH_SUSPEND: allTypes, //批量挂起
				BATCH_CHECK: {
					//'批量审核',
					waitCheck: this.$t("待审核"),
				},
				BATCH_CLOSE: {
					//批量关闭,
					waitCheck: this.$t("待审核"),
				},
				BATCH_CHANGE_BOOK: {
					//'正常单转预订单',
					all: this.$t("全部"),
					waitCheck: this.$t("待审核"),
					canCheck: this.$t("可审核"),
					shortage: this.$t("缺货"),
					waitFinanceCheck: this.$t("待财审"),
					pushFail: this.$t("推送失败"),
					pushInit: this.$t("待推送"),
					pushSuccess: this.$t("已推送"),
					waitGoods: this.$t("缺货单"),
				},
				BATCH_CHANGE_NORMAL: {
					//'预订单转正常单',
					all: this.$t("预订单"),
				},
				BATCH_CHANGE_WAREHOUSE: allTypes, //'批量转仓'
				BATCH_CHANGE_SHIPPING: allTypes, //'批量转快递'
				BATCH_ADD_GIFT: {
					//'批量增加赠品',
					all: this.$t("全部"),
					waitCheck: this.$t("待审核"),
				},
				BATCH_DELETE_GIFT: {
					//'批量删除赠品',
					all: this.$t("全部"),
					waitCheck: this.$t("待审核"),
				},
				BATCH_REVERT_WAIT_CHECK: {
					//'批量审核',
					CLOSED: this.$t("已作废"),
					SUSPEND: this.$t("挂起"),
				},
				BATCH_CHANGE_GOODS: {
					waitCheck: this.$t("待审核"),
					waitReserve: this.$t("待预定"),
				},
				BATCH_SPLIT_BY_BARCODE: {
					waitCheck: this.$t("待审核"),
					waitReserve: this.$t("待预定"),
					pushInit: this.$t("待推送"),
					pushSuccess: this.$t("已推送"),
				},
			}
		},
		actionNoteLabel() {
			let text = ""
			if (this.formData.taskType === "BATCH_CANCEL") {
				text = this.$t("作废原因") //作废原因
			} else if (this.formData.taskType === "BATCH_REJECT") {
				text = this.$t("驳回原因") //驳回原因
			} else {
				text = this.$t("备注") //备注
			}
			return text
		},
		tempCol() {
			return {
				"label-col": { span: 4 },
				"wrapper-col": { span: 8 },
			}
		},
		showReject() {
			return (
				this.formData.taskType === "BATCH_CHANGE_WAREHOUSE" ||
				this.formData.taskType === "BATCH_CHANGE_SHIPPING" ||
				this.formData.taskType === "BATCH_CHANGE_BOOK"
			)
		},
		scrollHeight() {
			return `${(window.innerHeight - 260) * this.$store.state.rate}px`
		},
	},
	watch: {
		"formData.changeWarehouseType"(newVal) {
			if (newVal === "STRATEGY_WAREHOUSE") {
				this.formData.chooseShippingType = "BEST_SHIIPING"
			}
		},
	},
	methods: {
		selectGoodsChangeSku(data) {
			this.formData.itemList[0].itemId = data.skuId
			this.formData.itemList[0].skuCode = data.skuCode
		},
		selectDelGoods(data) {
			this.formData.itemToDel = { skuCode: data.skuCode }
		},
		selectAddGoods(data) {
			this.formData.itemToAdd = { skuCode: data.skuCode }
		},
		// 下单时间
		getPlatformOrderTime(date) {
			this.formData.startPlatformOrderTime = date.start_time
			this.formData.endPlatformOrderTime = date.end_time
		},
		//订单时间
		getOrderTime(date) {
			this.formData.startOrderTime = date.start_time
			this.formData.endOrderTime = date.end_time
		},
		//付款时间
		getPayTime(date) {
			this.formData.startPayTime = date.start_time
			this.formData.endPayTime = date.end_time
		},
		//付定金时间
		getDepositTime(date) {
			this.formData.startEarnestTime = date.start_time
			this.formData.endEarnestTime = date.end_time
		},
		//异常单释放
		getBookActivateTime(date) {
			this.formData.startBookActivateTime = date.start_time
			this.formData.endBookActivateTime = date.end_time
		},
		//最晚发货时间
		getDeliveryTime(date) {
			this.formData.startDeliveryTime = date.start_time
			this.formData.endDeliveryTime = date.end_time
		},
		//设置激活时间
		setActivationTime(date) {
			this.formData.bookActivateTime = date
		},
		changeTaskType() {
			this.disabled = false
			this.formData.statusType = ""
			this.orderCount = { successNum: "", failOrderSns: [] }
			this.$nextTick(() => {
				if (this.formData.taskType === "BATCH_CHANGE_SHIPPING") {
					this.formData.warehouseList = []
				}
				const list = Object.keys(this.taskTypeStatusArray[this.formData.taskType])
				if (list?.length === 1) {
					this.formData.statusType = list[0]
				}
			})
		},
		//新建任务
		searchGoodsOrGroup(data) {
			this.selectedGoodsOrGroup = data
		},
		addGoodsOrGroup() {
			if (this.selectedGoodsOrGroup.skuCode) {
				for (let i = 0; i < this.selectedGoodsOrGroupArray.length; i++) {
					if (this.selectedGoodsOrGroupArray[i].skuCode === this.selectedGoodsOrGroup.skuCode) {
						this.$message.warning(this.$t("请勿重复添加！") + this.selectedGoodsOrGroup.skuName)
						return
					}
				}
				this.selectedGoodsOrGroupArray.push(deepClone(this.selectedGoodsOrGroup))
				this.selectedGoodsOrGroup = {}
				// this.$refs.s_goodsOrGroup.clear()
			} else {
				this.$warning(this.$t("请选择商品"))
			}
		},
		getOrderCount: debounce(function () {
			if (!this.userInputOrderSns) {
				//没有输入单号再验证其他
				if (!this.formData.statusType || this.formData.warehouseList?.length === 0) {
					this.$warning(this.$t("请先选择订单状态/订单时间/仓库"))
					return
				}
			}
			this.disabled = true
			if (this.userInputOrderSns !== "") {
				this.formData.orderSns = this.userInputOrderSns.split(",")
			}
			this.formData.skuCodes = []
			this.formData.goodsGroupIds = []
			this.selectedGoodsOrGroupArray.forEach((item) => {
				this.formData.skuCodes.push(item.skuCode)
			})
			this.orderCount = { successNum: "", failOrderSns: [] }
			this.formData.partyId = this.userSession.currentParty.partyId
			http({
				url: api.inspectionTaskORderCount,
				hasLoading: true,
				type: "post",
				data: { data: this.formData },
				success: (res) => {
					if (res.success) {
						this.orderCount = res.data
						this.formData.totalNum = this.orderCount.successNum
					} else {
						this.$error(this.$t("查询失败"))
					}
				},
			})
		}),
		getProvinceList() {
			http({
				instance: this,
				url: api.getRegionList,
				type: "post",
				data: {
					parentId: this.parentId,
					regionType: 1,
				},
				success: (res) => {
					this.provinceList = res.data
				},
			})
		},
		changeProvince(data) {
			this.formData.provinceIds = data.region_id
		},
		cloneOrder(value) {
			const txt = value.join(",")
			copy(txt)
		},
		onSubmit: debounce(function () {
			if (this.formData.totalNum === "" || this.formData.totalNum === undefined) {
				this.$warning(this.$t("请填写执行数量"))
				return
			}
			if (+this.formData.totalNum > +this.orderCount.successNum) {
				this.$warning(this.$t("执行数量不能超过订单数量"))
				return
			}
			if (this.formData.taskType === "BATCH_ADD_GIFT") {
				if (!this.formData.itemList[0].itemId || !this.formData.itemList[0].num) {
					this.$warning(`${this.$t("请选择赠品")},${this.$t("请填写赠品数量")}`)
					return
				}
			}
			if (this.formData.taskType === "BATCH_DELETE_GIFT") {
				if (!this.formData.itemList[0].itemId) {
					this.$warning(this.$t("请选择赠品"))
					return
				}
			}
			const formData = Object.assign({}, this.formData)
			formData.oneSku = !!formData.oneSku
			formData.barcodeList = this.selectedGoodsOrGroupArray.map((item) => item.barcode)
			http({
				instance: this,
				url: api.inspectionTaskAdd,
				data: { data: formData },
				type: "post",
				hasLoading: true,
				success: (res) => {
					if (res.success) {
						this.$success(this.$t("批量任务创建成功！任务id为：") + res.data)
						this.resetForm()
						this.$router.push({
							name: "batchOrderReview",
						})
					} else {
						this.$error(this.$t("创建失败"))
					}
				},
			})
		}),
		resetForm() {
			Object.assign(this.$data, this.$options.data())

			this.$refs.selectShopRef?.clear()
			this.$refs.selectWarehouseRef?.clear()
			this.$refs.payTime?.clear()
			this.$refs.deliveryTime?.clear()
		},
		selectWarehouse(data) {
			this.formData.newWarehouseCode = data.wmsWarehouseCode
			this.formData.warehouseChannel = data.warehouseChannel
		},
		selectOldWarehouse(list) {
			const warehouseList = list.map((item) => ({
				warehouseCode: item.wmsWarehouseCode,
				warehouseType: item.warehouseType,
			}))
			this.formData.warehouseList = warehouseList
		},
		selectShopList(data) {
			this.formData.shopList = data.map((item) => ({
				shopName: item.shopName,
				platform: item.platform,
				shopCode: item.code || item.shopCode,
			}))
		},
	},
}
</script>

<style lang="scss" scoped>
.form-content {
  padding: 20px 5%;
}

.ant-form-item {
  margin-bottom: 0;
}

.search-group {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 3px;

  .add {
    position: absolute;
    display: block;
    right: -45px;
  }
}

.goods-list {
  flex-wrap: wrap;

  .goods-item {
    line-height: normal;
    margin-right: 6px;
    margin-top: 4px;
    white-space: nowrap;
  }
}

.fixContent {
  // height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
