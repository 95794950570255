export default function deepClone(obj) {
	// 检查如果是原始类型或null，直接返回
	if (obj === null || typeof obj !== "object") {
		return obj
	}

	// 创建一个新的对象或数组
	const clone = Array.isArray(obj) ? [] : {}

	// 递归复制每个属性
	for (const key in obj) {
		clone[key] = deepClone(obj[key])
	}

	return clone
}
