/*! layer-v2.4 弹层组件 License LGPL  http://layer.layui.com/ By 贤心 */
!((a, b) => {
	let c
	let d
	const e = {
		getPath: (() => {
			const a = document.scripts
			const b = a[a.length - 1]
			const c = b.src
			if (!b.getAttribute("merge")) return c.substring(0, c.lastIndexOf("/") + 1)
		})(),
		enter: (a) => {
			13 === a.keyCode && a.preventDefault()
		},
		config: {},
		end: {},
		btn: ["&#x786E;&#x5B9A;", "&#x53D6;&#x6D88;"],
		type: ["dialog", "page", "iframe", "loading", "tips"],
	}
	const f = {
		v: "2.4",
		ie6: !!a.ActiveXObject && !a.XMLHttpRequest,
		index: 0,
		path: e.getPath,
		config: function (a, b) {
			let d = 0
			return (
				(a = a || {}),
				(f.cache = e.config = c.extend(e.config, a)),
				(f.path = e.config.path || f.path),
				"string" === typeof a.extend && (a.extend = [a.extend]),
				f.use(
					"skin/layer.css",
					a.extend && a.extend.length > 0
						? (function g() {
								const c = a.extend
								f.use(c[c[d] ? d : d - 1], d < c.length ? (() => (++d, g))() : b)
							})()
						: b
				),
				this
			)
		},
		use: function (_a, b, d) {
			const e = c("head")[0]
			const a = _a.replace(/\s/g, "")
			const g = /\.css$/.test(a)
			const h = document.createElement(g ? "link" : "script")
			const i = `layui_layer_${a.replace(/\.|\//g, "")}`
			return f.path
				? (g && (h.rel = "stylesheet"),
					(h[g ? "href" : "src"] = /^http:\/\//.test(a) ? a : f.path + a),
					(h.id = i),
					c(`#${i}`)[0] || e.appendChild(h),
					(function j() {
						;(g ? 1989 === Number.parseInt(c(`#${i}`).css("width")) : f[d || i])
							? (() => {
									b?.()
									try {
										g || e.removeChild(h)
									} catch (a) {}
								})()
							: setTimeout(j, 100)
					})(),
					this)
				: void 0
		},
		ready: function (a, b) {
			const d = "function" === typeof a
			return d && (b = a), f.config(c.extend(e.config, (() => (d ? {} : { path: a }))()), b), this
		},
		alert: (a, b, d) => {
			const e = "function" === typeof b
			return e && (d = b), f.open(c.extend({ content: a, yes: d }, e ? {} : b))
		},
		confirm: (a, b, d, g) => {
			const h = "function" === typeof b
			return h && ((g = d), (d = b)), f.open(c.extend({ content: a, btn: e.btn, yes: d, btn2: g }, h ? {} : b))
		},
		msg: (a, d, g) => {
			const i = "function" === typeof d
			const j = e.config.skin
			const k = (j ? `${j} ${j}-msg` : "") || "layui-layer-msg"
			const l = h.anim.length - 1
			return (
				i && (g = d),
				f.open(
					c.extend(
						{ content: a, time: 3e3, shade: !1, skin: k, title: !1, closeBtn: !1, btn: !1, end: g },
						i && !e.config.skin
							? { skin: `${k} layui-layer-hui`, shift: l }
							: (() => ((d = d || {}), (-1 === d.icon || (d.icon === b && !e.config.skin)) && (d.skin = `${k} ${d.skin || "layui-layer-hui"}`), d))()
					)
				)
			)
		},
		load: (a, b) => f.open(c.extend({ type: 3, icon: a || 0, shade: 0.01 }, b)),
		tips: (a, b, d) => f.open(c.extend({ type: 4, content: [a, b], closeBtn: !1, time: 3e3, shade: !1, fix: !1, maxWidth: 210 }, d)),
	}
	let g = function (a) {
		;(this.index = ++f.index), (this.config = c.extend({}, this.config, e.config, a)), this.creat()
	}
	g.pt = g.prototype
	let h = [
		"layui-layer",
		".layui-layer-title",
		".layui-layer-main",
		".layui-layer-dialog",
		"layui-layer-iframe",
		"layui-layer-content",
		"layui-layer-btn",
		"layui-layer-close",
	]
	;(h.anim = ["layer-anim", "layer-anim-01", "layer-anim-02", "layer-anim-03", "layer-anim-04", "layer-anim-05", "layer-anim-06"]),
		(g.pt.config = {
			type: 0,
			shade: 0.3,
			fix: !0,
			move: h[1],
			title: "&#x4FE1;&#x606F;",
			offset: "auto",
			area: "auto",
			closeBtn: 1,
			time: 0,
			zIndex: 19891014,
			maxWidth: 360,
			shift: 0,
			icon: -1,
			scrollbar: !0,
			tips: 2,
		}),
		(g.pt.vessel = function (a, b) {
			const d = this.index
			const f = this.config
			const g = f.zIndex + d
			const i = "object" === typeof f.title
			const j = f.maxmin && (1 === f.type || 2 === f.type)
			const k = f.title ? `<div class="layui-layer-title" style="${i ? f.title[1] : ""}">${i ? f.title[0] : f.title}</div>` : ""
			return (
				(f.zIndex = g),
				b(
					[
						f.shade
							? `<div class="layui-layer-shade" id="layui-layer-shade${d}" times="${d}" style="z-index:${g - 1}; background-color:${f.shade[1] || "#000"}; opacity:${f.shade[0] || f.shade}; filter:alpha(opacity=${100 * f.shade[0] || 100 * f.shade});"></div>`
							: "",
						`<div class="${h[0]} layui-layer-${e.type[f.type]}${(0 !== f.type && 2 !== f.type) || f.shade ? "" : " layui-layer-border"} ${f.skin || ""}" id="${h[0]}${d}" type="${e.type[f.type]}" times="${d}" showtime="${f.time}" conType="${a ? "object" : "string"}" style="z-index: ${g}; width:${f.area[0]};height:${f.area[1]}${f.fix ? "" : ";position:absolute;"}">${a && 2 !== f.type ? "" : k}<div id="${f.id || ""}" class="layui-layer-content${0 === f.type && -1 !== f.icon ? " layui-layer-padding" : ""}${3 === f.type ? ` layui-layer-loading${f.icon}` : ""}">${0 === f.type && -1 !== f.icon ? `<i class="layui-layer-ico layui-layer-ico${f.icon}"></i>` : ""}${1 === f.type && a ? "" : f.content || ""}</div><span class="layui-layer-setwin">${(() => {
							let a = j
								? '<a class="layui-layer-min" href="javascript:;"><cite></cite></a><a class="layui-layer-ico layui-layer-max" href="javascript:;"></a>'
								: ""
							return (
								f.closeBtn &&
									(a += `<a class="layui-layer-ico ${h[7]} ${h[7]}${f.title ? f.closeBtn : 4 === f.type ? "1" : "2"}" href="javascript:;"></a>`),
								a
							)
						})()}</span>${
							f.btn
								? (
										() => {
											let a = ""
											"string" === typeof f.btn && (f.btn = [f.btn])
											for (let b = 0, c = f.btn.length; c > b; b++) a += `<a class="${h[6]}${b}">${f.btn[b]}</a>`
											return `<div class="${h[6]}">${a}</div>`
										}
									)()
								: ""
						}</div>`,
					],
					k
				),
				this
			)
		}),
		(g.pt.creat = function () {
			const b = this.config
			const g = this.index
			const i = b.content
			const j = "object" === typeof i
			if (!c(`#${b.id}`)[0]) {
				switch (("string" === typeof b.area && (b.area = "auto" === b.area ? ["", ""] : [b.area, ""]), b.type)) {
					case 0:
						;(b.btn = "btn" in b ? b.btn : e.btn[0]), f.closeAll("dialog")
						break
					case 2: {
						const i = (b.content = j ? b.content : [b.content || "http://layer.layui.com", "auto"])
						b.content = `<iframe scrolling="${b.content[1] || "auto"}" allowtransparency="true" id="${h[4]}${g}" name="${h[4]}${g}" onload="this.className=\'\';" class="layui-layer-load" frameborder="0" src="${b.content[0]}"></iframe>`
						break
					}
					case 3:
						;(b.title = !1), (b.closeBtn = !1), -1 === b.icon && 0 === b.icon, f.closeAll("loading")
						break
					case 4:
						j || (b.content = [b.content, "body"]),
							(b.follow = b.content[1]),
							(b.content = `${b.content[0]}<i class="layui-layer-TipsG"></i>`),
							(b.title = !1),
							(b.tips = "object" === typeof b.tips ? b.tips : [b.tips, !0]),
							b.tipsMore || f.closeAll("tips")
				}
				this.vessel(j, (d, e) => {
					c("body").append(d[0]),
						j
							? (() => {
									2 === b.type || 4 === b.type
										? (() => {
												c("body").append(d[1])
											})()
										: (() => {
												i.parents(`.${h[0]}`)[0] || (i.show().addClass("layui-layer-wrap").wrap(d[1]), c(`#${h[0]}${g}`).find(`.${h[5]}`).before(e))
											})()
								})()
							: c("body").append(d[1]),
						(this.layero = c(`#${h[0]}${g}`)),
						b.scrollbar || h.html.css("overflow", "hidden").attr("layer-full", g)
				}).auto(g),
					2 === b.type && f.ie6 && this.layero.find("iframe").attr("src", i[0]),
					c(document).off("keydown", e.enter).on("keydown", e.enter),
					this.layero.on("keydown", (a) => {
						c(document).off("keydown", e.enter)
					}),
					4 === b.type ? this.tips() : this.offset(),
					b.fix &&
						d.on("resize", () => {
							this.offset(), (/^\d+%$/.test(b.area[0]) || /^\d+%$/.test(b.area[1])) && this.auto(g), 4 === b.type && this.tips()
						}),
					b.time <= 0 ||
						setTimeout(() => {
							f.close(this.index)
						}, b.time),
					this.move().callback(),
					h.anim[b.shift] && this.layero.addClass(h.anim[b.shift])
			}
		}),
		(g.pt.auto = function (a) {
			function b(a) {
				;(a = g.find(a)), a.height(i[1] - j - k - 2 * (0 | Number.parseFloat(a.css("padding"))))
			}
			const f = this.config
			const g = c(`#${h[0]}${a}`)
			"" === f.area[0] &&
				f.maxWidth > 0 &&
				(/MSIE 7/.test(navigator.userAgent) && f.btn && g.width(g.innerWidth()), g.outerWidth() > f.maxWidth && g.width(f.maxWidth))
			const i = [g.innerWidth(), g.innerHeight()]
			const j = g.find(h[1]).outerHeight() || 0
			const k = g.find(`.${h[6]}`).outerHeight() || 0
			switch (f.type) {
				case 2:
					b("iframe")
					break
				default:
					"" === f.area[1] ? f.fix && i[1] >= d.height() && ((i[1] = d.height()), b(`.${h[5]}`)) : b(`.${h[5]}`)
			}
			return this
		}),
		(g.pt.offset = function () {
			const b = this.config
			const c = this.layero
			const e = [c.outerWidth(), c.outerHeight()]
			const f = "object" === typeof b.offset
			;(this.offsetTop = (d.height() - e[1]) / 2),
				(this.offsetLeft = (d.width() - e[0]) / 2),
				f
					? ((this.offsetTop = b.offset[0]), (this.offsetLeft = b.offset[1] || this.offsetLeft))
					: "auto" !== b.offset &&
						((this.offsetTop = b.offset), "rb" === b.offset && ((this.offsetTop = d.height() - e[1]), (this.offsetLeft = d.width() - e[0]))),
				b.fix ||
					((this.offsetTop = /%$/.test(this.offsetTop) ? (d.height() * Number.parseFloat(this.offsetTop)) / 100 : Number.parseFloat(this.offsetTop)),
					(this.offsetLeft = /%$/.test(this.offsetLeft)
						? (d.width() * Number.parseFloat(this.offsetLeft)) / 100
						: Number.parseFloat(this.offsetLeft)),
					(this.offsetTop += d.scrollTop()),
					(this.offsetLeft += d.scrollLeft())),
				c.css({ top: this.offsetTop, left: this.offsetLeft })
		}),
		(g.pt.tips = function () {
			const b = this.config
			const e = this.layero
			const f = [e.outerWidth(), e.outerHeight()]
			let g = c(b.follow)
			g[0] || (g = c("body"))
			const i = { width: g.outerWidth(), height: g.outerHeight(), top: g.offset().top, left: g.offset().left }
			const j = e.find(".layui-layer-TipsG")
			const k = b.tips[0]
			b.tips[1] || j.remove(),
				(i.autoLeft = () => {
					i.left + f[0] - d.width() > 0 ? ((i.tipLeft = i.left + i.width - f[0]), j.css({ right: 12, left: "auto" })) : (i.tipLeft = i.left)
				}),
				(i.where = [
					() => {
						i.autoLeft(),
							(i.tipTop = i.top - f[1] - 10),
							j.removeClass("layui-layer-TipsB").addClass("layui-layer-TipsT").css("border-right-color", b.tips[1])
					},
					() => {
						;(i.tipLeft = i.left + i.width + 10),
							(i.tipTop = i.top),
							j.removeClass("layui-layer-TipsL").addClass("layui-layer-TipsR").css("border-bottom-color", b.tips[1])
					},
					() => {
						i.autoLeft(),
							(i.tipTop = i.top + i.height + 10),
							j.removeClass("layui-layer-TipsT").addClass("layui-layer-TipsB").css("border-right-color", b.tips[1])
					},
					() => {
						;(i.tipLeft = i.left - f[0] - 10),
							(i.tipTop = i.top),
							j.removeClass("layui-layer-TipsR").addClass("layui-layer-TipsL").css("border-bottom-color", b.tips[1])
					},
				]),
				i.where[k - 1](),
				1 === k
					? i.top - (d.scrollTop() + f[1] + 16) < 0 && i.where[2]()
					: 2 === k
						? d.width() - (i.left + i.width + f[0] + 16) > 0 || i.where[3]()
						: 3 === k
							? i.top - d.scrollTop() + i.height + f[1] + 16 - d.height() > 0 && i.where[0]()
							: 4 === k && f[0] + 16 - i.left > 0 && i.where[1](),
				e.find(`.${h[5]}`).css({ "background-color": b.tips[1], "padding-right": b.closeBtn ? "30px" : "" }),
				e.css({ left: i.tipLeft - (b.fix ? d.scrollLeft() : 0), top: i.tipTop - (b.fix ? d.scrollTop() : 0) })
		}),
		(g.pt.move = function () {
			const b = this.config
			const e = {
				setY: 0,
				moveLayer: () => {
					const a = e.layero
					const b = Number.parseInt(a.css("margin-left"))
					let c = Number.parseInt(e.move.css("left"))
					0 === b || (c -= b),
						"fixed" !== a.css("position") && ((c -= a.parent().offset().left), (e.setY = 0)),
						a.css({ left: c, top: Number.parseInt(e.move.css("top")) - e.setY })
				},
			}
			const f = this.layero.find(b.move)
			return (
				b.move && f.attr("move", "ok"),
				f.css({ cursor: b.move ? "move" : "auto" }),
				c(b.move).on("mousedown", function (a) {
					if ((a.preventDefault(), "ok" === c(this).attr("move"))) {
						;(e.ismove = !0), (e.layero = c(this).parents(`.${h[0]}`))
						const f = e.layero.offset().left
						const g = e.layero.offset().top
						const i = e.layero.outerWidth() - 6
						const j = e.layero.outerHeight() - 6
						c("#layui-layer-moves")[0] ||
							c("body").append(
								`<div id="layui-layer-moves" class="layui-layer-moves" style="left:${f}px; top:${g}px; width:${i}px; height:${j}px; z-index:2147483584"></div>`
							),
							(e.move = c("#layui-layer-moves")),
							b.moveType && e.move.css({ visibility: "hidden" }),
							(e.moveX = a.pageX - e.move.position().left),
							(e.moveY = a.pageY - e.move.position().top),
							"fixed" !== e.layero.css("position") || (e.setY = d.scrollTop())
					}
				}),
				c(document)
					.mousemove((a) => {
						if (e.ismove) {
							let c = a.pageX - e.moveX
							let f = a.pageY - e.moveY
							if ((a.preventDefault(), !b.moveOut)) {
								e.setY = d.scrollTop()
								const g = d.width() - e.move.outerWidth()
								const h = e.setY
								0 > c && (c = 0),
									c > g && (c = g),
									h > f && (f = h),
									f > d.height() - e.move.outerHeight() + e.setY && (f = d.height() - e.move.outerHeight() + e.setY)
							}
							e.move.css({ left: c, top: f }), b.moveType && e.moveLayer(), (c = f = g = h = null)
						}
					})
					.mouseup(() => {
						try {
							e.ismove && (e.moveLayer(), e.move.remove(), b.moveEnd?.()), (e.ismove = !1)
						} catch (a) {
							e.ismove = !1
						}
					}),
				this
			)
		}),
		(g.pt.callback = function () {
			function a() {
				const a = g.cancel?.(b.index, d)
				a === !1 || f.close(b.index)
			}
			const b = this
			const d = b.layero
			const g = b.config
			b.openLayer(),
				g.success &&
					(2 === g.type
						? d.find("iframe").on("load", () => {
								g.success(d, b.index)
							})
						: g.success(d, b.index)),
				f.ie6 && b.IE6(d),
				d
					.find(`.${h[6]}`)
					.children("a")
					.on("click", function () {
						const a = c(this).index()
						if (0 === a) g.yes ? g.yes(b.index, d) : g.btn1 ? g.btn1(b.index, d) : f.close(b.index)
						else {
							const e = g[`btn${a + 1}`] && g[`btn${a + 1}`](b.index, d)
							e === !1 || f.close(b.index)
						}
					}),
				d.find(`.${h[7]}`).on("click", a),
				g.shadeClose &&
					c(`#layui-layer-shade${b.index}`).on("click", () => {
						f.close(b.index)
					}),
				d.find(".layui-layer-min").on("click", () => {
					const a = g.min?.(d)
					a === !1 || f.min(b.index, g)
				}),
				d.find(".layui-layer-max").on("click", function () {
					c(this).hasClass("layui-layer-maxmin")
						? (f.restore(b.index), g.restore?.(d))
						: (f.full(b.index, g),
							setTimeout(() => {
								g.full?.(d)
							}, 100))
				}),
				g.end && (e.end[b.index] = g.end)
		}),
		(e.reselect = () => {
			c.each(c("select"), function (a, b) {
				let d = c(this)
				d.parents(`.${h[0]}`)[0] || (1 === d.attr("layer") && c(`.${h[0]}`).length < 1 && d.removeAttr("layer").show()), (d = null)
			})
		}),
		(g.pt.IE6 = (a) => {
			function b() {
				a.css({ top: f + (e.config.fix ? d.scrollTop() : 0) })
			}
			const f = a.offset().top
			b(),
				d.scroll(b),
				c("select").each(function (a, b) {
					let d = c(this)
					d.parents(`.${h[0]}`)[0] || "none" === d.css("display") || d.attr({ layer: "1" }).hide(), (d = null)
				})
		}),
		(g.pt.openLayer = function () {
			;(f.zIndex = this.config.zIndex),
				(f.setTop = (a) => {
					const b = () => {
						f.zIndex++, a.css("z-index", f.zIndex + 1)
					}
					return (f.zIndex = Number.parseInt(a[0].style.zIndex)), a.on("mousedown", b), f.zIndex
				})
		}),
		(e.record = (a) => {
			const b = [a.width(), a.height(), a.position().top, a.position().left + Number.parseFloat(a.css("margin-left"))]
			a.find(".layui-layer-max").addClass("layui-layer-maxmin"), a.attr({ area: b })
		}),
		(e.rescollbar = (a) => {
			h.html.attr("layer-full") === a &&
				(h.html[0].style.removeProperty ? h.html[0].style.removeProperty("overflow") : h.html[0].style.removeAttribute("overflow"),
				h.html.removeAttr("layer-full"))
		}),
		(a.layer = f),
		(f.getChildFrame = (a, b) => ((b = b || c(`.${h[4]}`).attr("times")), c(`#${h[0]}${b}`).find("iframe").contents().find(a))),
		(f.getFrameIndex = (a) => c(`#${a}`).parents(`.${h[4]}`).attr("times")),
		(f.iframeAuto = (a) => {
			if (a) {
				const b = f.getChildFrame("html", a).outerHeight()
				const d = c(`#${h[0]}${a}`)
				const e = d.find(h[1]).outerHeight() || 0
				const g = d.find(`.${h[6]}`).outerHeight() || 0
				d.css({ height: b + e + g }), d.find("iframe").css({ height: b })
			}
		}),
		(f.iframeSrc = (a, b) => {
			c(`#${h[0]}${a}`).find("iframe").attr("src", b)
		}),
		(f.style = (a, b) => {
			const d = c(`#${h[0]}${a}`)
			const f = d.attr("type")
			const g = d.find(h[1]).outerHeight() || 0
			const i = d.find(`.${h[6]}`).outerHeight() || 0
			;(f === e.type[1] || f === e.type[2]) && (d.css(b), f === e.type[2] && d.find("iframe").css({ height: Number.parseFloat(b.height) - g - i }))
		}),
		(f.min = (a, b) => {
			const d = c(`#${h[0]}${a}`)
			const g = d.find(h[1]).outerHeight() || 0
			e.record(d),
				f.style(a, { width: 180, height: g, overflow: "hidden" }),
				d.find(".layui-layer-min").hide(),
				"page" === d.attr("type") && d.find(h[4]).hide(),
				e.rescollbar(a)
		}),
		(f.restore = (a) => {
			const b = c(`#${h[0]}${a}`)
			const d = b.attr("area").split(",")
			b.attr("type")
			f.style(a, {
				width: Number.parseFloat(d[0]),
				height: Number.parseFloat(d[1]),
				top: Number.parseFloat(d[2]),
				left: Number.parseFloat(d[3]),
				overflow: "visible",
			}),
				b.find(".layui-layer-max").removeClass("layui-layer-maxmin"),
				b.find(".layui-layer-min").show(),
				"page" === b.attr("type") && b.find(h[4]).show(),
				e.rescollbar(a)
		}),
		(f.full = (a) => {
			let b
			const g = c(`#${h[0]}${a}`)
			e.record(g),
				h.html.attr("layer-full") || h.html.css("overflow", "hidden").attr("layer-full", a),
				clearTimeout(b),
				(b = setTimeout(() => {
					const b = "fixed" === g.css("position")
					f.style(a, { top: b ? 0 : d.scrollTop(), left: b ? 0 : d.scrollLeft(), width: d.width(), height: d.height() }),
						g.find(".layui-layer-min").hide()
				}, 100))
		}),
		(f.title = (a, b) => {
			const d = c(`#${h[0]}${b || f.index}`).find(h[1])
			d.html(a)
		}),
		(f.close = (a) => {
			const b = c(`#${h[0]}${a}`)
			const d = b.attr("type")
			if (b[0]) {
				if (d === e.type[1] && "object" === b.attr("conType")) {
					b.children(`:not(.${h[5]})`).remove()
					for (let g = 0; 2 > g; g++) b.find(".layui-layer-wrap").unwrap().hide()
				} else {
					if (d === e.type[2])
						try {
							const i = c(`#${h[4]}${a}`)[0]
							i.contentWindow.document.write(""), i.contentWindow.close(), b.find(`.${h[5]}`)[0].removeChild(i)
						} catch (j) {}
					;(b[0].innerHTML = ""), b.remove()
				}
				c(`#layui-layer-moves, #layui-layer-shade${a}`).remove(),
					f.ie6 && e.reselect(),
					e.rescollbar(a),
					c(document).off("keydown", e.enter),
					"function" === typeof e.end[a] && e.end[a](),
					delete e.end[a]
			}
		}),
		(f.closeAll = (a) => {
			c.each(c(`.${h[0]}`), function () {
				const b = c(this)
				let d = a ? b.attr("type") === a : 1
				d && f.close(b.attr("times")), (d = null)
			})
		})
	const i = f.cache || {}
	const j = (a) => (i.skin ? ` ${i.skin} ${i.skin}-${a}` : "")
	;(f.prompt = (a, b) => {
		;(a = a || {}), "function" === typeof a && (b = a)
		let d
		const e =
			2 === a.formType
				? `<textarea class="layui-layer-input">${a.value || ""}</textarea>`
				: (() => `<input type="${1 === a.formType ? "password" : "text"}" class="layui-layer-input" value="${a.value || ""}">`)()
		return f.open(
			c.extend(
				{
					btn: ["&#x786E;&#x5B9A;", "&#x53D6;&#x6D88;"],
					content: e,
					skin: `layui-layer-prompt${j("prompt")}`,
					success: (a) => {
						;(d = a.find(".layui-layer-input")), d.focus()
					},
					yes: (c) => {
						const e = d.val()
						"" === e
							? d.focus()
							: e.length > (a.maxlength || 500)
								? f.tips(`&#x6700;&#x591A;&#x8F93;&#x5165;${a.maxlength || 500}&#x4E2A;&#x5B57;&#x6570;`, d, { tips: 1 })
								: b?.(e, c, d)
					},
				},
				a
			)
		)
	}),
		(f.tab = (a) => {
			a = a || {}
			const b = a.tab || {}
			return f.open(
				c.extend(
					{
						type: 1,
						skin: `layui-layer-tab${j("tab")}`,
						title: (() => {
							const a = b.length
							let c = 1
							let d = ""
							if (a > 0) for (d = `<span class="layui-layer-tabnow">${b[0].title}</span>`; a > c; c++) d += `<span>${b[c].title}</span>`
							return d
						})(),
						content: `<ul class="layui-layer-tabmain">${(() => {
							const a = b.length
							let c = 1
							let d = ""
							if (a > 0)
								for (d = `<li class="layui-layer-tabli xubox_tab_layer">${b[0].content || "no content"}</li>`; a > c; c++)
									d += `<li class="layui-layer-tabli">${b[c].content || "no  content"}</li>`
							return d
						})()}</ul>`,
						success: (b) => {
							const d = b.find(".layui-layer-title").children()
							const e = b.find(".layui-layer-tabmain").children()
							d.on("mousedown", function (b) {
								b.stopPropagation ? b.stopPropagation() : (b.cancelBubble = !0)
								const d = c(this)
								const f = d.index()
								d.addClass("layui-layer-tabnow").siblings().removeClass("layui-layer-tabnow"),
									e.eq(f).show().siblings().hide(),
									"function" === typeof a.change && a.change(f)
							})
						},
					},
					a
				)
			)
		}),
		(f.photos = (b, d, e) => {
			function g(a, b, c) {
				const d = new Image()
				return (
					(d.src = a),
					d.complete
						? b(d)
						: ((d.onload = () => {
								;(d.onload = null), b(d)
							}),
							void (d.onerror = (a) => {
								;(d.onerror = null), c(a)
							}))
				)
			}
			const h = {}
			if (((b = b || {}), b.photos)) {
				const i = b.photos.constructor === Object
				const k = i ? b.photos : {}
				let l = k.data || []
				const m = k.start || 0
				if (((h.imgIndex = (0 | m) + 1), (b.img = b.img || "img"), i)) {
					if (0 === l.length) return f.msg("&#x6CA1;&#x6709;&#x56FE;&#x7247;")
				} else {
					const n = c(b.photos)
					const o = () => {
						;(l = []),
							n.find(b.img).each(function (a) {
								const b = c(this)
								b.attr("layer-index", a),
									l.push({ alt: b.attr("alt"), pid: b.attr("layer-pid"), src: b.attr("layer-src") || b.attr("src"), thumb: b.attr("src") })
							})
					}
					if ((o(), 0 === l.length)) return
					if (
						(d ||
							n.on("click", b.img, function () {
								const a = c(this)
								const d = a.attr("layer-index")
								f.photos(c.extend(b, { photos: { start: d, data: l, tab: b.tab }, full: b.full }), !0), o()
							}),
						!d)
					)
						return
				}
				;(h.imgprev = (a) => {
					h.imgIndex--, h.imgIndex < 1 && (h.imgIndex = l.length), h.tabimg(a)
				}),
					(h.imgnext = (a, b) => {
						h.imgIndex++, (h.imgIndex > l.length && ((h.imgIndex = 1), b)) || h.tabimg(a)
					}),
					(h.keyup = (a) => {
						if (!h.end) {
							const b = a.keyCode
							a.preventDefault(), 37 === b ? h.imgprev(!0) : 39 === b ? h.imgnext(!0) : 27 === b && f.close(h.index)
						}
					}),
					(h.tabimg = (a) => {
						l.length <= 1 || ((k.start = h.imgIndex - 1), f.close(h.index), f.photos(b, !0, a))
					}),
					(h.event = () => {
						h.bigimg.hover(
							() => {
								h.imgsee.show()
							},
							() => {
								h.imgsee.hide()
							}
						),
							h.bigimg.find(".layui-layer-imgprev").on("click", (a) => {
								a.preventDefault(), h.imgprev()
							}),
							h.bigimg.find(".layui-layer-imgnext").on("click", (a) => {
								a.preventDefault(), h.imgnext()
							}),
							c(document).on("keyup", h.keyup)
					}),
					(h.loadi = f.load(1, { shade: "shade" in b ? !1 : 0.9, scrollbar: !1 })),
					g(
						l[m].src,
						(d) => {
							f.close(h.loadi),
								(h.index = f.open(
									c.extend(
										{
											type: 1,
											area: (() => {
												const e = [d.width, d.height]
												const f = [c(a).width() - 50, c(a).height() - 50]
												return !b.full && e[0] > f[0] && ((e[0] = f[0]), (e[1] = (e[0] * d.height) / d.width)), [`${e[0]}px`, `${e[1]}px`]
											})(),
											title: !1,
											shade: 0.9,
											shadeClose: !0,
											closeBtn: !1,
											move: ".layui-layer-phimg img",
											moveType: 1,
											scrollbar: !1,
											moveOut: !0,
											shift: (5 * Math.random()) | 0,
											skin: `layui-layer-photos${j("photos")}`,
											content: `<div class="layui-layer-phimg"><img src="${l[m].src}" alt="${l[m].alt || ""}" layer-pid="${l[m].pid}"><div class="layui-layer-imgsee">${
												l.length > 1
													? '<span class="layui-layer-imguide"><a href="javascript:;" class="layui-layer-iconext layui-layer-imgprev"></a><a href="javascript:;" class="layui-layer-iconext layui-layer-imgnext"></a></span>'
													: ""
											}<div class="layui-layer-imgbar" style="display:${e ? "block" : ""}"><span class="layui-layer-imgtit"><a href="javascript:;">${l[m].alt || ""}</a><em>${h.imgIndex}/${l.length}</em></span></div></div></div>`,
											success: (a, c) => {
												;(h.bigimg = a.find(".layui-layer-phimg")),
													(h.imgsee = a.find(".layui-layer-imguide,.layui-layer-imgbar")),
													h.event(a),
													b.tab?.(l[m], a)
											},
											end: () => {
												;(h.end = !0), c(document).off("keyup", h.keyup)
											},
										},
										b
									)
								))
						},
						() => {
							f.close(h.loadi),
								f.msg(
									"&#x5F53;&#x524D;&#x56FE;&#x7247;&#x5730;&#x5740;&#x5F02;&#x5E38;<br>&#x662F;&#x5426;&#x7EE7;&#x7EED;&#x67E5;&#x770B;&#x4E0B;&#x4E00;&#x5F20;&#xFF1F;",
									{
										time: 3e4,
										btn: ["&#x4E0B;&#x4E00;&#x5F20;", "&#x4E0D;&#x770B;&#x4E86;"],
										yes: () => {
											l.length > 1 && h.imgnext(!0, !0)
										},
									}
								)
						}
					)
			}
		}),
		(e.run = () => {
			;(c = jQuery),
				(d = c(a)),
				(h.html = c("html")),
				(f.open = (a) => {
					const b = new g(a)
					return b.index
				})
		}),
		"function" === typeof define
			? define(() => (e.run(), f))
			: (() => {
					e.run(), f.use("skin/layer.css")
				})()
})(window)
