<template>
  <div class="order-tracking-detail" style="margin-top: 2%">
    <dl class="tracking-info">
      <div class="infoTitle">
        <i class="leftLine"></i>
        {{ $t('物流信息') }}
      </div>
      <dd>
        <div class="col-md-11 no-padding" v-if="trackList && trackList.length">
          <div style="width: 100%">
            <a-timeline class="formContentGoods">
              <a-timeline-item v-for="item in trackList" :key="item.orderActionId">
                <p class="text-info">
                  {{ item.subLogType }}
                </p>
                <p
                  v-html="item.content.replaceAll('\n', '<br />')"
                  style="word-wrap: break-word; word-break: break-all; display: inline-block"
                ></p>
                <p style="color: #999999">
                  {{ item.actionTime }}
                </p>
              </a-timeline-item>
            </a-timeline>
          </div>
        </div>
        <div v-else>
          <a-row>
            <a-col :span="11">{{ $t('暂无物流') }}</a-col>
          </a-row>
        </div>
      </dd>
    </dl>
  </div>
</template>

<script>
import api from "@service/api"
import http from "@service/axios"

export default {
	props: ["order-id"],
	data() {
		return {
			trackList: [],
		}
	},
	created() {
		this.getTrack()
	},
	methods: {
		getTrack() {
			http({
				url: api.getOrderTrackingList,
				type: "post",
				data: { orderId: this.orderId }, //testData  searchData
				hasLoading: true,
				success: (res) => {
					this.trackList = res?.result?.orderRoutes
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.order-tracking-detail {
  float: left;
  width: 100%;
  font-size: 12px;
}

.order-tracking-detail dl {
  margin-bottom: 0px;
}

.order-tracking-detail dt {
  font-size: 14px;
  color: #555;
  margin-top: 4px;
  margin-bottom: 2px;
  float: left;
}

.order-tracking-detail dd {
  padding: 10px 0px 10px 0px;
  background-color: $white;
  width: 100%;
  color: #666;
  float: left;
}

.not-editable {
  border: 0px;
  background-color: $white;
}

.steps-content {
  padding: 40px 30px;
}

.formContentGoods {
  /* padding: 10px 15px; */
  margin-bottom: 16px;
  background-color: white;
  color: #333333;
  font-size: 14px;
}

p {
  margin: 0;
}
</style>
