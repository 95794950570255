<template>
  <div>
    <component :is="'overlay'" v-show="showOverlay" :on-click="cancelNoteAdd"></component>

    <a-modal
      :visible="showModal"
      :title="$t(`添加备注内容`)"
      :ok-text="$t(`确认`)"
      :cancel-text="$t(`取消`)"
      :confirmLoading="loading"
      @ok="handlerClick"
      @cancel="cancelNoteAdd"
    >
      <!-- 标记 -->
      <div v-if="colorArr.length > 0" class="form-group">
        <label class="col-md-2 control-label" style="padding-top: 4px">{{ $t(`标记`) }}：</label>
        <div class="col-md-10 control-label no-padding" style="text-align: left">
          <block v-for="(item, index) in colorArr" :key="index">
            <input
              type="radio"
              :value="item.id"
              class="radio-inline"
              name="color"
              v-model="color"
            />
            <span @click="clickColor(index)" style="margin-right: 8px">
              <a-icon :style="'color:' + item.color" type="pie-chart" />
            </span>
          </block>
        </div>
      </div>
      <!-- 文本备注内容 -->
      <div class="form-group">
        <a-textarea :cols="cols" :rows="rows" v-model="note"></a-textarea>
      </div>
      <div v-if="addNote" class="form-group">
        <div class="col-md-12" style="text-align: left">{{ addNote }}</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import debounce from "@common/debounce"
import overlay from "../component/overlay"

export default {
	data() {
		return {
			showModal: true,
			note: "",
			color: "",
			colorArr: [],
		}
	},
	props: {
		showOverlay: {
			type: Boolean,
			default: true,
		},
		width: {
			type: String,
			default: "400px",
		},
		cols: {
			type: Number,
			default: 30,
		},
		rows: {
			type: Number,
			default: 5,
		},
		canEmpty: {
			type: Boolean,
			default: false,
		},
		addNote: {
			type: String,
			default: "",
		},
		head: {
			type: String,
			default: "添加备注内容",
		},
		colors: {
			type: Array,
			// default: [],
		},
		defaultColor: {
			default: "",
		},
		defaultNote: {
			type: String,
			default: "",
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		this.color = this.defaultColor
		if (this.colors) {
			this.colorArr = this.colors
		}
		if (this.defaultNote) {
			this.note = this.defaultNote
		}
	},
	computed: {
		style() {
			return {
				width: this.width,
			}
		},
	},
	methods: {
		clickColor(index) {
			this.color = this.colorArr[index].id
		},
		cancelNoteAdd() {
			this.$emit("cancel", false)
		},
		handlerClick: debounce(function () {
			if (!this.canEmpty && this.note === "") {
				this.$success(this.$t("备注内容不能为空"), {
					time: 2000,
				})
			} else {
				this.$emit("confirm", {
					note: this.note,
					color: this.color,
				})
			}
		}),
	},
	components: { overlay },
}
</script>

<style scoped lang="scss">
.note-add-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: $white;
  margin: 60px auto;
  float: none;
  opacity: 1;
  padding: 20px 30px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90%;
  z-index: 1002;
  margin-top: 10%;
  border-radius: 2px;
}
.note-add-modal label {
  padding-left: 0px;
  padding-right: 0px;
  text-align: left;
}
.btn-cancel {
  border-color: #367fa9;
  color: #367fa9;
}
.color-box {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 1px;
  background-color: red;
  margin-left: 10px;
  cursor: pointer;
}
.selected {
  border: 2px solid #ccc;
}
.btns {
  text-align: center;
}
.logBtn {
  display: inline-block;
  padding: 3px 15px;
  cursor: pointer;
  margin: 10px 0;
}
.trueBtn {
  color: #0066ff;
  border: 1px solid #0066ff;
  margin-left: 30px;
}
.falseBtn {
  color: #ff0000;
  border: 1px solid #ff0000;
}
.btn {
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 30px;
}
.head {
  font-weight: bold;
  font-size: 14px;
}
</style>
